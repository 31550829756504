import { RouteRecordRaw } from 'vue-router';
import dashboardRoutes from 'retailer/modules/dashboard/routes/dashboard.routes';
import downloadsRoutes from 'retailer/modules/downloads/routes/downloads.routes';
import editorRoutes from 'retailer/modules/editor/routes/editor.routes';
import modulesRoutes from 'retailer/modules/modules/routes/modules.routes';
import categoryRoutes from 'retailer/modules/categories/routes/categories.routes';
import productsRoutes from 'retailer/modules/products/routes/products.routes';
import tasksRoutes from 'retailer/modules/tasks/routes/tasks.routes';
import settingsRoutes from 'retailer/modules/settings/routes/settings.routes';
import userRoutes from 'retailer/modules/users/routes/users.routes';
import datamodelRoutes from 'retailer/modules/datamodels/routes/datamodels.routes';
import importRoutes from 'retailer/modules/import/routes/import.routes';
import toolsRoutes from 'retailer/modules/tools/routes/tools.routes';
import { getExportRetailerRoutes } from 'retailer/modules/export/routes/export.routes';
import mappingsRoutes from 'retailer/modules/mappings/routes/mappings.routes';

export async function getRetailerRoutes(): Promise<RouteRecordRaw[][]> {
  return [
    dashboardRoutes,
    categoryRoutes,
    await getExportRetailerRoutes(),
    await productsRoutes(),
    modulesRoutes,
    downloadsRoutes,
    editorRoutes,
    tasksRoutes,
    settingsRoutes,
    userRoutes,
    datamodelRoutes,
    importRoutes,
    toolsRoutes,
    mappingsRoutes,
  ];
}
