<script setup lang="ts">
import useFormValidation from 'composables/form-validation';
import { onMounted, ref } from 'vue';
import EditTemplateCard from 'admin/modules/templates/components/edit-template-card.vue';
import TemplateTable from 'admin/modules/templates/components/template-table.vue';

import CrudButtons from '@/general/ui/components/crud-buttons/crud-buttons.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { UpdateButton } from '@/general/ui/components/crud-buttons/ts/classes/update-crud-button.class';
import TemplateService from 'admin/modules/templates/ts/services/template.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Template } from 'platform-unit2-api/templates';
import { useRoute } from 'vue-router';
import { isPositive } from '@/general/utils/isPositive';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Composables */
const { resetFormErrors, parseFormError } = useFormValidation();
const route = useRoute();

/** Services */
const templateService = new TemplateService();
const ts = new TranslationService('admin', 'templates');
const toastService = ToastService.getInstance();

/** Constants */
const isTemplateCreated = ref(false);
const importedTemplate = ref<Template>();
const manualTemplate = ref<Template>();

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.LEFT,
  onClick: () => {
    emit('hide');
  },
});

const updateButton = new UpdateButton({
  label: ts.updateButton(),
  position: CrudButtonPosition.CENTER,
  onClick: () => handleUpdate(importedTemplate.value || manualTemplate.value),
});

const crudButtonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, updateButton],
});

const setTemplate = ($event: Template) => {
  importedTemplate.value = $event;
  isTemplateCreated.value = true;
};

const setLoadingState = (isUploading: boolean) => {
  crudButtonsOptions.value.saving = isUploading;
};

const changeTemplateName = (templateName: string) => {
  if (importedTemplate.value != null) {
    importedTemplate.value.name = templateName;
  }

  if (manualTemplate.value != null) {
    manualTemplate.value.name = templateName;
  }
};

const handleUpdate = (template?: Template) => {
  crudButtonsOptions.value.saving = true;
  resetFormErrors();
  if (template != undefined) {
    templateService
      .saveChangesToTemplate(template)
      .then(() => {
        toastService.displaySuccessToast(ts.updateSuccess());
      })
      .catch((err) => {
        parseFormError(err, () => {
          toastService.displayErrorToast(ts.updateFailed());
        });
      });
  }

  crudButtonsOptions.value.saving = false;
};

onMounted(async () => {
  if (isPositive(route.params.id)) {
    await templateService.getTemplate(Number(route.params.id)).then((template) => {
      if (template?.reference) {
        importedTemplate.value = template;
        return;
      }

      manualTemplate.value = template;
      return;
    });
  }
});
</script>
<template>
  <div class="relative">
    <div class="ml-5">
      <h3 class="font-bold mb-1 text-2xl">
        {{ ts.moduleUpdateTitle }}
      </h3>
      <p class="mb-5 text-lg">
        {{ ts.moduleUpdateSubTitle }}
      </p>
    </div>

    <div style="background-color: white" class="h-20rem"></div>
    <p-divider class="m-0 p-0" />
    <div style="background-color: rgb(245, 247, 250)" class="h-15rem"></div>

    <EditTemplateCard
      class="absolute ml-5"
      style="top: 5rem; width: 50%"
      :template="manualTemplate || importedTemplate"
      @template:created="setTemplate($event)"
      @change:template-name="changeTemplateName"
      @uploading="setLoadingState"
    />
  </div>

  <div>
    <TemplateTable :template="importedTemplate" :manual-template="manualTemplate" />
  </div>

  <CrudButtons class="bg-white bottom-0 p-3 sticky" :options="crudButtonsOptions" />
</template>
