import { IntergrationsRestService } from 'platform-unit2-api/intergrations';
import {
  ModulesRestService,
  IntegrationField,
  ModuleDetail,
  UpdateModuleRequest,
} from 'platform-unit2-api/modules';
import { BaseViewService } from '../view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import { EncryptedField } from '@/platforms/retailer/modules/modules/ts/interfaces/encrypted-field.interface';
import { isPositive } from '@/general/utils/isPositive';

export class ModulesViewService extends BaseViewService<
  ModulesRestService,
  ModuleDetail,
  {},
  UpdateModuleRequest & { id: number }
> {
  public credentialValues: Record<string, any> = { dynamic_settings: {} };
  public encryptedFields?: EncryptedField[];

  public get ts() {
    return this._ts;
  }

  public get isSearchResultEmpty() {
    return this.modules.length === 0 && this._fetchVariables.query !== '';
  }

  public get hasNoModules() {
    return this.modules.length === 0 && this.query === '';
  }

  /**
   * @inheritdoc
   */
  constructor(ts: TranslationService) {
    super({
      Api: ModulesRestService,
      ts: ts,
      fetchAllFunction: 'getAll',
      overviewRouteName: 'modules',
      createRouteName: '',
      updateRouteName: 'new-credentials',
      confirmPopUpGroup: 'modules',
    });
  }

  /**
   * Get modules.
   */
  public get modules(): ModuleDetail[] {
    return this._data;
  }

  //#region Edit cridentials view

  public isFieldHidden(field: IntegrationField): boolean {
    if (field?.hidden_when) {
      for (const [key, values] of Object.entries(field?.hidden_when)) {
        if (values.includes(this.credentialValues.dynamic_settings[key])) {
          return true;
        }

        if (values.includes(this.credentialValues[key])) {
          return true;
        }
      }
    }

    return false;
  }

  protected _get(id: number): void {
    this._isLoadingCrudComponent = true;

    this._restService
      .get(id)
      .then((object) => {
        if (!this.isCreating) {
          this.current = object;

          // Initialize CredentialValues
          this.current?.settings_fields?.fields?.forEach((field: IntegrationField) => {
            if (this.current?.settings != null) {
              this.credentialValues[field.label] = this.current?.settings[field.label];
            } else {
              this.credentialValues[field.label] = undefined;
            }
          });
          (this.current?.settings_fields?.dynamic_settings ?? []).forEach(
            (field: IntegrationField) => {
              this.credentialValues.dynamic_settings[field.label] =
                this.current?.settings?.dynamic_settings?.[field.label as any];
            },
          );

          // Initialize EncryptedFields
          this.encryptedFields =
            (this.current?.settings_fields?.fields ?? [])
              .concat(this.current?.settings_fields?.dynamic_settings ?? [])
              .filter((field: IntegrationField) => field.encrypted)
              .map((field: IntegrationField) => ({
                fieldName: field.label,
                visible: false,
              })) ?? [];
        }
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this.closeCrudComponent();
      })
      .finally(() => {
        this._isLoadingCrudComponent = false;
      });
  }

  public findFieldByFieldName(fieldName: string): IntegrationField | undefined {
    if (!this.current?.settings_fields) {
      return;
    }

    const dynamicSettingsField = this.current?.settings_fields['dynamic_settings']?.find(
      (field: IntegrationField) => field.label === fieldName,
    );

    if (dynamicSettingsField && !this.isFieldHidden(dynamicSettingsField)) {
      return dynamicSettingsField;
    }

    const regularField = this.current?.settings_fields['fields']?.find(
      (field: IntegrationField) => field.label === fieldName,
    );

    if (regularField && !this.isFieldHidden(regularField)) {
      return regularField;
    }

    return;
  }

  public getFieldLabel(fieldName: string): string {
    const field = this.findFieldByFieldName(fieldName);
    return field?.label_text ?? field?.label ?? '';
  }

  public goToEndpoint(fieldName: string) {
    const endpoint = this.findFieldByFieldName(fieldName)?.endpoint ?? '';
    if (isPositive(this._route.params.id)) {
      new IntergrationsRestService()
        .authorizeUser(parseInt(this._route.params.id as string, 10), endpoint)
        .then((res) => window.open(res))
        .catch((err: any) => this._toastService.displayErrorToast(err.response.data));
    }
  }

  public isEncrypted(fieldName: string): EncryptedField | undefined {
    return this.encryptedFields?.find((field) => field.fieldName === fieldName);
  }

  // #endregion

  public createBody(): {} | undefined {
    return;
  }

  public updateBody(): (UpdateModuleRequest & { id: number }) | undefined {
    const removeEmptyValues = Object.entries(this.credentialValues)
      .filter(([_, value]) => typeof value !== 'object')
      .filter(([_, value]) => typeof value === 'string' && value.trim())
      .reduce((ac, [key, value]) => ({ ...ac, [key]: value }), {});

    const removeEmptyValuesDynamicSettings = Object.entries(this.credentialValues.dynamic_settings)
      .filter(([_, value]) => typeof value !== 'object')
      .filter(([_, value]) => typeof value === 'string' && value.trim())
      .reduce((ac, [key, value]) => ({ ...ac, [key]: value }), {});

    const request: UpdateModuleRequest & { id: number } = {
      id: Number(this._route.params.id),
      name: this.partialObject.name,
      key: this.partialObject.key,
      type: this.partialObject.type,
      template_id: this.partialObject.template?.id,
      retailer_id: this.partialObject.retailer?.id,
      filename_template: this.partialObject.filename_template,
      media_template: this.partialObject.media_template,
      media_instructions: this.partialObject.media_instructions,
      file_per_product: this.partialObject.file_per_product,
      export_resize_enabled: this.partialObject.export_resize_enabled,
      settings: { ...removeEmptyValues, dynamic_settings: removeEmptyValuesDynamicSettings },
    };

    if (this.partialObject.export_resize_enabled === true) {
      request.export_resize_width = this.partialObject.export_resize_width;
      request.export_resize_height = this.partialObject.export_resize_height;
      request.export_resize_format = this.partialObject.export_resize_format;
    }

    return request;
  }

  public get validated(): boolean {
    return true;
  }

  public closeCrudComponent(): void {
    super.closeCrudComponent();
    this.credentialValues = { dynamic_settings: {} };
  }
}
