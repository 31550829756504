/**
 * @description A class to represent a path in a nested object
 * keeps track of the set path and the modifaction of the path.
 * @example const path = new FieldPath('1.2.3');
 * path.toString(); // '1.2.3'
 * path.path; // [1, 2, 3]
 * @example const path = new FieldPath();
 * path.toString(); // ''
 * path.path; // []
 */
export class FieldPath {
  /**
   * An representation of the given path as an array of numbers
   */
  public path: number[];

  /**
   * Creates a new instance of FieldPath,
   * if a path is given, it will be split by '.' and converted to an array of numbers
   * else, an empty array will be set
   * @param path - The path to be set
   */
  constructor(path?: string | null) {
    if (path) {
      const parts = path.split('.');
      this.path = parts.map(Number);
    } else {
      this.path = [];
    }
  }

  /**
   * @description Returns the path as a string
   * @returns  The path as a string
   */
  public toString(): string {
    if (this.path.length === 0) {
      return '';
    }

    return this.path.join('.');
  }

  /**
   * sets the last index to the given index,
   * a path can only modify the last index
   * @param index  The index to be set
   * @returns the FieldPath instance
   */
  public setPathIndex(index: number): FieldPath {
    this.path[this.path.length - 1] = index;
    return this;
  }

  /**
   * @returns the last index of the path
   */
  public getPathIndex(): number {
    return this.path[this.path.length - 1];
  }

  /**
   * determines the current level of the path
   * this represents how deep the object is compared with the root.
   * @example const path = new FieldPath('1.2.3');
   * path.currentLevel(); // 2
   * //this means that the field is 2 levels deep shown in the renderer
   * @returns the current level of the path
   */
  public currentLevel(): number {
    return this.path.length - 1;
  }

  /**
   * changes the level of the path to the given level and sets the index to the given index
   * @example const path = new FieldPath('1.2.3');
   * path.toString(); // '1.2.3'
   * path.changeLevelIndex(1, 4);
   * path.toString(); // '1.4.3'
   * @param level  The level to be changed
   * @param index  The index to be set
   * @returns the FieldPath instance
   */
  public changeLevelIndex(level: number, index: number): FieldPath {
    this.path[level] = index;
    return this;
  }

  /**
   * @description moves to the next level of the path
   * @emample const path = new FieldPath('1.2.3');
   * path.toString(); // '1.2.3'
   * path.nextLevel(4);
   * path.toString(); // '1.2.3.4
   * @param index  The index to be set
   * @returns the FieldPath instance
   */
  public nextLevel(index: number) {
    const newPath = new FieldPath();
    newPath.path = [...this.path, index];
    return newPath;
  }

  /**
   * get an new instance of FieldPath with the given level
   * @example const path = new FieldPath('1.2.3');
   * path.toString(); // '1.2.3'
   * path.getLevel(1).toString(); // '1'
   * @param level  The level to be set
   * @returns  The FieldPath instance
   */
  public getLevel(level: number): FieldPath {
    const curPath = this.path.slice(0, level);
    return FieldPath.from(curPath);
  }

  /**
   *  Creates a new instance of FieldPath from the given path
   * @param path  The path to be set
   * @returns  The FieldPath instance
   */
  public static from(path: number[]): FieldPath {
    const newPath = new this();
    newPath.path = path;
    return newPath;
  }
}
