<script setup lang="ts">
import { inject, ref } from 'vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { BaseFieldService } from '@/general/services/attribute-fields/field-services/base-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.v2.vue';

/** Props */
interface Props {
  attributeField: AttributeField<string | undefined>;
  productAttributeField: ProductAttributeField<string | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const baseFieldService = ref(
  new BaseFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="baseFieldService">
    <pInputText
      v-model="baseFieldService.productAttributeFieldValue"
      :disabled="baseFieldService.productAttributeField.locked"
      class="w-full"
      :class="baseFieldService.getErrorTypeClass()"
      :placeholder="baseFieldService.ts.tGlobal('placeholder')"
      type="text"
    />
  </WrapperField>
</template>
