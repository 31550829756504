<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import Tiptap from 'retailer/modules/editor/components/tiptap.vue';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  maxLength?: number;
  global: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  maxLength: undefined,
  global: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value?: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'labels');

/** Consts */
const inputText = ref('');

const validateData = (value?: string) => {
  if (value == null) {
    return;
  }

  const errorMessages = [];
  if (props.maxLength && inputText.value.length > props.maxLength) {
    errorMessages.push(ts.tModule('baseField.error', { params: { value: props.maxLength } }));
  }

  emit('error', errorMessages);
};

const valueUpdated = (value: string) => {
  if (value === props.modelValue) {
    return;
  }

  const val = '<p><br></p>' === value ? undefined : value?.replace('&nbsp;', ' ');

  if (val === props.modelValue) {
    return;
  }

  inputText.value = val?.replace(/<[^>]*>?/gm, '') ?? '';
  validateData(value);
  emit('update:modelValue', val);
};

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      inputText.value = props.modelValue?.replace(/<[^>]*>?/gm, '') ?? '';
    }
  },
);

onMounted(() => {
  inputText.value = props.modelValue?.replace(/<[^>]*>?/gm, '') ?? '';
});
</script>
<template>
  <div v-if="!disabled" class="border-1 border-200 editor-wrapper w-full">
    <Tiptap
      :model-value="modelValue"
      :allow-images="false"
      :allow-videos="false"
      :disabled="false"
      @update:model-value="valueUpdated"
    ></Tiptap>
  </div>
  <div v-else class="border-1 border-200 editor-wrapper w-full">
    <Tiptap
      :model-value="modelValue"
      :allow-images="false"
      :allow-videos="false"
      :disabled="true"
      @update:model-value="valueUpdated"
    ></Tiptap>
  </div>
  <span v-if="maxLength" class="bg-white min-w-max p-inputgroup-addon">
    {{ inputText ? inputText.length : 0 }} / {{ maxLength }}</span
  >
</template>
