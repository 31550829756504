<script setup lang="ts">
import ProductMeta from 'supplier/modules/products/components/product-meta.vue';
import ProductActionsGroup from 'supplier/modules/products/components/product-actions-group.vue';
import ProductStatusBar from 'supplier/modules/products/components/product-status-bar.vue';
import SidebarNavigation from 'supplier/modules/products/components/sidebar-navigation.vue';
import ProductDatamodelHeader from 'supplier/modules/products/components/product-datamodel-header.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter, RouteLocationRaw, onBeforeRouteLeave } from 'vue-router';
import { ref, onUnmounted, onMounted, watch, provide, Ref } from 'vue';
import useDirtyState from 'composables/dirty-state';
import { Product } from 'platform-unit2-api/products';
import { Datamodel, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { Locale, LocaleDetails } from 'platform-unit2-api/locales';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Attribute } from 'platform-unit2-api/attributes';
import { ProductDatamodelSelectModel } from '../ts/products.types';
import ProductDetailsValidationPanel from 'supplier/modules/products/components/validation-rules/product-details-validation-panel.vue';
import { ProductAttributesValidationService } from '@/general/services/attribute-fields/product-attribute-validation.service';
import { productAttributeValidationServiceKey } from '@/general/services/attribute-fields/service-keys';

const store = useStore();
const router = useRouter();
const route = useRoute();
const scrollListener = ref({} as HTMLElement);
const showShadow = ref(false);

const product = ref<Product>();
const loading = ref(true);
const selectedDatamodel = ref<ProductDatamodelSelectModel>();
const selectedLocales = ref<Locale[]>([]);
const filteredLocales = ref<Locale[]>([]);
const dataModelApi = new DatamodelsRestService();
const { dirtyStateDialog } = useDirtyState();
const productAttributeValidationService = ref(new ProductAttributesValidationService());

const datamodels = ref<ProductDatamodelSelectModel[]>([]);
const fields = ref<ProductField[]>([]);

provide(
  productAttributeValidationServiceKey,
  productAttributeValidationService as Ref<ProductAttributesValidationService>,
);

const getProductData = async () => {
  loading.value = true;
  try {
    await store.dispatch('products/CLEAR_PRODUCT');

    product.value = await store.dispatch('products/GET_PRODUCT_DETAILS', route.params.id);

    datamodels.value = (
      await store.dispatch('products/GET_PRODUCT_DATAMODELS', route.params.id)
    ).data;
    datamodels.value = datamodels.value.sort((datamodelA, datamodelB) =>
      ('' + datamodelA.name).localeCompare(datamodelB.name),
    );

    datamodels.value.filter((datamodel, index) => {
      if (product.value && datamodel.id == product.value.datamodel.id) {
        datamodels.value.splice(index, 1);
        datamodels.value.splice(0, 0, datamodel);
      }
    });

    await getProductDatamodelAttributes();

    /*Reorder attributes for the ODMs based on the order in customer datamodel*/
    datamodels.value.forEach((datamodel) => {
      reorderAttributesForOdm(datamodel.attributes);
    });

    /*Create the all attributes datamodel*/
    const allAttributes: Attribute[] = [];
    datamodels.value.forEach((item: Datamodel) => {
      allProductDatamodelAttributes.value.forEach((attr: Attribute) => {
        const foundAttribute = item.attributes.find((val: Attribute) => attr.id === val.id);
        if (foundAttribute) {
          allAttributes.push(foundAttribute);
        }
      });
    });

    // If 'All attributes' exists, do not add it again
    const doesAllAttributesDatamodelExists = datamodels.value.find(
      (dataModel) => dataModel.name == 'All attributes',
    );
    if (doesAllAttributesDatamodelExists == undefined) {
      const allDatamodel = ref<ProductDatamodelSelectModel>({} as ProductDatamodelSelectModel);
      allDatamodel.value!.name = 'All attributes';
      allDatamodel.value.attributes = allAttributes;
      allDatamodel.value.attributes_count = allAttributes.length;
      allDatamodel.value.hide_options = true;
      datamodels.value.unshift(allDatamodel.value);
    }

    selectedDatamodel.value = undefined;
    selectedDatamodel.value = datamodels.value[0];
  } catch (error) {
    await router.push({
      name: 'products',
    } as RouteLocationRaw);
  } finally {
    loading.value = product.value == null;
  }
};

/** Get all the attributes of the products datamodel */
const allProductDatamodelAttributes = ref<Attribute[]>([]);
const getProductDatamodelAttributes = async () => {
  if (!product.value?.datamodel.id) {
    return;
  }

  allProductDatamodelAttributes.value = (
    await dataModelApi.getDatamodelFieldDefinitions(product.value?.datamodel.id, true)
  ).data;
};

const reorderAttributesForOdm = (attributes: Attribute[]) => {
  attributes.map((attribute: Attribute) => {
    const foundAttribute = allProductDatamodelAttributes.value.find(
      (productDmAttr: Attribute) => attribute.id === productDmAttr.id,
    );
    if (foundAttribute) {
      attribute.order = foundAttribute.order;
    }
  });
  attributes.sort((attr1: Attribute, attr2: Attribute) =>
    attr1.order ?? 0 > (attr2.order ?? 0) ? 1 : -1,
  );
};

const getFieldData = async () => {
  fields.value = [];
  try {
    fields.value = await store.dispatch('products/GET_PRODUCT_FIELDS', {
      productId: route.params.id,
      locales: selectedLocales.value.map((locale) => locale.id),
    });

    selectedLocales.value = [
      ...new Map(
        fields.value.map((field: ProductField) => [JSON.stringify(field.locale), field.locale]),
      ).values(),
    ] as LocaleDetails[];

    filteredLocales.value = selectedLocales.value;
    filteredLocales.value = selectedLocales.value;
    if (selectedLocales.value.length <= 0) {
      selectedLocales.value = [store.getters['users/currentUser'].locale];
    }
  } catch (error) {
    await router.push({
      name: 'products',
    } as RouteLocationRaw);
  } finally {
    loading.value = product.value == null;
  }
};

const selectDatamodel = async (datamodel: ProductDatamodelSelectModel) => {
  selectedDatamodel.value = datamodel;
};

/** Search in attributes */
const searchedWord = ref<string>();
const setSearchedWord = async (searchedword: string) => {
  searchedWord.value = searchedword;
};

watch(
  () => route,
  async () => {
    selectedLocales.value = [];

    if (route.name === 'product-details') {
      router.push({
        name: 'product-attributes',
      });
    }

    if (route.name === 'product-attributes' || route.name === 'product-details') {
      await getProductData();
      await getFieldData();
    }

    if (route.name === 'product-assets' || route.name === 'product-story') {
      selectedDatamodel.value = undefined;
    }
  },
  {
    deep: true,
  },
);

watch(
  () => selectedLocales.value,
  async () => {
    filteredLocales.value = selectedLocales.value;
  },
);

onBeforeRouteLeave((_, __, next) => {
  dirtyStateDialog(() => {
    return;
  }, next);
});

onMounted(async () => {
  await getProductData();
  await getFieldData();
  scrollListener.value?.addEventListener(
    'scroll',
    () => (showShadow.value = scrollListener.value.scrollTop !== 0),
  );
});

onUnmounted(() =>
  scrollListener.value?.removeEventListener(
    'scroll',
    () => (showShadow.value = scrollListener.value.scrollTop !== 0),
  ),
);

// Pass reload function to deep child
provide('loadAsync', getProductData);
provide('loadFields', getFieldData);
provide('loadingProduct', loading);
</script>
<template>
  <section class="flex flex-column h-full">
    <div class="flex flex-column h-full justify-content-around overflow-hidden">
      <div class="flex h-full overflow-hidden">
        <!-- Navigatoin -->
        <SidebarNavigation
          class="h-full pl-3"
          :loading="loading"
          :datamodels="datamodels"
          :selected-datamodel="selectedDatamodel"
          :fields="fields"
          @set-selected-datamodel="(event: Datamodel) => selectDatamodel(event as ProductDatamodelSelectModel)"
          @searched-word="setSearchedWord"
        />
        <pCard class="m-3 overflow-y-auto p-0 text-left w-full">
          <template #content>
            <div
              ref="scrollListener"
              class="bg-white flex justify-content-between p-4 sticky top-0 z-3"
              :class="{ 'shadow-2 transition-duration-300': showShadow }"
              role="heading"
            >
              <ProductMeta />
              <ProductActionsGroup />
            </div>
            <div class="p-4">
              <!-- DATAMODEL HEADER -->
              <ProductDatamodelHeader
                :datamodel="selectedDatamodel"
                :fields="fields"
                :locales="selectedLocales"
                :filtered-locales="filteredLocales"
                :is-loading="loading"
                @update:filtered-locales="(value) => (filteredLocales = value)"
                @update:locales="(value) => (selectedLocales = value)"
              />
              <router-view
                class="pt-3"
                :selected-datamodel="selectedDatamodel"
                :fields="fields"
                :locales="selectedLocales"
                :filtered-locales="filteredLocales"
                :loading="loading"
                :product="product"
                :searched-word="searchedWord"
              />
            </div>
          </template>
        </pCard>

        <!-- Validation Panel-->
        <ProductDetailsValidationPanel />
      </div>

      <ProductStatusBar @refresh="getFieldData()" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
div[role='heading'] {
  color: $primary-text-color;
}
</style>
