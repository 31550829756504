//Types
import { PreferencesService } from '@/general/services/preferences/preferences.service';

/**
 * AppService holds all the logic needed for the initial load of the platform
 */
export class AppService {
  //#region Singleton
  private static _instance: AppService;

  public get instance(): AppService {
    return AppService._instance;
  }
  //#endregion

  private _cleanUpStorage() {}

  constructor() {
    if (AppService._instance) {
      return AppService._instance;
    }

    // Migrate (remove) old storage keys
    this._cleanUpStorage();
    new PreferencesService();

    AppService._instance = this;
  }
}
