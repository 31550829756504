<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import useFormValidation from 'composables/form-validation';

import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import ColorPicker from 'ui/components/color-picker.vue';

import { Client } from 'platform-unit2-api/clients';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { Retailer, UpdateRetailerRequest } from 'platform-unit2-api/retailers';
import { Thumbnail } from 'platform-unit2-api/thumbnails';
import { RetailersRestService } from 'platform-unit2-api/retailers';
import { isNegative } from '@/general/utils/isNegative';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';

/** emits */
const emit = defineEmits(['hide', 'refresh']);

/** Composables */
const route = useRoute();
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'retailers');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const retailerApi = new RetailersRestService();

/** Find retailer details to edit */
const loading = ref<boolean>(false);
const editorRetailer = ref<Retailer>();
const thumbnailValue = ref<Thumbnail>();

const findRetailer = async (): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.getFailed());
  }

  loading.value = true;

  try {
    editorRetailer.value = await retailerApi.get(Number(route.params.id));

    if (editorRetailer?.value?.color) {
      editorRetailer.value.color = editorRetailer?.value?.color?.replace('#', '');
    }

    thumbnailValue.value = editorRetailer.value?.thumbnail;
  } catch (err) {
    toastService.displayErrorToast(ts.getFailed());
  } finally {
    loading.value = false;
  }
};

/** Submit the update */
const saving = ref<boolean>(false);

const handleSubmit = async (): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.updateFailed(editorRetailer.value?.name));
    return;
  }

  resetFormErrors();
  try {
    saving.value = true;
    const retailer: UpdateRetailerRequest = {
      id: editorRetailer.value?.id ?? 0,
      email: editorRetailer.value?.email ?? '',
      name: editorRetailer.value?.name ?? '',
      thumbnail_id: thumbnailValue.value?.id ?? null,
      color: editorRetailer.value?.color ?? '',
      client_ids: editorRetailer.value?.clients?.map((client: Client) => client.id) ?? [],
    };

    await retailerApi.update(Number(route.params.id), retailer);

    emit('refresh');
    emit('hide');
    toastService.displaySuccessToast(ts.updateSuccess(editorRetailer.value?.name));
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.updateFailed(editorRetailer.value?.name));
    });
  } finally {
    saving.value = false;
  }
};

const setColor = (event: string | undefined) => {
  if (event == null || editorRetailer.value == null) {
    return;
  }

  if (editorRetailer.value != null) {
    const color = event.replace('#', '');
    editorRetailer.value.color = color;
  }
};

/** Delete retailer */
const deleteRetailer = async (id: number): Promise<void> => {
  loading.value = true;

  try {
    await retailerApi.delete(id);

    toastService.displaySuccessToast(ts.deleteSuccess(editorRetailer.value?.name ?? ''));

    emit('hide');
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed(editorRetailer.value?.name ?? ''));
  } finally {
    emit('refresh');
    loading.value = false;
  }
};

const confirmRetailerDelete = (event: PointerEvent, id?: number) => {
  if (id == null) {
    return;
  }

  confirmService.confirmDelete({
    event: event,
    group: 'retailer-edit',
    message: ts.deleteConfirm(editorRetailer.value?.name ?? ''),
    callback: () => deleteRetailer(id),
  });
};

/** onMounted lifecycle hook */
onMounted((): void => {
  findRetailer();
});
</script>
<template>
  <div class="h-full">
    <SidebarSkeleton v-if="loading" />
    <CrudSidebar
      v-else
      :title="ts.moduleUpdateTitle"
      :subtitle="ts.moduleUpdateSubTitle"
      :saving="saving"
      @cancel="emit('hide')"
      @save="handleSubmit()"
      @delete="confirmRetailerDelete($event, editorRetailer?.id)"
    >
      <template #sidebar-data>
        <!-- Name -->
        <div class="field mb-3">
          <label for="name">{{ ts.tForms('name') }}</label>
          <p-input-text
            v-if="editorRetailer"
            id="name"
            v-model="editorRetailer.name"
            class="w-full"
            :class="{ 'p-invalid': hasError('name') }"
            type="text"
          />
          <small
            v-if="hasError('name')"
            :class="{ 'p-error block': hasError('name') }"
            class="hidden"
            >{{ fieldErrorMessage('name').toString() }}</small
          >
        </div>
        <!-- Email -->
        <div class="field mb-3">
          <label for="email">{{ ts.tGlobal('email') }}</label>
          <p-input-text
            v-if="editorRetailer"
            id="email"
            v-model="editorRetailer.email"
            class="w-full"
            :class="{ 'p-invalid': hasError('email') }"
            type="email"
          />
          <small
            v-if="hasError('email')"
            :class="{ 'p-error block': hasError('email') }"
            class="hidden"
            >{{ fieldErrorMessage('email').toString() }}</small
          >
        </div>
        <!-- Logo -->
        <div class="field mb-3">
          <label for="logo">{{ ts.tGlobal('logo') }}</label>
          <div class="align-items-center flex formgroup-inline">
            <ThumbnailUploader
              v-if="editorRetailer"
              :thumbnail="editorRetailer.thumbnail"
              @uploaded="(value) => (thumbnailValue = value)"
            />
            <ThumbnailUploader
              v-else
              :thumbnail="thumbnailValue"
              @uploaded="(value) => (thumbnailValue = value)"
            />
          </div>
        </div>
        <!-- Color -->
        <div class="field flex-row mb-3">
          <label for="color">{{ ts.tGlobal('color') }}</label>
          <ColorPicker
            v-if="editorRetailer"
            id="color"
            :model-value="editorRetailer.color"
            @update:model-value="(value: string | undefined) => setColor(value) "
          />
        </div>
        <!-- Clients -->
        <div class="field">
          <ClientSelect
            v-if="editorRetailer"
            v-model="editorRetailer.clients"
            multiselect
            :class="{ 'p-invalid': hasError('client_ids') }"
          />
          <small
            v-if="hasError('client_ids')"
            :class="{ 'p-error block': hasError('client_ids') }"
            class="hidden"
            >{{ fieldErrorMessage('client_ids').toString() }}</small
          >
        </div>
        <p-confirm-popup group="retailer-edit" />
      </template>
    </CrudSidebar>
  </div>
</template>
