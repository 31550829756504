import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import FieldsView from 'admin/modules/fields/views/fields.overview.view.vue';
import { RouteRecordRaw } from 'vue-router';

const fieldsRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/fields',
    name: 'fields',
    component: FieldsView,
    translations: {
      module: 'fields',
      platform: 'admin',
    },
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-field',
        title: 'Fields',
        rights: [FeaturePermission.MANAGE_FIELDS],
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'edit-field',
        title: 'Fields',
        rights: [FeaturePermission.MANAGE_FIELDS],
      }),
    ],
    title: 'Fields',
    rights: [FeaturePermission.MANAGE_FIELDS],
  }),
];

export default fieldsRoutes;
