<script setup lang="ts">
import { ref, onMounted } from 'vue';
import store from '@/core/store/index';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CreateExport } from '../../ts/products.types';

/** Props */
interface Props {
  formData: CreateExport;
}
const props = defineProps<Props>();
/** Emits */
const emit = defineEmits<{
  (
    e: 'nextPage',
    pageObject: {
      pageIndex: number;
      mailTo: string[];
      customMessage: string;
    },
  ): void;
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

//** Constants */
const mailTo = ref<string[]>([]);
const customMessage = ref('');
const retailerEmail = ref<string | undefined>('');

const nextPage = () => {
  emit('nextPage', {
    pageIndex: 3,
    mailTo: mailTo.value,
    customMessage: customMessage.value,
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 3,
  });
};

const addToList = (newEmail?: string) => {
  if (newEmail == null) {
    return;
  }

  !mailTo.value.includes(newEmail) && mailTo.value.push(newEmail);
};

const isInList = (newEmail: string) => {
  return mailTo.value.includes(newEmail);
};

onMounted(() => {
  if (props.formData.mailTo) {
    mailTo.value = props.formData.mailTo;
  }

  if (props.formData.customMessage) {
    customMessage.value = props.formData.customMessage;
  }

  const selectedRetailerEmail = props.formData.selectedModule.retailer?.email;
  retailerEmail.value = selectedRetailerEmail;

  //If it's in user preference to autofill retailer email
  store.getters['users/currentUser'].preferences?.autofill_retailer_details &&
    selectedRetailerEmail &&
    mailTo.value.push(selectedRetailerEmail);
});
</script>
<template>
  <div class="mt-5">
    <div class="field mb-3 px-4">
      <label class="font-bold">{{ ts.tModule('export_modal.contact.title') }}</label>

      <div class="mb-2">
        {{ ts.tModule('export_modal.contact.retailer_email') }}
        <span
          v-if="retailerEmail"
          v-tooltip.right="
            !isInList(retailerEmail)
              ? {
                  value: ts.tModule('exports.modals.click_to_add'),
                  disabled: false,
                  class: 'text-sm',
                }
              : { disabled: true }
          "
          :class="`inline-block pr-2 ${
            isInList(retailerEmail)
              ? 'text-secondary'
              : 'text-primary retailer-email  cursor-pointer'
          }`"
          @click="addToList(retailerEmail)"
        >
          {{ retailerEmail }}
        </span>
      </div>

      <p-chips
        v-model="mailTo"
        :max="10"
        :placeholder="ts.tModule('export_modal.contact.email_to')"
        :add-on-blur="true"
        class="w-full"
      />
      <small :class="{ help: true, counter: true }"> {{ `${mailTo.length}/10` }} </small>
    </div>
    <div class="field mb-3 px-4">
      <p-textarea
        v-model="customMessage"
        class="w-full"
        rows="5"
        cols="60"
        :placeholder="ts.tModule('export_modal.contact.custom_message')"
      />
    </div>
    <div
      class="border-round-bottom-md flex flex-row justify-content-between px-4 py-4"
      style="background-color: #f5f7fa"
    >
      <div>
        <p-button
          :label="ts.tGlobal('cancel')"
          plain
          text
          class="mr-2"
          @click="emit('cancel-export')"
        />
      </div>
      <div>
        <p-button
          :label="ts.tGlobal('previous')"
          severity="secondary"
          class="mr-2"
          icon="mdi mdi-chevron-left"
          icon-pos="left"
          @click="previousPage()"
        />
        <p-button
          :label="ts.tGlobal('next')"
          :disabled="!mailTo"
          icon="mdi mdi-chevron-right"
          icon-pos="right"
          @click="nextPage"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-chips :deep(.p-chips-multiple-container) {
  width: 100%;
}

.retailer-email:hover {
  text-decoration: underline;
}
</style>
