<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { OrganisationRestService, Organisation } from 'platform-unit2-api/organisations';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<Organisation>>();
const organisationRestService = OrganisationRestService;
</script>
<template>
  <BaseSelect
    :rest-service="organisationRestService"
    option-label="name"
    class="w-full"
    module-name="organisation"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
