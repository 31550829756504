<script setup lang="ts">
//Core
import { ref, watchEffect } from 'vue';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { MappingService } from 'supplier/modules/mappings/services/mappings.service';

//Components
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import MappingDuplicateWarningModal from 'supplier/modules/mappings/components/mapping-duplicate-warning-modal.vue';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

//Types
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Module } from 'platform-unit2-api/modules';
import { MappingType } from 'supplier/modules/mappings/ts/enums/mapping-type.enum';

interface Props {
  visible: boolean;
  selectedDatamodel: Datamodel;
  selectedModule: Module;
  mappingType?: MappingType;
}
/** Props */
const props = withDefaults(defineProps<Props>(), {
  visible: false,
  mappingType: MappingType.EXPORT,
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

const ts = new TranslationService('supplier', 'mappings');
const mappingService = ref(new MappingService(emit));

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const duplicateButton = new CreateButton({
  label: ts.tGlobal('duplicate'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !mappingService.value.isDuplicateButtonDisabled(),
  onClick: () => {
    const payloadIsValid =
      mappingService.value.selectedClientToDuplicate?.id != null &&
      mappingService.value.selectedDatamodelToDuplicate?.id != null &&
      mappingService.value.selectedModuleToDuplicate?.id != null;

    if (payloadIsValid) {
      mappingService.value.duplicate({
        type: props.mappingType,
        payload: {
          clientId: mappingService.value.selectedClientToDuplicate!.id,
          datamodelId: mappingService.value.selectedDatamodelToDuplicate!.id,
          moduleId: mappingService.value.selectedModuleToDuplicate!.id,
          sourceDatamodelId: props.selectedDatamodel.id,
          sourceModuleId: props.selectedModule.id,
        },
      });
    }
  },
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, duplicateButton],
});

watchEffect(() => {
  !props.visible && mappingService.value.clearDuplicationForm();
});
</script>
<template>
  <BaseDialog
    :visible="visible"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="emit('hide')"
  >
    <template #header>
      <div>
        <h2 class="mb-2 text-800">
          {{
            ts.tModule('duplicate_mappings.modal_header', {
              params: { type: mappingType },
            })
          }}
        </h2>
        <p class="text-600">{{ ts.tModule('duplicate_mappings.modal_description') }}</p>
      </div>
    </template>

    <!-- Workspace -->
    <div class="field mb-3">
      <label class="block mb-1">{{ ts.tGlobal('workspace') }}</label>
      <p-dropdown
        v-model="mappingService.selectedClientToDuplicate"
        :options="mappingService.userWorkspaces"
        class="w-full"
        option-label="name"
        data-key="id"
        :filter="true"
        :placeholder="ts.tModule('duplicate_mappings.modal_client_placeholder')"
      />
    </div>

    <!-- Data model -->
    <div class="field mb-3">
      <DatamodelSelect
        v-model="mappingService.selectedDatamodelToDuplicate"
        :workspace-id="mappingService.selectedClientToDuplicate?.id"
        :disabled="!mappingService.selectedClientToDuplicate"
      />
    </div>

    <!-- Channel -->
    <div class="field mb-3">
      <ModuleSelect
        v-model="mappingService.selectedModuleToDuplicate"
        :workspace-id="mappingService.selectedClientToDuplicate?.id"
        :type="mappingType"
        :disabled="!mappingService.selectedDatamodelToDuplicate"
      />
    </div>
  </BaseDialog>

  <!-- If the mapping already exists -->
  <MappingDuplicateWarningModal
    :type="mappingType"
    :visible="mappingService.duplicateWarningDialogVisible"
    :client-to-duplicate="mappingService.selectedClientToDuplicate"
    :datamodel-to-duplicate="mappingService.selectedDatamodelToDuplicate"
    :module-to-duplicate="mappingService.selectedModuleToDuplicate"
    :source-module="selectedModule"
    :source-datamodel="selectedDatamodel"
    @hide="($event) => mappingService.closeDuplicateWarningDialog($event?.closeAllDialogs)"
  />
</template>

<style scoped lang="scss">
.p-button :deep(.p-button-label) {
  font-weight: normal;
}
</style>
