<script setup lang="ts">
//Components
import Overview from 'ui/views/over-view/over-view.vue';
import ValidationModelOverviewTable from 'admin/modules/validation-rules/components/models/validation-model-overview-table.vue';
import EmptyState from 'ui/components/empty-state.vue';
import ValidationModelForm from 'admin/modules/validation-rules/components/models/validation-model-form.vue';

//Services
import ValidationModelService from 'admin/modules/validation-rules/services/validation-models.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { useService } from '@/general/composables/useService/useService';

import { serviceKey } from 'admin/modules/validation-rules/ts/keys/validation-model-key';
import { onBeforeRouteLeave } from 'vue-router';
import { ServiceManager } from '@/general/composables/useService/service.manager';

const ts = new TranslationService('admin', 'validationRules');
const service = useService(serviceKey, ValidationModelService, ts);

const emptyStateProps = {
  translationService: ts,
  iconName: 'validation-model',
  emptyStateTitle: ts.tModule('emptyStateTitle'),
  emptyStateSubtitle: ts.tModule('emptyStateSubTitle'),
  buttonVisible: false,
};

//Kill service on route leave - Child routes have different services
const serviceManager = new ServiceManager();
onBeforeRouteLeave((to, from, next) => {
  serviceManager.unregisterService(serviceKey);
  next();
});
</script>
<template>
  <div>
    <Overview platform="admin" module-name="validationRules" :service="service">
      <template #content>
        <!-- Empty state -->
        <EmptyState v-if="!service.validationModels.length" v-bind="emptyStateProps"></EmptyState>

        <!-- Overview table -->
        <ValidationModelOverviewTable v-else :ts="ts" />

        <!-- Add / Update a validation model -->
        <ValidationModelForm :ts="ts" />

        <pConfirmDialog :style="{ width: '30vw' }" group="validationModels" />
      </template>
    </Overview>
  </div>
</template>
