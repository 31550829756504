import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Language | Languages',
  placeholder: 'Select a language | Select multiple languages',
};

const tranlation: Translation = {
  locales: translationObject,
};

export default tranlation;
