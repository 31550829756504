<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import useFormValidation from 'composables/form-validation';
import TemplateService from 'admin/modules/templates/ts/services/template.service';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Template } from 'platform-unit2-api/templates';
import { TemplateMetaData } from '../ts/interfaces/template-meta-data.interface';

/** Props */
export interface TemplateCardProps {
  template?: Template;
}

const props = defineProps<TemplateCardProps>();

/** Emits */
const emit = defineEmits<{
  (e: 'template:created', template: Template): void;
  (e: 'uploading', isUploading: boolean): void;
  (e: 'change:template-name', templateName: string, enable: boolean): void;
}>();

/** Composables */
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/* Services */
const ts = new TranslationService('admin', 'templates');
const toastService = ToastService.getInstance();

/** Services */
const templateService = new TemplateService();

/** Constants */
const templateMetaData = ref<TemplateMetaData>({
  name: props.template?.name ?? '',
  headerRows: +(props.template?.header_row ?? 0),
  dataRows: +(props.template?.data_row ?? 0),
  api: props.template?.type === 'api',
  sheets: props.template?.sheets?.split(',').map((s) => +s) ?? [],
});

const hasCompliantData = ref(false);
const isUploading = ref(false);
const uploadedTemplate = ref<Template>();

const handleUpload = async ($event: FileUploadUploaderEvent) => {
  isUploading.value = true;
  emit('uploading', true);
  resetFormErrors();
  await templateService
    .createNewTemplate($event, templateMetaData.value)
    .then((templateRes: Template) => {
      isUploading.value = false;
      emit('uploading', false);

      uploadedTemplate.value = templateRes;
      emit('template:created', templateRes);

      toastService.displaySuccessToast(ts.uploadSuccess());
    })
    .catch((err) => {
      emit('uploading', false);
      isUploading.value = false;

      parseFormError(err, () => {
        toastService.displayErrorToast(ts.tModule('new.failedToUpload'));
      });
    });
};

const fileExist = (url?: string): string => {
  if (url == null) {
    return '';
  }

  return url && url.substring(url.lastIndexOf('/') + 1);
};

/** Lifecycle */
onMounted(() => {
  hasCompliantData.value = templateService.isDataCompliant(templateMetaData.value);
  templateMetaData.value.name = props.template?.name ?? '';
});

watch(
  () => templateMetaData.value,
  () => {
    hasCompliantData.value = templateService.isDataCompliant(templateMetaData.value);
    emit(
      'change:template-name',
      templateMetaData.value.name,
      !templateService.isDataCompliant(templateMetaData.value),
    );
  },
  {
    deep: true,
  },
);

watch(
  () => props.template,
  () => {
    templateMetaData.value = {
      name: props.template?.name ?? '',
      headerRows: +(props.template?.header_row ?? 0),
      dataRows: +(props.template?.data_row ?? 0),
      api: props.template?.type === 'api',
      sheets: props.template?.sheets?.split(',').map((s) => +s) ?? [],
    };
  },
);
</script>

<template>
  <div>
    <p-card>
      <template #content>
        <div style="display: flex; justify-content: space-around; gap: 25px">
          <div style="width: 100%">
            <pBadge
              v-if="template?.type != null"
              :value="template?.type.toUpperCase()"
              severity="info"
            />
            <div class="flex justify-content-between">
              <label class="align-self-center" for="name">{{ ts.tForms('name') }}</label>
              <p-input-text
                v-model="templateMetaData.name"
                :disabled="isUploading"
                class="w-7"
                :class="{ 'p-invalid': hasError('name') }"
              />
              <small
                v-if="hasError('name')"
                :class="{ 'p-error block': hasError('name') }"
                class="hidden"
                >{{ fieldErrorMessage('name').toString() }}</small
              >
            </div>
          </div>
          <div :style="`display: ${template?.type === 'api' ? 'none' : 'initial'}; width: 100%`">
            <h3 class="font-bold">{{ ts.tModule('new.tableRow') }}</h3>
            <div class="flex justify-content-between mb-3">
              <div class="align-items-center flex">
                <label for="row" class="flex">
                  <div class="mr-2" v-html="ts.tModule('new.titleRow')"></div>
                  <i
                    v-tooltip.right="{ value: ts.tModule('new.titleRowNote') }"
                    class="mdi mdi-help-circle"
                  ></i>
                </label>
              </div>
              <p-input-number
                v-model="templateMetaData.headerRows"
                input-class="w-2"
                class="w-2"
                :disabled="isUploading || template?.type === 'api' || templateMetaData.api"
                :class="{ 'p-invalid': hasError('header_row') }"
              />
            </div>
            <div class="flex justify-content-between">
              <div class="align-items-center flex">
                <label for="data" class="flex">
                  <div class="mr-2" v-html="ts.tModule('new.dataRow')"></div>
                  <i
                    v-tooltip.right="{
                      value: ts.tModule('new.dataRowNote'),
                    }"
                    class="mdi mdi-help-circle"
                  ></i>
                </label>
              </div>
              <p-input-number
                v-model="templateMetaData.dataRows"
                input-class="w-2"
                class="w-2"
                :disabled="isUploading || template?.type === 'api' || templateMetaData.api"
                :class="{ 'p-invalid': hasError('data_row') }"
              />
            </div>
            <div class="flex justify-content-between my-3">
              <div class="align-items-center flex">
                <label class="flex mr-2">
                  {{ ts.tModule('sheets') }}
                </label>
              </div>
              <pChips
                v-model="templateMetaData.sheets"
                :disabled="isUploading || templateMetaData.api"
              />
            </div>
            <div>
              <div class="field mb-3 mt-4">
                <label for="name"
                  >{{ ts.tGlobal('files') }}

                  <i
                    v-tooltip.right="{ value: ts.tModule('new.fileUploadNote') }"
                    class="mdi mdi-help-circle"
                  ></i>
                </label>
                <div class="align-items-center flex justify-content-start">
                  <p-file-upload
                    ref="uploader"
                    name="template"
                    mode="basic"
                    :disabled="
                      isUploading ||
                      hasCompliantData ||
                      template?.type === 'api' ||
                      templateMetaData.api
                    "
                    auto
                    custom-upload
                    accept=".xlsx, .xls"
                    :choose-label="ts.tModule('edit.change')"
                    @uploader="($event: FileUploadUploaderEvent) => handleUpload($event)"
                  />
                  <p-progress-spinner
                    v-if="isUploading"
                    class="m-0 mx-2"
                    :style="{ width: '15px', height: 'auto' }"
                  />
                  <a
                    v-if="fileExist(template?.public_url)"
                    :href="uploadedTemplate?.public_url || template?.public_url"
                    target="_blank"
                  >
                    <p-button
                      v-tooltip.bottom="{ value: ts.tGlobal('download') }"
                      icon="mdi mdi-download"
                      class="ml-3 p-button p-button-outlined"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </p-card>
  </div>
</template>
