<script setup lang="ts">
import ProductFilterV2 from 'supplier/modules/products/components/product-filter.v2.vue';
import ProductVariantsV2 from 'supplier/modules/products/components/product-variantsV2.vue';
import { ref, watch, inject } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { isNegative } from '@/general/utils/isNegative';
/** Props */
const props = withDefaults(
  defineProps<{
    datamodel?: Datamodel;
    isLoading: boolean;
  }>(),
  {
    datamodel: undefined,
    fields: () => [],
    isLoading: false,
  },
);

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const filterActive = ref<boolean>(false);

const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

const filterVisible = (value: boolean): void => {
  filterActive.value = value;
};

const calculatedFilledInFields = ref<number>(0);

watch(
  () =>
    productAttributeFieldService.value.isSaving ||
    productAttributeFieldService.value.hasLocalesChanged ||
    props.datamodel?.name,
  () => {
    const dmAttributes = props.datamodel?.attributes.map((attribute) => attribute.id);

    calculatedFilledInFields.value =
      productAttributeFieldService.value.getFilledInAttributesForDatamodel(dmAttributes ?? []) ??
      calculatedFilledInFields.value;
  },
  {
    deep: true,
  },
);
</script>
<template>
  <section>
    <ProductFilterV2 v-if="filterActive" :is-active="filterActive" @hide="filterVisible(false)" />
    <div class="bg-white border-200 border-bottom-1 sticky top-0 z-2">
      <div class="align-items-center flex justify-content-between mb-3">
        <h3 v-if="datamodel && $route.name === 'product-attributes'">
          {{ datamodel?.name }}
          <h5 class="inline-block m-0 text-gray-400">
            ({{ isNegative(calculatedFilledInFields) ? 0 : calculatedFilledInFields }}/{{
              datamodel?.attributes_count
            }})
          </h5>
        </h3>
        <h3 v-else-if="$route.name === 'product-assets' || $route.name === 'product-story'">
          {{ $route.name === 'product-assets' ? ts.tGlobal('assets') : ts.tModule('story') }}
        </h3>
        <div class="flex">
          <p-button
            outlined
            plain
            icon="mdi mdi-filter-variant"
            :label="ts.tModule('filters')"
            :disabled="$route.name === 'product-assets' || $route.name === 'product-story'"
            @click="filterVisible(true)"
          />
        </div>
      </div>
      <ProductVariantsV2 />
    </div>
  </section>
</template>
