import { ContentStoryRestService } from '@/api/v1/content-story/content-story.rest';
import { BaseViewService } from '../view/base-view.service';
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';
import { CreateContentStoryRequest } from '@/api/v1/content-story/ts/create-content-story-request.interface';
import { UpdateContentStoryRequest } from '@/api/v1/content-story/ts/update-content-story-request.interface';
import { TranslationService } from '../../translations/translation.service';

/**
 * Service for the story manager view.
 */
export class StoryManagerViewService extends BaseViewService<
  ContentStoryRestService,
  ContentStory,
  CreateContentStoryRequest,
  UpdateContentStoryRequest
> {
  /**
   * Get Content Stories, this.data is assignend to content stories for readability
   */
  public get contentStories() {
    return this._data;
  }

  /**
   *
   * @inheritdoc
   */
  constructor(ts: TranslationService) {
    super({
      Api: ContentStoryRestService,
      ts: ts,
      fetchAllFunction: 'getAll',
      overviewRouteName: 'story-manager',
      createRouteName: 'new-story',
      updateRouteName: 'edit-story',
      confirmPopUpGroup: 'story-manager-pop-up',
    });
  }

  /**
   *
   * @inheritdoc
   * Create CreateContentStoryRequest from the current story
   */
  public createBody(): CreateContentStoryRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const createBody: CreateContentStoryRequest = {
      story_name: this.current.story_name,
      is_draft: true,
    };

    return createBody;
  }
  /**
   *
   * @inheritdoc
   * Create UpdateContentStoryRequest from the current story
   */
  public updateBody(): UpdateContentStoryRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const updateBody: UpdateContentStoryRequest = {
      id: this.current.id!,
      story_name: this.current.story_name,
      is_draft: true,
    };

    return updateBody;
  }

  /**
   * @inheritdoc
   * Check if the current story is valid
   */
  public get validated(): boolean {
    if (this.current?.story_name == null || this.current?.story_name === '') {
      return false;
    }

    return true;
  }

  /**
   *
   * @param id number that represents the id of the story to be deleted
   * @returns
   * @description delete the story with the given id
   */
  public deleteAction(id?: number): void {
    if (id == null) {
      return;
    }

    this._confirmService.confirmDelete({
      group: this.confirmPopUpGroup,
      callback: () => {
        this._restService
          .delete(id)
          .then(() => {
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this._reloadPage();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingCrudComponent = false;
          });
      },
      message: this._ts.deleteConfirm(),
    });
  }

  /**
   *
   * @param id number that represents the id of the story to be duplicated
   * @returns
   * @description duplicate the story with the given id
   */
  public duplicationAction(id?: number): void {
    if (id == null) {
      return;
    }

    this._restService
      .duplicate(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this._reloadPage();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.deleteFailed());
      })
      .finally(() => {
        this._isLoadingCrudComponent = false;
      });
  }
}
