<script setup lang="ts">
import AttributeEditor from '../components/attribute-editor.vue';
import { computed, onMounted, Ref, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useAttributeOption from 'utils/attribute-option';
import Sortable from 'sortablejs';
import EmptyGroupState from 'retailer/modules/products/components/fields/empty-group-state.vue';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Product, UpdateProductField } from 'platform-unit2-api/products';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { FieldPath } from '@/general/services/field-path.class';
import { AttributeTypeEnum, Attribute } from 'platform-unit2-api/attributes';
import { Locale, LocaleDetails } from 'platform-unit2-api/locales';
import { AttributeViewFields } from '../products.types';
import { getLocaleDetails } from '@/general/utils/get-locale-details';

/** Props */
interface Props {
  attribute: Attribute;
  fields: AttributeViewFields[];
  currentProduct: Product;
  locales: LocaleDetails[];
  selectedDatamodel: Datamodel;
  flagged?: boolean;
  currentPath?: FieldPath;
  currentIndex?: FieldPath;
  lockable?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  flagged: true,
  fields: () => [],
  filteredLocales: () => [],
  currentPath: () => new FieldPath(),
  currentIndex: () => new FieldPath(),
  lockable: true,
});

/** Consts */
const store = useStore();

const fieldPath = ref(props.currentPath);

const index: Ref<FieldPath> = ref(new FieldPath());

const listRef = ref<HTMLElement[]>([]);
const currentFields = ref<AttributeViewFields[]>(props.fields);

watch(
  () => props.currentIndex,
  () => {
    index.value = props.currentIndex;
    fieldPath.value.changeLevelIndex(index.value.currentLevel(), index.value.getPathIndex());
  },
);

watch(
  () => props.fields,
  () => {
    currentFields.value = props.fields;
  },
);

watch(
  () => store.getters['products/editingProductFields'],
  () => {
    if (store.getters['products/editingProductFields'].length == 0) {
      newGroups.value = [];

      currentFields.value.forEach((field) => {
        const path = (field as any).path;

        if (path != undefined) {
          field.path = path;
        }
      });

      currentFields.value
        .filter((field) => field.deleted)
        .forEach((field) => {
          field.deleted = false;
        });
    }
  },
);

const { getFieldDefinitionOption } = useAttributeOption();

const getFieldValue = computed(
  () => (attributeId?: number, localeId?: number, path?: FieldPath) => {
    const editingFieldExist = store.getters['products/editingProductFields'].find(
      (field: ProductField) =>
        field.attribute_id === attributeId &&
        field.locale_id === localeId &&
        (field.path == path?.toString() || (path?.toString() === '' && field.path == null)),
    );

    return (
      editingFieldExist ??
      props.fields.find(
        (field: AttributeViewFields) =>
          field.attribute_id === attributeId &&
          field.locale_id === localeId &&
          (field.path == path?.toString() || (path?.toString() === '' && field.path == null)) &&
          !field.deleted,
      )
    );
  },
);

const getFieldValueHeader = computed(
  () => (attributeId?: number, localeId?: number, path?: FieldPath) => {
    const editingFieldExist = store.getters['products/editingProductFields'].find(
      (field: AttributeViewFields) =>
        field.attribute_id === attributeId &&
        field.locale_id === localeId &&
        (field.path == path?.toString() || (path?.toString() === '' && field.path == null)),
    ) as UpdateProductField;

    if (
      props.attribute.children != null &&
      props.attribute.children[0].options.type === AttributeTypeEnum.KEY_VALUE_FIELD &&
      editingFieldExist
    ) {
      if (editingFieldExist.value?.length === 0) {
        return '...';
      }

      return editingFieldExist.value
        ? (editingFieldExist.value as unknown as Record<string, string>[])[0]['value']
        : '...';
    }

    return (
      editingFieldExist ??
      props.fields.find(
        (field: AttributeViewFields) =>
          field.attribute_id === attributeId &&
          field.locale_id === localeId &&
          (field.path == path?.toString() || (path?.toString() === '' && field.path == null)) &&
          !field.deleted,
      )
    )?.value;
  },
);

const newGroups = ref<{ path: FieldPath; deleted: boolean }[]>([]);

const maxPathIndex = ref(-Infinity);

const createAttribute = () => {
  const nextPathIndex = maxPathIndex.value == -Infinity ? 0 : maxPathIndex.value + 1;
  newGroups.value.push({
    path: fieldPath.value.nextLevel(nextPathIndex),
    deleted: false,
  });
  maxPathIndex.value = nextPathIndex;
};

const hasChildFields = (localeId: number) => {
  return (
    currentFields.value.filter((field) => {
      return props.attribute.children?.some(
        (child) =>
          child.id === field.attribute_id &&
          new FieldPath(field.path).getLevel(Number(props.attribute.level)).toString() ===
            fieldPath.value.toString() &&
          field.locale_id === localeId,
      );
    }).length !== 0
  );
};

const calculateGroupValues = (localeId: number) => {
  if (props.attribute.options?.type !== AttributeTypeEnum.GROUP_FIELD) {
    return [
      {
        path: fieldPath.value.nextLevel(0),
        deleted: false,
      },
    ];
  }

  //get fields if attribute id is inside children id of array
  const childFields = currentFields.value.filter((field) => {
    return props.attribute.children?.some(
      (child) =>
        child.id === field.attribute_id &&
        new FieldPath(field.path).getLevel(Number(props.attribute.level)).toString() ===
          fieldPath.value.toString() &&
        field.locale_id === localeId &&
        !newGroups.value.some(
          (group) => group.deleted === true && group.path.toString() === field.path,
        ),
    );
  });
  //get all indexes of child fields as uniques
  const indexes = [...new Set(childFields.map((field) => field.path))].sort((a, b) =>
    a?.localeCompare(b, [], { numeric: true, sensitivity: 'base' }),
  );
  const maxPath: number[] = [];
  //for all indexes check if chieldfield is deleted
  const uniqueIndexes = indexes.map((path) => {
    const deleted = childFields
      .filter((field) => field.path === path)
      .some((field) => field.deleted);
    const curPath = new FieldPath(path);
    maxPath.push(curPath.getPathIndex());
    return { path: curPath, deleted };
  });
  if (
    uniqueIndexes.length == 0 &&
    (!props.attribute.options?.multiple || !props.attribute.options.collapsible)
  ) {
    uniqueIndexes.push({
      path: fieldPath.value.nextLevel(0),
      deleted: false,
    });
  }

  maxPathIndex.value = Math.max(
    ...maxPath,
    ...uniqueIndexes.map((indexes) => indexes.path.getPathIndex()),
    ...newGroups.value.map((new_group) => new_group.path.getPathIndex()),
  );
  // Set last path value as the max group
  return [...uniqueIndexes, ...newGroups.value];
};

const updateFieldValue = (attributeId: number, locale?: Locale) => {
  return async (value: any) => {
    await store.dispatch('products/REMOVE_ERROR_FIELD_DATA', {
      attribute_id: attributeId,
      locale_id: locale?.id,
    });

    await store.dispatch('products/SET_EDITING_FIELD_DATA', {
      attribute_id: attributeId,
      value: value,
      locale_id: locale?.id,
      overwrite: false,
      path: fieldPath.value.toString(),
    });
  };
};

const deleteFieldValue = (attribute: Attribute, locale?: Locale) => {
  return async (delete_path: FieldPath) => {
    await store.dispatch('products/SET_EDITING_FIELD_DATA', {
      attribute_id: attribute?.id,
      locale_id: locale?.id,
      value: null,
      overwrite: false,
      path: fieldPath.value.toString(),
      delete_paths: [delete_path.toString()],
    });

    // In case of group we hide it
    if (attribute.options?.type == AttributeTypeEnum.GROUP_FIELD) {
      const getGroup = newGroups.value.find((instance) => {
        return instance.path.toString() === delete_path.toString();
      });

      if (getGroup) {
        getGroup.deleted = true;
      } else {
        newGroups.value.push({
          path: delete_path,
          deleted: true,
        });
      }
    }
  };
};

const reorderFieldValues = async (attribute: Attribute, item: HTMLElement, locale_id: number) => {
  const newOrder = Sortable.get(item)?.toArray();

  let reorderPaths: { old_path: string | null; new_path: string | null }[] = [];

  newOrder?.forEach(async (item: any, new_path_index: any) => {
    const itemPath = new FieldPath(item);

    reorderPaths = reorderPaths.concat({
      old_path: itemPath.toString(),
      new_path: itemPath.setPathIndex(new_path_index).toString(),
    });
  });

  await store.dispatch('products/SET_EDITING_FIELD_DATA', {
    attribute_id: attribute.id,
    locale_id: locale_id,
    value: null,
    overwrite: false,
    path: fieldPath.value.toString(),
    reorder_paths: reorderPaths,
  });
};

const helpText = (attribute: Attribute) => {
  if (attribute.options) {
    return getFieldDefinitionOption(attribute.options, 'helpText', props.currentProduct?.module_id);
  }

  return null;
};

const articleLink = (attribute: Attribute) => {
  if (attribute.options) {
    return getFieldDefinitionOption(
      attribute.options,
      'articleLink',
      props.currentProduct?.module_id,
    );
  }

  return null;
};

const updateIndex = (_index: number) => {
  index.value = fieldPath.value.nextLevel(_index);
};

const attributeGroupSize = (attribute: Attribute) => {
  let size = 12;
  if (attribute.options && attribute.options?.group_size) {
    size = attribute.options.group_size;
  }

  if (size > 0 && size <= 12) {
    return 'col-' + size;
  }

  // Default
  return 'col-12';
};

const attributeIdsToDuplicate = ref<any[]>([]);
const getOldValue = (
  attributeId?: number,
  localeId?: number,
  path?: FieldPath,
): AttributeViewFields | undefined => {
  const editingFieldExist = store.getters['products/editingProductFields'].find(
    (field: AttributeViewFields) =>
      field.attribute_id === attributeId &&
      field.locale_id === localeId &&
      (field.path == path?.toString() || (path?.toString() === '' && field.path == null)),
  );

  return (
    editingFieldExist ??
    props.fields.find(
      (field: AttributeViewFields) =>
        field.attribute_id === attributeId &&
        field.locale_id === localeId &&
        (field.path == path?.toString() || (path?.toString() === '' && field.path == null)) &&
        !field.deleted,
    )
  );
};

const getChildren = (attribute: Attribute, fp: FieldPath, oldPath: FieldPath, localeId: number) => {
  attribute.children?.map((attr) => {
    const oldValue = getOldValue(attr.id, localeId, oldPath);
    oldValue &&
      attributeIdsToDuplicate.value.push({
        attribute_id: attr.id,
        path: fp.toString(),
        value: oldValue.value,
        locale_id: localeId,
        overwrite: false,
      });
    attr.children && getChildren(attr, fp.nextLevel(0), oldPath.nextLevel(0), localeId);
  });
};

const duplicateTab = (indexes: {
  copyFromIndex: number;
  copyToIndex: number;
  localeId: number;
}) => {
  attributeIdsToDuplicate.value = [];
  const idx = new FieldPath(indexes.copyToIndex.toString());
  const oldIdx = new FieldPath(indexes.copyFromIndex.toString());
  getChildren(props.attribute, idx, oldIdx, indexes.localeId);
  attributeIdsToDuplicate.value.forEach(async (record) => {
    await store.dispatch('products/SET_EDITING_FIELD_DATA', record);
  });
};

const switchTabs = (indexes: {
  fromIndex: number;
  toIndex: number;
  attribute: Attribute;
  localeId: number;
}) => {
  const newTabs: string = changeTabOrder(
    indexes.fromIndex,
    indexes.toIndex,
    indexes.attribute,
    indexes.localeId,
  );

  const tabObjectToSave = {
    attribute_id: indexes.attribute.id,
    value: newTabs,
    locale_id: indexes.localeId,
    overwrite: false,
  };

  const needsToBeChangedToDestinationPath = findValubyPath(indexes.fromIndex);
  const to = needsToBeChangedToDestinationPath.map((values) => ({
    attribute_id: values.attribute_id,
    value: values.value,
    locale_id: values.locale_id,
    overwrite: false,
    path: values.path.replace(indexes.fromIndex.toString(), indexes.toIndex.toString()),
  }));

  const needsToBeChangedToSourcePath = findValubyPath(indexes.toIndex);
  const from = needsToBeChangedToSourcePath.map((values) => ({
    attribute_id: values.attribute_id,
    value: values.value,
    locale_id: values.locale_id,
    overwrite: false,
    path: values.path.replace(indexes.toIndex.toString(), indexes.fromIndex.toString()),
  }));

  [...to, ...from, tabObjectToSave].forEach(async (record) => {
    await store.dispatch('products/SET_EDITING_FIELD_DATA', record);
  });
};

const changeTabOrder = (from: number, to: number, attribute: Attribute, localeId: number) => {
  const tabs = findTabs(attribute, localeId);
  const temp = tabs[from];
  tabs[from] = tabs[to];
  tabs[to] = temp;
  return tabs;
};

const findTabs = (attribute: Attribute, localeId: number) => {
  const editingFieldExist = store.getters['products/editingProductFields'].find(
    (field: AttributeViewFields) =>
      field.attribute_id === attribute.id && field.locale_id === localeId,
  );
  return editingFieldExist
    ? editingFieldExist.value
    : props.fields.find(
        (field: AttributeViewFields) =>
          field.attribute_id === attribute.id && field.locale_id === localeId && !field.deleted,
      )?.value;
};

const findValubyPath = (path: number): AttributeViewFields[] => {
  const editedFields: AttributeViewFields[] = store.getters['products/editingProductFields'].filter(
    (field: AttributeViewFields) => field.path && field.path.split('.')[0] == path?.toString(),
  );

  const editedFieldIds = editedFields.map((value: AttributeViewFields) => value.attribute_id);

  const savedFields = props.fields.filter(
    (field: AttributeViewFields) =>
      field.path &&
      field.path.split('.')[0] == path?.toString() &&
      !editedFieldIds.includes(field.attribute_id),
  );

  return [...editedFields, ...savedFields];
};

onMounted(() => {
  // index.value = props.currentIndex;
  /* sortable fields */
  if (props.attribute?.options?.type == AttributeTypeEnum.GROUP_FIELD) {
    listRef.value?.forEach(function (item) {
      const localeId = Number(item.dataset.locale);
      Sortable.get(item)?.destroy();
      Sortable.create(item, {
        group: props.attribute?.id.toString() + ' ' + localeId,
        handle: '.group-drag-handle',
        ghostClass: 'group-dragging',
        onUpdate: async () => {
          reorderFieldValues(props.attribute, item, localeId);
        },
      });
    });
  }
});
</script>
<template>
  <!-- #region: Attribute key -if it's not a tabField- Tab fields will show their own key -->
  <div>
    <strong v-if="attribute?.options?.type != AttributeTypeEnum.TAB_FIELD" class="mr-1">{{
      attribute.key
    }}</strong>
    <span
      v-if="attribute.required || attribute.options?.required"
      class="font-bold text-pink-500"
      :class="attribute.required ? 'mr-2' : ''"
      >&ast;</span
    >
    <i
      v-if="helpText(attribute)"
      v-tooltip.right="
        helpText(attribute)
          ? { value: helpText(attribute), disabled: false, class: 'text-sm' }
          : { disabled: true }
      "
      class="cursor-pointer mdi mdi-help-circle-outline text-xl"
    ></i>
    <a
      v-if="articleLink(attribute)"
      class="ml-2 text-500"
      :href="articleLink(attribute)"
      target="_blank"
    >
      <i class="cursor-pointer mdi mdi-file-document-outline text-xl"></i>
    </a>
  </div>
  <!-- #endregion -->

  <div
    v-for="(locale, c) in props.locales"
    :key="currentProduct.id + '' + attribute.id + '' + locale.id"
  >
    <div v-if="!attribute.global_attribute || c == 0">
      <div
        v-if="
          !hasChildFields(locale.id) &&
          attribute.options?.multiple &&
          attribute.options.collapsible &&
          newGroups.length == 0
        "
      >
        <EmptyGroupState
          :header-text="'There are no ' + attribute.key.toLowerCase() + ' yet!'"
        ></EmptyGroupState>
      </div>
      <div
        ref="listRef"
        :key="currentProduct.id + '' + attribute.id + '' + locale.id"
        :data-locale="locale.id"
      >
        <AttributeEditor
          v-for="group in calculateGroupValues(locale.id)"
          :key="attribute.id + '' + locale.id + group.path.toString()"
          :attribute="attribute"
          :data-id="group.path.toString()"
          :hidden="group.deleted"
          :locale="(getLocaleDetails(locale ) as LocaleDetails)"
          :global="attribute.global_attribute"
          :field-path="group.path"
          :current-product="currentProduct"
          :flagged="flagged"
          :field-data="getFieldValue(attribute.id, locale.id, fieldPath)"
          :fields="currentFields"
          :lockable="lockable"
          @changeindex="(index) => updateIndex(index)"
          @duplicate-tab="duplicateTab"
          @input="(value) => updateFieldValue(attribute.id, locale)(value)"
          @delete="(deletePath) => deleteFieldValue(attribute, locale)(deletePath)"
          @switch-tabs="(indexes:any) => switchTabs(indexes)"
        >
          <template #header>
            {{
              getFieldValueHeader(
                attribute?.children ? attribute?.children[0].id : undefined,
                locale.id,
                group.path,
              ) ?? '...'
            }}
          </template>
          <template #children>
            <div class="grid w-full">
              <div
                v-for="child in attribute.children"
                :key="child.id"
                :class="attributeGroupSize(child)"
              >
                <AttributeView
                  :current-path="group.path"
                  :locales="[locale]"
                  :current-index="index"
                  :fields="currentFields"
                  :selected-datamodel="selectedDatamodel"
                  :attribute="child"
                  :flagged="false"
                  :lockable="false"
                  :current-product="currentProduct"
                />
              </div>
            </div>
          </template>
        </AttributeEditor>
      </div>
      <div v-if="attribute.options?.multiple">
        <p-button class="mt-2" @click="createAttribute"
          >Add {{ attribute.key.toLowerCase() }}
        </p-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.group-dragging {
  border: 1px dashed black;
}
</style>
