<script setup lang="ts">
import { Attribute } from 'platform-unit2-api/attributes';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { DeleteButton } from '@/general/ui/components/crud-buttons/ts/classes/delete-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { ref, watch, provide, Ref } from 'vue';
import { datamodelAttributeServiceKey } from '../../datamodel-attributes/ts/datamodel-attribute.keys';
import Override from './override.vue';
import { OverrideViewService } from '../ts/services/override.view.service';
import { useService } from '@/general/composables/useService';
import { DatamodelAttributesService } from '@/general/services/overview-service/modules/datamodel-attributes.service';
import { DefaultFieldService } from '../ts/services/default-fields.service';
import AttributeDefaultField from './attribute-default-field.vue';
import { TabMenuChangeEvent } from 'primevue/tabmenu';
import { overrideServiceKey } from '../ts/keys/override-service.key';
import { cloneDeep } from 'lodash';
import { DatamodelAttributeConfig } from 'platform-unit2-api';

const props = defineProps<{
  attribute: Attribute;
  visible: boolean;
}>();

const ts = new TranslationService('supplier', 'datamodelsConfig');

const datamodelService = useService(datamodelAttributeServiceKey, DatamodelAttributesService);

const overrideService = ref(
  new OverrideViewService(
    datamodelService.datamodel!,
    cloneDeep(props.attribute),
    () => {
      emit('close');
    },
    (override: DatamodelAttributeConfig) => {
      emit('created', override);
      if (override.options) {
        defaultFieldService.value.attribute.options = override.options;
      }
    },
    () => {
      emit('deleted');
    },
    ts,
    datamodelService.overrides.get(props.attribute.id),
  ),
);

provide(overrideServiceKey, overrideService as Ref<OverrideViewService>);

const defaultFieldService = ref(
  new DefaultFieldService(
    cloneDeep(props.attribute),
    datamodelService.datamodel!,
    () => {
      emit('close');
    },
    datamodelService.overrides.get(props.attribute.id),
  ),
);

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'deleted'): void;
  (e: 'created', override: DatamodelAttributeConfig): void;
}>();

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => closeDialog(),
});

const closeDialog = () => {
  overrideService.value.options = {
    ...(datamodelService.overrides.get(props.attribute.id)?.options ?? props.attribute.options),
  };

  emit('close');
};

const resetOverrideButton = new DeleteButton({
  label: ts.tModule('reset_override'),
  position: CrudButtonPosition.LEFT,
  styleClasses: 'p-button-danger p-button-text w-full',
  isEnabled: () => !!overrideService.value.override.id,
  onClick: (event) => {
    overrideService.value.confirmResetOverride(event!);
  },
});

const createOverrideButton = new CreateButton({
  label: ts.tGlobal('update'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => overrideService.value.validated,
  onClick: () => {
    defaultFieldService.value.createOrUpdateDefaultFields();
    overrideService.value.createOverride();
  },
});

//TODO enable clause
const createDefaultButton = new CreateButton({
  label: ts.tGlobal('update'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => true,
  onClick: () => {
    overrideService.value.createOverride();
    defaultFieldService.value.createOrUpdateDefaultFields();
  },
});

const overrideButtons = [resetOverrideButton, cancelButton, createOverrideButton];
const defaultButtons = [cancelButton, createDefaultButton];

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: overrideButtons,
});

const activeIndex = ref(0);

watch(
  () => activeIndex.value,
  () => {
    if (activeIndex.value === 0) {
      buttonsOptions.value.buttons = overrideButtons;
      return;
    }

    buttonsOptions.value.buttons = defaultButtons;
  },
);
</script>

<template>
  <BaseDialog
    :title="ts.tModule('configuration.dialog.title', { params: { name: attribute.key } })"
    size="medium"
    :visible="visible"
    :buttons-options="buttonsOptions"
    @update:visible="closeDialog"
  >
    <pTabView
      :active-index="activeIndex"
      @tab-change="(event: TabMenuChangeEvent) => {activeIndex = event.index}"
    >
      <pTabPanel :header="ts.tModule('configuration.dialog.overrideHeader')">
        <Override :attribute="attribute" />
      </pTabPanel>
      <pTabPanel
        v-if="
          attribute?.options?.type !== 'GROUP_FIELD' && attribute?.options?.type !== 'TAB_FIELD'
        "
        :header="ts.tModule('configuration.dialog.defaultHeader')"
      >
        <AttributeDefaultField :service="(defaultFieldService as DefaultFieldService)" />
      </pTabPanel>
    </pTabView>
  </BaseDialog>
</template>
