<script setup lang="ts">
import BaseFieldComponent from 'supplier/modules/products/components/fields/base-field.vue';
import BrandFieldComponent from 'supplier/modules/products/components/fields/brand-field.vue';
import CategoryFieldComponent from 'supplier/modules/products/components/fields/category-field.vue';
import ChoiceFieldComponent from 'supplier/modules/products/components/fields/choice-field.vue';
import GtinFieldComponent from 'supplier/modules/products/components/fields/gtin-field.vue';
import KoagFieldComponent from 'supplier/modules/products/components/fields/koag-field.vue';
import InputSelectFieldComponent from 'supplier/modules/products/components/fields/input-select-field.vue';
import MultipleChoiceFieldComponent from 'supplier/modules/products/components/fields/multiple-choice-field.vue';
import DateTimeFieldComponent from 'supplier/modules/products/components/fields/date-time-field.vue';
import NumberFieldComponent from 'supplier/modules/products/components/fields/number-field.vue';
import RichTextFieldComponent from 'supplier/modules/products/components/fields/rich-text-field.vue';
import SwitchFieldComponent from 'supplier/modules/products/components/fields/switch-field.vue';
import TagFieldComponent from 'supplier/modules/products/components/fields/tag-field.vue';
import TextAreaFieldComponent from 'supplier/modules/products/components/fields/text-area-field.vue';
import KeyValueFieldComponent from 'supplier/modules/products/components/fields/key-value-field.vue';
import FinancialFieldComponent from 'supplier/modules/products/components/fields/financial-field.vue';
import GroupFieldComponent from 'supplier/modules/products/components/fields/group-field.vue';
import TabFieldComponent from 'supplier/modules/products/components/fields/tab-field.vue';
import Flag from 'ui/components/attribute-fields/flag.vue';
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { computed, onMounted, ref, watch } from 'vue';
import useAttributeOption from 'utils/attribute-option';
import { useStore } from 'vuex';
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Product } from 'platform-unit2-api/products';
import { FieldPath } from '@/general/services/field-path.class';

/** Props */
const props = withDefaults(
  defineProps<{
    fieldData?: ProductField;
    attribute: Attribute;
    locale: LocaleDetails;
    global?: boolean;
    flagged?: boolean;
    currentProduct?: Product;
    lockable: boolean;
    fieldPath?: FieldPath;
  }>(),
  {
    fieldData: undefined,
    attribute: undefined,
    locale: undefined,
    fieldPath: undefined,
    global: false,
    flagged: true,
    currentProduct: undefined,
    lockable: true,
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'input', value: string | null): void; // needs to be null in order for the backend to work
  (e: 'delete', deletePath: FieldPath): void;
  (e: 'changeindex', index: number): void;
  (
    e: 'duplicateTab',
    indexes: { copyFromIndex: number; copyToIndex: number; localeId: number },
  ): void;
  (
    e: 'switchTabs',
    indexes: {
      fromIndex: number;
      toIndex: number;
      attribute: Attribute;
      localeId: number;
    },
  ): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const store = useStore();
const { getFieldDefinitionOption } = useAttributeOption();

const errorMessages = ref<string[]>([]);
const isLocking = ref(false);

const fieldDataLocked = computed(() => {
  if (!props.fieldData?.id) {
    return false;
  }

  return props.fieldData.locked ?? false;
});

const isRequired = () => {
  if (props.attribute) {
    return props.attribute.required || props.attribute.options?.required;
  }

  return false;
};

const maxLength = computed(() => {
  if (props.attribute.options) {
    const allowedMaxLength = getFieldDefinitionOption(
      props.attribute.options,
      'maxLength',
      props.currentProduct?.module_id,
    );
    return +allowedMaxLength; // return a number since the input expect a number and not a string
  }

  return null;
});

const fieldDataValue = computed(() => {
  return props.fieldData?.value ?? undefined;
});

const dissableLock = ref(false);

switch (props.attribute.options?.type) {
  case AttributeTypeEnum.GROUP_FIELD:
    dissableLock.value = true;
    break;
  case AttributeTypeEnum.TAB_FIELD:
    dissableLock.value = true;
    break;
  case AttributeTypeEnum.LIST_FIELD:
    dissableLock.value = true;
    break;
}

const componentType = computed(() => {
  if (!props.attribute.options) {
    return BaseFieldComponent;
  }

  switch (props.attribute.options?.type) {
    case AttributeTypeEnum.LIST_FIELD:
      return BaseFieldComponent;
    case AttributeTypeEnum.GTIN_FIELD:
      return GtinFieldComponent;
    case AttributeTypeEnum.KOAG_FIELD:
      return KoagFieldComponent;
    case AttributeTypeEnum.BRAND_FIELD:
      return BrandFieldComponent;
    case AttributeTypeEnum.CATEGORY_FIELD:
      return CategoryFieldComponent;
    case AttributeTypeEnum.TEXT_AREA:
      return TextAreaFieldComponent;
    case AttributeTypeEnum.RICHT_TEXT_FIELD:
      return RichTextFieldComponent;
    case AttributeTypeEnum.INPUT_SELECT_FIELD:
      return InputSelectFieldComponent;
    case AttributeTypeEnum.SWITCH_FIELD:
      return SwitchFieldComponent;
    case AttributeTypeEnum.NUMBER_FIELD:
      return NumberFieldComponent;
    case AttributeTypeEnum.TAG_FIELD:
      return TagFieldComponent;
    case AttributeTypeEnum.CHOICE_FIELD:
      return ChoiceFieldComponent;
    case AttributeTypeEnum.MULTIPLE_CHOICE_FIELD:
      return MultipleChoiceFieldComponent;
    case AttributeTypeEnum.KEY_VALUE_FIELD:
      return KeyValueFieldComponent;
    case AttributeTypeEnum.FINANCIAL_FIELD:
      return FinancialFieldComponent;
    case AttributeTypeEnum.DATE_TIME_FIELD:
      return DateTimeFieldComponent;
    case AttributeTypeEnum.GROUP_FIELD:
      return GroupFieldComponent;
    case AttributeTypeEnum.TAB_FIELD:
      return TabFieldComponent;
    case AttributeTypeEnum.DEFAULT:
      return BaseFieldComponent;
    default:
      return BaseFieldComponent;
  }
});

const lockPermission = computed(() =>
  store.getters['users/currentUser'].permissions.includes('update-productfield'),
);

const setErrorMessages = (errorMsg: string[]) => {
  errorMessages.value = errorMsg;
};

const valueUpdated = (val: string, onMounted?: boolean) => {
  const values = val !== '' ? val : null;
  let valueToCheckValidity = values;
  const emptyMessage = ts.tModule('field_required');

  // checking whether the input has any selected values as options
  // and if it does, then make an additional check whether the input is empty
  // without the selectedValues value (e.g., mm, cm)
  if ((props.attribute.options?.selectValues?.length ?? 0) > 0) {
    const finalVal = parseInt(valueToCheckValidity ?? '');
    valueToCheckValidity = !isNaN(finalVal) ? finalVal.toString() : '';
  }

  if (isRequired() && (valueToCheckValidity == null || valueToCheckValidity === '')) {
    setErrorMessages([emptyMessage]);
  } else if (errorMessages.value.indexOf(emptyMessage) !== -1) {
    setErrorMessages([]);
  }

  if (!onMounted) {
    emit('input', values);
    store.dispatch('products/SET_VALIDATION_STATUS', false);
  }

  if (
    errorMessages.value.length > 0 &&
    props.attribute.options?.type !== AttributeTypeEnum.RICHT_TEXT_FIELD &&
    props.attribute.options?.type !== AttributeTypeEnum.TEXT_AREA
  ) {
    setErrorFieldValue(props.attribute.id, props.locale);
  }
};

const setErrorFieldValue = async (attributeId?: number, locale?: LocaleDetails) => {
  await store.dispatch('products/UPDATE_ERROR_FIELD_DATA', {
    errorFieldData: {
      attribute_id: attributeId,
      locale_id: locale?.id ?? null,
    },
  });
};

const valueDeleted = (path: FieldPath) => {
  emit('delete', path);
};

const toggleLockField = async () => {
  if (!props.fieldData) {
    return;
  }

  try {
    await store.dispatch('products/TOGGLE_PRODUCT_FIELD_LOCK_STATE', props.fieldData);
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('product_attributes.lock_field.lock_field_failed'));
  }
};

const changeIndex = (index: number) => {
  emit('changeindex', index);
};

const duplicateTab = (indexes: {
  copyFromIndex: number;
  copyToIndex: number;
  localeId: number;
}) => {
  emit('duplicateTab', indexes);
};

const switchTabs = (indexes: { fromIndex: number; toIndex: number }) => {
  emit('switchTabs', {
    ...indexes,
    attribute: props.attribute,
    localeId: props.locale.id,
  });
};

watch(
  () => store.getters['products/errorProductFields'],
  () => {
    if (!store.getters['products/errorProductFields'].length) setErrorMessages([]);
  },
);

/*** Validation on page load */
const originalValue = ref<string | null>(); //To run the validation after discarding the changes

const shouldRunValidation = computed((): boolean => {
  return store.getters['products/shouldRunAttributesValidation'];
});

watch(
  () => shouldRunValidation.value,
  () => {
    shouldRunValidation.value && valueUpdated(originalValue.value ?? '', true);
  },
);

onMounted(() => {
  originalValue.value = props.fieldData?.value;
  valueUpdated(props.fieldData?.value ?? '', true);
});
</script>

<template>
  <div>
    <div class="flex">
      <div class="font-bold mb-2"></div>
      <div v-if="errorMessages" class="ml-auto mr-6">
        <p v-for="error in errorMessages" :key="error" class="mr-2 text-pink-500">
          {{ error }}
        </p>
      </div>
    </div>
    <div class="align-items-start flex w-full">
      <div class="p-inputgroup">
        <Flag v-if="flagged" :locale="locale" :global="global" class="max-h-3rem" />
        <component
          :is="componentType"
          :model-value="(fieldDataValue as any)"
          :options="attribute?.options"
          :module-id="currentProduct?.module_id"
          :disabled="fieldDataLocked"
          :max-length="maxLength"
          :name="attribute?.key"
          :field-path="fieldPath"
          :locale="locale"
          :attribute="attribute"
          :global="global"
          @update:model-value="valueUpdated"
          @delete:model-value="valueDeleted"
          @changeindex="changeIndex"
          @duplicate-tab="duplicateTab"
          @switch-tabs="switchTabs"
          @error="setErrorMessages"
        >
          <template #header>
            <slot name="header"></slot>
          </template>
          <template #items>
            <slot name="children"></slot>
          </template>
        </component>
      </div>
      <p-button
        v-if="lockable"
        plain
        text
        rounded
        :disabled="isLocking || !fieldData?.id || !lockPermission"
        :icon="fieldDataLocked ? 'mdi mdi-lock-outline' : 'mdi mdi-lock-open-outline'"
        @click="toggleLockField"
      />
    </div>
    <div class="flex">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
