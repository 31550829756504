<script setup lang="ts">
import AppLayout from './app-layout.vue';

import { AppService } from './ts/services/App.service';
new AppService();
</script>

<template>
  <AppLayout />
</template>
