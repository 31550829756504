<template>
  <small>
    <span class="error-badge">!</span>
    <slot />
  </small>
</template>
<style scoped lang="scss">
small {
  color: #ac110c;
}

.error-badge {
  background-color: #ac110c;
  color: white;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  width: 13px;
  margin-right: 2px;
}
</style>
