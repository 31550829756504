import { LocaleDetails } from 'platform-unit2-api/locales';
import { ProductService } from '@/general/services/products/product.service';
import { ProductFieldsService } from '@/general/services/product-fields/product-fields.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';

import { Attribute } from 'platform-unit2-api/attributes';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ProductField } from 'platform-unit2-api/product-fields';
import { ref } from 'vue';

/**
 * @description This service holds the logic for the incomplete products
 */

export class IncompleteProductVueService {
  public isLoading = ref(false);
  public allLocales: LocaleDetails[] = [];
  public emptyFields = ref<
    {
      attribute: Attribute;
      localeIds: number[];
      product_field?: ProductField;
    }[]
  >([]);

  // #region vue and prime related
  private ts: TranslationService;
  private _productService: ProductService;
  private _productFieldsService: ProductFieldsService;
  private _toastService: ToastService;

  // #endregion

  constructor() {
    this._toastService = ToastService.getInstance();
    this._productService = new ProductService();
    this._productFieldsService = new ProductFieldsService();

    const ts = new TranslationService('retailer', 'incompleteProducts');
    this.ts = ts;
  }

  public async loadAsyncFields(productId: number) {
    this.isLoading.value = true;
    let attributes: Attribute[] = [];
    let filledFields: ProductField[] = [];
    const datamodels: Datamodel[] = (await this._productService.getProductDatamodels(productId))
      .data;

    const attributeLists = datamodels?.map((datamodel) => datamodel.attributes);

    attributeLists?.forEach((attributeList) => {
      attributes = [...attributes, ...attributeList];
    });

    Promise.all(
      this.allLocales.map(async (locale) => {
        const newFilledFields: ProductField[] = await this._productFieldsService.getProductFields(
          productId,
          [locale.id],
        );
        filledFields = filledFields.concat(newFilledFields);
      }),
    )
      .then(() => {
        attributes.forEach((attr) => {
          const newAttribute: {
            attribute: Attribute;
            localeIds: number[];
            product_field?: ProductField;
          } = {
            attribute: attr,
            localeIds: [],
          };
          // //Find the empty fields in every locales
          this.allLocales.forEach((locale) => {
            const filledField = filledFields.find((field) => {
              return field.attribute_id === attr.id && field.locale_id === locale.id;
            });

            filledField === undefined && newAttribute.localeIds.push(locale.id);
          });

          //If the attribute is NOT filled for every locales
          newAttribute.localeIds.length > 0 && this.emptyFields.value.push(newAttribute);
        });
      })
      .finally(() => {
        this.isLoading.value = false;
      });
  }
}
