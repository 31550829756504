<script setup lang="ts">
import { inject } from 'vue';

import pDataTable from 'primevue/datatable';
import { tsKey } from '@/general/services/translations/translation.key';
import { fieldServiceKey } from '../../ts/keys/fields.keys';
import { Attribute } from 'platform-unit2-api/attributes';
import FieldTableEmptyState from './field-table-empty-state.vue';

/**
 * If this table is to be used in another view, the service must be provided.
 *
 * Or this component can be rewritten to also use props.
 * Service should then based on typed "TableService | OverViewService" that implement a common interface.
 */
const ts = inject(tsKey)!;
const service = inject(fieldServiceKey)!;
</script>

<template>
  <pDataTable
    scrollable
    scroll-height="flex"
    :value="service.fields"
    removable-sort
    :row-hover="true"
    :lazy="true"
    :paginator="service.total > (service.limit ?? 5)"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    @row-click="service.openCrudComponent($event.data)"
    @page="service.onPageChange($event.page)"
    @sort="service.onSortChange($event)"
  >
    <p-column field="key" :header="ts.tGlobal('name')" sortable />
    <p-column field="options" :header="ts.tModule('type')">
      <template #body="slotProps: { data: Attribute }">
        {{ service.getType(slotProps.data) }}
      </template>
    </p-column>
    <p-column>
      <template #body="slotProps: { data: Attribute }">
        <div class="flex justify-content-end">
          <p-button
            icon="mdi mdi-delete-outline"
            text
            rounded
            severity="danger"
            @click="(event:PointerEvent) => service.confirmDelete(event, slotProps.data)"
          />
        </div>
      </template>
    </p-column>
    <template #empty> <FieldTableEmptyState /> </template>
  </pDataTable>
</template>
