<script setup lang="ts">
//Core
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

//Types
import {
  CreateMappingItemRequest,
  MappingItem,
  MappingSourceType,
  MappingItemRestService,
} from 'platform-unit2-api/mapping-items';
import { TemplateEndpoint } from 'platform-unit2-api/template-endpoints';
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { Datamodel, DatamodelAttributeConfig } from 'platform-unit2-api/datamodels';
import { Module } from 'platform-unit2-api/modules';
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import { Locale } from 'platform-unit2-api/locales';

//Composables and Services
import useAttributeOption from 'utils/attribute-option';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';

//Components
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';

// Props
const props = defineProps<{
  mappingItems: MappingItem[];
  ownerId: number;
  endpoints: TemplateEndpoint[];
  attributes: Attribute[];
  selectedDatamodel?: Datamodel;
  selectedModule?: Module;
  viewOnly: boolean;
  attributeConfigs: DatamodelAttributeConfig[];
  loading: boolean;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'export_mappings');
const tsAttributes = new TranslationService('retailer', 'attributes');
const mappingItemsApi = new MappingItemRestService();

// Constants
const store = useStore();
const { getFieldDefinitionOption } = useAttributeOption();

const editableMappingChipItems = ref<any[]>([]);
const checked = ref<number>();
const used = ref<boolean>(false);
const selectedSource = ref<{ value: MappingSourceType }[]>([]);
const selectedLocale = ref<Locale[]>([]);
const searchText = ref<string>('');
const defaultMappingSource = [
  {
    value: MappingSourceType.VALUE,
    label: ts.tModule('field_sources.take_value'),
  },
  {
    value: MappingSourceType.LOCALE,
    label: ts.tModule('field_sources.take_locale'),
  },
];

const sourcesPerFieldType: Record<
  AttributeTypeEnum,
  Array<{ value: MappingSourceType; label: string }>
> = {
  DEFAULT: [...defaultMappingSource],
  GTIN_FIELD: [...defaultMappingSource],
  TEXT_AREA_FIELD: [...defaultMappingSource],
  KOAG_FIELD: [...defaultMappingSource],
  CATEGORY_FIELD: [...defaultMappingSource],
  LIST_FIELD: [...defaultMappingSource],
  LIST_GROUP: [...defaultMappingSource],
  TAB_FIELD: [...defaultMappingSource],
  GROUP_FIELD: [...defaultMappingSource],
  RICH_TEXT_FIELD: [
    {
      value: MappingSourceType.VALUE,
      label: ts.tModule('field_sources.take_html'),
    },
    {
      value: MappingSourceType.TEXT,
      label: ts.tModule('field_sources.take_text'),
    },
    {
      value: MappingSourceType.LOCALE,
      label: ts.tModule('field_sources.take_locale'),
    },
  ],
  SWITCH_FIELD: [...defaultMappingSource],
  NUMBER_FIELD: [...defaultMappingSource],
  DATE_TIME_FIELD: [...defaultMappingSource],
  BRAND_FIELD: [...defaultMappingSource],
  TAG_FIELD: [...defaultMappingSource],
  CHOICE_FIELD: [...defaultMappingSource],
  MULTIPLE_CHOICE_FIELD: [...defaultMappingSource],
  FINANCIAL_FIELD: [
    ...defaultMappingSource,
    {
      value: MappingSourceType.DECIMAL,
      label: ts.tModule('field_sources.take_number'),
    },
    {
      value: MappingSourceType.UNIT,
      label: ts.tModule('field_sources.take_unit'),
    },
  ],
  KEY_VALUE_FIELD: [
    ...defaultMappingSource,
    {
      value: MappingSourceType.KEY,
      label: ts.tModule('field_sources.take_key'),
    },
  ],
  INPUT_SELECT_FIELD: [
    ...defaultMappingSource,
    {
      value: MappingSourceType.DECIMAL,
      label: ts.tModule('field_sources.take_number'),
    },
    {
      value: MappingSourceType.UNIT,
      label: ts.tModule('field_sources.take_unit'),
    },
  ],
};

const getSources = (attribute?: Attribute) => {
  const type = attribute?.options
    ? getFieldDefinitionOption(attribute?.options, 'type', undefined) || AttributeTypeEnum.DEFAULT
    : AttributeTypeEnum.DEFAULT;

  return sourcesPerFieldType[type as AttributeTypeEnum];
};

const addAttribute = async (attribute: Attribute) => {
  if (checked.value == null) {
    return;
  }

  const index = checked.value;

  if (
    editableMappingChipItems.value[index] != null &&
    editableMappingChipItems.value[index].length > 0
  ) {
    return;
  }

  if (editableMappingChipItems.value[index] == null) {
    editableMappingChipItems.value[index] = [];
  }

  editableMappingChipItems.value[index].forEach((item: any) => {
    if (item.attribute.id == attribute.id) used.value = true;
    else used.value = false;
  });

  if (!used.value) {
    const mappingItem = await saveAttributes(attribute, index);

    if (mappingItem) {
      editableMappingChipItems.value[index].push(mappingItem);
    }
  } else {
    toastService.displayErrorToast(ts.tModule('export_mapping_supported.attribute_exists'));
  }
};

const saveAttributes = async (attribute: Attribute, index: number): Promise<MappingItem | void> => {
  try {
    const mapping: CreateMappingItemRequest = {
      module_id: props.selectedModule?.id ?? 0,
      datamodel_id: props.selectedDatamodel?.id ?? 0,
      attribute_id: attribute.id ?? 0,
      template_endpoint_id: index,
      source: selectedSource?.value[attribute?.id].value,
      owner_id: props.ownerId ?? 0,
      source_locale_id: attribute && selectedLocale.value[attribute.id]?.id,
      argument: '',
    };

    const mappingItem = await mappingItemsApi.post(mapping);

    toastService.displaySuccessToast(ts.createSuccess());

    return mappingItem;
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('export_mapping_supported.adding_failed'));
  }
};

const removeMappingItem = async (mappingItem: MappingItem) => {
  try {
    await mappingItemsApi.delete(mappingItem.id);
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  }
};

const onAttributeRemove = (mappingItem: MappingItem) => {
  const index =
    editableMappingChipItems.value[mappingItem.template_endpoint_id ?? 0].indexOf(mappingItem);

  removeMappingItem(mappingItem);
  editableMappingChipItems.value[mappingItem.template_endpoint_id ?? 0].splice(index, 1);
};

const removeAll = (id: number) => {
  editableMappingChipItems.value[id].forEach((item: MappingItem) => {
    removeMappingItem(item);
  });

  editableMappingChipItems.value[id] = [];
};

const loadData = () => {
  editableMappingChipItems.value = [];
  props.mappingItems.forEach((el) => {
    editableMappingChipItems.value[el.template_endpoint_id ?? 0] = [];
    editableMappingChipItems.value[el.template_endpoint_id ?? 0].push(el);
  });
};

watch(
  () => props.endpoints,
  () => {
    loadData();
  },
);

const sortMapping = (data: any[], sortBy: string) => {
  return data
    ? data.sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0))
    : data;
};

const filteredAttributes = computed(() => {
  return searchText.value
    ? props.attributes.filter((attribute) =>
        attribute.key.toLowerCase().includes(searchText.value.toLowerCase()),
      )
    : props.attributes;
});

const isOriginalKeyValid = (id: number) => {
  return props.attributeConfigs?.find((x) => x.attribute_id === id)?.name ?? undefined;
};

/** Getting the locales */
const locales = ref<LocaleDetails[]>();
const getLocales = async () => {
  locales.value = (await store.dispatch('locales/SEARCH_LOCALES', { query: '' })).data;
};

onMounted(() => {
  loadData();
  getLocales();
});
</script>
<template>
  <div class="flex w-full">
    <div class="col-12" :class="!viewOnly ? 'md:col-6' : ''">
      <LoadingIndicator v-if="loading" />
      <div
        v-for="endpoint in sortMapping(endpoints, 'label')"
        v-else
        :key="endpoint.id"
        class="mb-3 mt-3"
      >
        <div class="flex justify-content-between">
          <label class="block font-bold mb-1" :for="endpoint.label">
            {{ endpoint.label }}
          </label>
        </div>
        <small class="block mb-2 text-sm">{{ endpoint.description }}</small>
        <div class="grid mr-4">
          <div class="col-12 md-12">
            <div
              class="flex"
              :class="checked == endpoint.id ? 'border-1 border-round border-primary' : ''"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <div class="p-field-radiobutton">
                    <p-radio-button v-model="checked" :value="endpoint.id" :disabled="viewOnly" />
                  </div>
                </span>
                <p-chips
                  v-model="editableMappingChipItems[endpoint.id]"
                  readonly
                  :disabled="viewOnly"
                >
                  <template #chip="slotProps">
                    <div class="align-items-center flex">
                      <span v-if="slotProps.value.attribute.key != null" class="text-lg">
                        {{
                          isOriginalKeyValid(slotProps.value.attribute.id) ??
                          slotProps.value.attribute.key
                        }}
                      </span>
                      <span v-else> {{ slotProps.value }} </span>
                      <span v-if="slotProps.value.source_locale_value != null" class="ml-2 text-sm">
                        [ {{ slotProps.value.source_locale_value }} ]</span
                      >
                      <span v-if="slotProps.value.source != null" class="text-sm"
                        >&nbsp; [ {{ slotProps.value.source }} ]</span
                      >
                      <span
                        class="cursor-pointer mdi mdi-close-circle-outline ml-1"
                        @click="onAttributeRemove(slotProps.value)"
                      />
                      <p
                        v-if="isOriginalKeyValid(slotProps.value.attribute.id)"
                        v-tooltip.top="{
                          value: slotProps.value.attribute.key,
                        }"
                        class="ml-2 original-name text-sm"
                      >
                        <b>{{ ts.tModule('export_mapping_supported.original') }}:</b>
                        {{ slotProps.value.attribute.key }}
                      </p>
                    </div>
                  </template>
                </p-chips>
                <div>
                  <p-button
                    :disabled="
                      editableMappingChipItems[endpoint.id] <= 0 ||
                      editableMappingChipItems[endpoint.id] == null ||
                      viewOnly
                    "
                    style="border-radius: 0 5px 5px 0"
                    class="h-full"
                    icon="mdi mdi-delete"
                    @click="removeAll(endpoint.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!viewOnly" class="col-6 hidden md:block md:col-6">
      <p-fieldset
        :legend="tsAttributes.tModule('title', { choice: 2 })"
        class="flex p-fieldset-custom w-full"
      >
        <div>
          <div style="max-height: 35rem" class="grid overflow-x-hidden overflow-y-auto">
            <!-- Search Input -->
            <span class="mb-3 p-input-icon-left pr-2 w-full">
              <i class="mdi mdi-magnify" />
              <p-inputText
                v-model="searchText"
                class="w-full"
                type="text"
                :placeholder="ts.tGlobal('search')"
              />
            </span>
            <div
              v-for="attribute in sortMapping(filteredAttributes, 'key')"
              :key="attribute.id"
              class="col-12 pl-0"
            >
              <div
                class="border-1 border-200 border-round hover:surface-50 p-2 py-3"
                :class="checked == null ? 'surface-50' : ''"
              >
                <div class="flex justify-content-between pb-2">
                  <div>
                    <span class="font-bold">
                      {{ isOriginalKeyValid(attribute.id) ?? attribute.key }}
                    </span>
                    <p class="text-xs">
                      {{ attribute.options ? attribute.options.type : 'DEFAULT' }}
                    </p>
                  </div>
                  <div
                    v-if="isOriginalKeyValid(attribute.id) !== undefined"
                    class="flex flex-wrap justify-content-end w-4"
                  >
                    <p
                      v-tooltip.top="{
                        value: attribute.key,
                      }"
                      class="original-name text-sm"
                    >
                      <b>{{ ts.tModule('export_mapping_supported.original') }}:</b>
                      {{ attribute.key }}
                    </p>
                  </div>
                </div>

                <div class="align-items-center flex justify-content-between">
                  <div class="align-content-end flex w-full">
                    <div style="width: 45%">
                      <LocaleSelect
                        v-model="selectedLocale[attribute.id]"
                        :disabled="checked == null"
                        do-not-put-value-on-mounted
                        hide-label
                        :options="locales"
                        class="align-items-center flex h-full mr-2"
                      />
                    </div>
                    <div style="width: 45%">
                      <pDropdown
                        v-model="selectedSource[attribute.id]"
                        :disabled="checked == null"
                        class="align-items-center h-fullflex mr-2 w-full"
                        option-label="label"
                        :options="getSources(attribute)"
                        :placeholder="ts.tModule('export_mapping_supported.select_source')"
                        :show-clear="true"
                      ></pDropdown>
                    </div>

                    <div class="align-items-center flex justify-content-center" style="width: 10%">
                      <p-button
                        text
                        :disabled="checked == null || selectedSource[attribute.id] == null"
                        class="button-plus-custom p-button-sm"
                        icon="mdi mdi-plus"
                        @click="addAttribute(attribute)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.p-chips :deep(.p-chips-token-icon) {
  display: none;
}
.p-fieldset-custom :deep > legend {
  border: 0px solid #fff;
  color: #000;
  background: rgba($color: #000000, $alpha: 0);
}
.p-fieldset-custom :deep {
  min-inline-size: 0;
}
.p-fieldset-custom :deep > div {
  min-inline-size: 0;
}
.original-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(139, 170, 201);
}
.button-plus-custom :deep > span::before {
  scale: 2.5;
}
</style>
