<script setup lang="ts">
import { User } from 'platform-unit2-api/users';
import store from '@/core/store';
import { computed } from 'vue';

const currentUser = computed<User>(() => store.getters['users/currentUser']);
</script>

<template>
  <p-inline-message
    v-if="currentUser && currentUser.admin_mode"
    severity="info"
    style="height: 40px"
    :sticky="true"
    :closable="true"
  >
    You are using Productsup Platform as: Admin!</p-inline-message
  >
</template>
