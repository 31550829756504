<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import usePagination from 'composables/usePagination/pagination';
import useSortable from 'composables/sortable';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { Role, RolesRestService } from 'platform-unit2-api/roles';
import { DataTablePageEvent, DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';

import useDebounce from 'utils/debounce';

/** Composables */
const router = useRouter();
const route = useRoute();
const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const { sort, onSortChange: $onSortChange } = useSortable();
const { debounce } = useDebounce();

/** Services */
const ts = new TranslationService('admin', 'roles');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const rolesApi = new RolesRestService();

/** Constants */
const roles = ref<Role[]>([]);
const total = ref<number>(0);
let searchCallback: any = undefined;

const loading = ref(false);
const showSidebar = ref(false);

const hideDetails = () => {
  router.push({
    name: 'roles',
  } as RouteLocationRaw);
};

const loadAsyncData = async (searchQuery?: string): Promise<void> => {
  loading.value = true;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    const response = await rolesApi.getAll({
      query: query.value,
      page: page.value,
      limit: perPage.value,
      sortBy: sort.value,
    });

    roles.value = response?.data;
    total.value = response.meta?.total ?? 0;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData(query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

const deleteRole = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await rolesApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    loading.value = false;
  }
};

const confirmRoleDelete = (event: PointerEvent, role: Role) => {
  confirmService.confirmDelete({
    event: event,
    group: 'roles',
    message: ts.deleteConfirm(role.name),
    callback: () => deleteRole(role.id),
  });
};

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

const onSortChange = (event: DataTableSortEvent) => {
  $onSortChange((event.sortOrder ?? 0 < 0 ? '-' : '') + event.sortField, loadAsyncData);
};

// Lifecycle hooks
onMounted(() => {
  if (route.name === 'new-role' || route.name === 'edit-role') {
    showSidebar.value = true;
  }

  loadAsyncData();
});

watch(
  () => route,
  () => {
    if (route.name === 'new-role' || route.name === 'edit-role') {
      showSidebar.value = true;
    }

    if (route.name === 'roles') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);
</script>

<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <pIconField icon-position="left" class="my-3">
      <pInputIcon class="pi pi-search" />
      <pInputText
        :placeholder="ts.tGlobal('search')"
        @update:model-value="(value: string) => search(value)"
      />
    </pIconField>

    <pDataTable
      scrollable
      scroll-height="flex"
      :value="roles"
      removable-sort
      :row-hover="true"
      :lazy="true"
      :paginator="(total ?? 0) > perPage"
      :rows="perPage"
      :total-records="total"
      :loading="loading"
      :first="(page - 1) * perPage"
      @row-click="
              (event: DataTableRowClickEvent) =>
                router.push({ name: 'edit-role', params: { id: event.data.id.toString() } })
            "
      @page="(event: DataTablePageEvent) => onPageChange(event)"
      @sort="(event: DataTableSortEvent) => onSortChange(event)"
    >
      <p-column field="name" :header="ts.tGlobal('name')" sortable />
      <p-column field="icons">
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <p-button
              icon="mdi mdi-delete-outline"
              text
              severity="danger"
              rounded
              @click="(event: any) => confirmRoleDelete(event, slotProps.data)"
            />
          </div>
        </template>
      </p-column>
      <template #empty>
        <p-message p-message severity="info" :closable="false">
          {{ ts.notFoundWarning }}
        </p-message>
      </template>
    </pDataTable>

    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-md sidebar-crud"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="loadAsyncData" />
    </pSidebar>
    <p-confirm-popup group="roles" />
  </section>
</template>
