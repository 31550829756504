import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Field',
  subTitle: 'Here you can create a new field.',
};

const update: TranslationObjectAction = {
  title: 'Edit Field',
  subTitle: 'Here you can edit an field.',
};

const translationObject: TranslationObject = {
  title: 'Field | Fields',
  placeholder: 'Select field | Select fields',

  failedToFindParent: 'failed to find parent field',
  type: 'Field Type',
  globalField: 'Global field',

  article_link: 'Article link',

  helpText: 'Help text',
  maxLength: 'Maximum length',
  choices: 'Choices',
  inputSelectValues: 'Input-select values',
  currencies: 'Currencies',
  preDefinedOptions: 'Pre-defined options',
  chooseDefaultUnit: 'Choose a default unit',
  chooseDefaultCurrency: 'Choose a default currency',
  selectType: 'Multi-select',
  groupOrder: 'Group order',
  multiple: 'Allow multiple instances',
  groupSize: 'Group column size',
  collapsible: 'Collapsible',
  parentId: 'Parent attribute',

  articleLink: 'Article link',

  keyValueField: {
    notJson: 'Failed to parse the old data structure into key and value pairs.',
    noMoreOptions: 'There are no other predefined options.',
    key: 'Key',
    noResultsFound: 'No results found',
    value: 'Value',
    addPair: 'Add pair',
  },

  emptyStateTitle: 'No attributes found',
  emptyStateSubTitle:
    'Start by creating your attributes. Once you have created a attribute it will be shown here.',
  createButton: 'Create attribute',

  create: create,
  update: update,
};

const tranlation: Translation = {
  fields: translationObject,
};

export default tranlation;
