<script setup lang="ts">
import AttributeEditor from 'retailer/modules/products/components/attribute-editor.vue';
import AttributeSelect from '@/general/ui/components/selects/attribute-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Attribute } from 'platform-unit2-api/attributes';
import { LocaleDetails } from 'platform-unit2-api/locales';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');

/** Consts */
const store = useStore();
const attribute = ref<Attribute>();
const value = ref<string | null | undefined>();
const selectedLocale = ref<LocaleDetails>();

onMounted(() => {
  if (store.getters['users/currentUser'] && store.getters['users/currentUser'].locale) {
    selectedLocale.value = store.getters['users/currentUser'].locale;
  }
});

const emptyFieldDefinition = () => {
  attribute.value = undefined;
};

const assign = async () => {
  if (value.value == null || !attribute.value) {
    return;
  }

  try {
    await store.dispatch('products/BULK_ACTION_MASS_UPDATE_FIELDS', {
      ids: props.ids,
      locale_id: selectedLocale.value?.id,
      productDefinitionId: attribute.value?.id,
      value: value.value,
    });
    toastService.displaySuccessToast(
      ts.tModule('mass_update.success', {
        params: {
          field: attribute.value.key,
          amount: props.ids.length,
        },
      }),
    );
    emit('hide');
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('mass_update.error'));
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const updateButton = new CreateButton({
  label: ts.tGlobal('update'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!(attribute.value || value),
  onClick: assign,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, updateButton],
});
</script>

<template>
  <BaseDialog
    :title="ts.tModule('mass_update.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="$emit('hide')"
  >
    <div class="field mb-3">
      <label class="font-bold">{{ ts.tGlobal('select_language') }}</label>
      <LocaleSelect v-model="selectedLocale" hide-label />
    </div>

    <div v-if="!attribute" class="field mb-3">
      <AttributeSelect v-model="attribute" />
      <small class="p-text-secondary">{{ ts.tModule('mass_update.select_field_message') }}</small>
    </div>

    <div v-else class="field mb-3">
      <div class="align-items-center flex justify-content-between">
        <p>
          {{
            ts.tModule('mass_update.updating_notification', {
              params: {
                field: attribute.key,
                amount: ids.length,
              },
            })
          }}
        </p>
        <p-button
          outlined
          :label="ts.tModule('mass_update.change_field')"
          @click="emptyFieldDefinition"
        />
      </div>
      <div>
        <AttributeEditor
          :locale="selectedLocale"
          :attribute="attribute"
          :lockable="false"
          @input="value = $event"
        />
      </div>
    </div>
  </BaseDialog>
</template>
