import { CrudDataFilterOption } from 'platform-unit2-api/core';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Locale } from 'platform-unit2-api/locales';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Product, UpdateProductField, ErrorProductFieldData } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import { MutationTree } from 'vuex';
import { ProductsState, TableColumn } from '../products.types';

export const mutations: MutationTree<ProductsState> = {
  setProducts(state: ProductsState, products: ListResponse<Product> | undefined) {
    state.products = products;
  },

  setProduct(state: ProductsState, product: Product | undefined) {
    state.currentProduct = product;
  },

  setLoadingProduct(state: ProductsState, product: number | undefined) {
    state.loadingProduct = product;
  },

  setNextProduct(state: ProductsState, product: Product | undefined) {
    state.nextProduct = product;
  },

  setPreviousProduct(state: ProductsState, product: Product | undefined) {
    state.previousProduct = product;
  },

  setResetProduct(state: ProductsState, product: Product | undefined) {
    state.currentResetProduct = product;
  },

  setEditingProductFields(state: ProductsState, editingFields: UpdateProductField[]) {
    state.editingProductFields = editingFields;
  },

  setErrorProductFields(state: ProductsState, errorFields: ErrorProductFieldData[]) {
    state.errorProductFields = errorFields;
  },

  setLoadingCompleteness(state: ProductsState, value: boolean) {
    state.loadingCompleteness = value;
  },

  setBrandFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.brandFilter = filter;
  },

  setCategoryFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.categoryFilter = filter;
  },

  setDataModelFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.dataModelFilter = filter;
  },

  setVariantFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.variantFilter = filter;
  },

  setStatusFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.statusFilter = filter;
  },

  setOwnerFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.ownerFilter = filter;
  },

  setModuleFilter(state: ProductsState, filter: CrudDataFilterOption[]) {
    state.moduleFilter = filter;
  },

  setGtinFilter(state: ProductsState, filter: []) {
    state.gtinFilter = filter;
  },

  setGlnFilter(state: ProductsState, filter: number[]) {
    state.glnFilter = filter;
  },

  setOverviewPagination(state: ProductsState, pagination: PaginationObject) {
    state.overviewPagination = pagination;
  },

  setColumns(state: ProductsState, columns: TableColumn[]) {
    state.columns = columns;
  },

  setIdFilter(state: ProductsState, filter: number[]) {
    state.idFilter = filter;
  },

  setSearch(state: ProductsState, query: string) {
    state.search = query;
  },

  setCurrentProductVariants(state: ProductsState, variants: Product[]) {
    state.currentProductVariants = variants;
  },

  setProductDatamodels(state: ProductsState, datamodels: Datamodel[]) {
    state.currentProductDatamodels = datamodels;
  },

  setInitialized(state: ProductsState, value: boolean) {
    state.initialized = value;
  },

  setMissingFields(state: ProductsState, value: boolean) {
    state.showMissingFields = value;
  },

  setSelectedLocales(state: ProductsState, locales: Locale[]) {
    state.selectedLocales = locales;
  },

  setCopyFromLanguage(state: ProductsState, status: boolean) {
    state.copyFromLanguage = status;
  },

  setProductFields(state: ProductsState, fields: ProductField[]) {
    state.fields = fields;
  },

  setImagesIncluded(state: ProductsState, imagesIncluded: Upload[]) {
    state.imagesIncluded = imagesIncluded;
  },

  setCurrentProduct(state: ProductsState, product: Product) {
    state.currentProduct = product;
  },

  setShouldRunAttributesValidation(state: ProductsState, value: boolean) {
    state.shouldRunAttributesValidation = value;
  },
};
