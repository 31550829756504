<script setup lang="ts">
//Core
import { onMounted, ref } from 'vue';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { DeveloperService } from '@/platforms/supplier/modules/settings/services/developers.service';

//Components
import NoAccess from '@/platforms/supplier/modules/settings/components/developers/no-access.vue';
import GenerateToken from '@/platforms/supplier/modules/settings/components/developers/generateToken.vue';
import TokensOverview from '@/platforms/supplier/modules/settings/components/developers/tokensOverview.vue';
import ApiLog from '@/platforms/supplier/modules/settings/components/developers/api-log.vue';

/* Services */
const ts = new TranslationService('supplier', 'settings');
const developerService = ref(new DeveloperService());

onMounted(async () => {
  if (developerService.value.currentUser?.workspace?.has_public_api) {
    await developerService.value.getApiKey();
  }
});
</script>

<template>
  <section class="h-full pt-3 px-4">
    <!-- Header -->
    <div class="flex justify-content-between m-3">
      <div>
        <h4 class="font-bold mb-1">{{ ts.tModule('developers.title') }}</h4>
        <p class="p-button-outlined text-600 text-sm">{{ ts.tModule('developers.description') }}</p>
      </div>
      <a href="https://api.worldofcontent.com/docs/" target="_blank"
        ><p-button class="p-button-outlined text-sm">{{
          ts.tModule('developers.guide')
        }}</p-button></a
      >
    </div>

    <!-- Tab view -->
    <p-tab-view class="m-3">
      <p-tab-panel :header="ts.tModule('developers.api_key')">
        <div
          v-if="developerService.loading"
          class="align-items-center flex flex-column justify-content-center"
        >
          <p-skeleton height="30px" class="mb-2" />
          <div class="loading-box">
            <p-skeleton height="30px" class="mb-2" />
            <p-skeleton height="150px" class="mb-2" />
            <p-skeleton height="30px" class="mb-2" />
          </div>
        </div>
        <div v-if="!developerService.loading">
          <!-- The user doesn't have access to generate api key -->
          <NoAccess v-if="!developerService.currentUser?.workspace?.has_public_api" />

          <!-- The user has the access but there is no API key yet -->
          <GenerateToken
            v-else-if="!developerService.apiKey.length"
            @generate-new-key="developerService.generateNewKey()"
          />
          <!-- The user has already created an API key -->
          <TokensOverview
            v-else-if="developerService.apiKey.length"
            :developer-service="developerService"
            @delete-key="(event) => developerService.deleteKey(event)"
          />
        </div>
      </p-tab-panel>
      <p-tab-panel
        :header="ts.tModule('developers.api_log')"
        :disabled="!developerService.currentUser?.workspace?.has_public_api"
      >
        <ApiLog />
      </p-tab-panel>
    </p-tab-view>
  </section>
</template>
<style lang="scss" scoped>
.loading-box {
  margin-top: 40px;
  width: clamp(300px, 100%, 600px);
}
</style>
