<script setup lang="ts">
import { onMounted, ref } from 'vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import UserSelect from '@/general/ui/components/selects/user-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import OrganisationSelect from '@/general/ui/components/selects/organisation-select.vue';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

import { Client, CreateClientRequest, ClientsRestService } from 'platform-unit2-api/clients';
import { ClientType, ClientTypesRestService } from 'platform-unit2-api/client-types';
import { Thumbnail } from 'platform-unit2-api/thumbnails';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

const formErrors = ref({
  name: '',
  workspace_type_id: '',
  organisation_id: '',
  locale_ids: '',
});

const resetFormErrors = () => {
  formErrors.value = {
    name: '',
    workspace_type_id: '',
    organisation_id: '',
    locale_ids: '',
  };
};

/** Services */
const ts = new TranslationService('admin', 'clients');
const toastService = ToastService.getInstance();
const clientTypeAPi = new ClientTypesRestService();
const clientApi = new ClientsRestService();

/** Constants */
const saving = ref(false);
const editorWorkspace = ref<Client>({} as Client);
const thumbnailValue = ref<Thumbnail>();
const selectedClientType = ref<ClientType>();
const clientTypes = ref<ClientType[]>([]);

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const client: CreateClientRequest = {
      name: editorWorkspace.value?.name ?? '',
      workspace_type_id: selectedClientType?.value?.id,
      general_contact_id: editorWorkspace?.value?.general_contact
        ? editorWorkspace?.value.general_contact.id
        : undefined,
      financial_contact_id: editorWorkspace?.value!.financial_contact
        ? editorWorkspace?.value.financial_contact.id
        : undefined,
      thumbnail_id: thumbnailValue.value ? thumbnailValue.value.id : undefined,
      locale_ids: editorWorkspace?.value?.locales
        ? editorWorkspace?.value.locales?.map((locale) => locale.id)
        : [],
      module_ids: editorWorkspace?.value?.modules
        ? editorWorkspace?.value?.modules.map((module) => module.id)
        : [],
      organisation_id: editorWorkspace.value?.organisation?.id,

      has_public_api: editorWorkspace.value?.has_public_api ?? false,
      has_ai_scanning: editorWorkspace.value?.has_ai_scanning ?? false,
    };

    if (!client.name) {
      formErrors.value.name = ts.t('validation.required');
    }

    if (client.workspace_type_id == null) {
      formErrors.value.workspace_type_id = ts.t('validation.required');
    }

    if (client.locale_ids.length === 0) {
      formErrors.value.locale_ids = ts.t('validation.required');
    }

    if (client.organisation_id == null) {
      formErrors.value.organisation_id = ts.t('validation.required');
    }

    if (Object.values(formErrors.value).every((err) => !err)) {
      await clientApi.post(client);
      emit('refresh');
      emit('hide');

      toastService.displaySuccessToast(ts.createSuccess(client.name));
    }
  } catch (err) {
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    saving.value = false;
  }
};

/** lifecycle */
onMounted(async () => {
  clientTypes.value = (await clientTypeAPi.getClientTypes()).data;
});
</script>
<template>
  <CrudSidebar
    :title="ts.moduleCreateTitle"
    :subtitle="ts.moduleCreateSubTitle"
    :saving="saving"
    @cancel="$emit('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <div class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          v-model="editorWorkspace.name"
          class="w-full"
          :class="{ 'p-invalid': formErrors.name }"
          type="text"
        />
        <small v-if="formErrors.name" :class="{ 'p-error block': formErrors.name }">{{
          formErrors.name
        }}</small>
      </div>
      <div class="field mb-3">
        <label for="email">{{ ts.tModule('general_contact') }}</label>
        <UserSelect v-model="editorWorkspace.general_contact" hide-label />
      </div>

      <div class="field mb-3">
        <label for="email">{{ ts.tModule('financial_contact') }}</label>
        <UserSelect v-model="editorWorkspace.financial_contact" hide-label />
      </div>
      <div class="field mb-3">
        <label for="logo">{{ ts.tGlobal('logo') }}</label>
        <div class="align-items-center flex formgroup-inline">
          <ThumbnailUploader
            :thumbnail="thumbnailValue"
            @uploaded="(value) => (thumbnailValue = value)"
          />
        </div>
      </div>
      <div class="field">
        <ModuleSelect
          v-model="editorWorkspace.modules"
          :pagination-params="{
            limit: 500,
            page: 1,
            sortBy: 'name',
          }"
          multiselect
        />
      </div>
      <div class="field mb-3">
        <OrganisationSelect
          v-model="editorWorkspace.organisation"
          :error-message="formErrors.organisation_id"
        />
      </div>
      <div class="flex gap-4">
        <!-- Public API -->
        <div class="field mb-3">
          <label class="w-full">{{ ts.tModule('has_public_api') }}</label>
          <p-input-switch v-model="editorWorkspace.has_public_api" />
        </div>
        <!-- AI Scanning -->
        <div class="field mb-3">
          <label class="w-full">{{ ts.tModule('hasAiScanning') }}</label>
          <p-input-switch v-model="editorWorkspace.has_ai_scanning" />
        </div>
      </div>
      <div class="field mb-3">
        <label>{{ ts.tGlobal('languages', { choice: 2 }) }}</label>
        <LocaleSelect
          v-model="editorWorkspace.locales"
          :error-message="formErrors.locale_ids"
          multiselect
          hide-label
        />
      </div>

      <div class="field mb-5">
        <label for="type">{{ ts.tGlobal('type') }}</label>
        <p-select-button
          id="type"
          v-model="selectedClientType"
          :options="clientTypes"
          option-label="type"
        />
        <small
          v-if="formErrors.workspace_type_id"
          :class="{ 'p-error block': formErrors.workspace_type_id }"
          class="hidden"
          >{{ ts.t('validation.required') }}</small
        >
      </div>
    </template>
  </CrudSidebar>
</template>
