<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import UserSelect from '@/general/ui/components/selects/user-select.vue';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import useFormValidation from 'composables/form-validation';
import { useRoute } from 'vue-router';
import OrganisationSelect from '@/general/ui/components/selects/organisation-select.vue';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';

import { ClientType, ClientTypesRestService } from 'platform-unit2-api/client-types';
import { Client, UpdateClientRequest, ClientsRestService } from 'platform-unit2-api/clients';
import { Thumbnail } from 'platform-unit2-api/thumbnails';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { isNegative } from '@/general/utils/isNegative';
import { isPositive } from '@/general/utils/isPositive';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const route = useRoute();

const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'clients');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const clientApi = new ClientsRestService();
const clientTypeAPi = new ClientTypesRestService();

/** Constant */
const loading = ref(false);
const saving = ref(false);
const selectedClientType = ref<ClientType>();
const clientTypes = ref<ClientType[]>([]);
const editorWorkspace = ref<Client>();
const thumbnailValue = ref<Thumbnail | undefined>();

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();

  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.updateFailed());
    return;
  }

  try {
    saving.value = true;
    const client: UpdateClientRequest = {
      id: editorWorkspace.value!.id,
      name: editorWorkspace.value!.name,
      workspace_type_id: selectedClientType.value?.id,
      general_contact_id: editorWorkspace.value?.general_contact
        ? editorWorkspace.value?.general_contact.id
        : undefined,
      financial_contact_id: editorWorkspace.value?.financial_contact
        ? editorWorkspace.value?.financial_contact.id
        : undefined,
      thumbnail_id: thumbnailValue.value?.id ?? null,
      module_ids: editorWorkspace.value!.modules!.map((module) => module.id),
      locale_ids: editorWorkspace.value!.locales!.map((locale) => locale.id),
      organisation_id: editorWorkspace.value!.organisation!.id,
      has_public_api: editorWorkspace.value!.has_public_api,
      has_ai_scanning: editorWorkspace.value!.has_ai_scanning,
    };

    await clientApi.update(Number(route.params.id), client);
    emit('refresh');
    emit('hide');
    toastService.displaySuccessToast(ts.updateSuccess(client.name));
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.updateFailed());
    });
  } finally {
    saving.value = false;
  }
};

const deleteClient = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await clientApi.delete(id);
    emit('refresh');
    emit('hide');
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (err: any) {
    toastService.displayErrorToast(ts.deleteFailed());
  }

  loading.value = false;
};

const confirmClientDelete = (event: PointerEvent | undefined, id?: number) => {
  if (id != null) {
    confirmService.confirmDelete({
      event: event,
      message: ts.deleteConfirm(editorWorkspace.value?.name),
      group: 'client-edit',
      callback: () => deleteClient(id),
    });
  }
};

//If the user tries to disable the api access, show a warning
//We also do it just when the client already has the api enabled before editing
const doesUserAllowApiAccess = ref(); //The current status of the api access
const showApiWarning = ref(false);
watch(
  () => editorWorkspace.value?.has_public_api,
  (newValue) => {
    if (newValue === false && doesUserAllowApiAccess.value === true) {
      showApiWarning.value = true;
    } else {
      showApiWarning.value = false;
    }
  },
);

const editApiAccess = (status: boolean) => {
  editorWorkspace.value!.has_public_api = status;
  showApiWarning.value = false;
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => editApiAccess(true),
});

const disableButton = new CreateButton({
  label: ts.tModule('api_warning_dialog_confirm'),
  position: CrudButtonPosition.RIGHT,
  styleClasses: 'p-button-danger',
  onClick: () => editApiAccess(false),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, disableButton],
});

/** Lifecylce */
onMounted(async () => {
  loading.value = true;

  if (isPositive(route.params.id)) {
    editorWorkspace.value = await clientApi.get(Number(route.params.id));
  }

  //The current status of the api access
  doesUserAllowApiAccess.value = editorWorkspace.value?.has_public_api;
  thumbnailValue.value = editorWorkspace.value?.thumbnail;
  selectedClientType.value = editorWorkspace.value?.workspace_type;

  clientTypes.value = (await clientTypeAPi.getClientTypes()).data;

  loading.value = false;
});
</script>
<template>
  <SidebarSkeleton v-if="loading" />
  <CrudSidebar
    v-else
    :disable-save="false"
    :title="ts.moduleUpdateTitle"
    :subtitle="ts.moduleUpdateSubTitle"
    :saving="saving"
    @cancel="emit('hide')"
    @save="handleSubmit()"
    @delete="confirmClientDelete($event, editorWorkspace?.id)"
  >
    <template #sidebar-data>
      <div v-if="editorWorkspace" class="field mb-3">
        <label>{{ ts.tForms('name') }}</label>
        <p-input-text
          v-model="editorWorkspace.name"
          class="w-full"
          :class="{ 'p-invalid': hasError('name') }"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <label>{{ ts.tModule('general_contact') }}</label>
        <UserSelect
          v-model="editorWorkspace.general_contact"
          :class="{ 'p-invalid': hasError('general_contact_id') }"
          hide-label
        />
        <small
          v-if="hasError('general_contact_id')"
          :class="{ 'p-error block': hasError('general_contact_id') }"
          class="hidden"
          >{{ fieldErrorMessage('general_contact_id').toString() }}</small
        >
      </div>

      <div v-if="editorWorkspace" class="field mb-3">
        <label>{{ ts.tModule('financial_contact') }}</label>
        <UserSelect
          v-model="editorWorkspace.financial_contact"
          :class="{ 'p-invalid': hasError('financial_contact_id') }"
          hide-label
        />
        <small
          v-if="hasError('financial_contact_id')"
          :class="{ 'p-error block': hasError('financial_contact_id') }"
          class="hidden"
          >{{ fieldErrorMessage('financial_contact_id').toString() }}</small
        >
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <label>{{ ts.tGlobal('logo') }}</label>
        <div class="align-items-center flex formgroup-inline">
          <ThumbnailUploader
            :thumbnail="thumbnailValue || editorWorkspace.thumbnail"
            @uploaded="(value) => (thumbnailValue = value)"
          />
        </div>
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <ModuleSelect
          v-model="editorWorkspace.modules"
          :class="{ 'p-invalid': hasError('module_ids') }"
          :pagination-params="{
            limit: 500,
            page: 1,
            sortBy: 'name',
          }"
          multiselect
        />
        <small
          v-if="hasError('module_ids')"
          :class="{ 'p-error block': hasError('module_ids') }"
          class="hidden"
          >{{ ts.t('validation.required') }}</small
        >
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <OrganisationSelect
          v-model="editorWorkspace.organisation"
          :class="{ 'p-invalid': hasError('organisation_id') }"
        />
        <small
          v-if="hasError('organisation_id')"
          :class="{ 'p-error block': hasError('organisation_id') }"
          class="hidden"
          >{{ ts.t('validation.required') }}</small
        >
      </div>

      <div class="flex gap-4">
        <div v-if="editorWorkspace" class="field mb-3">
          <label class="w-full">{{ ts.tModule('has_public_api') }}</label>
          <p-input-switch v-model="editorWorkspace.has_public_api" />
        </div>
        <!-- AI Scanning -->
        <div v-if="editorWorkspace" class="field mb-3">
          <label class="w-full">{{ ts.tModule('hasAiScanning') }}</label>
          <p-input-switch v-model="editorWorkspace.has_ai_scanning" />
        </div>
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <label>{{ ts.tGlobal('languages', { choice: 2 }) }}</label>
        <LocaleSelect
          v-model="editorWorkspace.locales"
          :class="{ 'p-invalid': hasError('locale_ids') }"
          multiselect
          hide-label
        />
        <small
          v-if="hasError('locale_ids')"
          :class="{ 'p-error block': hasError('locale_ids') }"
          class="hidden"
          >{{ ts.t('validation.required') }}</small
        >
      </div>

      <!-- Api warning Dialog -->
      <BaseDialog
        :visible="showApiWarning"
        :title="ts.tModule('api_warning_dialog_header')"
        size="small"
        :buttons-options="buttonsOptions"
      >
        <p>{{ ts.tModule('api_warning_dialog_content') }}</p>
      </BaseDialog>

      <!-- Confirm popup -->
      <p-confirm-popup group="client-edit" />
      <div class="field mb-5">
        <label for="type">{{ ts.tGlobal('type') }}</label>
        <p-select-button
          id="type"
          v-model="selectedClientType"
          :options="clientTypes"
          option-label="type"
        />
      </div>
    </template>
  </CrudSidebar>
</template>
