import { BaseViewService } from '../../view/base-view.service';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import store from '@/core/store';
import { TranslationService } from '../../../translations/translation.service';
import { User } from 'platform-unit2-api/users';
import { PaginationObject, ListResponse } from 'platform-unit2-api/core';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import axios from 'axios';
import { AwsFileService } from '../../../file-service/aws-file.service';
import {
  CreateUploadRequest,
  Upload,
  UpdateUploadRequest,
  UploadsRestService,
} from 'platform-unit2-api/uploads';
import { useStore } from 'vuex';
import AttachAssetsService from './attach-assets.service';
import ImportAssetsService from './import-assets.service';

export default class AssetViewService extends BaseViewService<
  UploadsRestService,
  Upload,
  {},
  UpdateUploadRequest & { id: number }
> {
  private _awsFileService = new AwsFileService();
  private store;
  selectedStatus: 'all' | 'some' | 'none' = 'none';
  checkedAssets: Upload[] = [];
  isUploading = false;
  layout = 'grid';
  disabled = false;
  sortFields: { label: string; field: string }[] = [
    { label: this._ts.tModule('sort.new_old'), field: '-created_at' },
    { label: this._ts.tModule('sort.old_new'), field: 'created_at' },
    { label: 'A - Z', field: 'filename' },
    { label: 'Z - A', field: '-filename' },
  ];
  importAssetsModal;
  attachAssetsModal;

  constructor(ts?: TranslationService) {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;
    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    super({
      Api: UploadsRestService,
      fetchAllFunction: 'getAll',
      ts:
        ts ??
        new TranslationService(
          currentSpace.toLowerCase() as 'admin' | 'retailer' | 'supplier' | 'general' | 'global',
          'media',
        ),
      confirmPopUpGroup: 'media-assets',
      createRouteName: 'new-media-asset',
      updateRouteName: 'update-media-asset',
      overviewRouteName: 'media-assets',
    });

    this.refetch = this.fetchAll;
    this._fetchVariables.limit = 16;
    this.sortBy = this.sortFields[0].field;
    this.store = useStore();

    this.importAssetsModal = new ImportAssetsService(this._ts, this._router, this.store);
    this.attachAssetsModal = new AttachAssetsService(this._ts, this.store);
  }

  public get assets() {
    return this._data;
  }
  public get checkedIds(): number[] {
    return this.checkedAssets.map((c) => c.id);
  }

  public get validated(): boolean {
    return true;
  }

  public get renderSelectButtonLabel(): string {
    const text =
      this.selectedStatus === 'none'
        ? this._ts.tGlobal('select_all')
        : this._ts.tGlobal('deselect_all');
    return text + ` (${this.checkedAssets.length}/${this.total})`;
  }

  public get renderSelectedStatusIcon(): string {
    return this.selectedStatus === 'none'
      ? 'mdi mdi-checkbox-blank-outline'
      : this.selectedStatus === 'some'
      ? 'mdi mdi-minus-box-outline'
      : 'mdi mdi-checkbox-marked-outline';
  }

  public createBody(): {} | undefined {
    return;
  }
  public updateBody(): (UpdateUploadRequest & { id: number }) | undefined {
    return {
      id: this.partialObject.id ?? 0,
      filename: this.partialObject.filename ?? '',
      asset_type_id: this.partialObject.asset_type?.id,
    };
  }

  public getFileSize(image?: Upload) {
    const upload = image ?? this.partialObject;
    const bytes = upload.file_size;
    if (bytes == null || bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  public manageSelectingAllFiles() {
    if (this.selectedStatus === 'none') {
      this.checkedAssets.push(
        ...this.assets.filter((asset) => !this.checkedIds.includes(asset.id)),
      );
      this.selectedStatus = 'all';
    } else {
      this.checkedAssets = this.checkedAssets.filter(
        (asset) => !this.assets.map((a) => a.id).includes(asset.id),
      );
      this.selectedStatus = 'none';
    }
  }

  public fetchAll(): void {
    this._isLoadingOverview = true;
    (
      this._restService[this._fetchAllFunction] as (
        variables: PaginationObject,
      ) => Promise<ListResponse<Upload>>
    )(this._fetchVariables)
      .then((response: ListResponse<Upload>) => {
        this._data = response.data;
        this.total = response.meta?.total;

        this.selectedStatus = this.assets
          .map((a) => a.id)
          .every((assetId) => this.checkedIds.includes(assetId))
          ? 'all'
          : this.assets.map((a) => a.id).some((assetId) => this.checkedIds.includes(assetId))
          ? 'some'
          : 'none';
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._data = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.isInitialized = true;
      });
  }

  private _createUploadRequest(file: File, ref: string): CreateUploadRequest {
    return {
      filename: file.name,
      contenttype: file.type,
      reference: ref,
      resolution_in_pixels_height: 0,
      resolution_in_pixels_width: 0,
    };
  }

  public uploadAssets(event: FileUploadUploaderEvent) {
    this.isUploading = true;
    const promises = (event.files as File[]).map(async (file: File) => {
      const sign = await this._awsFileService.signAndUpload(file);

      if (sign != null) {
        const createUploadRequest = this._createUploadRequest(file, sign);
        await this._restService.post(createUploadRequest);
      }
    });

    Promise.all(promises)
      .then(() => this.refetch())
      .catch(() => this._toastService.displayErrorToast(this._ts.uploadFailed()))
      .finally(() => {
        this.isUploading = false;
      });
  }

  public checkAsset(asset: Upload) {
    if (!this.checkedAssets.includes(asset)) {
      this.checkedAssets.push(asset);
    } else {
      this.checkedAssets.splice(this.checkedAssets.indexOf(asset), 1);
    }
  }

  public redirectToUpdate(id: number): void {
    this._router.push({ name: this._updateRouteName, params: { id: id.toString() } });
  }

  public deleteAction(id: number): void {
    this._confirmService.confirmDelete({
      callback: () => {
        this._restService
          .delete(id)
          .then((_) => {
            this._isLoadingOverview = true;
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this.refetch();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'media-dialog',
    });
  }

  public bulkDeleteAction() {
    this._confirmService.confirmDelete({
      callback: () => {
        Promise.all(
          this.checkedAssets.map(async (asset) => await this._restService.delete(asset.id)),
        )
          .then(() => {
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this.checkedAssets = [];
            this.refetch();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'media-dialog',
    });
  }

  public async download(asset: Upload) {
    try {
      axios
        .get(asset.public_url, {
          responseType: 'blob',
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: asset.asset_type?.name ?? 'application/pdf',
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', asset.filename);
          link.setAttribute('target', '_blank');
          link.click();
          URL.revokeObjectURL(link.href);

          this.checkedAssets = [];
          this._toastService.displaySuccessToast(this._ts.downloadSuccess());
        });
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.downloadFailed());
    }
  }

  public bulkDownload() {
    this.checkedAssets.forEach((asset: Upload) => {
      this.download(asset);
    });
  }

  public completeAttachAssets() {
    this.attachAssetsModal.completeAttachAssets(this.checkedAssets);
  }
}
