<script setup lang="ts">
import { inject, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { NumberFieldService } from '@/general/services/attribute-fields/field-services/number-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.v2.vue';
import { InputNumberInputEvent } from 'primevue/inputnumber';

/** Props */
interface Props {
  attributeField: AttributeField<number | undefined>;
  productAttributeField: ProductAttributeField<number | undefined>;
}
const props = defineProps<Props>();
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const numberFieldService = ref(
  new NumberFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
const ts = new TranslationService('supplier', 'products');
</script>
<template>
  <WrapperField :attribute-field-service="numberFieldService">
    <pInputNumber
      v-model="numberFieldService.productAttributeFieldValue"
      show-buttons
      :step="1"
      :use-grouping="false"
      input-class="border-noround-right"
      :pt="{
        input: () => ({ class: numberFieldService.getErrorTypeClass() + ' max-h-3rem' }),
        buttonGroup: () => ({ class: 'max-h-3rem' }),
      }"
      :placeholder="ts.tModule('numberField.placeholder')"
      :disabled="numberFieldService.productAttributeField.locked"
      @input="(event: InputNumberInputEvent) => numberFieldService.updateValue(event.value as any)"
    />
  </WrapperField>
</template>
