<script setup lang="ts">
//Components
import OverViewDialog from '@/general/ui/components/over-view/over-view-dialog.vue';
import AmazonModulesSelect from '@/general/ui/components/selects/integrations-selects/amazon/amazon-modules-select.vue';
import AmazonProductTypesSelect from '@/general/ui/components/selects/integrations-selects/amazon/amazon-product-types-select.vue';
import AmazonMarketplaceIdsSelect from '@/general/ui/components/selects/integrations-selects/amazon/amazon-marketplace-ids-select.vue';

//Composables and Services
import { jsonSchemaServiceKey } from '../ts/keys/json-schemas-service.key';
import { inject, watch } from 'vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { capitalize } from 'lodash';

/** Services */
const ts = inject(tsKey)!;
const jsonSchemasService = inject(jsonSchemaServiceKey)!;

// Disselect chosen product type when module changes (product types are different for each module)
watch(
  () => jsonSchemasService.value.module,
  () => (jsonSchemasService.value.partialObject.product_type = undefined),
);
</script>
<template>
  <OverViewDialog
    :service="jsonSchemasService"
    size="medium"
    :has-subtitle="false"
    :title="ts.tModule('retrieveJsonSchema.dialogTitle')"
    :overwrite-call-to-action-label="ts.tModule('retrieveJsonSchema.submitButtonLabel')"
  >
    <template #form="{ data }">
      <div class="flex flex-column gap-4">
        <div class="flex gap-5">
          <AmazonModulesSelect v-model="jsonSchemasService.module" />
          <div class="flex flex-column w-6">
            <label class="mb-3">{{ ts.tModule('retrieveJsonSchema.amazonCentral') }}</label>
            <label class="text-gray-400">{{ capitalize(jsonSchemasService.module?.type) }}</label>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="w-6">
            <AmazonMarketplaceIdsSelect v-model="data.marketplace_id" />
          </div>
          <div class="flex flex-column w-6">
            <label class="mb-1">{{ ts.tGlobal('languages') }}</label>
            <pDropdown
              v-model="data.locale"
              :options="jsonSchemasService.localesOptions"
              option-label="value"
              option-value="key"
              :placeholder="ts.tModule('retrieveJsonSchema.selectLanguage')"
            />
          </div>
        </div>
        <AmazonProductTypesSelect
          v-model="data.product_type"
          :module-id="jsonSchemasService.module?.id"
          :disabled="jsonSchemasService.module == null"
        />
      </div>
    </template>
    <template #skeleton>
      <div>
        <p-skeleton height="2rem" width="25rem" class="mb-5" />
        <div class="mb-5">
          <p-skeleton width="10rem" class="mb-2" />
          <p-skeleton height="2.25rem" class="p-mb-2" />
        </div>
      </div>
    </template>
  </OverViewDialog>
</template>
