<script setup lang="ts">
//Core
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

//Component
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';

import { Locale } from 'platform-unit2-api/locales';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { isNegative } from '@/general/utils/isNegative';
import {
  ExportRestService,
  ExportTypeEnum,
  ExportChangedProduct,
  RestartExportRequest,
} from 'platform-unit2-api/exports';
import { PipelinesRestService, ExportParameters, Pipeline } from 'platform-unit2-api/pipelines';
import { ModulesRestService, ModuleDetail } from 'platform-unit2-api/modules';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'loadAsyncData'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'exports');
const tsLocales = new TranslationService('retailer', 'locales');
const exportPipelineApi = new ExportRestService();
const pipelineApi = new PipelinesRestService();
const moduleApi = new ModulesRestService();

/** Constants */
const route = useRoute();

const loading = ref(false);
const editorExport = ref<Pipeline>();
const editorModule = ref<ModuleDetail>();
const editorLocale = ref<Locale>();
const mailTo = ref<string[]>([]);
const customMessage = ref<string>();
const includeAssets = ref(false);
const includeData = ref(false);
const changedProductType = ref({ value: 'always' });
const changedProducts = ref<ExportChangedProduct[]>();
const dates = ref<Date[]>([]);

const handleSubmit = async (): Promise<void> => {
  loading.value = true;
  changedProducts.value = [
    {
      beforeDate:
        changedProductType.value.value === 'dates'
          ? dates.value[1]
            ? moment(dates.value[1]).toDate().toUTCString()
            : undefined
          : undefined,
      afterDate:
        changedProductType.value.value === 'dates'
          ? dates.value[0]
            ? moment(dates.value[0]).toDate().toUTCString()
            : undefined
          : undefined,
      afterLastExport: changedProductType.value.value === 'export',
    },
  ];

  try {
    if (editorExport.value?.id == null) {
      return;
    }

    const data: RestartExportRequest = {
      id: editorExport.value?.id,
      mail_to: mailTo.value,
      custom_message: customMessage.value,
      module_id: editorModule.value?.id ?? 0,
      locale_id: editorLocale.value?.id ?? 0,
      changed_products: changedProducts.value,
      include_data: includeData.value,
      include_assets: includeAssets.value,
      product_ids: (editorExport.value?.payload?.parameters as ExportParameters).product_ids,
      files: editorExport.value?.payload?.parameters.files ?? undefined,
    };
    await exportPipelineApi.restartExport(data);
  } catch (err) {
    toastService.displayErrorToast(ts.exportFailed());
  } finally {
    loading.value = false;
    emit('hide');
    emit('loadAsyncData');
  }
};

const loadAsyncData = async (): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.loadFailed());
    return;
  }

  loading.value = true;

  try {
    editorExport.value = await pipelineApi.get(Number(route.params.id));

    if (editorExport.value.payload?.parameters.module_id === undefined) {
      return;
    }

    editorModule.value =
      editorExport.value && (await moduleApi.get(editorExport.value.payload?.parameters.module_id));

    if (editorExport.value) {
      includeAssets.value =
        (editorExport.value.payload?.parameters as ExportParameters).include_assets ?? false;
      includeData.value =
        (editorExport.value.payload?.parameters as ExportParameters).include_data ?? false;
      mailTo.value = (editorExport.value.payload?.parameters as ExportParameters).mail_to ?? [];
      customMessage.value = (
        editorExport.value.payload?.parameters as ExportParameters
      ).custom_message;
      changedProducts.value = (
        editorExport.value.payload?.parameters as ExportParameters
      ).changed_products;
    }

    if (!changedProducts.value || !changedProducts.value[0].beforeDate) {
      changedProductType.value.value =
        changedProducts.value && changedProducts.value[0].afterLastExport ? 'export' : 'always';
    } else {
      changedProductType.value.value = 'dates';
      dates.value[0] = moment(changedProducts.value[0].afterDate).toDate();
      dates.value[1] = moment(changedProducts.value[0].beforeDate).toDate();
    }
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

/** Lyfecycle */
onMounted(async () => {
  await loadAsyncData();
});
</script>
<template>
  <div class="h-full">
    <SidebarSkeleton v-if="loading" />
    <CrudSidebar
      v-else
      :title="ts.tModule('export_restart.title')"
      :subtitle="''"
      @cancel="emit('hide')"
      @save="handleSubmit"
    >
      <template #sidebar-data>
        <div class="field mb-3">
          <ClientModuleSelect v-model="editorModule" type="export" />
        </div>

        <template
          v-if="
            !editorExport?.payload?.parameters.files ||
            editorExport?.payload?.parameters.files.length <= 0
          "
        >
          <div class="field mb-4">
            <label for="name">{{ tsLocales.tModule('locale_select.label') }}</label>
            <LocaleSelect v-model="editorLocale" hide-label />
          </div>

          <div
            v-if="(editorExport?.payload?.parameters as ExportParameters)?.export_type === ExportTypeEnum.MAPPING_EXPORT"
            class="field"
          >
            <label>{{ ts.tModule('export_restart.sources') }}</label>
            <div class="flex mt-2">
              <div class="field-checkbox mr-4">
                <p-checkbox v-model="includeData" :binary="true" />
                <label>{{ ts.tModule('export_restart.product_attributes') }}</label>
              </div>

              <div class="field-checkbox mr-4">
                <p-checkbox v-model="includeAssets" :binary="true" />
                <label>{{ ts.tModule('export_restart.product_assets') }}</label>
              </div>
            </div>
          </div>

          <div class="field mb-4">
            <label class="block">{{ ts.tModule('export_restart.date_limit') }}</label>
            <pDropdown
              v-model="changedProductType"
              :options="[
                { value: 'always', label: ts.tModule('export_restart.since_always') },
                { value: 'export', label: ts.tModule('export_restart.since_last_export') },
                { value: 'dates', label: ts.tModule('export_restart.between_dates') },
              ]"
              option-label="label"
              data-key="value"
              display="chip"
              class="mb-3 w-full"
              :filter="true"
            />

            <div v-if="changedProductType.value === 'dates'" class="field">
              <label class="block">{{ ts.tModule('export_restart.select_dates') }}</label>
              <p-calendar
                id="range"
                v-model="dates"
                class="mb-1 w-full"
                placeholder="Select dates"
                :max-date="new Date()"
                selection-mode="range"
                :manual-input="false"
                :show-button-bar="true"
                :show-icon="true"
              />
            </div>
          </div>

          <div class="field mb-3">
            <div class="mb-2">
              <label for="retailer_email">{{ ts.tGlobal('contact') }}</label>
              <div class="mt-2 w-full">
                <p-chips
                  v-model="mailTo"
                  class="w-full"
                  :max="10"
                  :placeholder="ts.tModule('details.mail_to')"
                  :add-on-blur="true"
                  :pt="{
                    container: 'p-1',
                  }"
                />
              </div>
            </div>
          </div>

          <div class="field mb-3">
            <p-textarea
              v-model="customMessage"
              class="w-full"
              rows="5"
              cols="60"
              :placeholder="ts.tModule('export_restart.custom_message')"
            />
          </div>
        </template>
      </template>
    </CrudSidebar>
  </div>
</template>

<style lang="scss" scoped>
.p-chips :deep(.p-chips-multiple-container) {
  width: 100%;
}
</style>
