<script setup lang="ts">
import { useEditor, EditorContent, Editor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import { onBeforeMount, onMounted, ref, ShallowRef, watch } from 'vue';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image';
import video from 'supplier/modules/editor/components/video-extension';
import { youTubeGetID } from 'utils/youtube';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { SelectButtonChangeEvent } from 'primevue/selectbutton';

// Props
interface Props {
  modelValue: string;
  imageUrl?: string;
  allowImages?: boolean;
  allowVideos?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  imageUrl: '',
  allowImages: true,
  allowVideos: true,
  disabled: false,
});

// Emits
const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void;
  (e: 'addImage'): void;
  (e: 'focus-changed', val: boolean): void;
}>();

// Constants
const fontStyles = ref([
  { name: 'normal', type: '' },
  { name: 'Heading', type: '4' },
  { name: 'Heading', type: '3' },
  { name: 'Heading', type: '2' },
]);

const justifyOptions = ref([
  { icon: 'mdi mdi-format-align-left', value: 'left' },
  { icon: 'mdi mdi-format-align-right', value: 'right' },
  { icon: 'mdi mdi-format-align-center', value: 'center' },
  { icon: 'mdi mdi-format-align-justify', value: 'justify' },
]);
const editor = ref<ShallowRef<Editor | undefined>>(
  useEditor({
    extensions: [StarterKit],
  }),
);

// helper functions
const setAlignment = (event: any) => {
  editor.value?.chain().focus().setTextAlign(event.value.value).run();
};

const setFontStyle = (event: any) => {
  event.value.type == 2 && editor.value?.chain().focus().toggleHeading({ level: 2 }).run();
  event.value.type == 3 && editor.value?.chain().focus().toggleHeading({ level: 3 }).run();
  event.value.type == 4 && editor.value?.chain().focus().toggleHeading({ level: 4 }).run();
  if (!event.value.type) {
    editor.value?.isActive('heading', { level: 2 }) &&
      editor.value?.chain().focus().toggleHeading({ level: 2 }).run();
    editor.value?.isActive('heading', { level: 3 }) &&
      editor.value?.chain().focus().toggleHeading({ level: 3 }).run();
    editor.value?.isActive('heading', { level: 4 }) &&
      editor.value?.chain().focus().toggleHeading({ level: 4 }).run();
  }
};

const addImage = () => {
  emit('addImage');
};

const addVideo = () => {
  const prompt: string | null = window.prompt();

  if (prompt) {
    const youtubeId: string | undefined = youTubeGetID(prompt);
    if (youtubeId) {
      const url = youtubeId ? 'https://www.youtube.com/embed/' + youtubeId : prompt;
      url && editor.value?.chain().focus().setVideo({ src: url }).run();
    } else {
      prompt && editor.value?.chain().focus().setVideo({ src: prompt }).run();
    }
  }
};

// Lifecycle hooks
watch(
  () => {
    props.imageUrl;
  },
  () => {
    props.imageUrl && editor.value?.chain().focus().setImage({ src: props.imageUrl }).run();
  },
  { deep: true },
);

watch(
  () => {
    props.modelValue;
  },
  () => {
    const text = editor?.value?.getHTML()?.replace(/<li><p>(.*?)<\/p><\/li>/gm, '<li>$1</li>');
    const isSame = text === props.modelValue;
    if (isSame) {
      return;
    }

    editor.value?.commands.setContent(props.modelValue, false);
  },
  { deep: true },
);

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Superscript,
      Subscript,
      Image,
      video,
    ],
    content: props.modelValue,
    enablePasteRules: false,
    onUpdate: () => {
      const text = editor?.value?.getHTML()?.replace(/<li><p>(.*?)<\/p><\/li>/gm, '<li>$1</li>');
      emit('update:modelValue', text ?? '');
    },
  });
  props.disabled && editor.value?.setOptions({ editable: false });
});

onBeforeMount(() => {
  editor.value?.destroy();
});

watch(
  () => {
    props.disabled;
  },
  () => {
    props.disabled
      ? editor.value?.setOptions({ editable: false })
      : editor.value?.setOptions({ editable: true });
  },
  { deep: true },
);

function handleFocus() {
  if (props.disabled) {
    emit('focus-changed', false);
    return;
  }

  emit('focus-changed', editor.value?.isFocused ?? false);
}
</script>
<template>
  <div v-if="editor" class="border-200 border-bottom-1 mb-3 p-1">
    <pDropdown
      class="min-w-max mr-1"
      :options="fontStyles"
      option-label="name"
      @change="(e: DropdownChangeEvent) => setFontStyle(e)"
    >
      <template #value>
        <div class="country-item country-item-value">
          <div v-if="editor.isActive('heading', { level: 2 })">
            <h2>Heading</h2>
          </div>
          <div v-else-if="editor.isActive('heading', { level: 3 })">
            <h3>Heading</h3>
          </div>
          <div v-else-if="editor.isActive('heading', { level: 4 })">
            <h4>Heading</h4>
          </div>
          <div v-else>normal</div>
        </div>
      </template>
      <template #option="slotProps">
        <div class="country-item">
          <div
            v-if="slotProps.option.type"
            v-html="
              `<h${slotProps.option.type}>${slotProps.option.name}</h${slotProps.option.type}>`
            "
          ></div>
          <div v-else>{{ slotProps.option.name }}</div>
        </div>
      </template>
    </pDropdown>
    <p-select-button
      :options="justifyOptions"
      data-key="value"
      class="inline mr-1"
      @change="(e: SelectButtonChangeEvent) => setAlignment(e)"
    >
      <template #option="slotProps">
        <i
          :class="`${slotProps.option.icon} ${
            editor.isActive({ textAlign: slotProps.option.value }) && 'text-cyan-500'
          }`"
        ></i>
      </template>
    </p-select-button>
    <p-button
      v-tooltip.top="'Bold'"
      text
      plain
      icon="mdi mdi-format-bold"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('bold') }"
      @click="editor?.chain().focus().toggleBold().run()"
    />
    <p-button
      v-tooltip.top="'Italic'"
      text
      plain
      icon="mdi mdi-format-italic"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('italic') }"
      @click="editor?.chain().focus().toggleItalic().run()"
    />
    <p-button
      v-tooltip.top="'Underline'"
      text
      plain
      icon="mdi mdi-format-underline"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('underline') }"
      @click="editor?.chain().focus().toggleUnderline().run()"
    />
    <p-button
      v-tooltip.top="'Strike'"
      text
      plain
      icon="mdi mdi-format-strikethrough"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('strike') }"
      @click="editor?.chain().focus().toggleStrike().run()"
    />
    <p-button
      v-tooltip.top="'Super script'"
      text
      plain
      icon="mdi mdi-format-superscript"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('superscript') }"
      @click="editor?.chain().focus().toggleSuperscript().run()"
    />
    <p-button
      v-tooltip.top="'Subscript'"
      text
      plain
      icon="mdi mdi-format-subscript"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('subscript') }"
      @click="editor?.chain().focus().toggleSubscript().run()"
    />

    <p-button
      v-tooltip.top="'bullet list'"
      text
      plain
      icon="mdi mdi-format-list-bulleted"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('bulletList') }"
      @click="editor?.chain().focus().toggleBulletList().run()"
    />
    <p-button
      v-tooltip.top="'ordered list'"
      text
      plain
      icon="mdi mdi-format-list-numbered"
      class="mr-1 mt-1"
      :class="{ 'is-active': editor.isActive('orderedList') }"
      @click="editor?.chain().focus().toggleOrderedList().run()"
    />
    <p-button
      v-if="allowImages"
      v-tooltip.top="'Add Image'"
      text
      plain
      icon="mdi mdi-image-outline"
      class="mr-1 mt-1"
      @click="addImage"
    />
    <p-button
      v-if="allowVideos"
      v-tooltip.top="'Add Video'"
      text
      plain
      icon="mdi mdi-video-outline"
      class="mr-1 mt-1"
      @click="addVideo"
    />

    <p-button
      v-tooltip.top="'hard break'"
      text
      plain
      icon="mdi mdi-subdirectory-arrow-left"
      class="mr-1 mt-1"
      @click="editor?.chain().focus().setHardBreak().run()"
    />
    <p-button
      v-tooltip.top="'Clear marks'"
      text
      plain
      icon="mdi mdi-broom"
      class="mr-1 mt-1"
      @click="editor?.chain().focus().unsetAllMarks().run()"
    />
    <p-button
      v-tooltip.top="'Undo'"
      text
      plain
      icon="mdi mdi-undo"
      class="mr-1 mt-1"
      @click="editor?.chain().focus().undo().run()"
    />
    <p-button
      v-tooltip.top="'Redo'"
      text
      plain
      icon="mdi mdi-redo"
      class="mr-1 mt-1"
      @click="editor?.chain().focus().redo().run()"
    />
  </div>
  <editor-content v-on-focus="handleFocus" :editor="editor" class="bg-white" />
</template>
<style lang="scss" scoped>
.editor-wrapper {
  .is-active {
    background: rgb(133, 212, 254);
  }
}
</style>
