import {
  AttributeField,
  AttributeFieldChild,
  ProductAttributeField,
  AttributeFieldsRestService,
  FieldErrorEnum,
} from 'platform-unit2-api/attribute-fields';
import { Product, UpdateProductField } from 'platform-unit2-api/products';
import { DebounceService } from '../debounce-service/debounce.service';
import { PaginationObject } from 'platform-unit2-api/core';
import { DirtyStateService } from '../dirty-state/dirty-state.service';
import { FieldFunctions } from './interfaces/field-functions.interface';
import { Defaults } from '@/general/utils/constants/defaults';
import { AttributeFieldLinkedList } from './attribute-field-linked-list';
import { AttributeFieldNode } from './attribute-field-node';

import { cloneDeep } from 'lodash';
import store from '@/core/store';
import { User } from 'platform-unit2-api/users';
import { Locale } from 'platform-unit2-api/locales';
import { isAdvanced, isFinacialFieldOrInputSelectField, isTabField } from './advance-fields.utils';
import { NavigationGuardNext } from 'vue-router';

/**
 * Service for managing product attribute fields
 */
export class ProductAttributeFieldsService implements FieldFunctions {
  /**
   * Rest service for fetching attribute fields
   */
  private _attributeFieldsRestService = new AttributeFieldsRestService();

  /**
   * Needed for managing errors from the services
   * Removes the need of another recursion
   */
  private _productFieldsErrors: ProductAttributeField<any>[] = [];

  /**
   * Debounce service for fetching attribute fields
   * This is needed to prevent the service from fetching attribute fields on every keystroke
   * or every change in datamodel / locale
   */
  private _debounceService = new DebounceService(Defaults.DEBOUNCE_TIME);

  /**
   * Pagination object for fetching attribute fields
   * This is needed to fetch all attribute fields with specific filters
   */
  private _pagination: PaginationObject = { page: 1, limit: 5000, query: undefined };

  /**
   * Current product, which is set in the constructor.
   * This is needed to fetch the correct attribute fields.
   */
  private _currentProduct?: Product;

  attrFieldLinkedList: AttributeFieldLinkedList<any> = new AttributeFieldLinkedList();
  originalAttributes: AttributeField<any>[] = [];

  /**
   * Setter for the current product
   * @param value Product
   */
  public set currentProduct(value: Product | undefined) {
    this._currentProduct = value;
    this.fetchData(true);
  }

  /**
   * Getter for the current product
   * @returns Product | undefined
   */
  public get currentProduct(): Product | undefined {
    return this._currentProduct;
  }

  /**
   * Getter to see if there are changes
   * @returns boolean
   */
  public get hasAttributeFieldChanges(): boolean {
    return this.dirtyStateHasChanges();
  }

  lastSearchedAttributeIds: number[] = [];

  /**
   * Getter for the search query this is an variable of the
   * @private _pagination object
   */
  public get searchQuery(): string | undefined {
    return this._pagination.query;
  }

  /**
   * Setter for the search query this is an variable of the
   * if the value is empty the query will be undefined
   * if the value is not empty the query will be the value
   * the debounce service will be called to fetch the attribute fields
   * @private _pagination object
   * @param value string
   *
   */
  public set searchQuery(value: string) {
    if (value === this.searchQuery) {
      return;
    }

    this._pagination.query = value === '' ? undefined : value;
    this._debounceService.debounce(() => this.fetchData());
  }

  /**
   * Fields that have been updated by the user and need to be saved to the backend.
   * The field services are responsible for transforming the AttributeField to the UpdateProductField
   * due to lack of information provided in this service
   */
  public updatedFields: UpdateProductField[] = [];

  /**
   * Dirty state service for checking if there are changes
   */
  public dirtyStateService = new DirtyStateService<Record<string, any>>();

  /**
   * get locales that are selected by the user
   * @returns number[]
   */
  _locales: number[] = [];
  get locales(): number[] {
    return this.workspaceLocales.map((l) => l.id) ?? [];
  }
  set locales(_: number[]) {
    this._locales = this.workspaceLocales.map((l) => l.id);
  }
  workspaceLocales: Locale[] = [];
  _chosenLocales: number[] = [];

  get chosenLocaleIds(): number[] {
    return this._chosenLocales;
  }

  set chosenLocaleIds(value: number[]) {
    this.hasLocalesChanged = true;
    this._chosenLocales = value;
    this.attributeFields = this.findOldValuesAndReplace(this.originalAttributes);
    this.hasLocalesChanged = false;
  }

  public hasLocalesChanged = false;

  /**
   * Datamodels that are selected by the user
   */
  private _datamodels: number[] | null = null;

  /**
   * get datamodels that are selected by the user
   * @returns number[] | null
   */
  public get datamodels(): number[] | null {
    return this._datamodels;
  }

  /**
   * set datamodels that are selected by the user
   * if the value is null the datamodels will be null
   * if the value is not null the datamodels will be the value
   * the debounce service will be called to fetch the attribute fields
   * @param value number[] | null
   */
  public set datamodels(value: number[] | null) {
    if (!value) {
      this._datamodels = null;
      return;
    }

    //checks if the value given in the array is a number
    //its typed as a number[] but some how the current implementation
    //of the old pdp page manages to send a [null]
    this._datamodels = value?.every((datamodel) => datamodel != null) ? value : null;
    this._debounceService.debounce(() => this.fetchData());
  }

  /**
   * List of attribute fields for the current product,
   * the generic value is of type any because the value isnt used here so type narrowing is omitted
   */
  public attributeFields: AttributeField<any>[] = [];

  /**
   * Getter for the attribute fields that are empty
   * if some of the values of the fields are empty the function will return true
   * if none of the values of the fields are empty the function will return false
   * @returns boolean
   */
  public get hasEmptyFields(): boolean {
    return this._hasEmptyFields();
  }

  constructor() {
    this.workspaceLocales = (store.getters['users/currentUser'] as User)?.workspace?.locales ?? [];
    this.chosenLocaleIds = this.workspaceLocales.map((l) => l.id);
  }

  private _hasEmptyFields(): boolean {
    return this.showOnlyEmptyFields && this.attributeFields.length !== 0;
  }

  public getAmountOfAttributesForADatamodel(attributeIds: number[]) {
    return this.attributeFields.filter((attributeField) =>
      attributeIds.includes(attributeField.attribute.id),
    ).length;
  }

  public setEmptyFields(state: boolean) {
    this.showOnlyEmptyFields = state;
    this.attributeFields = this.findOldValuesAndReplace(this.originalAttributes);
  }

  public showOnlyEmptyFields = false;

  /**
   * Saving state of the service, to display loading indicators
   */
  public isSaving = false;

  /**
   * Loading state of the service
   */
  public loading = true;

  public emptyFields: AttributeField<any>[] = [];

  private _settingFilledAttributes = false;

  public filledAttributes: AttributeField<any>[] = [];

  public getFilledInAttributesForDatamodel(attributeIds: number[]): number | undefined {
    this._settingFilledAttributes = true;
    const filledIn = this._calculateDataModelCompleteness(
      cloneDeep(
        this.originalAttributes.filter((attributeField) =>
          attributeIds.includes(attributeField.attribute.id),
        ),
      ),
    );
    this._settingFilledAttributes = false;

    if (
      this.originalAttributes.filter((attributeField) =>
        attributeIds.includes(attributeField.attribute.id),
      ).length === 0
    ) {
      return undefined;
    }

    return attributeIds.length - filledIn.length;
  }

  /**
   * Find the values and update them
   * loop over the array given as parameter and check if the field has an updated value
   * if the field has an updated value the value will be updated
   * @param arrayToReplaceValuesIn: AttributeField<any>[]
   * @returns AttributeField<any>[]
   */
  private _calculateDataModelCompleteness(result: AttributeField<any>[]): AttributeField<any>[] {
    return result
      .map((af) => ({
        ...af,
        values:
          af.values?.filter(
            (val) => this.chosenLocaleIds.includes(val.locale.id) || val.locale.value === 'global',
          ) ?? [],
        children: this.getChildren(af.children ?? []),
      }))
      .filter((af) =>
        this.showOnlyEmptyFields || this._settingFilledAttributes ? this._filterFields(af) : true,
      );
  }

  //#region public functions

  /**
   *
   * @param field Field that has been updated by the user and needs to be saved to the backend.
   * if it is already in the updated fields, replace it.
   */
  public addToUpdatedFields(field: UpdateProductField) {
    if (this.updatedFields.length > 0) {
      const index = this.updatedFields.findIndex(
        (f) =>
          f.attribute_id === field.attribute_id &&
          f.locale_id === field.locale_id &&
          f.path === field.path,
      );
      if (index !== -1) {
        this.updatedFields[index] = field;

        return;
      }
    }

    this.updatedFields.push(field);
  }

  hasChanges<T>(attributeField: AttributeField<T>, updatedValue: UpdateProductField): boolean {
    const node = AttributeFieldNode.fromUpdatedField(
      updatedValue,
      attributeField.attribute.options.type,
      attributeField.attribute.parent_id,
    );

    return this.attrFieldLinkedList.getNodeFromList(node)?.hasChanges() ?? false;
  }

  handleDirtyState<T>(
    attributeField: AttributeField<T>,
    updatedValue: UpdateProductField,
    originalValue: T | null,
    preset?: AttributeField<T>[],
  ) {
    const node = AttributeFieldNode.fromUpdatedField(
      updatedValue,
      attributeField.attribute.options.type,
      attributeField.attribute.parent_id,
    );

    this.attrFieldLinkedList.updateNode(node, preset, updatedValue.delete_paths);

    const originalAttributeField = this.attrFieldLinkedList.findAttributeFromNode(
      node,
      this.originalAttributes,
    );

    const originalAttrFieldValue = originalAttributeField?.values?.find(
      (v) => v.locale?.id === node.localeId,
    );

    if (originalAttrFieldValue != null) {
      (originalAttrFieldValue.value as T | null) =
        originalValue === undefined ? (node.value as T | null) : (originalValue as T | null);
    }

    const originalAttrFieldChild = originalAttributeField?.children?.find(
      (v) => v.locale?.id === node.localeId,
    );
    if (originalAttrFieldChild != null && preset != null) {
      originalAttrFieldChild.instances?.push(preset);
    }
  }

  public showDialog(next: NavigationGuardNext) {
    this.attrFieldLinkedList.showDialog(this, next);
  }

  public dirtyStateHasChanges(): boolean {
    return this.attrFieldLinkedList.isTouched();
  }

  public advancedFieldExists(
    attributeId: number,
    localeId: number,
    advancedFieldPath: string | null,
    childrenPath: string | null,
    parentId?: number,
  ): boolean {
    return this.attrFieldLinkedList.advancedFieldExists(
      attributeId,
      localeId,
      advancedFieldPath,
      childrenPath,
      parentId,
    );
  }

  /**
   * Handle incoming errors from the services
   * @param productField ProductAttributeField<T>
   */
  public handleIncomingErrorsFromField<T>(productField: ProductAttributeField<T>) {
    const fieldError: number = this._productFieldsErrors.findIndex(
      (er) =>
        er.attribute_id === productField.attribute_id &&
        er.id === productField.id &&
        er.locale.id === productField.locale.id &&
        er.path === productField.path,
    );

    if (fieldError == -1) {
      this._productFieldsErrors.push(productField);
      return;
    }

    this._productFieldsErrors[fieldError] = productField;
  }

  /**
   * Check if the save button is disabled
   */
  public isSaveButtonDisabled(): boolean {
    return this._productFieldsErrors
      .map((productAttrField) => productAttrField.errors)
      .flat()
      .some((err) => err.severity === FieldErrorEnum.ERROR);
  }

  /**
   * Fetches the attribute fields for the current product, Loading state is set to true while fetching and set to false when finished
   * If an error occurs, it is thrown
   * @throws e: Error or backend error
   */
  public fetchAttributeFields(discardChanges = false) {
    this.loading = true;
    this._attributeFieldsRestService
      .getProductAttributeFields({
        productId: this._currentProduct!.id,
        locales: this.locales,
        datamodels: this.datamodels ?? null,
        pagination: this._pagination,
      })
      .then((result) => {
        this.lastSearchedAttributeIds = result.map((a) => a.attribute.id);
        if (discardChanges || !this.attrFieldLinkedList.hasInitialDataAlreadyAssigned()) {
          this.attrFieldLinkedList.init(cloneDeep(result));
          this.originalAttributes = cloneDeep(result);
        }

        this.attributeFields = this.findOldValuesAndReplace(result);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        this.loading = false;
        this.isSaving = false;
      });
  }

  /**
   * Discards all changes made by the user and resets the attribute fields to the original state
   * if refetch is true, the attribute fields are fetched again from the backend
   * the refetch parameter is true by default
   * @param refetch: boolean
   */
  public discardChanges(refetch = true) {
    this.updatedFields = [];

    if (refetch) {
      this._pagination.query = undefined;
      this.fetchData(true);
    }
  }

  /**
   * Saves all changes made by the user to the backend
   * Loading state is set to true while saving and set to false when finished
   * if successful, the attribute fields are updated with the new values
   * and the updated fields are reset
   * If an error occurs, it is thrown
   * the parameter overrides is an object with two arrays
   * the first array is an array of attribute ids which you want to update on the id's of the second array, the product variants
   * @param overrides: { attributes: number[]; variants: number[] }
   * @throws e: Error or backend error
   */
  public async saveAttributeFields(overrides: {
    attributes: number[];
    variants: number[];
  }): Promise<void> {
    if (!this._currentProduct) {
      return Promise.reject(new Error('No product set'));
    }

    const fallBackSearch = this._pagination.query;
    this._pagination.query = undefined;

    this.isSaving = true;
    return this._attributeFieldsRestService
      .saveProductAttributeFields(
        this._currentProduct.id,
        this._mapAndCheckIfAttributeNeedToBeOverwrittenInVariant(overrides.attributes),
        overrides.variants,
      )
      .catch((e) => {
        this.isSaving = false;
        this._pagination.query = fallBackSearch;
        throw e;
      })
      .finally(() => {
        this._productFieldsErrors = [];
        this.discardChanges();
      });
  }

  //#endregion

  //#region private functions
  /**
   * Map the updated fields and check if the attribute id is in the array
   * if the attribute id is in the array the overwrite will be set to true
   * @param attributeIds: number[]
   * @returns UpdateProductField[]
   */
  private _mapAndCheckIfAttributeNeedToBeOverwrittenInVariant(
    attributeIds: number[],
  ): UpdateProductField[] {
    return this.updatedFields.map((field) => {
      if (attributeIds.includes(field.attribute_id)) {
        field.overwrite = true;
      }

      return field;
    });
  }

  /**
   * Find the values and update them
   * loop over the array given as parameter and check if the field has an updated value
   * if the field has an updated value the value will be updated
   * @param arrayToReplaceValuesIn: AttributeField<any>[]
   * @returns AttributeField<any>[]
   */
  findOldValuesAndReplace(result: AttributeField<any>[]): AttributeField<any>[] {
    return result
      .map((attributeField) => {
        const oldAttributeField = this.originalAttributes.find(
          (a) => a.attribute.id === attributeField.attribute.id,
        );

        return {
          ...attributeField,
          values:
            attributeField?.values?.map((val) => {
              return {
                ...val,
                value:
                  oldAttributeField?.values?.find((v) => v.locale.id === val.locale.id)?.value ??
                  val.value,
              };
            }) ?? [],
          children:
            attributeField.children?.map((child) => {
              return {
                ...child,
                instances:
                  oldAttributeField?.children?.find((c) => c.locale?.id === child.locale?.id)
                    ?.instances ?? child.instances,
              };
            }) ?? [],
        };
      })
      .filter((af) => this.lastSearchedAttributeIds.includes(af.attribute.id))
      .map((af) => ({
        ...af,
        values: af.values.filter(
          (val) => this.chosenLocaleIds.includes(val.locale.id) || val.locale.value === 'global',
        ),
        children: this.getChildren(af.children),
      }))
      .filter((af) =>
        this.showOnlyEmptyFields || this._settingFilledAttributes ? this._filterFields(af) : true,
      );
  }

  private _filterFields(attributeField: AttributeField<any>): boolean {
    return isAdvanced(attributeField)
      ? this._filterAdvanceFields(attributeField)
      : this.getValues(attributeField.values ?? [], attributeField).length !== 0;
  }

  private _filterAdvanceFields(attributeField: AttributeField<any>): boolean {
    return (
      attributeField.children?.some((child) => {
        if (child.instances == null || child.instances.length === 0) {
          return true;
        }

        return child.instances?.some((instance) => {
          return instance.some((attributeField) => {
            return isAdvanced(attributeField)
              ? this._filterAdvanceFields(attributeField)
              : this.getValues(attributeField.values ?? [], attributeField).length !== 0;
          });
        });
      }) || attributeField.children == null
    );
  }

  getValues(
    current: ProductAttributeField<any>[],
    attributeField: AttributeField<any>,
  ): ProductAttributeField<any>[] {
    const values = current.filter(
      (val) => this.chosenLocaleIds.includes(val.locale.id) || val.locale.value === 'global',
    );
    if (this.showOnlyEmptyFields || this._settingFilledAttributes) {
      if (isTabField(attributeField)) {
        return this._resolveEmptyTabHeaders(values) ?? [];
      }

      if (isFinacialFieldOrInputSelectField(attributeField)) {
        return this._hasObjectNullValues(values) ?? [];
      }

      return values.filter(
        (v) => v?.value == null || v?.value === '' || v.value === '<p></p>' || v.value.length === 0,
      );
    }

    return values;
  }

  private _resolveEmptyTabHeaders(current: ProductAttributeField<string[]>[]) {
    const values = current.map((value) => {
      return {
        ...value,
        value: value?.value?.filter((v) => v == null || v === ''),
      };
    });

    return values;
  }

  /**
   * Check if the values of the attribute field with value object[] keys are null
   * @param attributeField AttributeField<any>
   * @returns {ProductAttributeField<any>[] | null} ProductAttributeField<any>[] | null
   */
  private _hasObjectNullValues(
    values: ProductAttributeField<any>[],
  ): ProductAttributeField<any>[] | null {
    return (
      values?.filter((value) => {
        if (value.value == null) {
          return value;
        }

        const keys = Object.keys(value.value);
        if (keys.length < 2) {
          return value;
        }

        return keys.some((key) => value.value[key] == null || value.value[key] === '')
          ? value
          : null;
      }) ?? null
    );
  }

  getChildren(current: AttributeFieldChild<any>[]): AttributeFieldChild<any>[] {
    const children = current.filter(
      (c) => this.chosenLocaleIds.includes(c.locale?.id ?? 0) || c.locale?.value === 'global',
    );

    return children.map((child) => ({
      ...child,
      instances: this.getInstances(child.instances ?? []),
    }));
  }

  getInstances(current: AttributeField<any>[][]): AttributeField<any>[][] {
    return current.map((instance) =>
      instance.map((i) => ({
        ...i,
        values:
          i.values?.filter(
            (val) => this.chosenLocaleIds.includes(val.locale.id) || val.locale.value === 'global',
          ) ?? [],
        children: this.getChildren(i.children ?? []),
      })),
    );
  }

  fetchData(discardChanges = false) {
    if (this.locales?.length === 0) {
      return;
    }

    if (!this._currentProduct) {
      return;
    }

    this.fetchAttributeFields(discardChanges);
  }

  //#endregion
}
