<script setup lang="ts">
import { ValidationRule } from 'platform-unit2-api/validation-rules';
import ValidationRuleFooter from './validation-rule-footer.vue';
import { ref } from 'vue';
import ReadMore from '@/general/ui/components/read-more.vue';

interface Props {
  severity: string;
  validationRulesPerAttributeName: { [attributeName: string]: ValidationRule[] };
}

const props = defineProps<Props>();
const pagePerCarousel = ref<{ [x: string]: number }>(assignPages());

function assignPages(): { [attributeName: string]: number } {
  const pagePerAttributeAndSeverity = {} as { [x: string]: number };
  for (const attributeName in props.validationRulesPerAttributeName) {
    pagePerAttributeAndSeverity[attributeName + props.severity] = 0;
  }

  return pagePerAttributeAndSeverity;
}

function updatePage(attributeName: string, value: number) {
  pagePerCarousel.value[attributeName + props.severity] = value;
}
</script>
<template>
  <pCarousel
    v-for="attributeName in Object.keys(validationRulesPerAttributeName)"
    :key="attributeName"
    :value="validationRulesPerAttributeName[attributeName]"
    :circular="validationRulesPerAttributeName[attributeName].length > 1"
    :show-navigators="false"
    :show-indicators="false"
    :page="pagePerCarousel[attributeName + severity]"
    :class="`bg-${
      severity === 'Error' ? 'red' : 'orange'
    }-100 ${severity.toLocaleLowerCase()}-list`"
    :pt="{
      container: {
        style: { textAlign: 'justify' },
      },
    }"
  >
    <template #item="{ data }: { data: ValidationRule, index: number }">
      <div :class="`${severity.toLocaleLowerCase()}-item pup-p-4`">
        <div :class="`${severity.toLocaleLowerCase()}-item-title font-bold pup-my-3`">
          {{ data.name }}
        </div>

        <ReadMore :text="data.error_message" :char-count="200" :line-count="3" />
      </div>
    </template>
    <template #footer>
      <ValidationRuleFooter
        :attribute-name="attributeName"
        :severity="severity"
        :validation-rules="validationRulesPerAttributeName[attributeName]"
        @update:page="(val: number) => updatePage(attributeName, val)"
      />
    </template>
  </pCarousel>
</template>
<style lang="scss" scoped>
.error-item.active {
  background-color: #f9c2ca;
  border: 1px solid #f04359;
}

.warning-item.active {
  background-color: #f9d5b4;
  border: 1px solid #f08b43;
}
</style>
