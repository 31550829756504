<script setup lang="ts">
import { AssetType, AssetTypeRestService } from 'platform-unit2-api/asset-types';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<AssetType>>();
const assetTypeRestService = AssetTypeRestService;
</script>
<template>
  <BaseSelect
    :rest-service="assetTypeRestService"
    option-label="name"
    class="w-full"
    module-name="assetType"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
