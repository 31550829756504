import { PaginationObject } from 'platform-unit2-api/core';
import { Defaults } from '@/general/utils/constants/defaults';

export class ApiVariableService {
  protected _fetchVariables: PaginationObject = {
    page: 1,
    limit: Defaults.REQUEST_LIMIT,
    sortBy: Defaults.TABLE_SORT_BY,
    query: '',
  };
}
