import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import TemplateEditView from 'admin/modules/templates/components/template-edit.vue';
import TemplateNew from 'admin/modules/templates/components/template-new.vue';
import Templates from 'admin/modules/templates/views/templates.view.vue';
import { RouteRecordRaw } from 'vue-router';

const templatesRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/templates',
    name: 'templates',
    component: Templates,
    translations: {
      module: 'templates',
      platform: 'admin',
    },
    rights: [FeaturePermission.MANAGE_TEMPLATES],
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-template',
        component: TemplateNew,
        title: 'Template',
        rights: [FeaturePermission.MANAGE_TEMPLATES],
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'template-edit',
        component: TemplateEditView,
        title: 'Template',
        rights: [FeaturePermission.MANAGE_TEMPLATES],
      }),
    ],
  }),
];

export default templatesRoutes;
