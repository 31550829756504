<script setup lang="ts">
import useDebounce from 'utils/debounce';
import { onMounted, ref, watch } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DropdownChangeEvent, DropdownFilterEvent } from 'primevue/dropdown';
import { Attribute, AttributeRestService } from 'platform-unit2-api/attributes';

/** Props */
interface Props {
  modelValue?: Partial<Attribute>;
  ownerId?: number;
  exclude: Partial<Attribute>;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  ownerId: undefined,
  exclude: undefined,
});

/** Emit */
const emit = defineEmits<{
  (e: 'update:modelValue', selectedAttribute: Partial<Attribute> | undefined): void;
}>();

/** Services */
const ts = new TranslationService('admin', 'attributes');
const toastService = ToastService.getInstance;
const attribueRestService = new AttributeRestService();

/** Composables */
const { debounce } = useDebounce();

/** Constants */
const loading = ref<boolean>(true);
const selectedAttribute = ref<Partial<Attribute>>();
const attributes = ref<Attribute[]>([]);
let searchCallback: any = undefined;

const search = (query = '') => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    loading.value = true;

    attribueRestService
      .getParents(props.ownerId, query)
      .then((result) => {
        if (props.exclude) {
          attributes.value = result.data.filter((attribute) => {
            return props.exclude.id != attribute.id;
          });
        } else {
          attributes.value = result.data;
        }
      })
      .catch(() => {
        toastService().displayErrorToast(ts.searchFailed(query));
        attributes.value = [];
      })
      .finally(() => {
        loading.value = false;
      });
  }, 250);

  searchCallback();
};

const change = (event: DropdownChangeEvent) => {
  selectedAttribute.value = event.value ? event.value : null;
  emit('update:modelValue', selectedAttribute.value);
};

/** Lifecycles */
onMounted(() => {
  search(props.modelValue?.key ?? '');

  if (props.modelValue) {
    selectedAttribute.value = props.modelValue;
  }
});

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      selectedAttribute.value = props.modelValue;
      search(props.modelValue?.key ?? '');
    }
  },
);
</script>
<template>
  <pDropdown
    v-model="selectedAttribute"
    :options="attributes"
    :option-label="'key'"
    data-key="id"
    :placeholder="ts.placeholder"
    :filter="true"
    :show-clear="true"
    :filter-empty-message="ts.searchNoResults"
    @filter="(event: DropdownFilterEvent) => search(event.value)"
    @change="(event: DropdownChangeEvent) => change(event)"
  />
</template>
