import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Channel',
  subTitle: 'Here you can create a new Channel.',
};

const update: TranslationObjectAction = {
  title: 'Edit Channel',
  subTitle: 'Here you can edit a Channel.',
};

const translationObject: TranslationObject = {
  title: 'Channel | Channels',
  placeholder: 'Select a channel | Select channels',

  key: 'Key',
  name: 'Name',
  pixels: 'pixels',
  retailer: 'Select retailer',
  exportTemplate: 'Export template',
  mediaTemplate: 'Media template',
  mediaInstructions: 'Media Instructions',
  filePerProduct: 'File per product',
  exportResizeEnabled: 'Export resize enabled',
  template: 'Template',

  table: {
    name: 'Name',
    key: 'Key',
    type: 'Type',
    retailer: 'Retailer',
    template: 'Template',
  },
  fields: {
    type: 'Type',
    key: 'Key',
    name: 'Name',
    pixels: 'pixels',
    retailer: 'Select retailer',
    exportTemplate: 'Export template',
    mediaTemplate: 'Media template',
    mediaInstructions: 'Media Instructions',
    filePerProduct: 'File per product',
    exportResizeEnabled: 'Export resize enabled',
    exportResizeWidth: 'Width',
    exportResizeHeight: 'Height',
    exportResizeFormat: 'Extension',
    allowedFileTypes: 'Allowed file types',
    allowedFileTypesPlaceholder: 'Select allowed file types',
  },

  create: create,
  update: update,
};

const tranlation: Translation = {
  modules: translationObject,
};

export default tranlation;
