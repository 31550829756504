import { PaginationObject } from 'platform-unit2-api/core';
import { OrganisationRestService, Organisation } from 'platform-unit2-api/organisations';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Defaults } from '@/general/utils/constants/defaults';
import { Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

export default class OrganisationViewService {
  api: OrganisationRestService;
  loading: Ref<boolean> = ref(false);
  ts = new TranslationService('admin', 'organisations');
  organisations: Ref<Organisation[]>;
  total: Ref<number>;
  router;
  toastService = ToastService.getInstance();

  constructor() {
    this.api = new OrganisationRestService();
    this.organisations = ref([]);
    this.total = ref(0);
    this.router = useRouter();
  }
  /**
   *
   * @param params PaginationObject
   * fetches organisations (with deleted) and assignes the organisations variable to the result
   * also, assigns the total to the total of the response
   */
  private fetchOrganisations = async (params?: PaginationObject) => {
    const response = await this.api.getAll({ ...params }, { withDeleted: true });
    this.organisations.value = response.data;
    this.total.value = response.meta?.total ?? 0;
  };

  /**
   *
   * @param query string
   * Fetches the organisations with a limit of 500 and the query provided as a parameter
   * Utilises the createApiCall function from the same file to set the loading state as well
   */
  search = async (query = '') => {
    await this.createApiCall(this.fetchOrganisations, {
      query: query,
      limit: Defaults.REQUEST_LIMIT,
    });
  };

  /**
   *
   * @param page number
   * @param perPage number - amount of items per page
   * Changes the page of the pagination by also changing the url
   * Utilises the createApiCall function from the same file to set the loading state as well
   */
  changePage = async (page: number, perPage?: number) => {
    const pageNr = page + 1;
    await this.router.push({
      query: { page: pageNr.toString(), limit: perPage?.toString() ?? '15' },
    });
    await this.createApiCall(this.fetchOrganisations, { page: pageNr });
  };

  /**
   *
   * @param sortBy string - key of the column to sort by
   * Fetches the organisations with the corresponding sortBy option
   * Utilises the createApiCall function from the same file to set the loading state as well
   */
  sortTable = async (sortBy: string) => {
    await this.createApiCall(this.fetchOrganisations, { sortBy: sortBy });
  };

  /**
   *
   * @param id number
   * Deletes an organisation and refreshes the table
   */
  delete = async (id: number) => {
    await this.api.delete(id);
    await this.search();
  };

  restoreOrganisation = async (id: number) => {
    try {
      await this.api.restoreOrganisation(id);
      this.toastService.displaySuccessToast(this.ts.tModule('restoreOrganisation.success'));
    } catch (err) {
      this.toastService.displaySuccessToast(this.ts.tModule('restoreOrganisation.error'));
    }

    await this.search();
  };
  /**
   *
   * @param func Function - the async API call to be made
   * @param params any - params of the function
   * Switches the state of the loading ref
   */
  private async createApiCall(func: Function, ...params: any) {
    this.loading.value = true;
    await func(...params);
    this.loading.value = false;
  }
}
