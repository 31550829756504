<script setup lang="ts">
import { computed } from 'vue';
import UserAvatar from 'ui/components/user-avatar.vue';
import { useStore } from 'vuex';
import { Comment } from 'platform-unit2-api/comments';
/** Props */
export interface Props {
  comment?: Comment;
}
const props = defineProps<Props>();

const store = useStore();

const userName = computed(() => props.comment?.user?.name);
const userAvatar = computed(() => props.comment?.user?.thumbnail?.public_url);
const self = computed(() => props.comment?.user?.id === store.getters['users/currentUser']?.id);
const modelName = computed(() => props.comment?.model?.display_name ?? '');
const modelUrl = computed(() => props.comment?.model?.thumbnail?.public_url ?? '');
</script>
<template>
  <div>
    <div
      class="flex mb-3 w-8"
      :class="self ? 'mr-2 ml-auto flex-row-reverse' : 'ml-2 mr-auto flex-row'"
    >
      <UserAvatar
        class="flex-shrink-0"
        :class="self ? 'ml-2' : 'mr-2'"
        :url="userAvatar"
        :username="userName"
      />
      <div
        class="align-items-start border-1 border-50 flex p-3 w-full"
        :class="self ? 'flex-row-reverse bg-bluegray-50' : 'flex-row bg-blue-50'"
        :style="self ? 'border-radius: 1rem 0 1rem 1rem' : 'border-radius: 0 1rem 1rem 1rem'"
      >
        <div :class="self ? 'ml-3 mr-auto' : 'mr-3 ml-auto'" class="w-auto">
          {{ comment?.comment }}
        </div>
        <UserAvatar
          v-if="comment?.model"
          class="flex-shrink-0"
          :url="modelUrl"
          :username="modelName ?? undefined"
        />
      </div>
    </div>
  </div>
</template>
