<script setup lang="ts">
import { AttributeFieldsRendererService } from './attribute-fields-renderer.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { TranslationService } from '@/general/services/translations/translation.service';
import TabFieldV2 from 'ui/components/attribute-fields/tabs-field/tabs-field.v2.vue';
import GroupFieldV2 from './group-field/group-field.v2.vue';
import { uniqueId } from 'lodash';
defineProps<{
  attributeFields: AttributeField<any>[];
  advanceKey?: string;
}>();
const attributeFieldsRendererService = new AttributeFieldsRendererService();
const ts = new TranslationService('global', 'general');
</script>
<template>
  <div
    v-for="(attributeField, index) in attributeFields"
    :key="uniqueId(attributeField.attribute.id.toString() + index.toString() + (advanceKey ?? ''))"
    :class="
      attributeField.attribute.options?.group_size
        ? `col-${attributeField.attribute.options?.group_size}`
        : 'col-12'
    "
  >
    <div class="flex justify-content-between">
      <div class="flex justify-self-start">
        <strong class="mr-1">{{ attributeField.attribute.key }}</strong>
        <span v-if="attributeField.attribute.required" class="font-bold text-pink-500">&ast;</span>
        <i
          v-if="attributeFieldsRendererService.helpText(attributeField.attribute)"
          v-tooltip.right="
            attributeFieldsRendererService.helpText(attributeField.attribute)
              ? {
                  value: attributeFieldsRendererService.helpText(attributeField.attribute),
                  disabled: false,
                  class: 'text-sm',
                }
              : { disabled: true }
          "
          class="cursor-pointer mdi mdi-help-circle-outline ml-2 text-xl"
        ></i>
        <a
          v-if="attributeFieldsRendererService.articleLink(attributeField.attribute)"
          class="ml-2 text-500"
          :href="attributeFieldsRendererService.articleLink(attributeField.attribute)"
          target="_blank"
        >
          <i class="cursor-pointer mdi mdi-file-document-outline text-xl"></i>
        </a>
      </div>
      <div
        v-if="attributeFieldsRendererService.hasOverwrittenKey(attributeField.attribute)"
        class="flex justify-self-end original-name"
      >
        <div>
          {{
            ts.tGlobal('original', {
              params: {
                original: attributeField.attribute.original_key,
              },
            })
          }}
        </div>
      </div>
    </div>
    <div
      v-if="
        attributeField.attribute.options.type !== AttributeTypeEnum.TAB_FIELD &&
        attributeField.attribute.options.type !== AttributeTypeEnum.GROUP_FIELD
      "
    >
      <Component
        :is="attributeFieldsRendererService.getComponent(attributeField)"
        v-for="productAttributeField in attributeField.values || attributeField.children"
        :key="
        uniqueId(
          attributeField.attribute.id.toString() + (productAttributeField as ProductAttributeField<any>)?.id?.toString() + (productAttributeField.locale?.id ?? '').toString())
        "
        :product-attribute-field="productAttributeField"
        :attribute-field="attributeField"
      />
    </div>

    <GroupFieldV2
      v-if="attributeField.attribute.options.type === AttributeTypeEnum.GROUP_FIELD"
      :attribute-field="attributeField"
    />

    <TabFieldV2
      v-if="attributeField.attribute.options.type === AttributeTypeEnum.TAB_FIELD"
      :attribute-field="attributeField"
    />
  </div>
</template>

<style lang="scss" scoped>
.original-name {
  margin-right: 34px; //to align with end of input
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(139, 170, 201);
}
</style>
