<script setup lang="ts">
import store from '@/core/store';
import { TranslationService } from '@/general/services/translations/translation.service';
import { User } from '@sentry/vue';
import moment from 'moment';
import { computed } from 'vue';

const ts = new TranslationService('general', 'components');

const currentUser = computed<User>(() => store.getters['users/currentUser']);

const demoDurationPeriod = computed(() =>
  currentUser.value?.workspace?.organisation?.demo_end_date
    ? moment
        .duration(
          moment(new Date(currentUser.value?.workspace?.organisation?.demo_end_date))
            .endOf('day')
            .diff(moment(new Date()).endOf('day')),
        )
        .asDays()
    : null,
);

const isDemoAccount =
  currentUser.value &&
  currentUser.value.workspace?.organisation?.is_demo &&
  !currentUser.value.admin_mode;
</script>

<template>
  <p-inline-message
    v-if="isDemoAccount"
    severity="info"
    style="height: 40px"
    :sticky="true"
    :closable="true"
  >
    {{ ts.tModule('app.demo_account') }}
    {{
      demoDurationPeriod
        ? ts.tModule('app.expiration_days', { params: { demoDurationPeriod } })
        : ''
    }}</p-inline-message
  >
</template>
