<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { DataTablePageEvent, DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { Organisation } from 'platform-unit2-api/organisations';
import OrganisationViewService from './organisation.view.service';
import useDebounce from 'utils/debounce';
import { ToastService } from '@/general/services/toasts/toast.service';

/** Composables */
const router = useRouter();
const route = useRoute();
const { debounce } = useDebounce();

/** Services */
const ts = new TranslationService('admin', 'organisations');
const confirmService = new ConfirmService();
const orgService = new OrganisationViewService();
/** constants */
const perPage = route.query.limit ? +route.query.limit : 15;
let searchCallback: any = undefined;

/** Delete organisation */
const confirmOrganisationDelete = (event: PointerEvent, organisationObj: Organisation) => {
  confirmService.confirmDelete({
    event: event,
    group: 'organisations',
    message: ts.deleteConfirm(organisationObj.name),
    callback: () => orgService.delete(organisationObj.id),
  });
};

/** Restore organisation */
const confirmOragnisationRestore = (event: DataTableRowClickEvent) => {
  confirmService.confirmReset({
    group: 'organisationsRestore',
    message: ts.tModule('restoreOrganisation.message', {
      entity: event.data.name.toString(),
    }),
    callback: () => orgService.restoreOrganisation(event.data.id.toString()),
  });
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await orgService.search(query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

/** Displaying of Add/Edit sidebar */
const showSidebar = ref(false);

const toggleSidebar = () => {
  if (route.name === 'new-organisation' || route.name === 'edit-organisation') {
    showSidebar.value = true;
  }

  if (route.name === 'organisations') {
    showSidebar.value = false;
  }
};

/** Watching the route */
watch(
  () => route,
  () => {
    toggleSidebar();
  },
  {
    deep: true,
  },
);

/** onMounted lifecycle hook */
onMounted(async () => {
  toggleSidebar();
  await orgService.search();
});

const hideSidebar = () => {
  router.push({
    name: 'organisations',
  } as RouteLocationRaw);
};

const onRowClick = (event: DataTableRowClickEvent) => {
  if (event.data.deleted_at == null) {
    router.push({
      name: 'edit-organisation',
      params: { id: event.data.id.toString() },
    });
  } else {
    confirmOragnisationRestore(event);
  }
};
</script>
<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <div>
      <pIconField icon-position="left" class="my-3">
        <pInputIcon class="pi pi-search" />
        <pInputText
          :placeholder="ts.tGlobal('search')"
          @update:model-value="(value: string) => search(value)"
        />
      </pIconField>

      <!-- #region Data Table -->
      <pDataTable
        scrollable
        scroll-height="flex"
        :row-hover="true"
        removable-sort
        :lazy="true"
        :paginator="(orgService.total.value ?? 0) > perPage"
        :rows="perPage"
        :loading="orgService.loading.value"
        :total-records="orgService.total.value"
        :value="orgService.organisations.value"
        @row-click="onRowClick($event)"
        @page="(event: DataTablePageEvent) => orgService.changePage(event.page, perPage)"
        @sort="(event: DataTableSortEvent) => orgService.sortTable(((event.sortOrder ?? 0) < 0 ? '-' : '') + event.sortField)"
      >
        <p-column field="name" :header="ts.tForms('name')" sortable>
          <template #body="{ data }: { data: Organisation }">
            {{ data.name }}
            <pBadge
              v-if="data.deleted_at != null"
              severity="danger"
              :value="ts.tModule('deleted')"
              class="ml-2"
            />
          </template>
        </p-column>
        <p-column field="Description" :header="ts.tGlobal('description')">
          <template #body="slotProps">
            {{ slotProps.data.description ? slotProps.data.description : '-' }}
          </template>
        </p-column>

        <p-column>
          <template #body="slotProps">
            <div class="flex justify-content-end">
              <p-button
                icon="mdi mdi-delete-outline"
                severity="danger"
                rounded
                text
                @click="confirmOrganisationDelete($event, slotProps.data)"
              />
            </div>
          </template>
        </p-column>
      </pDataTable>
      <!-- #endregion -->
    </div>

    <!-- #region Add/Edit Sidebar -->
    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-md sidebar-crud"
      :dismissable="false"
      position="right"
      @hide="hideSidebar"
    >
      <router-view @hide="hideSidebar" @refresh="orgService.search" />
    </pSidebar>
    <!-- #endregion -->
    <p-confirm-popup group="organisations" />
    <p-confirm-dialog group="organisationsRestore" />
  </section>
</template>
