<script setup lang="ts">
import { computed, onBeforeMount, ref, watch, onMounted, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

//New layout
import SidebarContent from 'ui/layout/sidebar-content.vue';
import { User } from 'platform-unit2-api/users';

const currentUser = computed<User>(() => store.getters['users/currentUser']);

const store = useStore();
const route = useRoute();
const displayPaymentDialog = ref(false);

watch(
  () => store.getters['users/currentUser']?.id,
  () => {
    document.documentElement.style.fontSize =
      store.getters['users/currentUser']?.preferences?.scale.toString() + 'px';
  },
);

watch(
  () => route,
  () => {
    displayPaymentDialog.value = true;
  },
  {
    deep: true,
  },
);

onBeforeMount(() => {
  const scale =
    useStore().getters['users/currentUser'] &&
    useStore().getters['users/currentUser'].preferences.scale;
  document.documentElement.style.fontSize = scale + 'px';
});

/*** Feature flag */
const isSidebarcollapsed = ref(localStorage.getItem('navigation_collapsed') === 'true');

// #region : Sidebar */

const toggleSidebar = (value: boolean) => {
  isSidebarcollapsed.value = value;
  localStorage.setItem('navigation_collapsed', JSON.stringify(isSidebarcollapsed.value));
};

//Collapse sidebar in small screen
const collapseAtSize = 800;
const windowWidth = ref(window.innerWidth);

onMounted(() => {
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
});

watchEffect(() => {
  if (windowWidth.value <= collapseAtSize) {
    toggleSidebar(true);
  }
});
// #endregion */
</script>

<template>
  <aside v-if="currentUser" class="h-full" :class="{ collapsed: isSidebarcollapsed }">
    <div
      v-if="windowWidth > collapseAtSize"
      class="collapse-button"
      @click="toggleSidebar(!isSidebarcollapsed)"
    >
      <i
        v-tooltip.right="{
          value: isSidebarcollapsed ? 'Expand menu' : 'Collapse menu',
        }"
        class="mdi mdi-chevron-double-left"
      />
    </div>

    <SidebarContent :is-collapsed="isSidebarcollapsed" />
  </aside>
</template>

<style scoped lang="scss">
aside {
  width: 27em;
  grid-area: sidebar;
  background-color: #f5f7fa;
  border-right: none;
  position: relative;
  transition: all $animation-duration ease;
  .collapse-button {
    width: 24px;
    aspect-ratio: 1;
    border: 1px solid #cad9e7;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -12px;
    top: 6%;
    z-index: 120;
    cursor: pointer;
    color: $link-color;
    transition: all $animation-duration ease;

    &:hover {
      border-color: $primary-color;
    }
  }

  &.collapsed {
    width: 60px;
    .collapse-button {
      transform: rotate(180deg);
    }
  }
}
</style>
