<script setup lang="ts">
//Components
import Upload from '@/general/ui/components/upload.vue';
// import CreateExport from '../components/create-export.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';

//Types
import { User } from 'platform-unit2-api/users';
import { MenuItem } from 'primevue/menuitem';
import Menu from 'primevue/menu';
import { Export } from 'platform-unit2-api/exports';
import {
  ExportPipelineFilters,
  PipelineStatus,
  PipelineStageEnum,
  Pipeline,
  PipelinesRestService,
} from 'platform-unit2-api/pipelines';

//Core
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';

//Composables and Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import usePagination from 'composables/usePagination/pagination';
import { DataTablePageEvent } from 'primevue/datatable';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'exports');
const confirmService = new ConfirmService();
const pipelineApi = new PipelinesRestService();

/** Constants */
const store = useStore();
const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const router = useRouter();
const selectedExport = ref<Export>();
const route = useRoute();
const menu = ref<Menu>();

const loading = ref(false);
const showSidebar = ref<boolean>(false);
let timer!: ReturnType<typeof setInterval>;
const expandedRows = ref([]);

// const displayModal = ref<boolean>(false);
const pipelines = ref<Pipeline[]>([]);
const total = ref();

const currentUser = computed((): User => {
  return store.getters['users/currentUser'];
});

const hideDetails = (): void => {
  showSidebar.value = false;
  router.push({
    name: 'export' as string,
  } as RouteLocationRaw);
};

const menuItems = (): MenuItem[] => [
  {
    label: ts.tGlobal('download'),
    icon: 'mdi mdi-download',
    command: () => {
      openDownload();
    },
  },
  {
    label: ts.tModule('tooltips.restart'),
    icon: 'mdi mdi-restart',
    command: () => {
      restartExport();
    },
  },
  {
    label: ts.tModule(`tooltips.go_to_products`),
    icon: 'mdi mdi-open-in-new',
    command: () => {
      goToProducts();
    },
  },
  {
    separator: true,
  },
  {
    label: ts.deleteTitle,
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      confirmExportDelete();
    },
  },
];

const loadAsyncData = async (showLoadingIcon = true, searchQuery?: string): Promise<void> => {
  loading.value = showLoadingIcon;
  if (searchQuery !== undefined) {
    query.value = searchQuery;
  }

  try {
    const filterParams: ExportPipelineFilters = { type: PipelineStageEnum.EXPORT };
    const response = await pipelineApi.getAll(
      { page: page.value, limit: perPage.value, query: query.value },
      filterParams,
    );
    pipelines.value = response.data;
    if (response.meta !== undefined) {
      total.value = response.meta.total;
    }
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }

  loading.value = false;
};

const toggleActionMenu = (event: PointerEvent, exportItem: Export) => {
  selectedExport.value = exportItem;
  menu.value?.toggle(event);
};

// const closeExportModal = () => {
//   displayModal.value = false;
// };

const goToProducts = async (id?: number) => {
  router.push({
    name: 'products',
    query: { pipelineId: id } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const openDownload = () => {
  window.open(
    import.meta.env.BASE_URL.toString().replace(/\/$/, '') + selectedExport.value?.public_url,
    '_blank',
  );
};

const restartExport = async () => {
  router.push({
    name: 'restart-export',
    params: {
      id: selectedExport.value?.id,
    } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const confirmExportDelete = () => {
  confirmService.confirmDelete({
    callback: () => deleteExport(selectedExport.value?.id),
    group: 'pipelines',
    message: ts.deleteConfirm(),
  });
};

const deleteExport = async (id?: number): Promise<void> => {
  try {
    if (id === undefined) {
      return;
    }

    await pipelineApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    loadAsyncData();
  } catch {
    toastService.displayErrorToast(ts.deleteFailed());
  }
};

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

/** Lifecycle */
watch(
  () => route,
  () => {
    if (route.name === 'restart-export') {
      showSidebar.value = true;
    }

    if (route.name === 'export') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

onMounted(() => {
  timer = setInterval(loadAsyncData.bind(this, false), 5000);
  loadAsyncData();
});

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <pDataTable
      v-model:expanded-rows="expandedRows"
      scrollable
      scroll-height="flex"
      :value="pipelines"
      :row-hover="true"
      :loading="loading"
      :lazy="true"
      :paginator="total > perPage"
      :rows="perPage"
      :total-records="total"
      :first="(page - 1) * perPage"
      data-key="id"
      @page="(event: DataTablePageEvent) => onPageChange(event)"
    >
      <template #empty>
        <EmptyState
          :translation-service="ts"
          :button-visible="true"
          :button-label="ts.tGlobal('productOverview')"
          :icon-name="'exports'"
          @clicked="goToProducts()"
        />
      </template>
      <p-column :expander="true" header-style="width: 3rem" />
      <!-- #region column names-->
      <p-column :header="ts.tModule('table_header.retailer')" width="1">
        <template #body="slotProps">
          <upload class="w-4rem" :upload="slotProps.data.retailer?.thumbnail" />
        </template>
      </p-column>
      <p-column field="retailer" header>
        <template #body="slotProps">
          {{ slotProps.data.retailer ? slotProps.data.retailer.name : 'Productsup' }}
        </template>
      </p-column>
      <p-column field="user" :header="ts.tModule('table_header.user')">
        <template #body="slotProps">
          <div v-if="slotProps.data.user">
            {{
              slotProps.data.user != null
                ? currentUser && slotProps.data.user.id === currentUser.id
                  ? ts.tGlobal('me')
                  : slotProps.data.user.name
                : ts.tGlobal('system')
            }}
          </div>
          <div v-else>{{ ts.tGlobal('unknown_user') }}</div>
        </template>
      </p-column>
      <p-column field="status" :header="ts.tModule('table_header.status')">
        <template #body="slotProps">
          <StatusChip
            icon-only
            :label="slotProps.data.status.message"
            :severity="slotProps.data.status.color"
          />
        </template>
      </p-column>

      <p-column field="created_at" :header="ts.tModule('table_header.created_at')">
        <template #body="slotProps">
          {{ moment(slotProps.data.created_at).format('DD MMM Y, HH:mm') }}
        </template>
      </p-column>

      <p-column class="flex-none" header-style="width: 5rem" body-style="width: 5rem">
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <p-button
              icon="mdi mdi-dots-vertical"
              text
              plain
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="(event: PointerEvent) =>toggleActionMenu(event, slotProps.data)"
            />
            <p-menu
              id="overlay_menu"
              ref="menu"
              class="w-auto"
              :model="menuItems()"
              :popup="true"
            />
          </div>
        </template>
      </p-column>
      <!-- #endregion-->
      <!-- region dropdown expansion per export-->
      <template #expansion="slotProps">
        <div class="flex justify-content-around w-full">
          <div class="w-6">
            <div class="pl-8 w-full">
              <h3 class="font-bold mb-6 text-xl">{{ ts.tModule('details.title') }}</h3>
              <div class="grid">
                <div class="col-6">
                  <div class="flex mb-1">
                    <div class="col-8">
                      <label>{{ ts.tModule('details.dry_run') }}</label>
                    </div>

                    <div class="col-4">
                      <p-checkbox
                        v-model="slotProps.data.payload.dry_run"
                        :binary="true"
                        class="w-full"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div
                    v-if="slotProps.data.payload.parameters.export_type === 'mapping-export'"
                    class="flex mb-1"
                  >
                    <div class="col-8">
                      <label>{{ ts.tModule('details.include_data') }}</label>
                    </div>

                    <div class="col-4">
                      <p-checkbox
                        v-model="slotProps.data.payload.parameters.include_data"
                        :binary="true"
                        class="w-full"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div
                    v-if="slotProps.data.payload.parameters.export_type === 'mapping-export'"
                    class="flex mb-1"
                  >
                    <div class="col-8">
                      <label>{{ ts.tModule('details.include_assets') }}</label>
                    </div>

                    <div class="col-4">
                      <p-checkbox
                        v-model="slotProps.data.payload.parameters.include_assets"
                        :binary="true"
                        class="w-full"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="flex mb-1">
                    <div class="col-6">
                      <label>{{ ts.tModule('details.channel') }}</label>
                    </div>

                    <div class="col-6">
                      <template v-if="slotProps.data.module">
                        {{ slotProps.data.module.name }}
                      </template>
                    </div>
                  </div>

                  <div class="flex mb-1">
                    <div class="col-6">
                      <label>{{ ts.tModule('details.locale') }}</label>
                    </div>

                    <div class="col-6">
                      <template v-if="slotProps.data.locale">
                        {{ slotProps.data.locale.value }}
                      </template>
                    </div>
                  </div>

                  <div v-if="slotProps.data.payload.parameters.files" class="flex mb-1">
                    <div class="col-6">
                      <label>File</label>
                    </div>

                    <div class="col-6">
                      <a download target="_blank" :href="slotProps.data.payload.parameters.files"
                        ><i class="mdi mdi-download text-primary"
                      /></a>
                    </div>
                  </div>

                  <div class="flex mb-1">
                    <div class="col-6">
                      <label>{{ ts.tModule('details.mail_to') }}</label>
                    </div>

                    <div class="col-6">
                      <template v-for="email in slotProps.data.payload.parameters.mail_to">
                        {{ email }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- vertical history of the export-->
          <div class="align-items-center flex w-6">
            <p-timeline
              :value="slotProps.data.statuses.map((item: PipelineStatus) => item).reverse()"
              layout="vertical"
              align="left"
            >
              <template #marker="prop">
                <p-badge :severity="prop.item.level === 'error' ? 'danger' : prop.item.level" />
              </template>
              <template #opposite="prop">
                <small class="p-text-secondary">
                  {{ moment(prop.item.occurred_at).format('DD MMM Y, HH:mm') }}
                </small>
              </template>
              <template #content="prop">
                <p
                  v-tooltip.right="{
                    value: prop.item.message ?? '',
                    disabled: !prop.item.message,
                    class: 'text-sm',
                  }"
                >
                  {{ prop.item.text }}
                </p>
              </template>
            </p-timeline>
          </div>
        </div>
        <!-- endregion-->
      </template>
    </pDataTable>
    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-md"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @load-async-data="loadAsyncData" />
    </pSidebar>
    <p-confirm-dialog group="pipelines" />

    <!-- <create-export :display="displayModal" @close="closeExportModal" /> -->
  </section>
</template>
