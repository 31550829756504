import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Organisation',
  subTitle: 'Here you can create a new organisation.',
};

const update: TranslationObjectAction = {
  title: 'Edit Organisation',
  subTitle: 'Here you can edit an organisation.',
};

const translationObject: TranslationObject = {
  title: 'Organisation | Organisations',
  placeholder: 'Select a organisation | Select organisation',

  workspaceInfo: 'To add workspaces to an organisation visit the workspaces page.',

  friendlyPaymentReminder: {
    title: 'Friendly Payment Reminder',
    description:
      'This reminder will show a banner on top of the page with a message that the customer needs to pay his invoice.',
    message:
      'It seems that your payments have not been completed yet. Please contact Productsup for more information.',
  },
  productStatusSelect: {
    placeholder: 'Select product status',
  },
  urgentPaymentReminder: {
    title: 'Urgent Payment Reminder',
    description:
      'This reminder will show a pop-up everytime the user switch a page. Use this reminder only when it is really necessary because it is in fact very annoying and causes the customer to barely be able to work. ',
    message:
      'It seems that your payments have not been completed yet. Please contact Productsup for more information. Otherwise, your account will be deactivated shortly. ',
  },
  selectUsersPlaceholder: 'Select Users',
  deleted: 'Deleted',

  demoAccount: {
    title: 'Demo account',
    bannerTitle: 'Mark as demo account',
    bannerDescription:
      'Enabling this option will mark the organisation as demo account. The organisation will be automatically deleted after 90 days. ',
  },

  restoreOrganisation: {
    message: 'Are you sure you want to restore "{entity}" organisation?',
    success: 'Organisation restored successfully!',
    error: 'We could not restore the organisation.',
  },

  workflowLabels: 'Workflow labels',
  workflowLabelsInfo:
    'These labels determine the flow of enriching products. In he near future these labels can be changed, for now they are the same for each organisation.',

  paymentReminders: 'Payment Reminder | Payment Reminders',

  create: create,
  update: update,
};

const tranlation: Translation = {
  organisations: translationObject,
};

export default tranlation;
