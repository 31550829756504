<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import { FeaturePermission, UserPermission } from 'platform-unit2-api/roles';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import useFormValidation from 'composables/form-validation';
import { enumToArray } from '@/general/utils/enumToArray';
import { featurePermissionMap } from 'platform-unit2-api/roles';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';

import { Role, UpdateRole, RolesRestService } from 'platform-unit2-api/roles';
import { isNegative } from '@/general/utils/isNegative';
import { isPositive } from '@/general/utils/isPositive';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const store = useStore();
const route = useRoute();

/** Services */
const ts = new TranslationService('admin', 'roles');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const rolesApi = new RolesRestService();

/** Constants */
const loading = ref(false);
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();
const saving = ref(false);
const editorRole = ref<Role>();
const oldFeaturePermissions = ref<FeaturePermission[]>([]);

const filteredFeaturePermissions = computed((): FeaturePermission[] => {
  return enumToArray(FeaturePermission).map((item) => Object.assign({ name: item }));
});

const filteredUserPermissions = computed(() => {
  return enumToArray(UserPermission).map((item) => Object.assign({ name: item }));
});

const getFeaturePermissions = computed(() => {
  const featurePermissions = [];
  for (const permission of enumToArray(FeaturePermission)) {
    const hasPermission = featurePermissionMap[permission].every((userPermission) => {
      return editorRole.value?.permissions?.includes(userPermission);
    });

    if (hasPermission) {
      featurePermissions.push(permission);
    }
  }

  return featurePermissions;
});

const setFeaturePermissions = (featurePermissions: FeaturePermission[]) => {
  let permissions = editorRole.value?.permissions;

  // Get a list of all unique user permissions previously selected
  const oldUserPermissions = [
    ...new Set(
      oldFeaturePermissions.value
        .map((featurePermission) => featurePermissionMap[featurePermission])
        .flat(),
    ),
  ];

  // Remove those permissions from the current list of user permissions
  permissions = permissions?.filter((permission) => !oldUserPermissions.includes(permission));

  // Add the user permissions corresponding to the selected feature permissions
  for (const permission of featurePermissions) {
    permissions?.push(...featurePermissionMap[permission]);
  }

  oldFeaturePermissions.value = [...featurePermissions];
  // Set the editor role permissions to be a unique set of the new permissions
  if (editorRole.value != null) {
    editorRole.value.permissions = [...new Set(permissions)];
  }
};

const handleSubmit = async (): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.updateFailed(editorRole.value?.name ?? ''));
    return;
  }

  resetFormErrors();
  try {
    saving.value = true;
    const role: UpdateRole = {
      id: +route.params.id[0] ?? 0,
      name: editorRole.value?.name ?? '',
      permissions: editorRole.value?.permissions ?? [],
      created_at: editorRole.value?.created_at ?? '',
      updated_at: new Date().toString(),
    };

    await store.dispatch('roles/UPDATE_ROLE', role);

    emit('refresh');
    emit('hide');

    toastService.displaySuccessToast(ts.updateSuccess(editorRole.value?.name ?? ''));
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.updateFailed(editorRole.value?.name ?? ''));
    });
  } finally {
    saving.value = false;
  }
};

const deleteRole = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await rolesApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess(editorRole.value?.name ?? ''));
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed(editorRole.value?.name ?? ''));
  } finally {
    emit('refresh');
    emit('hide');
    loading.value = false;
  }
};

const confirmRoleDelete = (event: PointerEvent, id?: number) => {
  if (id == null) {
    return;
  }

  confirmService.confirmDelete({
    event: event,
    group: 'role-edit',
    message: ts.deleteConfirm(editorRole.value?.name ?? ''),
    callback: () => deleteRole(id),
  });
};

// Lifecycle hooks
onMounted(async () => {
  if (isPositive(route.params.id)) {
    loading.value = true;
    editorRole.value = await rolesApi.get(Number(route.params.id));
    loading.value = false;
  }
});
</script>

<template>
  <div class="h-full">
    <SidebarSkeleton v-if="loading" />
    <CrudSidebar
      v-else
      :title="ts.moduleUpdateTitle"
      :subtitle="ts.moduleUpdateSubTitle"
      :saving="saving"
      @cancel="emit('hide')"
      @save="handleSubmit()"
      @delete="(event: any) => confirmRoleDelete(event, editorRole?.id)"
    >
      <template #sidebar-data>
        <div class="field mb-3">
          <label for="name">{{ ts.tForms('name') }}</label>
          <p-input-text
            v-if="editorRole"
            v-model="editorRole.name"
            class="w-full"
            :class="{ 'p-invalid': hasError('name') }"
            type="text"
          ></p-input-text>
          <small
            v-if="hasError('name')"
            :class="{ 'p-error block': hasError('name') }"
            class="hidden"
            >{{ fieldErrorMessage('name').toString() }}</small
          >
        </div>

        <div class="field mb-3">
          <label>{{ ts.tModule('permissionGroupsLabel') }}</label>
          <pMultiselect
            :model-value="getFeaturePermissions"
            :options="filteredFeaturePermissions"
            :placeholder="ts.tModule('permissionGroupsPlaceholder')"
            option-label="name"
            option-value="name"
            display="chip"
            :empty-filter-message="ts.tModule('permissionGroupsNotFound')"
            class="w-full"
            :filter="true"
            :class="{ 'p-error block': hasError('permissions') }"
            @update:model-value="(event: any) => setFeaturePermissions(event)"
          ></pMultiselect>
          <small
            v-if="hasError('permissions')"
            :class="{ 'p-error block': hasError('permissions') }"
            class="hidden"
            >{{ fieldErrorMessage('permissions').toString() }}</small
          >
        </div>

        <div class="field mb-3">
          <label>{{ ts.tModule('permissionsLabel') }}</label>
          <pMultiselect
            v-if="editorRole"
            v-model="editorRole.permissions"
            :options="filteredUserPermissions"
            :placeholder="ts.tModule('permissionsPlaceholder')"
            option-label="name"
            option-value="name"
            :empty-filter-message="ts.tModule('permissionsNotFound')"
            display="chip"
            class="w-full"
            :class="{ 'p-invalid': hasError('permissions') }"
            :filter="true"
          ></pMultiselect>
          <small
            v-if="hasError('permissions')"
            :class="{ 'p-error block': hasError('permissions') }"
            class="hidden"
            >{{ fieldErrorMessage('permissions').toString() }}</small
          >
        </div>

        <p-confirm-popup group="role-edit" />
      </template>
    </CrudSidebar>
  </div>
</template>
