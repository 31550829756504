import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import ClientsEdit from 'admin/modules/clients/components/client-edit.vue';
import ClientsNew from 'admin/modules/clients/components/client-new.vue';
import Clients from 'admin/modules/clients/views/clients.view.vue';
import { RouteRecordRaw } from 'vue-router';

const clientsRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/clients',
    name: 'clients',
    component: Clients,
    title: 'Workspaces',
    translations: {
      module: 'clients',
      platform: 'admin',
    },
    children: [
      RouteFactory.createEmptyRoute({
        path: 'new',
        name: 'new-client',
        component: ClientsNew,
        rights: [FeaturePermission.MANAGE_CLIENTS],
        title: 'New client',
      }),
      RouteFactory.createEmptyRoute({
        path: ':id',
        name: 'edit-client',
        component: ClientsEdit,
        title: 'Edit client',
        rights: [FeaturePermission.MANAGE_CLIENTS],
      }),
    ],
  }),
];

export default clientsRoutes;
