import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Status Notification | Status Notifications',
  dataCategory: 'Data Category',
  dataCheckDateTime: 'Data Check  Time',
  dataCheckKey: 'Data Check Key',
  gln: 'GLN',
  glnClientName: 'GLN client name',
  gtin: 'GTIN',
  startAvailabilityDateTime: 'Start Availability Time',
  status: 'Status',
  actions: 'Actions',
  open: 'Open',
  closed: 'Closed',
  cancelled: 'Cancelled',
  approve: 'Approve',
  approveInProgress: 'Approval in progress',
  approveSuccess: 'Status notification approval request has been sent successfully',
  approveFailed: 'Status notification approval request failed',
  emptyStateTitle: 'No status notifications found',
  emptyStateSubtitle: 'There are no status notifications to display',
  searchPlaceholder: 'Search by GLN or GTIN',
  uploadButton: 'Upload client names',
};

const translation: Translation = {
  statusNotifications: translationObject,
};

export default translation;
