<script setup lang="ts">
import { inject, ref } from 'vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { InputSelectFieldService } from 'services/attribute-fields/field-services/input-select-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.v2.vue';
import { InputSelectType } from '@/general/services/attribute-fields/interfaces/input-select.interface';

/** Props */
interface Props {
  attributeField: AttributeField<InputSelectType>;
  productAttributeField: ProductAttributeField<InputSelectType>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const inputSelectFieldService = ref(
  new InputSelectFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="inputSelectFieldService">
    <pInputGroup
      :class="
        inputSelectFieldService.getErrorTypeClass() === ''
          ? 'input-group-focus'
          : `${inputSelectFieldService.getErrorTypeClass()} border-1`
      "
    >
      <pInputNumber
        v-model="inputSelectFieldService.decimal"
        input-class="border-right-none border-noround-left "
        :disabled="inputSelectFieldService.productAttributeField.locked"
        :placeholder="inputSelectFieldService.ts.tGlobal('placeholder')"
      />
      <pInputGroupAddon>
        <pDropdown
          v-model="inputSelectFieldService.unit"
          class="bg-white border-left-none border-noround-left h-full min-w-min"
          :show-clear="
            inputSelectFieldService.unit != null &&
            !inputSelectFieldService.productAttributeField.locked
          "
          :placeholder="inputSelectFieldService.ts.tGlobal('unit')"
          :options="inputSelectFieldService.attributeField.attribute?.options?.selectValues"
          :disabled="inputSelectFieldService.productAttributeField.locked"
          :pt="{
            input: {
              class: 'align-items-center flex',
            },
          }"
        />
      </pInputGroupAddon>
    </pInputGroup>
  </WrapperField>
</template>
<style lang="scss" scoped>
.input-group-focus:focus-within {
  border: 1px solid $focus-border-color;
}
</style>
