import {
  OrganisationRestService,
  Organisation,
  CreateOrganisation,
  OrganisationUserUpdateRequest,
  UpdateOrganisationRequest,
} from 'platform-unit2-api/organisations';
import { ProductStatusesRestService, ProductStatus } from 'platform-unit2-api/product-statuses';
import { User } from 'platform-unit2-api/users';
import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import { ActionTree } from 'vuex';

const organisationApi = new OrganisationRestService();
const productStatusApi = new ProductStatusesRestService();

export const actions: ActionTree<{}, {}> = {
  async GET_ORGANISATIONS(
    {},
    { page, limit, query, sortBy, withDeleted }: PaginationObject,
  ): Promise<ListResponse<Organisation>> {
    return await organisationApi.getAll(
      { page, limit, query, sortBy },
      { withDeleted: withDeleted ?? false },
    );
  },

  async DELETE_ORGANISATION({}, { id }): Promise<void> {
    await organisationApi.delete(id);
  },

  async GET_ORGANISATION_DETAILS({}, { id }): Promise<Organisation> {
    const organisation = await organisationApi.get(id);
    return organisation;
  },

  async UPDATE_ORGANISATION(
    {},
    { id, updatedOrganisation }: { id: number; updatedOrganisation: UpdateOrganisationRequest },
  ): Promise<void> {
    await organisationApi.update(id, updatedOrganisation);
  },

  async CREATE_ORGANISATION({}, organisation: CreateOrganisation): Promise<Organisation> {
    const newOrganisation = await organisationApi.post(organisation);
    return newOrganisation;
  },

  async GET_ORGANISATION_USERS({}, { id }): Promise<ListResponse<User>> {
    return await organisationApi.getOrganisationUsers(id);
  },

  async UPDATE_BILLING_USERS(
    {},
    { id, user_ids }: { id: number; user_ids: OrganisationUserUpdateRequest },
  ): Promise<Organisation> {
    return await organisationApi.updateBillingUsers(id, user_ids);
  },

  async GET_PRODUCT_STATUSES({}): Promise<ProductStatus[]> {
    return (await productStatusApi.getAll()).data;
  },
};
