<script setup lang="ts">
import { ref } from 'vue';

import useFormValidation from 'composables/form-validation';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import { RouteLocationRaw, RouteParamsRaw, useRouter } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';

import { CreateDatamodelRequest, Datamodel } from 'platform-unit2-api/datamodels';
import { AdminRestService } from 'platform-unit2-api/admin';

const emits = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'datamodels');
const toastService = ToastService.getInstance();
const router = useRouter();
const adminApi = new AdminRestService();

/** Constants */
const saving = ref(false);
const editorDatamodel = ref<Datamodel>({} as Datamodel);

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const datamodel: CreateDatamodelRequest = {
      name: editorDatamodel.value.name,
    };

    const response = await adminApi.createDatamodelAdmin(datamodel);

    emits('refresh');
    emits('hide');

    toastService.displaySuccessToast(ts.createSuccess(datamodel.name));

    router.push({
      name: 'edit-datamodel',
      params: { id: response.id.toString() } as RouteParamsRaw,
    } as RouteLocationRaw);
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.createFailed());
    });
  } finally {
    saving.value = false;
  }
};
</script>
<template>
  <CrudSidebar
    :title="ts.moduleCreateTitle"
    :subtitle="ts.moduleCreateSubTitle"
    :saving="saving"
    @cancel="emits('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <div class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          v-if="editorDatamodel"
          id="name"
          v-model="editorDatamodel.name"
          class="w-full"
          :class="{ 'p-invalid': hasError('name') }"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
    </template>
  </CrudSidebar>
</template>
