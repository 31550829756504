<script setup lang="ts">
//Types
import { Attribute } from 'platform-unit2-api/attributes';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ModuleDetail } from 'platform-unit2-api/modules';
import { LocaleDetails } from 'platform-unit2-api/locales';
import { MappingsRestService } from 'platform-unit2-api/mappings';

//Components
import EmptyState from '@/general/ui/components/empty-state.vue';
import ExportMappingUnsupported from 'retailer/modules/export-mappings/components/export-mapping-unsupported.vue';
import ExportMappingSupported from 'retailer/modules/export-mappings/components/export-mapping-supported.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import MappingDuplicateModal from 'retailer/modules/mappings/components/mapping-duplicate-modal.vue';

//Core
import { onMounted, computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';

//Composables and Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ExportMappingService } from 'retailer/modules/export-mappings/services/export-mapping.service';
import { MappingService } from 'retailer/modules/mappings/services/mappings.service';
import { PaginationObject } from 'platform-unit2-api/core';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'export_mappings');
const exportMappingService = ref(new ExportMappingService());
const mappingService = ref(new MappingService());
const mappingsApi = new MappingsRestService();

// Constants
const store = useStore();
const selectedDatamodel = ref<Datamodel>();
const selectedModule = ref<ModuleDetail>();
const attributes = ref<Attribute[]>([]);
const locales = ref<LocaleDetails[]>([]);

const selectedDatamodelEdit = computed(() => {
  return selectedDatamodel.value;
});

const selectedModuleEdit = computed(() => {
  return selectedModule.value;
});

const loadAsyncData = async (): Promise<void> => {
  try {
    await mappingsApi.getAll(
      {
        page: 1,
        limit: 1000,
      } as PaginationObject,
      { module_type: 'export' },
    );
    loadLocales();
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tGlobal('data')));
  }
};

const loadLocales = async () => {
  locales.value = (await store.dispatch('locales/SEARCH_LOCALES', { query: '' })).data;
};

// Lifecycle hooks
watch(
  () => selectedDatamodel.value,
  () => {
    if (selectedDatamodel.value) attributes.value = selectedDatamodel.value?.attributes;
  },
);

onMounted(() => {
  loadAsyncData();
});

watchEffect(async () => {
  if (exportMappingService.value.selectedModule && exportMappingService.value.selectedDatamodel) {
    await exportMappingService.value.getTemplateEndpoint();
  }
});

const duplicateMenuItems = ref([
  {
    label: ts.tModule('duplicate_mappings.title'),
    icon: 'mdi mdi-content-copy',
    disabled: () => exportMappingService.value.isDuplicationDisabled(),
    command: () => {
      mappingService.value.openModal();
    },
  },
]);
</script>

<template>
  <div class="flex flex-column p-4" style="height: inherit">
    <!-- Selection Form -->
    <div class="align-items-center flex justify-content-between">
      <div class="col-12 md:col-5">
        <DatamodelSelect
          v-model="selectedDatamodel"
          flatten-children-attributes
          @update:model-value="exportMappingService.selectedDatamodel = selectedDatamodel"
        />
      </div>

      <div class="col-1">
        <p-divider class="hidden md:flex" layout="vertical">
          <b>{{ ts.tGlobal('and') }}</b>
        </p-divider>
        <p-divider class="flex md:hidden" align="center">
          <b>{{ ts.tGlobal('and') }}</b>
        </p-divider>
      </div>

      <div class="col-12 md:col-5">
        <ModuleSelect
          v-model="selectedModule"
          type="export"
          @update:model-value="exportMappingService.selectedModule = selectedModule"
        />
      </div>

      <div class="divider"></div>

      <!-- Duplicate Menu -->
      <div class="flex-end m-2 mt-auto">
        <ActionsMenu :menu-items="duplicateMenuItems" />
      </div>
    </div>

    <!-- Mapping Items -->
    <template v-if="selectedDatamodel && selectedModule">
      <div class="text-right w-full"></div>
      <p-divider />

      <div class="w-full">
        <p-tab-view v-if="exportMappingService.filteredMappingItemOwner()">
          <p-tab-panel header="Supported items">
            <div>
              <ExportMappingSupported
                :mapping-items="exportMappingService.mappingItems"
                :endpoints="exportMappingService.endpoints"
                :attributes="attributes"
                :selected-datamodel="selectedDatamodelEdit"
                :selected-module="selectedModuleEdit"
                :attribute-configs="exportMappingService.attributeConfigs"
                :owner-id="exportMappingService.filteredMappingItemOwner()!.id"
                :view-only="false"
                :loading="exportMappingService.loading"
                @refresh="exportMappingService.getMappingItems()"
              />
            </div>
          </p-tab-panel>
          <p-tab-panel header="Unsupported items">
            <div>
              <ExportMappingUnsupported
                :mapping-items="exportMappingService.mappingItems"
                :selected-datamodel="selectedDatamodelEdit"
                :selected-module="selectedModuleEdit"
                :owner="exportMappingService.filteredMappingItemOwner()!"
                :attributes="attributes"
                :view-only="false"
                :loading-mapping-items="exportMappingService.loading"
                @refresh="exportMappingService.getMappingItems()"
              />
            </div>
          </p-tab-panel>
        </p-tab-view>
      </div>
    </template>
    <EmptyState
      v-else
      style="flex-grow: 1"
      :translation-service="ts"
      :icon-name="'export-mappings'"
      :button-visible="false"
    />
    <MappingDuplicateModal
      v-if="selectedDatamodel && selectedModule"
      :visible="mappingService.duplicateDialogVisible"
      :selected-datamodel="selectedDatamodel"
      :selected-module="selectedModule"
      @hide="mappingService.duplicateDialogVisible = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.p-autocomplete :deep(.p-inputtext) {
  width: 100%;
}

.p-divider :deep(.p-divider-content) {
  background-color: #f3f6f9;
}
</style>
