<script setup lang="ts">
import usePagination from 'composables/usePagination/pagination';
import Upload from '@/general/ui/components/upload.vue';
import useSortable from 'composables/sortable';
import { onMounted, ref, watch } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { DataTablePageEvent, DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { Client, ClientsRestService } from 'platform-unit2-api/clients';

import useDebounce from 'utils/debounce';

/** Composables */
const router = useRouter();
const route = useRoute();
const { debounce } = useDebounce();

const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const { sort, onSortChange: $onSortChange } = useSortable();

/** Services */
const ts = new TranslationService('admin', 'clients');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const clientApi = new ClientsRestService();

/** Constants */
const loading = ref(false);
const showSidebar = ref(false);

const clients = ref<Client[]>([]);
const total = ref(0);
const sortField = ref('');
let searchCallback: any = undefined;

const loadAsyncData = async (customSort?: string, searchQuery?: string): Promise<void> => {
  loading.value = true;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    const response = await clientApi.getAll({
      query: query.value,
      page: page.value,
      limit: perPage.value,
      sortBy: customSort ?? sort.value,
    });

    clients.value = response.data;
    total.value = response.meta?.total ?? 0;
  } catch (err: any) {
    toastService.displayErrorToast(ts.loadFailed());
  }

  loading.value = false;
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData(undefined, query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

const hideDetails = () => {
  router.push({
    name: 'clients',
  } as RouteLocationRaw);
};

const deleteClient = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await clientApi.delete(id);

    toastService.displaySuccessToast(ts.deleteSuccess());

    await loadAsyncData();
  } catch (err: any) {
    toastService.displayErrorToast(ts.deleteFailed());
  }

  loading.value = false;
};

const confirmClientDelete = (event: PointerEvent, client: Client) => {
  confirmService.confirmDelete({
    event: event,
    group: 'clients',
    message: ts.deleteConfirm(client.name),
    callback: () => deleteClient(client.id),
  });
};

/** Emit Events */
const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

const onSortChange = (event: DataTableSortEvent) => {
  if (event.sortOrder == null) {
    $onSortChange('', loadAsyncData);
    return;
  }

  $onSortChange((event.sortOrder < 0 ? '-' : '') + event.sortField, loadAsyncData);
};

const refresh = () => {
  loadAsyncData();
  sortField.value = '';
};

/** Lifecycle */
onMounted(() => {
  if (route.name === 'new-client' || route.name === 'edit-client') {
    showSidebar.value = true;
  }

  loadAsyncData();
});

watch(
  () => route,
  () => {
    if (route.name === 'new-client' || route.name === 'edit-client') {
      showSidebar.value = true;
    }

    if (route.name === 'clients') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);
</script>
<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <div>
      <pIconField icon-position="left" class="my-3">
        <pInputIcon class="pi pi-search" />
        <pInputText
          :placeholder="ts.tGlobal('search')"
          @update:model-value="(value: string) => search(value)"
        />
      </pIconField>
      <pDataTable
        ref="table"
        scrollable
        scroll-height="flex"
        :value="clients"
        :row-hover="true"
        removable-sort
        :loading="loading"
        :lazy="true"
        :paginator="total > perPage"
        :rows="perPage"
        :sort-field="sortField"
        :sort-order="1"
        :total-records="total"
        :first="(page - 1) * perPage"
        @row-click="(event: DataTableRowClickEvent) =>
          $router.push({ name: 'edit-client', params: { id: event.data.id.toString() } })
        "
        @page="($event: DataTablePageEvent) => onPageChange($event)"
        @sort="($event: DataTableSortEvent) => onSortChange($event)"
      >
        <p-column width="1">
          <template #body="slotProps">
            <Upload :upload="slotProps.data.thumbnail" class="w-5rem" />
          </template>
        </p-column>
        <p-column field="name" header="Name" sortable />
        <p-column field="general_contact" header="General contact">
          <template #body="slotProps">
            {{ slotProps.data.general_contact ? slotProps.data.general_contact.name : '-' }}
          </template>
        </p-column>
        <p-column field="financial_contact" header="Financial contact">
          <template #body="slotProps">
            {{ slotProps.data.financial_contact ? slotProps.data.financial_contact.name : '-' }}
          </template>
        </p-column>
        <p-column>
          <template #body="slotProps">
            <div class="flex justify-content-end">
              <p-button
                icon="mdi mdi-delete-outline"
                text
                rounded
                severity="danger"
                @click="confirmClientDelete($event, slotProps.data)"
              />
            </div>
          </template>
        </p-column>
        <template #empty>
          <p-message p-message severity="info" :closable="false">
            {{ ts.notFoundWarning }}
          </p-message>
        </template>
      </pDataTable>
    </div>

    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-md sidebar-crud"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view v-slot="{ Component }">
        <transition>
          <component :is="Component" @hide="hideDetails" @refresh="refresh" />
        </transition>
      </router-view>
    </pSidebar>
    <p-confirm-popup group="clients" />
  </section>
</template>
