import { FeaturePermission } from 'platform-unit2-api/roles';
import DatamodelEdit from 'admin/modules/datamodels/components/datamodel-edit.vue';
import DatamodelNew from 'admin/modules/datamodels/components/datamodel-new.vue';
import DatamodelDuplicate from 'admin/modules/datamodels/components/datamodel-duplicate.vue';
import Datamodels from 'admin/modules/datamodels/views/datamodels.view.vue';

import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';

const datamodelRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/datamodels',
    name: 'datamodels',
    title: 'Datamodels',
    translations: {
      module: 'datamodels',
      platform: 'supplier',
    },
    component: Datamodels,
    rights: [FeaturePermission.MANAGE_DATAMODELS],
    children: [
      RouteFactory.createEmptyRoute({
        path: 'new',
        name: 'new-datamodel',
        component: DatamodelNew,
        rights: [FeaturePermission.MANAGE_DATAMODELS],
      }),
      RouteFactory.createEmptyRoute({
        path: ':id/:model?',
        name: 'edit-datamodel',
        component: DatamodelEdit,
        rights: [FeaturePermission.MANAGE_DATAMODELS],
      }),
      RouteFactory.createEmptyRoute({
        path: ':id/duplicate',
        name: 'duplicate-datamodel',
        component: DatamodelDuplicate,
        rights: [FeaturePermission.MANAGE_DATAMODELS],
      }),
    ],
  }),
];

export default datamodelRoutes;
