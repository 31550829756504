import NotFound from 'ui/views/http-error/404.view.vue';
import NoPermission from 'ui/views/http-error/403.view.vue';
import downloadsRoutes from 'supplier/modules/downloads/routes/downloads.routes';
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';

import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { User, UserRestService } from 'platform-unit2-api/users';
import navigationGuard from '@/general/guards/navigation.guard';
import { getAdminRoutes } from '@/platforms/admin/routes';
import { getRetailerRoutes } from '@/platforms/retailer/router';
import { getSupplierRoutes } from '@/platforms/supplier/router';

//New layout
import { Client } from 'platform-unit2-api/clients';

/**
 * This service is responsible for the routing of the application.
 * It is a singleton and can be accessed with the static method `getInstanc()` or `instance`.
 * It is also responsible for adding the routes of the modules.
 */

export class RouterService {
  /**
   * Static instance of the RouterService.
   */
  private static _instance: RouterService;

  /**
   * Default routes that are always available.
   */
  private _defaultRoutes: RouteRecordRaw[] = [
    {
      path: '/login',
      name: 'login',
      redirect: '/',
    },
    {
      path: '/403/:errorCode?',
      name: 'noPermission',
      component: NoPermission,
      meta: {
        title: 'Productsup Platform - 403',
        disableAuthentication: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: NotFound,
    },
    ...downloadsRoutes,
  ];

  /**
   * The vue router instance.
   */
  private _router!: Router;

  public get router(): Router {
    return this._router;
  }

  //#region get instance
  private static _getInstance(): RouterService {
    if (RouterService._instance == null) {
      RouterService._instance = new RouterService();
    }

    return RouterService._instance;
  }

  public static getInstance(): RouterService {
    return RouterService._getInstance();
  }

  public static get instance(): RouterService {
    return RouterService._getInstance();
  }

  //#endregion

  private _lastResolvedRoutes?: ClientTypeEnum | 'UNKNOWN';
  private _lastWorkspace?: Client;

  /**
   * User service for getting the current user.
   */
  private _userService = new UserRestService();

  private static _user?: User | null;

  public set currentUser(value: User | undefined | null) {
    RouterService._user = value;
  }

  constructor() {
    if (RouterService._instance != null) {
      throw new Error(
        "Can't instantiate more than one RouterService, use RouterService.getInstance()",
      );
    }

    //Create router
    this._router = createRouter({
      history: createWebHistory(import.meta.env.BASE_URL),
      routes: this._defaultRoutes,
    });

    //Add navigation guard
    this._router.beforeEach(navigationGuard);

    //reset instance if instance is overwritten
    RouterService._user = undefined;
    RouterService._instance = this;
  }

  /**
   * Dynamicly adds routes to the router.
   * @param routes routes to add to router
   * @param defaultRoute the default route of the router
   */
  private _addRoutes(routes: RouteRecordRaw[][], defaultRoute?: string) {
    routes.forEach((routeRecordRaw: RouteRecordRaw[]) => {
      routeRecordRaw.forEach((route) => {
        this._router.addRoute(route);
      });
    });

    this._router.addRoute({
      path: '/',
      redirect: defaultRoute ?? '/dashboard',
      name: 'default',
    } as RouteRecordRaw);
  }

  /**
   * Resets the routes to the default routes.
   */
  private async _resetRoutes() {
    const defaultRoutes = this._defaultRoutes;
    const router = this._router;

    router.getRoutes().forEach(function (route) {
      if (!defaultRoutes.some((defaultRoute) => defaultRoute.name === route.name)) {
        if (route.name) {
          router.removeRoute(route.name);
        }
      }
    });
  }

  /**
   * Same as router push but resolves routes before going to the path.
   * This also happens in the naviagtion guard but the guard is not async
   * so in some cases it can't wait for the routes to be resolved.
   * This function garnautees that the routes are resolved before going to the path.
   * @param path path to go to
   */
  public async go(path: { name: string }): Promise<void> {
    await this.resolveRoutes();
    this._router.push(path);
  }

  private async resetAndAddRoutes(routes: RouteRecordRaw[][], defaultRoute?: string) {
    await this._resetRoutes();
    this._addRoutes(routes, defaultRoute);
  }

  /**
   * Resolves the routes for the current user.
   * If the user is an admin the admin routes are added.
   * If the user is a retailer the retailer routes are added.
   * If the user is a supplier the supplier routes are added.
   */
  public async resolveRoutes(): Promise<void> {
    let user: User | undefined;

    try {
      if (RouterService._user == null) {
        RouterService._user = await this._userService.getCurrentUser(false);
      }

      user = RouterService._user;
    } catch {
      return;
    }

    if (!user || user.workspace == null || user.workspace.workspace_type?.type == null) {
      return;
    }

    let resolvingRoutesFor: ClientTypeEnum | undefined | 'UNKNOWN';

    if (user.admin_mode) {
      resolvingRoutesFor = ClientTypeEnum.ADMIN;
    } else {
      switch (user.workspace.workspace_type?.type) {
        case ClientTypeEnum.RETAILER:
          resolvingRoutesFor = ClientTypeEnum.RETAILER;
          break;
        case ClientTypeEnum.SUPPLIER:
          resolvingRoutesFor = ClientTypeEnum.SUPPLIER;
          break;
        default:
          resolvingRoutesFor = 'UNKNOWN';
      }
    }

    if (
      this._lastResolvedRoutes != resolvingRoutesFor ||
      user.workspace.id != this._lastWorkspace?.id
    ) {
      this._lastWorkspace = user.workspace;

      switch (resolvingRoutesFor) {
        case ClientTypeEnum.ADMIN:
          await this.resetAndAddRoutes(getAdminRoutes(), '/dashboard');
          this._lastResolvedRoutes = ClientTypeEnum.ADMIN;

          return;
        case ClientTypeEnum.RETAILER:
          await this.resetAndAddRoutes(await getRetailerRoutes(), '/dashboard');
          this._lastResolvedRoutes = ClientTypeEnum.RETAILER;

          return;
        case ClientTypeEnum.SUPPLIER:
          await this.resetAndAddRoutes(await getSupplierRoutes(), '/dashboard');
          this._lastResolvedRoutes = ClientTypeEnum.SUPPLIER;

          return;
        default:
          await this.resetAndAddRoutes(await getSupplierRoutes(), '/dashboard');
          this._lastResolvedRoutes = 'UNKNOWN';

          return;
      }
    }
  }
}
