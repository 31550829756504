<script setup lang="ts">
import { NotificationPreferencesService } from '@/general/services/preferences/notification-preferences.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { computed, onMounted, ref } from 'vue';

export interface NotificationPreferencesExtended {
  key: 'send_mail_action_created' | 'send_mail_action_commented' | 'send_mail_action_updated';
  title: string;
  description: string;
  value?: boolean;
}

const ts = new TranslationService('retailer', 'settings');
const preferencesService = ref(new NotificationPreferencesService(ts));

const toggleAll = computed<boolean>(() =>
  preferencesService.value.notificationPreferences.every((obj) => obj.value),
);

const toggleAllPreferences = (value: boolean) => {
  preferencesService.value.notificationPreferences.forEach((obj) => {
    obj.value = value;
  });
  preferencesService.value.updateNotificationPreferences();
};

onMounted(() => preferencesService.value.loadNotificationPreferences());
</script>
<template>
  <section class="h-full px-4">
    <section class="pup-mr-10">
      <div class="align-items-center flex gap-3 justify-content-between pup-mb-5">
        <div>
          <h4 class="font-bold mb-2">{{ ts.tModule('notifications.email.title') }}</h4>
          <p>{{ ts.tModule('notifications.email.subtitle') }}</p>
        </div>
        <pInputSwitch v-model="toggleAll" @update:model-value="toggleAllPreferences" />
      </div>
      <p-divider />

      <div
        v-for="option in preferencesService.notificationPreferences"
        :key="option.key + option.value"
        class="align-items-center flex justify-content-between pup-mb-5"
      >
        <div>
          <h5 class="my-1">{{ option.title }}</h5>
          <p class="text-gray-500">{{ option.description }}</p>
        </div>
        <pInputSwitch
          v-model="option.value"
          @update:model-value="preferencesService.updateNotificationPreferences()"
        />
      </div>
    </section>
  </section>
</template>
