<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import usePagination from 'composables/usePagination/pagination';
import Upload from '@/general/ui/components/upload.vue';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';

import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { PageState } from 'primevue/paginator';

import useDebounce from 'utils/debounce';
import { RetailersRestService, Retailer } from 'platform-unit2-api/retailers';
import { PaginationObject } from 'platform-unit2-api/core';

/** Composables */
const router = useRouter();
const route = useRoute();
const { debounce } = useDebounce();

/** Services */
const ts = new TranslationService('admin', 'retailers');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const retailerApi = new RetailersRestService();

/** constants */
const retailersLength = ref<number>(3);

/** Load retailers data */
const retailers = ref<Retailer[]>([]);
const total = ref<number>();
const loading = ref(false);
let searchCallback: any = undefined;

const loadAsyncData = async (searchQuery?: string): Promise<void> => {
  loading.value = true;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    const response = await retailerApi.getAll({
      query: query.value,
      page: page.value,
      limit: perPage.value,
    } as PaginationObject);

    retailers.value = response.data;
    total.value = response.meta?.total ?? 0;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData(query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

/** Pagination */
const { page, perPage, onPageChange: $onPageChange, query } = usePagination();

const onPageChange = (event: PageState) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

watch(
  () => page.value,
  () => loadAsyncData(),
);

/** Show retailer details */
const showDetails = (id: number): void => {
  router.push({
    name: 'edit-retailer',
    params: { id: id.toString() } as RouteParamsRaw,
  } as RouteLocationRaw);
};

/** Delete Retailer */
const deleteRetailer = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await retailerApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    loading.value = false;
  }
};

const confirmRetailerDelete = (event: PointerEvent, retailer: Retailer): void => {
  confirmService.confirmDelete({
    event: event,
    group: 'retailers',
    message: ts.deleteConfirm(retailer.name),
    callback: () => deleteRetailer(retailer.id),
  });
};

/** Check sidebar visibility */
const showSidebar = ref(false);

const checkSidebarVisibility = () => {
  if (route.name === 'new-retailer' || route.name === 'edit-retailer') {
    showSidebar.value = true;
  }

  if (route.name === 'retailers') {
    showSidebar.value = false;
  }
};

onMounted(async () => {
  checkSidebarVisibility();
  await loadAsyncData();
});

watch(
  () => route,
  () => {
    checkSidebarVisibility();
  },
  {
    deep: true,
  },
);

const hideDetails = (): void => {
  router.push({
    name: 'retailers',
  } as RouteLocationRaw);
};
</script>
<template>
  <section class="h-full pt-3 px-4">
    <pIconField icon-position="left" class="my-3">
      <pInputIcon class="pi pi-search" />
      <pInputText
        :placeholder="ts.tGlobal('search')"
        @update:model-value="(value: string) => search(value)"
      />
    </pIconField>

    <!-- #region : If it's loading the data  -->
    <CardSkeleton v-if="loading" />
    <!-- #endregion -->

    <!-- #region : If there is no data -->
    <div v-else-if="!total && !loading">
      <p-message severity="info" :closable="false">
        {{ ts.notFoundWarning }}
      </p-message>
    </div>
    <!-- #endregion -->

    <!-- #region : Show retailers  -->
    <div v-else>
      <div class="grid">
        <div v-for="retailer in retailers" :key="retailer.id" class="col-12 lg:col-3 md:col-6">
          <p-card class="h-full">
            <template #header>
              <div
                class="align-items-center cursor-pointer flex justify-content-center py-4"
                @click="showDetails(retailer.id)"
              >
                <upload class="h-5rem w-5rem" :upload="retailer.thumbnail" />
              </div>
            </template>
            <template #content>
              <div class="cursor-pointer" @click="showDetails(retailer.id)">
                <div class="align-items-center flex flex-row justify-content-between mb-3">
                  <h2 class="font-bold mr-3 text-lg">
                    {{ retailer.name }}
                  </h2>
                </div>
                <p>{{ retailer.email }}</p>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-content-between">
                <p-avatar-group v-if="retailer.clients">
                  <div v-if="retailer.clients.length <= retailersLength">
                    <p-avatar
                      v-for="client in retailer.clients"
                      :key="client.id"
                      v-tooltip.bottom="{ value: client.name, class: 'text-sm' }"
                      :label="client.name.charAt(0)"
                      shape="circle"
                    />
                  </div>
                  <div v-else>
                    <p-avatar
                      v-for="client in retailer.clients.slice(0, retailersLength)"
                      :key="client.id"
                      v-tooltip.bottom="{ value: client.name, class: 'text-sm' }"
                      :label="client.name.charAt(0)"
                      shape="circle"
                    />
                    <p-avatar
                      :label="`+${retailer.clients.length - retailersLength}`"
                      shape="circle"
                      style="
                        background-color: var(--primary-color);
                        color: var(--primary-color-text);
                      "
                    />
                  </div>
                </p-avatar-group>
                <div class="flex justify-content-end">
                  <p-button
                    icon="mdi mdi-delete-outline"
                    text
                    severity="danger"
                    rounded
                    @click="confirmRetailerDelete($event, retailer)"
                  />
                </div>
              </div>
            </template>
          </p-card>
        </div>
      </div>
    </div>
    <!-- #endregion  -->

    <!-- #region : Paginator -->
    <p-paginator
      v-if="(total ?? 0) > perPage"
      :first="0"
      :rows="(perPage - 1) * perPage"
      :total-records="total"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      @page="(event: PageState)=>onPageChange(event)"
    />
    <!-- #endregion -->

    <!-- #region : Add/Edit Sidebar -->
    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-md sidebar-crud"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="loadAsyncData" />
    </pSidebar>
    <!-- #endregion -->
    <p-confirm-popup group="retailers" />
  </section>
</template>
