import { DisplayToastSetting } from '@/general/composables/UseDispatch/types';
import { useFetch } from '@/general/composables/useFetch';
import { ref, unref } from 'vue';
import { useRouter } from 'vue-router';
import { useDelete } from '@/general/composables/UseDelete';
import { FetchOptions } from '@/general/composables/useFetch/types';
import { useUpdate } from '@/general/composables/UseUpdate';

import { useCreate } from '@/general/composables/useCreate';
import { useShow } from '@/general/composables/useShow';
import { UpdateReject } from '@/general/composables/UseUpdate/types';
import { CreateReject } from '@/general/composables/useCreate/types';
import { TranslationService } from '@/general/services/translations/translation.service';
import {
  CreateOrganisation,
  Organisation,
  UpdateOrganisationRequest,
} from 'platform-unit2-api/organisations';
import { ProductStatus } from 'platform-unit2-api/product-statuses';
import { User } from 'platform-unit2-api/users';
import { ListResponse } from 'platform-unit2-api/core';
import { Defaults } from '@/general/utils/constants/defaults';

/**
 * useOrganisationService helps the user to call the store functionalities easily.
 * @returns loading, meta, fetch(), sort(), search(), remove(), changePage(), perPage
 */

export function useOrganisationService() {
  const ts = new TranslationService('admin', 'organisations');
  const router = useRouter();

  /** Define default options to send through useFetch */
  const options = ref<FetchOptions<Organisation>>({
    parameters: { query: '', limit: Defaults.REQUEST_LIMIT },
    autoLoad: false,
    toast: {
      displaySetting: DisplayToastSetting.NONE,
    },
  });

  /** Define default options to send through useFetch for the Product Statuses */
  const optionsFetchStatuses = ref<FetchOptions<ProductStatus>>({
    parameters: { query: '' },
    autoLoad: false,
    toast: {
      displaySetting: DisplayToastSetting.NONE,
    },
  });

  /** useFetch Organisations */
  const { loading, fetchData, data, total, page, perPage } = useFetch<Organisation>({
    action: 'organisations/GET_ORGANISATIONS',
    options,
  });

  /** useFetch ProductStatuses */
  const { fetchData: fetchProductStatuses, data: productStatuses } = useFetch<ProductStatus>({
    action: 'organisations/GET_PRODUCT_STATUSES',
    options: optionsFetchStatuses,
  });

  /** useUpdate */
  const { update } = useUpdate();

  /** useCreate */
  const { create, loading: savingCreate } = useCreate();

  /** useDelete */
  const { deleteAction } = useDelete();

  /** useShow */
  const {
    show: getOrganisationDetails,
    data: organisationDetails,
    loading: loadingOrganisationDetails,
  } = useShow<Organisation>({
    action: 'organisations/GET_ORGANISATION_DETAILS',
    options: {},
  });

  const {
    show: getOrganisationUsers,
    data: organisationUsers,
    loading: loadingOrganisationUsers,
  } = useShow<ListResponse<User>>({
    action: 'organisations/GET_ORGANISATION_USERS',
    options: {},
  });

  /** Show organisation */
  const showOrganisationDetails = (id: number) => {
    getOrganisationDetails(id);
    return organisationDetails;
  };

  const showOrganisationUsers = (id: number) => {
    getOrganisationUsers(id);
    return organisationUsers;
  };

  /** Fetch organisations */
  const fetch = (custom_options?: FetchOptions<Organisation>) => {
    options.value.parameters = { ...options.value.parameters, ...custom_options?.parameters };

    fetchData();
    return data;
  };

  const fetchStatuses = () => {
    fetchProductStatuses();
    return productStatuses;
  };

  /** Sort organisations */
  const sort = (sortBy: string) => {
    options.value.parameters = { ...options.value.parameters, sortBy };
    options.value.toast = { ...options.value.toast, displaySetting: DisplayToastSetting.NONE };
    fetchData();
  };

  /** Search in organisations */
  const search = (query: string) => {
    page.value = 1;
    options.value.parameters = { ...options.value.parameters, page: 1, query };
    options.value.toast = { ...options.value.toast, displaySetting: DisplayToastSetting.NONE };

    fetchData();
  };

  /** Pagination */
  const changePage = async (page: number) => {
    await router.push({ query: { page: page.toString(), limit: unref(perPage)?.toString() } });
    options.value.pagination = true;
    options.value.toast = { ...options.value.toast, displaySetting: DisplayToastSetting.NONE };

    fetchData();
  };

  /**Update organisation */
  const updateOrganisation = (
    id: number,
    organisation: UpdateOrganisationRequest,
    parseFormError: { (err: UpdateReject): void },
  ) => {
    return update({
      action: 'organisations/UPDATE_ORGANISATION',
      options: {
        parameters: {
          id: id,
          updatedOrganisation: organisation,
        },
        toast: {
          displaySetting: DisplayToastSetting.BOTH,
          successMessage: ts.updateSuccess(),
          rejectMessage: ts.updateFailed(),
        },
        rejectCallback: (err) => {
          parseFormError(err);
        },
      },
    });
  };

  /**Update billing users */
  const updateBillingUsers = (id: number, user_ids: number[] | undefined) => {
    update({
      action: 'organisations/UPDATE_BILLING_USERS',
      options: {
        parameters: {
          id: id,
          user_ids: { user_ids: user_ids },
        },
        toast: {
          displaySetting: DisplayToastSetting.NONE,
        },
      },
    });
  };

  const createOrganisation = async (
    data: CreateOrganisation,
    parseFormError: { (err: CreateReject): void },
  ) => {
    return create({
      action: 'organisations/CREATE_ORGANISATION',
      options: {
        parameters: data,
        toast: {
          displaySetting: DisplayToastSetting.BOTH,
          successMessage: ts.createSuccess(),
          rejectMessage: ts.createFailed(),
        },
        rejectCallback: (err) => {
          parseFormError(err);
        },
      },
    });
  };

  /** Delete organisation */
  const remove = (id: number) => {
    deleteAction({
      action: 'organisations/DELETE_ORGANISATION',
      options: {
        id: id,
        success: () => {
          options.value.toast = {
            ...options.value.toast,
            displaySetting: DisplayToastSetting.NONE,
          };
          fetchData();
        },
      },
    });
  };

  /** return functions */
  return {
    fetch,
    fetchStatuses,
    sort,
    search,
    remove,
    changePage,
    updateOrganisation,
    updateBillingUsers,
    createOrganisation,
    showOrganisationDetails,
    showOrganisationUsers,
    loadingOrganisationDetails,
    loadingOrganisationUsers,
    organisationDetails,
    perPage,
    loading,
    savingCreate,
    total,
  };
}
