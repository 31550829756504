<script setup lang="ts">
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import { PaginationObject } from 'platform-unit2-api/core';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { DatamodelsRestService, DatamodelCompact } from 'platform-unit2-api/datamodels';
import { ModuleDetail } from 'platform-unit2-api/modules';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const datamodelApi = new DatamodelsRestService();

/** Consts */
const store = useStore();
const copyMasterData = ref(true);
const onlyPrimaryUpload = ref(false);
const skipDuplicate = ref(false);

const selectedModule = ref<ModuleDetail>();
const selectedDatamodel = ref<DatamodelCompact>();
const datamodelSelect = ref();

const variantName = ref('');

const saveData = async () => {
  buttonsOptions.value.saving = true;
  try {
    await store.dispatch('products/MASS_CREATE_PRODUCT_VARIANT', {
      data: {
        product_ids: props.ids,
        variant_name: variantName.value,
        module_id: selectedModule.value ? selectedModule.value.id : null,
        copy_master_data: copyMasterData.value,
        only_primary_upload: onlyPrimaryUpload.value,
        skip_duplicate: skipDuplicate.value,
        datamodel_id: selectedDatamodel.value?.id ?? null,
      },
    });

    // Update variants filter to include these newly created variant names
    store.dispatch('products/SEARCH_VARIANTS', {} as PaginationObject);

    toastService.displaySuccessToast(ts.createSuccess('create_variant.create_success'));
    emit('close');
  } catch (ex) {
    toastService.displayErrorToast(ts.tModule('create_variant.create_failed'));
  } finally {
    buttonsOptions.value.saving = false;
    emit('hide');
  }
};

const getModuleDatamodel = async (): Promise<void> => {
  if (selectedModule.value?.template?.id == null) return;

  try {
    const response = await datamodelApi.getAll(
      { limit: 100 },
      { template_id: selectedModule.value?.template?.id },
    );

    if (datamodelSelect.value) {
      datamodelSelect.value.setDatamodel(response.data);
    }
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const addButton = ref<CreateButton>(
  new CreateButton({
    label: ts.tGlobal('add'),
    position: CrudButtonPosition.RIGHT,
    isEnabled: () => variantName.value.length >= 2,
    onClick: saveData,
  }),
);

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, addButton.value],
});

watch(
  () => selectedModule.value,
  () => {
    if (selectedModule.value) {
      variantName.value = selectedModule.value.name ?? '';
    } else {
      variantName.value = '';
    }
  },
);
</script>

<template>
  <BaseDialog
    :title="ts.tModule('create_variant.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="$emit('hide')"
  >
    <div class="field mb-3">
      {{
        ts.tModule('product_details.create_variant.creating_notification', {
          params: { amount: ids.length },
        })
      }}
    </div>

    <div class="field mb-3">
      <label for="variant_name">{{
        ts.tModule('product_details.create_variant.name.label')
      }}</label>
      <p-input-text v-model="variantName" class="w-full" type="text" />
    </div>

    <div class="field mb-3">
      <ClientModuleSelect v-model="selectedModule" type="export" @change="getModuleDatamodel" />
    </div>

    <div class="mb-3">
      <p-checkbox v-model="copyMasterData" :binary="true" class="mr-3" />
      <label>
        {{ ts.tModule('product_details.create_variant.copy_master_data') }}
      </label>
    </div>

    <div v-if="copyMasterData" class="border-2 border-50 border-solid m-3 p-3">
      <div class="field-radiobutton">
        <p-radio-button v-model="onlyPrimaryUpload" name="name" :value="false" />
        <label>{{ ts.tModule('product_details.create_variant.all_uploads') }}</label>
      </div>

      <div class="field-radiobutton">
        <p-radio-button v-model="onlyPrimaryUpload" name="name" :value="true" />
        <label>{{ ts.tModule('product_details.create_variant.only_primary_upload') }}</label>
      </div>
    </div>

    <div class="mb-3">
      <p-checkbox v-model="skipDuplicate" :binary="true" class="mr-3" />
      <label>
        {{ ts.tModule('product_details.create_variant.skip_duplicate') }}
      </label>
    </div>
  </BaseDialog>
</template>
