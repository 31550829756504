<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { AttributeOptions, KeyValueOptions } from 'platform-unit2-api/attributes';

/** Props */

const props = defineProps<{
  modelValue: KeyValueOptions[];
  disabled: boolean;
  options: AttributeOptions;
  moduleId: number;
}>();

/** Emits */

const emit = defineEmits<{
  (e: 'update:modelValue', value: KeyValueOptions[]): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Constants */
const choices = ref<(KeyValueOptions | string)[]>();
const selectedOption = ref<KeyValueOptions>();
const selectedOptions = ref<KeyValueOptions[]>([]);

const isClear = computed(() => {
  return !!selectedOption.value;
});

const valueUpdated = (): void => {
  if (props.options?.multiSelect) {
    emit('update:modelValue', selectedOptions.value);
    return;
  }

  if (selectedOption.value) {
    emit('update:modelValue', [selectedOption.value]);
    return;
  }

  emit('update:modelValue', []);
};

// if it is multi-select add all selected options to the selectedOptions array
const loadMultiSelect = () => {
  selectedOptions.value = props.modelValue;
};

const loadSingleSelect = () => {
  //if single select set the selectedOption var to the first element
  selectedOption.value = props.modelValue[0];

  /*
    if more than one element is selected and the user has overwritten
    the field from multi to single select
    trigger update.
  */
  if (props.modelValue.length > 1) {
    valueUpdated();
  }
};

const loadValues = (): void => {
  choices.value = props.options.choices;

  if (props.modelValue == null) {
    return;
  }

  if (props.options?.multiSelect) {
    loadMultiSelect();
  } else {
    loadSingleSelect();
  }
};

/** Lifecycles */

onMounted(() => {
  loadValues();
});
</script>

<template>
  <p-dropdown
    v-if="!options?.multiSelect"
    v-model="selectedOption"
    :options="choices"
    :filter="true"
    :disabled="disabled"
    placeholder="Select type"
    :show-clear="isClear"
    class="w-full"
    option-label="key"
    @change="valueUpdated"
  >
    <template #empty>
      {{ ts.tModule('keyValueField.noResultsFound') }}
    </template>
  </p-dropdown>
  <div v-else class="relative w-full">
    <p-multiselect
      v-model="selectedOptions"
      :options="choices"
      option-label="key"
      placeholder="Select types"
      display="chip"
      class="w-full"
      :disabled="disabled"
      :show-toggle-all="!!choices"
      @change="valueUpdated"
    />
  </div>
</template>
