<script setup lang="ts">
import { ValidationRule } from 'platform-unit2-api/validation-rules';
import { ref, inject } from 'vue';
import { productAttributeValidationServiceKey } from '@/general/services/attribute-fields/service-keys';

interface Props {
  attributeName: string;
  severity: string;
  validationRules: ValidationRule[];
}

const productAttributesValidationService = inject(productAttributeValidationServiceKey)!;

const props = defineProps<Props>();
const emit = defineEmits<{ 'update:page': [value: number] }>();
const currentPage = ref(0);

function next() {
  if (currentPage.value < (props.validationRules?.length ?? 0)) {
    currentPage.value++;
  } else {
    currentPage.value = 1;
  }

  emit('update:page', currentPage.value);
}

function prev() {
  if (currentPage.value < 2) {
    currentPage.value--;
  } else {
    currentPage.value = 1;
  }

  emit('update:page', currentPage.value);
}
</script>
<template>
  <div :class="`align-items-center ${severity.toLocaleLowerCase()}-item-targets flex pup-m-3`">
    <div class="pup-mr-3 pup-my-3">
      <pButton
        icon="mdi mdi-chevron-left"
        class="border-gray-300 border-noround-right border-right-none text-gray-700"
        severity="secondary"
        :disabled="validationRules?.[currentPage - 1] == null"
        @click="prev()"
      />
      <pButton
        icon="mdi mdi-chevron-right"
        class="border-gray-300 border-noround-left text-gray-700"
        severity="secondary"
        :disabled="validationRules?.[currentPage + 1] == null"
        @click="next()"
      />
    </div>
    <div class="attrs font-bold">
      {{
        productAttributesValidationService.getAttributeValidationRulesCountString(
          currentPage,
          severity,
          attributeName,
        )
      }}
      {{ attributeName }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.error-item.active {
  background-color: #f9c2ca !important;
  border: 1px solid #f04359 !important;
}

.warning-item.active {
  background-color: #f9d5b4 !important;
  border: 1px solid #f08b43 !important;
}
</style>
