<script setup lang="ts">
//Core
import { onMounted, ref, watch } from 'vue';

//Types
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ModuleDetail } from 'platform-unit2-api/modules';

//Components
import MapAttribute from 'retailer/modules/mappings/components/map-attribute.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';

//Composables and Services
import useAttributeOption from 'utils/attribute-option';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ClientCompact } from 'platform-unit2-api/clients';
import { DataTableRowReorderEvent } from 'primevue/datatable';
import {
  MappingItemRestService,
  MappingSourceType,
  CreateMappingItemRequest,
  MappingItem,
  UpdateMappingItemRequest,
} from 'platform-unit2-api/mapping-items';
import { MappingsRestService } from 'platform-unit2-api/mappings';
import { ToastService } from '@/general/services/toasts/toast.service';

// Props
const props = defineProps<{
  mappingItems: MappingItem[];
  selectedDatamodel?: Datamodel;
  selectedModule?: ModuleDetail;
  viewOnly: boolean;
  owner: ClientCompact;
  attributes: Attribute[];
  loadingMappingItems: boolean;
}>();

// Emits
const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'export_mappings');
const tsAttributes = new TranslationService('retailer', 'attributes');
const mappingItemsApi = new MappingItemRestService();
const mappingsApi = new MappingsRestService();
const toastService = ToastService.getInstance();

// Constants
const { getFieldDefinitionOption } = useAttributeOption();
const defaultMappingSource = {
  value: MappingSourceType.VALUE,
  label: ts.tModule('field_sources.take_value'),
};

const sourcesPerFieldType: Record<
  AttributeTypeEnum,
  Array<{ value: MappingSourceType; label: string }>
> = {
  DEFAULT: [defaultMappingSource],
  LIST_FIELD: [
    {
      value: MappingSourceType.LIST,
      label: ts.tModule('field_sources.take_list'),
    },
    {
      value: MappingSourceType.LIST_ITEM,
      label: ts.tModule('field_sources.take_list_item'),
    },
  ],
  GTIN_FIELD: [defaultMappingSource],
  TEXT_AREA_FIELD: [defaultMappingSource],
  KOAG_FIELD: [defaultMappingSource],
  LIST_GROUP: [defaultMappingSource],
  TAB_FIELD: [defaultMappingSource],
  GROUP_FIELD: [defaultMappingSource],
  RICH_TEXT_FIELD: [
    {
      value: MappingSourceType.VALUE,
      label: ts.tModule('field_sources.take_html'),
    },
    {
      value: MappingSourceType.TEXT,
      label: ts.tModule('field_sources.take_text'),
    },
  ],
  SWITCH_FIELD: [defaultMappingSource],
  NUMBER_FIELD: [defaultMappingSource],
  DATE_TIME_FIELD: [defaultMappingSource],
  BRAND_FIELD: [defaultMappingSource],
  CATEGORY_FIELD: [defaultMappingSource],
  TAG_FIELD: [defaultMappingSource],
  CHOICE_FIELD: [defaultMappingSource],
  MULTIPLE_CHOICE_FIELD: [defaultMappingSource],
  FINANCIAL_FIELD: [
    defaultMappingSource,
    {
      value: MappingSourceType.DECIMAL,
      label: ts.tModule('field_sources.take_number'),
    },
    {
      value: MappingSourceType.UNIT,
      label: ts.tModule('field_sources.take_unit'),
    },
  ],
  KEY_VALUE_FIELD: [
    defaultMappingSource,
    {
      value: MappingSourceType.KEY,
      label: ts.tModule('field_sources.take_key'),
    },
  ],
  INPUT_SELECT_FIELD: [
    defaultMappingSource,
    {
      value: MappingSourceType.DECIMAL,
      label: ts.tModule('field_sources.take_number'),
    },
    {
      value: MappingSourceType.UNIT,
      label: ts.tModule('field_sources.take_unit'),
    },
  ],
};

const saving = ref<boolean>(false);
const loading = ref<boolean>(false);
const loadingDeleteId = ref<Number>();
const loadingAddMapping = ref<boolean>(false);

const editableMappingItems = ref<MappingItem[]>([]);

const newMappingItem = ref<Partial<Omit<MappingItem, 'id'>>>({
  source: MappingSourceType.VALUE,
});

const getSources = (attribute?: Attribute) => {
  const type = attribute?.options
    ? getFieldDefinitionOption(attribute?.options, 'type', undefined) || AttributeTypeEnum.DEFAULT
    : AttributeTypeEnum.DEFAULT;

  return sourcesPerFieldType[type as AttributeTypeEnum];
};

const createMapping = async (): Promise<void> => {
  if (
    !newMappingItem.value.attribute ||
    !newMappingItem.value.source ||
    !newMappingItem.value.argument
  ) {
    return;
  }

  try {
    loadingAddMapping.value = true;

    if (props.selectedModule?.id && props.selectedDatamodel?.id) {
      const mappingItem: CreateMappingItemRequest = {
        module_id: props.selectedModule?.id,
        datamodel_id: props.selectedDatamodel?.id,
        argument: newMappingItem.value?.argument,
        attribute_id: newMappingItem.value.attribute!.id,
        source: newMappingItem.value.source,
        source_index: newMappingItem.value.source_index! - 1,
        template_endpoint_id: null,
        owner_id: null,
      };
      await mappingItemsApi.post(mappingItem);
    }

    toastService.displaySuccessToast(ts.createSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    newMappingItem.value = {
      source: MappingSourceType.VALUE,
    };
    emit('refresh');
    loadingAddMapping.value = false;
  }
};

const onRowReorder = async (event: DataTableRowReorderEvent): Promise<void> => {
  loading.value = true;

  editableMappingItems.value = event.value;

  const data = {
    order: event.dropIndex,
  };

  await mappingsApi.orderMappingItem(
    props.mappingItems[0]?.mapping_id,
    editableMappingItems.value[event.dropIndex].id,
    data,
  );

  loading.value = false;
};

const createEditableMappingItems = (mappingItems: MappingItem[]) => {
  return mappingItems
    .filter((mappingItem: MappingItem) => mappingItem !== null)
    .filter((mappingItem: MappingItem) => mappingItem.template_endpoint_id === null)
    .map((mappingItem: MappingItem) => {
      return {
        id: mappingItem.id,
        mapping_id: mappingItem.mapping_id,
        argument: mappingItem.argument,
        attribute: mappingItem.attribute,
        order: mappingItem.order,
        source: mappingItem.source,
        source_index: mappingItem.source_index! + 1,
        owner_id: mappingItem.owner_id,
      };
    });
};

const save = async (): Promise<void> => {
  try {
    saving.value = true;

    const mappingItemsToUpdate: UpdateMappingItemRequest[] = editableMappingItems.value.map(
      (mappingItem: MappingItem) => {
        return {
          id: mappingItem.id,
          mapping_id: mappingItem.mapping_id,
          argument: mappingItem.argument,
          attribute_id: mappingItem.attribute?.id,
          source: mappingItem.source,
          source_index: mappingItem.source_index! - 1,
          owner_id: null,
        } as UpdateMappingItemRequest;
      },
    );

    const promises = mappingItemsToUpdate.map((element) => {
      return mappingItemsApi.update(element.id, element);
    });

    await Promise.all(promises);
    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    emit('refresh');
    saving.value = false;
  }
};

const remove = async (mappingItemId: number): Promise<void> => {
  loadingDeleteId.value = mappingItemId;

  try {
    await mappingItemsApi.delete(mappingItemId);
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    emit('refresh');
    loadingDeleteId.value = undefined;
  }
};

const getArray = (attribute?: Attribute): Array<number> => {
  if (!attribute) return [];
  return Array.from(Array(parseInt(attribute.options?.maxLength ?? '0')).keys(), (n) => n + 1);
};

const copyToTargetField = (): void => {
  if (!newMappingItem.value.attribute) {
    return;
  } else {
    newMappingItem.value.argument = newMappingItem.value.attribute.key;
  }
};

onMounted(() => {
  editableMappingItems.value = createEditableMappingItems(props.mappingItems);
});

watch(
  () => props.mappingItems,
  () => {
    editableMappingItems.value = createEditableMappingItems(props.mappingItems);
  },
  {
    deep: true,
  },
);
</script>

<template>
  <div>
    <LoadingIndicator v-if="loadingMappingItems" />
    <pDataTable
      v-else
      :value="editableMappingItems"
      edit-mode="row"
      data-key="id"
      :loading="loading || saving || loadingDeleteId != null"
      responsive-layout="scroll"
      @row-reorder="(event: DataTableRowReorderEvent) => onRowReorder(event)"
    >
      <p-column v-if="!viewOnly" :row-reorder="true" header-style="width: 1rem" />
      <p-column
        field="attribute"
        :header="tsAttributes.title"
        style="flex-grow: 1; flex-basis: 200px; max-width: 200px"
      >
        <template #body="slotProps">
          <MapAttribute
            v-if="!loading"
            v-model="slotProps.data.attribute"
            :values="attributes"
            :disabled="viewOnly"
          />
        </template>
        <template v-if="!viewOnly" #footer>
          <div class="p-inputgroup">
            <MapAttribute
              v-if="!loading"
              v-model="newMappingItem.attribute"
              :values="attributes"
              :show-clear="true"
            />
            <span
              v-tooltip.bottom="{
                value: ts.tModule('export_mapping_unsupported.copy_to_target'),
                class: 'text-sm',
              }"
              class="cursor-pointer p-inputgroup-addon"
              @click="copyToTargetField"
            >
              <i class="mdi mdi-swap-horizontal"></i>
            </span>
          </div>
        </template>
      </p-column>

      <p-column
        field="source"
        :header="ts.tModule('export_mapping_unsupported.source')"
        style="flex-grow: 1; flex-basis: 200px; max-width: 200px"
      >
        <template #body="slotProps">
          <div class="p-inputgroup">
            <pDropdown
              v-model="slotProps.data.source"
              option-value="value"
              option-label="label"
              data-key="value"
              :options="getSources(slotProps.data.attribute)"
              placeholder="Select type"
              :disabled="viewOnly"
            />

            <pDropdown
              v-if="slotProps.data.source === 'list_item'"
              v-model="slotProps.data.source_index"
              :options="getArray(slotProps.data.attribute)"
            />
          </div>
        </template>
        <template v-if="!viewOnly" #footer>
          <div class="p-inputgroup">
            <pDropdown
              v-model="newMappingItem.source"
              option-value="value"
              option-label="label"
              data-key="value"
              :options="getSources(newMappingItem.attribute ?? undefined)"
              placeholder="Select type"
            />
            <pDropdown
              v-if="newMappingItem.source === 'list_item'"
              v-model="newMappingItem.source_index"
              :options="getArray(newMappingItem.attribute)"
              placeholder="Select an index"
            />
          </div>
        </template>
      </p-column>

      <p-column
        field="argument"
        :header="ts.tModule('export_mapping_unsupported.target')"
        style="flex-grow: 1; flex-basis: 200px; max-width: 200px"
      >
        <template #body="slotProps">
          <p-input-text v-model="slotProps.data.argument" :disabled="viewOnly" class="w-full" />
        </template>
        <template v-if="!viewOnly" #footer>
          <p-input-text v-model="newMappingItem.argument" :disabled="viewOnly" class="w-full" />
        </template>
      </p-column>

      <p-column v-if="!viewOnly" header-style="width: 2rem">
        <template #body="slotProps">
          <p-button
            text
            rounded
            icon="mdi mdi-delete-outline"
            severity="danger"
            @click="remove(slotProps.data.id)"
          />
        </template>
        <template #footer>
          <p-button
            text
            rounded
            icon="mdi mdi-plus"
            :disabled="loadingAddMapping"
            @click="createMapping"
          />
        </template>
      </p-column>

      <template v-if="!viewOnly" #empty>
        <p-message severity="info" :closable="false"
          >{{ ts.tModule('export_mapping_unsupported.add_records') }}
        </p-message>
      </template>
    </pDataTable>

    <div v-if="!viewOnly" class="bg-white border-100 border-top-1 float-right mt-5 py-3 text-right">
      <p-button
        :disabled="saving"
        :label="ts.tGlobal('save')"
        :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
        icon-pos="right"
        @click="save"
      />
    </div>
  </div>
</template>
