<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import useAttributeOption from 'utils/attribute-option';
import { useSort } from '@/general/composables/useSort';
import { TranslationService } from '@/general/services/translations/translation.service';
import { AttributeOptions, AddAttributesToChoiceFieldRequest } from 'platform-unit2-api/attributes';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  moduleId?: number;
  options: AttributeOptions;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  moduleId: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const { getFieldDefinitionOption } = useAttributeOption();
const selectedOption = ref<AddAttributesToChoiceFieldRequest | undefined>();
const { sortArray } = useSort();

const choices = computed(() => {
  const items = getFieldDefinitionOption(props.options, 'choices', props.moduleId) || [];
  return items.map((item: any) => {
    return {
      name: item,
    };
  });
});
sortArray(choices.value, 'name');
const showClear = computed(() => {
  return selectedOption.value?.name == null ? false : true;
});

const valueUpdated = () => {
  selectedOption.value == null
    ? emit('update:modelValue', '')
    : emit('update:modelValue', selectedOption.value.name);
};

watch(
  () => props.modelValue,
  () => {
    selectedOption.value = { name: props.modelValue };
  },
);

onMounted(() => {
  selectedOption.value = { name: props.modelValue };
});
</script>

<template>
  <p-dropdown
    v-model="selectedOption"
    :options="choices"
    :filter="true"
    :disabled="disabled"
    :placeholder="ts.tModule('choiceField.placeholder')"
    :show-clear="showClear"
    option-label="name"
    @change="valueUpdated"
  >
    <template #empty> {{ ts.notFoundWarning }}</template>
  </p-dropdown>
</template>
