import { RouteFactory } from '@/core/router/route.factory';
import { RouteRecordRaw } from 'vue-router';
import ValidationModels from 'admin/modules/validation-rules/views/validation-models.view.vue';

const validationRulesRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/validation-rules',
    name: 'validations',
    component: ValidationModels,
    translations: {
      module: 'validationRules',
      platform: 'admin',
    },
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'newValidationModel',
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'updateValidationModel',
      }),
    ],
  }),
  RouteFactory.createAppRoute({
    path: '/validation-rules/:modelId/collections',
    name: 'validationCollections',
    component: () => import('admin/modules/validation-rules/views/validation-collections.view.vue'),
    header: () =>
      import('admin/modules/validation-rules/components/collections/validation-set-header.vue'),
    translations: {
      module: 'validationRules',
      platform: 'admin',
    },
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'newValidationSet',
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'updateValidationSet',
      }),
    ],
  }),
  RouteFactory.createAppRoute({
    path: '/validation-rules/:modelId/collection/:collectionId/rules',
    name: 'validationRules',
    component: () => import('admin/modules/validation-rules/views/validation-rules.view.vue'),
    header: () =>
      import('admin/modules/validation-rules/components/rules/validation-rules-header.vue'),
    translations: {
      module: 'validationRules',
      platform: 'admin',
    },
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'newValidationRule',
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'updateValidationRule',
      }),
    ],
  }),
];

export default validationRulesRoutes;
