import i18n from '@/core/i18n';
import { ImportMappingViewService } from '@/general/services/overview-service/modules/import-mapping-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Composer } from 'vue-i18n';

export class RetailerImportMappingViewService extends ImportMappingViewService {
  constructor() {
    super(
      new TranslationService(
        'retailer',
        'importMappings',
        process.env.NODE_ENV !== 'production',
        i18n.global as unknown as Composer,
      ),
    );
  }
}
