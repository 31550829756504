import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import RoleEdit from 'admin/modules/roles/components/role-edit.vue';
import RoleNew from 'admin/modules/roles/components/role-new.vue';
import Roles from 'admin/modules/roles/views/roles.view.vue';
import { RouteRecordRaw } from 'vue-router';

const rolesRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/roles',
    name: 'roles',
    component: Roles,
    translations: {
      module: 'roles',
      platform: 'admin',
    },
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-role',
        component: RoleNew,
        title: 'New role',
        rights: [FeaturePermission.MANAGE_ROLES],
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'edit-role',
        component: RoleEdit,
        title: 'Edit role',
        rights: [FeaturePermission.MANAGE_ROLES],
      }),
    ],
    title: 'Roles',
    rights: [FeaturePermission.MANAGE_ROLES],
  }),
];

export default rolesRoutes;
