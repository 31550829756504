<script setup lang="ts">
import { ref, inject } from 'vue';
import WrapperField from './wrapper-field.v2.vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { SwitchFieldService } from 'services/attribute-fields/field-services/switch-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
/** Props */
interface Props {
  attributeField: AttributeField<boolean | null>;
  productAttributeField: ProductAttributeField<boolean | null>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const switchFieldService = ref(
  new SwitchFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="switchFieldService">
    <span
      class="bg-white justify-content-start p-2 p-inputgroup-addon pl-3 w-full"
      :class="switchFieldService.getErrorTypeClass()"
    >
      <p-tri-state-checkbox
        v-model="switchFieldService.productAttributeFieldValue"
        :disabled="switchFieldService.productAttributeField.locked"
      />
    </span>
  </WrapperField>
</template>
