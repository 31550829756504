import { Navigation } from 'platform-unit2-api/core';
import { FeaturePermission } from 'platform-unit2-api/roles';

const navigations: Navigation[] = [
  {
    title: 'dashboard',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline',
  },

  {
    title: 'users',
    to: '/users',
    icon: 'mdi-account-outline',
    permission: FeaturePermission.MANAGE_USERS,
  },
  {
    title: 'clients',
    to: '/clients',
    icon: 'mdi-account-tie-outline',
    permission: FeaturePermission.MANAGE_CLIENTS,
  },
  {
    title: 'organisations',
    to: '/organisations',
    icon: 'mdi-office-building',
  },
  {
    title: 'datamodels',
    icon: 'mdi-format-list-text',
    to: '/datamodels',
    permission: FeaturePermission.MANAGE_DATAMODELS,
  },
  {
    title: 'fields',
    to: '/fields',
    icon: 'mdi-format-align-bottom',
    permission: FeaturePermission.MANAGE_FIELDS,
  },
  {
    title: 'templates',
    to: '/templates',
    icon: 'mdi-note-multiple-outline',
    permission: FeaturePermission.MANAGE_TEMPLATES,
  },

  {
    title: 'retailers',
    to: '/retailers',
    icon: 'mdi-storefront-outline',
    permission: FeaturePermission.MANAGE_RETAILERS,
  },
  {
    title: 'channels',
    to: '/channels',
    icon: 'mdi-share-variant',
    permission: FeaturePermission.MANAGE_MODULES,
  },
  {
    title: 'roles',
    to: '/roles',
    icon: 'mdi-shield-key-outline',
    permission: FeaturePermission.MANAGE_ROLES,
  },
  {
    title: 'validation_rules',
    to: '/validation-rules',
    icon: 'mdi-list-status',
  },

  {
    title: 'status_notifications',
    to: '/status-notifications',
    icon: 'mdi-information-variant-box-outline',
  },
];

export default navigations;
