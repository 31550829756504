<script setup lang="ts">
import moment from 'moment';
import { onMounted, ref } from 'vue';
import { Activity } from 'platform-unit2-api/activities';

/** Props */
const props = defineProps<{
  activity: Activity[];
}>();

/** constants */
const activities = ref<Activity[]>([]);

onMounted(() => {
  activities.value = props.activity;
});
</script>
<template>
  <div v-if="activities?.length" class="flex">
    <!-- #region: label -->
    <div class="flex flex-column">
      <div
        class="align-items-center bg-yellow-500 border-circle flex font-bold justify-content-center text-white text-xl"
        :style="{ width: '40px', aspectRatio: '1' }"
      >
        {{ activities[0]?.user?.charAt(0).toLocaleUpperCase() }}
      </div>
      <div class="flex flex-grow-1 justify-content-center line py-1">
        <div class="border-1 border-gray-200" :style="{ width: '0px' }"></div>
      </div>
    </div>
    <!--  #endregion -->

    <!-- #region: activity details -->
    <div class="body flex-grow-1 px-3 text-gray-600">
      <!-- Title -->
      <div class="mb-3">
        {{ activities[0]?.user }}
        <span class="font-bold text-gray-800">has uploaded {{ activities.length }} images</span> :
      </div>
      <!-- details -->
      <div class="mb-3">
        <div
          v-for="currentActivity in activities"
          :key="currentActivity.id"
          class="bg-gray-50 m-2 p-2"
        >
          {{ currentActivity.modified?.upload?.new?.filename }}
        </div>
      </div>
      <!-- Time -->
      <div class="mb-4 text-gray-500 text-sm time">
        {{ moment(activities[0].created_at).format('DD MMM Y, HH:mm') }}
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>
