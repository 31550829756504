import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { Attribute } from 'platform-unit2-api/attributes';
import { IDatamodelService } from 'admin/modules/datamodels/ts/interfaces/datamodel-service.interface';
import { DatamodelsRestService } from 'platform-unit2-api/datamodels';

export class DatamodelService implements IDatamodelService {
  private _confirmService = new ConfirmService();
  private ts = new TranslationService('admin', 'datamodels');
  private toastService = ToastService.getInstance();
  private datamodelApi = new DatamodelsRestService();

  getAllODMAttributes(): Promise<Attribute[]> {
    return this.datamodelApi.getAllODMAttributes().then((res) => {
      return res.data;
    });
  }

  /**
   * Sets the current page and loads the attributes of that page.
   * @param datamodelId the id of the datamodel required for the request
   * @param attributeIds number[] of attribute ids to be detached
   * @param callback optional additional callback if needed for actions in the view
   */
  async bulkDetach(datamodelId: number, attributeIds: number[], callback?: () => void) {
    this._confirmService.confirmDelete({
      group: 'fields-dialog',
      message: this.ts.tModule('confirmDetach', {
        params: {
          count: attributeIds.length,
        },
      }),
      callback: async () => {
        try {
          await this.datamodelApi.bulkDetachDatamodelField(datamodelId, attributeIds);
          this.toastService.displaySuccessToast(this.ts.tModule('detachAttributesSuccess'));
          callback?.();
        } catch {
          this.toastService.displayErrorToast(this.ts.deleteFailed());
        }
      },
    });
  }
}
