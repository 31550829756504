import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Product',
  subTitle: 'Here you can create a new Product.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Product',
};

const update: TranslationObjectAction = {
  title: 'Edit Product',
  subTitle: 'Here you can edit a Product.',
};

const translationObject: TranslationObject = {
  title: 'Product | Products',
  placeholder: 'Select Product | Select Products',
  emptyStateSubtitle:
    'Start by creating your first product. When a product is created it will show here.',
  create: create,
  delete: deleteTranslation,
  update: update,
  validations: 'Validations',
  //

  productTable: {
    filters: {
      date: {
        infoText: 'Filter from selection date till today',
      },
    },
  },

  validationModels: {
    dialogTitle: 'Validation model',
    dialogSubtitle: 'Please select the validation model you woould like to apply to {moduleName}',
    applied: 'Validation model applied',
    errorsAndSolutions: 'Errors and solutions',
    showAll: 'Show all ({count})',
    noErrorsFound: 'No errors or warnings to be found',
  },

  assetsIncluded: 'Assets included in export',
  filters: 'Filters',
  adding_to_scheduled_export: 'You are adding products to your scheduled export',
  select_columns: 'Select Columns',
  confirm_delete: 'Are you sure you want to delete {amount} product(s)?',
  duplicated_successfully: 'Successfully duplicated {amount} product(s).',
  duplicate_failed: 'Failed to duplicate product(s). Please try again.',
  share_with_retailer_failed: 'Failed to share with retailer',
  share_with_retailer_warning: 'Products without variants found',
  share_with_retailer_success: 'Successfully shared {amount} products(s) with retailer',
  unshare_with_retailer_success: 'Successfully unshared {amount} products(s) with retailer',
  share_with_retailer_warn:
    'Shared {shared} products(s) out of {amount} products(s) with retailer.',
  unshare_with_retailer_warn:
    'Unshared {shared} products(s) out of {amount} products(s) with retailer.',
  unshare_with_retailer_fail_no_variants:
    'None of the selected products had a variant that can be shared.',
  product_name: 'Product Name',
  show_missing_fields: 'Only show the empty fields',
  introduced_at: 'Introduction date',
  delisted_at: 'Delist date',
  remove_variant: 'Remove variant',
  add_language: 'Add Language',
  add_variant: 'Add variant',
  attribute_search_placeholder: 'Search attributes',
  product_assets: {
    title: 'Asset | Assets',
    upload_assets: 'Upload assets',
    add_from_library: 'Add assets from library',
    empty_state_title: 'Hey, there are no assets selected yet!',
    empty_state_description:
      'Start by adding assets from the media library or by uploading new assets.\nNew assets will automaticall be added to your library.',
    open_asset: 'Open asset',
    asset_name: 'Asset name',
    download_asset: 'Download asset',
    rename_asset: 'Rename asset',
    remove_asset: 'Remove asset',
    table_header: {
      asset_type: 'Asset type',
      resolution: 'Resolution',
      filesize: 'File size',
    },
    success: {
      uploaded_successfully: 'Asset(s) uploaded successfully.',
      removed_successfully: 'Successfully removed upload.',
      downloaded_successfully: 'Asset(s) downloaded successfully.',
      included_assests_successfully: 'Asset(s) included in export successfully.',
    },
    warnings: {
      reorder_failed: 'We were unable to delete the {name}. Please try again.',
      downloading_failed: 'We were unable to download the file. Please try again.',
      uploading_failed: 'Failed to upload assets(s)',
      removing_failed: 'We were unable to remove asset.',
    },
    include_in_export: 'Include in export',
    bulk_delete_assets: "You are about to remove multiple assets. You won't be able to recover it.",
  },

  variant_modal: {
    select_datamodel: 'Select a datamodel (optional)',
  },

  advanced_search: {
    only_show_selected_products: 'Only show the seleted products',
  },
  product_catrgories: {
    add_category: 'Select a new Category',
    add_btn: 'Save category',
    select_placeholder: 'Select to add',
    categoryAddedSuccessfully: 'the category is added to the product',
    categories_not_filled: 'Not yet filled',
  },
  valildationPanel: {
    emptyStateTitle: 'No validation model applied',
    emptyStateSubtitle:
      'Start by applying a validation model. When the validation model is active, errors and warnings will be shown here.',
    emptyStateButtonLabel: 'Apply validation model',
  },
  //Old translation
  product: 'Product',
  variant_name: 'Variant Name',
  shared_retailer: 'Shared',
  story: 'Story',
  stories: 'Stories',
  start_creating: 'Start creating',
  create_story: 'Create story',
  field_required: 'Field is required.',
  field_required_not_empty: 'Field cannot be empty.',
  missing_fields: 'Missing Fields',
  all_fields: 'All Fields',
  show_fields_used_by_channel: 'Show fields used by channel',
  show_all_fields: 'Show all fields',
  show_empty_fields: 'Show empty fields',
  no_empty_fields: 'There are no empty fields to display.',
  no_empty_fields_module:
    'There are no fields to display. This is likely because there are no export mappings for the selected channel.',
  no_fields_found: 'Could not find any fields matching your search.',
  variants: 'Variants',
  variant: 'Variant',
  create_variant: 'Create Variant',
  how_is_this_calculated: 'How is this calculated?',
  delete_variant: 'Delete Variant',
  completeness_message:
    'The completeness score is calculated by dividing the amount of filled-in fields by the total amount of fields. De presence of at least one image counts as an extra field and thus impacts the score. What you see here is the average score of all languages for which content is available.',
  update_product_data: 'Update product data',
  no_thumbnail:
    'Cannot select a thumbnail because this product does not have any supported thumbnail assets related to it. You can start uploading assets on the "Assets" tab.',
  cannot_publish: 'You need at least the superuser role to publish this product.',
  navigation: 'Product Navigation',
  hierarchy: 'Hierarchy',
  next_product: 'Next product',
  previous_product: 'Previous product',
  last_updated: 'Last updated',
  save_override: 'Save with override',
  undifined_gtins: 'Warning: {amount} GTIN code(s) could not be found!',
  invalid_gtins: 'Error: {amount} GTIN code(s) are invalid!',
  gtins: 'GTIN code(s)',
  gtin_search: 'search GTIN code(s)',
  reset_filters: 'Reset all applied filters',
  column_configuration: 'Products table configuration',
  active_gtins_search:
    'You are currently searching for GTIN codes, It might not show all your products.',
  active_variants_filter:
    'You just created a variant. It might not be shown in your products list, due to active variants filter.',
  assign_to_client: {
    title: 'Transfer',
    transfer_started:
      'Started transferring {amount} product(s) to {client}, this might take a minute.',
    assign_failed: 'Unable to assign. Please try again.',
  },
  new: {
    title: 'New product',
    subtitle: 'Here you can create a new product.',
  },
  save: {
    title: 'Save and overwrite',
    description:
      'Save the modified fields. If it is desired to overwrite variants please select the variants and fields to overwrite.',
    variants: 'Select Variants',
    fields: 'Select Fields',
  },
  task_modal: {
    describe: 'Describe',
    assign: 'Assign',
    deadline: 'Deadline',
    settings: 'Settings',
  },
  export_modal: {
    title: 'Export products',
    scheduled_successfully:
      'Export has been scheduled. Navigate to the exports scheduled overview page to see your export schedule(s)',
    starting_failed: 'Failed to start export. Please try again.',
    started_successfully:
      'Export has been started. Navigate to the exports overview page to see your export(s)',
    channel: {
      title: 'Select channel',
    },
    type: {
      title: 'Type',
      type_question: 'What kind of export do you want to make?',
      data_dump: 'Generic export',
      generic_export_desc: 'This is an export that ignores all mappings.',
      mapping_export: 'Mapping Export',
      mapping_export_desc: 'This is an export that will use mapping in the export.',
      next_button: 'Next step',
      cancel_export: 'Cancel export',
    },
    generic: {
      title: 'Generic export',
      question: 'What kind of elements do you want in your export?',
      back_button: 'Previous step',
      next_button: 'Next step',
      use_overwritten_names: 'Use overwritten names',
    },
    product_overview: {
      title: 'Generic export overview',
      subtitle: 'The following products are included in the export',
      column_gtin: 'GTIN',
      column_product_name: 'Product name',
      column_data_model: 'Data model',
      column_varient_name: 'Varient name',
      back_button: 'Previous step',
      export_button: 'Export product data',
      tooltip: 'Remove from export',
    },
    settings: {
      title: 'Settings',
      source_warning: "Settings can't be applied, select at least one source to export",
      select_source: 'Select sources to export',
      attributes: 'Product attributes',
      assets: 'Product assets',
      date_limit_label: 'Only export products updated',
      date_limit_always: 'since always (export all products)',
      date_limit_export: 'since last export',
      date_limit_dates: 'between dates',
      select_date_range: 'Select a date range',
      select_date: 'Select date',
      schedule_export: 'Schedule the export',
      schedule_description: "don't check this item if you want to directly go to step 4",
    },
    schedule: {
      title: 'Schedule',
      type_name: 'Type a name for the scheduled export',
      ignore_product_status: 'Ignore product status',
    },
    contact: {
      title: 'Contact',
      retailer_email: 'Retailer e-mail',
      email_to: 'E-mail to',
      custom_message: 'Custom message',
      message: 'Message',
    },
    overview: {
      title: 'Overview',
      selected_sources: 'Selected sources',
      scheduled: 'Scheduled',
      included_products:
        'No products are included in the export | 1 product is included in the export | {count} products are included in the export',
      included_info:
        'These products are included in the export due to their status allowing them to be exported',
      excluded_products:
        'No products are excluded from export | 1 product is excluded from export | {count} products are excluded from the export',
      excluded_info:
        'These products are not included in the export because their status does not allow them to be exported',
      go_to_products: 'Go to products',
    },
    publishing_failed: 'Failed to publish product. Please try again.',
    publishing_success: 'Successfully published product.',
  },
  publication_modal: {
    title: 'Publish/unPublish products',
    gln_input: 'Insert GLN number',
    get_channel_failed: 'We couldnt get your publication channel details',
    starting_failed: 'Failed to process your request. Please try again.',
    started_successfully:
      'Your publication request was successfully sent. Visit the export overview page for more details',
  },
  hierarchies: {
    fetch_failed: 'Failed to fetch hierarchies. Please try again.',
  },
  actions: {
    create_variant: 'Create variant',
    detach_assets: 'Detach all assets',
    add_task: 'Add task',
    share: 'Share with Workspace',
    share_with_retailer: 'Share with Retailer',
    unshare_with_retailer: 'Unshare with Retailer',
    update_field: 'Update field',
    bulkAttachBrand: 'Attach brand',
    sync_name: 'Sync product name',
    sync_brand: 'Sync brand',
    delete_locale_content: 'Delete locale content',
    attach_to_task: 'Attach to existing task',
    lock_fields: 'Lock field(s)',
    duplicate: 'Duplicate Product(s)',
    transition_state: 'Change status',
    export: 'Export products',
    publication: 'Publication',
  },
  product_tasks: {
    assignee: 'Assignee',
    assigned_by: 'Assigned by',
    due_date: 'Due date',
    related_products: 'Related products',
    comments: 'Comments',
    view_comments: 'View all comments',
    add_remark: ' Type here your remark regarding this task and product.',
    attach_header: 'Attach {count} products to task',
    attach_info:
      'Please select the task you want to connect the products to. If not yet available in the workspace of the owner of the task the products will be shared.',
    attached_successfully: 'Products have been attached to task.',
    attach_failed: 'We were unable attach products to the task. Please try again',
  },
  share_products: {
    title: 'Share',
    message: 'Message',
    message_placeholder: 'Enter a message for the recipient',
    sharing_started: 'Started sharing {amount} product(s) to {email}, this might take a minute.',
    sharing_failed: 'Something went wrong. Please try again.',
  },
  select_language_modal: {
    copy_from: 'Copy fields from another language',
  },
  delete_locale_content: {
    title: 'Delete locale content',
    deleted_successfully:
      'Deleting {locale} content for {amount} product(s), this might take a minute.',
    error: 'Could not sync selected products. Please try again.',
  },
  mass_sync_brand: {
    title: 'Sync brand',
    warning:
      'This action will sync the brand for all selected products to the value of the selected field.',
    error: 'Could not sync selected products. Please try again or try using a different field.',
    success: 'Syncing {field} for {amount} product(s), this might take a minute.',
    select_field: 'Select a reference field for the sync',
    change_field: 'Change field',
    select_field_message:
      'Please select a field of the type BRAND_FIELD which is a brand field like the Brand field. If the field is not of the right type the this action will fail.',
    select_locale: 'Select language',
    updating_notification:
      'Syncing brand based on the value of the {field} for the {amount} selected product(s).',
  },
  mass_sync_display_name: {
    title: 'Sync product name',
    warning:
      'This action will sync the product name for all selected products to the value of the selected field.',
    error: 'Could not sync selected products. Please try again or try using a different field.',
    success: 'Syncing {field} for {amount} product(s), this might take a minute.',
    select_field: 'Select a reference field for the sync',
    change_field: 'Change field',
    select_field_message:
      'Please select a field of the type DEFAULT which is a normal text field like the Title field. If the field is not of the right type the this action will fail.',
    updating_notification:
      'Syncing product name based on the value of the {field} for the {amount} selected product(s).',
  },
  transition_state: {
    title: 'Change status',
    success: 'Change to {state} for {amount} product(s), this might take a minute.',
    error: 'Could not change selected products. Please try again.',
    select_state: 'Select status',
  },

  mass_update: {
    title: 'Update field',
    error: 'Failed to update fields',
    warning: 'This action will update a field for all selected products.',
    success: 'Updating {field} for {amount} product(s), this might take a minute.',
    select_field: 'Select a field to update',
    change_field: 'Change Field',
    select_field_message: "Don't worry, this won't update locked fields.",
    updating_notification: 'Update the {field} field for {amount} product(s).',
    update_column: 'Update product column',
    select_column: 'Select a column',
    update_value: 'Update value to',
    update_product_successful: 'Updating {amount} product(s), this might take a minute.',
    bulkAttachBrandTitle: 'Bulk attach a brand',
    bulkAttachBrandSelect: 'Select a brand to attach to selected product(s):',
  },

  product_story: {
    title: 'Product Story | Product Stories',
    empty: 'Nothing here.',
    start_creating: 'Start creating',
    create_story: 'Create story',
  },
  product_details: {
    product_meta: {
      offline: 'Offline',
      online: 'Online',
    },
    shared: {
      with_retailer: 'Shared with retailer',
      without_retailer: 'Not shared with retailer',
    },
    product_action_group: {
      activity: 'Activity',
      delete_product: 'Delete product',
      delete_product_confirm: 'Are you sure you want to delete this product?',
      delete_product_warning:
        'Are you sure you want to delete this product? This will also delete its variants that are created.',
      removing_not_allowed: 'you should have atleast one selected language',
    },
    product_meta_edit: {
      update_product_data: 'Update product data',
      introduced_at: 'Introduction date',
      delisted_at: 'Delist date',
    },
    product_status_bar: {
      unsaved_changes: 'Unsaved changes',
      product_status: 'Product status',
      calculate_completeness_failed: 'Failed to re-calculate completeness. Please try again.',
      reset_product:
        'Are you sure you want to cancel editing and revert all changes to the latest version?',
      reset_product_title: 'Revert Changes',
    },
    sidebar_navigation: {
      title: 'Product navigation',
      attribute_groups: 'Attribute Groups',
    },
    create_variant: {
      title: 'Create Variant | Create variants',
      variants: 'Variant | Variants',
      select_product: 'Select a product',
      creating_notification: 'Create variant for {amount} product(s).',
      copy_master_data: 'Copy attributes and assets from Master Data',
      all_uploads: 'Copy all assets',
      only_primary_upload: 'Copy only the Primary Asset',
      skip_duplicate: 'Skip duplicate variants',
      created_successfully: 'Creating variant for {amount} product(s), this might take a minute.',
      name: {
        label: 'Variant name',
        placeholder: 'Type a name',
      },
      channel: {
        label: 'Channel',
      },
      copy_data_from: {
        label: 'Copy data from (optional)',
      },
      copy_assets_from: {
        label: 'Copy assets from (optional)',
      },
    },
    assets_included: 'Assets included',

    link_products: {
      menu: 'Link products',
      title: 'Link products',
      subtitle: 'Linked products',
      success: 'Product has been successfully linked.',
      description:
        'Link products with their quantities to the hierarchy. Only link items that are one level below the active product.',
      product_gtin: 'Product GTIN',
      linked_product_amount: {
        title: 'Quantity',
      },
    },

    hierarchies: {
      title: 'Hierarchies',
      link_products: 'Link products',
      select_hierarchy: 'Select a hierarchy',
    },
  },
  product_attributes: {
    no_export_mappings_title: 'There are no attributes for this channel yet',
    no_export_mappings_subtitle:
      'This is because there are no export mappings for the selected channel.',
    no_empty_fields: 'There are no empty fields to display.',
    no_attributes_attached: 'This is because there are no attributes attached to the data model.',
    no_attributes_attached_title: 'There are no attributes found',
    show_fields_used_by_channel: 'Show fields used by channel',
    show_all_fields: 'Show all fields',
    lock_field: {
      lock_field_failed: 'Failed to lock the field. | Failed to lock the fields.',
      title: 'Lock fields',
      set_lock: 'Set lock',
      lock: 'Lock',
      unlock: 'Unlock',
      locked_successfully: 'Successfully locked fields',
      unlocked_successfully: 'Successfully unlocked fields',
    },
  },
  product_assets_old: {
    title: 'Product Assets',
    assets: 'Asset | Assets',
    asset_type_select: {
      label: 'Asset Type',
      placeholder: 'Select a type',
    },
    quality_tip:
      '<strong>Tip:</strong> To ensure high quality content we advice to upload images that are at ' +
      'least 300dpi and 2000px long or wide. Most suppliers expect all images to be in the ' +
      'PNG file type.',
    include_in_export: 'Include In Export',
    start_uploading: 'Start Uploading',
    add_more: 'Add More',
    edit_filename: 'Edit filename',
    updated_order_successfully: 'Successfully update order',
    updated_successfully: 'Successfully updated {name} asset',
    type_changed: 'Type has been changed {name}',
    removed_successfully: 'Successfully removed upload.',
    reordering_failed: 'Failed to reorder the row. Please try again.',
    detach_assets: {
      title: 'Detach all assets',
      detached_successfully: 'Detaching assets from {amount} product(s). This might take a minute',
      confirm_message: 'Are you sure you want to detach all assets from {amount} product(s)?',
      include_variants: 'Include assets of variants',
      except_thumbnail: 'Detach all assets except thumbnail',
      error: 'Could not detach assets from the selected product(s). Please try again.',
    },
    product_details: {
      filters: 'Filters',
    },
  },
  product_activities: {
    title: 'Activities',
  },
  textAreaField: {
    placeholder: 'Enter a text',
  },
  numberField: {
    placeholder: 'Enter a number',
    notNumeric: 'Must be a number',
  },
  choiceField: {
    placeholder: 'Select a value',
  },
  dateTimeField: {
    invalid_date: 'Invalid date',
  },
  gtinField: {
    notNumeric: 'GTIN must be numeric',
  },
  financialField: {
    invalidCurrency: 'Invalid currency',
    invalidNumber: 'Invalid number',
    emptyFields: 'Fields cannot be empty',
  },
  inputSelectField: {
    invalidUnit: 'Invalid unit of measure',
  },
  tasks: {
    title: 'Tasks',
    noTasksFound: 'No tasks found',
  },
  snackbars: {
    loadingFailed: 'Failed to load product(s). Please try again.',
    duplicateSuccess: 'Successfully duplicated {amount} product(s).',
    duplicateFailed: 'Failed to duplicate product(s). Please try again.',
  },
  lengthOfGTIN: {
    error: 'The length of the GTIN should be {length}',
  },
  baseField: {
    error: 'Error: Above the maximum amount of character ({value})',
  },
  exports: {
    modals: {
      click_to_add: 'Click to add',
    },
  },
  keyValueField: { noResultsFound: 'No results found' },
  edit_filename: 'Edit filename',
};

const translation: Translation = {
  products: translationObject,
};

export default translation;
