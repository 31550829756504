import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import RetailersEdit from 'admin/modules/retailers/components/retailer-edit.vue';
import RetailersNew from 'admin/modules/retailers/components/retailer-new.vue';
import Retailers from 'admin/modules/retailers/views/retailers.view.vue';
import { RouteRecordRaw } from 'vue-router';

const retailersRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/retailers',
    name: 'retailers',
    component: Retailers,
    translations: {
      module: 'retailers',
      platform: 'admin',
    },
    rights: [FeaturePermission.MANAGE_RETAILERS],
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-retailer',
        component: RetailersNew,
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'edit-retailer',
        component: RetailersEdit,
      }),
    ],
  }),
];

export default retailersRoutes;
