<script setup lang="ts">
import { Category, CategoryCompact } from 'platform-unit2-api/categories';
import WrapperField from './wrapper-field.v2.vue';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { inject, ref } from 'vue';
import { CategoryFieldService } from 'services/attribute-fields/field-services/category-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import CategorySelect from '../selects/category-select.vue';

/** Props */
interface Props {
  attributeField: AttributeField<Category | CategoryCompact | undefined>;
  productAttributeField: ProductAttributeField<Category | CategoryCompact | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const categoryFieldService = ref(
  new CategoryFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>

<template>
  <WrapperField :attribute-field-service="categoryFieldService">
    <CategorySelect
      v-model="categoryFieldService.productAttributeFieldValue"
      :show-clear="!categoryFieldService.productAttributeField.locked"
      hide-label
      :disabled="categoryFieldService.productAttributeField.locked"
      :pt="{
        root: {
          class: 'border-noround-left ' + categoryFieldService.getErrorTypeClass(),
        },
      }"
    />
  </WrapperField>
</template>
