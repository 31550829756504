import { RouteFactory } from '@/core/router/route.factory';
import organisationsEdit from 'admin/modules/organisations/components/organisation-edit.vue';
import organisationsNew from 'admin/modules/organisations/components/organisation-new.vue';
import organisations from 'admin/modules/organisations/views/organisations.view.vue';
import { RouteRecordRaw } from 'vue-router';

const organisationRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/organisations',
    name: 'organisations',
    component: organisations,
    title: 'Organisations',
    children: [
      RouteFactory.createEmptyRoute({
        path: 'new',
        name: 'new-organisation',
        component: organisationsNew,
        title: 'Productsup Platform New organisation',
      }),
      RouteFactory.createEmptyRoute({
        path: ':id',
        name: 'edit-organisation',
        component: organisationsEdit,
        title: 'Productsup Platform Edit organisation',
      }),
    ],
  }),
];

export default organisationRoutes;
