import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import {
  ValidationModelsRestService,
  ValidationModel,
  ValidationModelCreateRequest,
  ValidationModelUpdateRequest,
} from 'platform-unit2-api/validation-models';

import { FieldErrors } from '../ts/types/field-errors.type';
import { ValidationModelStatusEnum } from '../ts/enums/validation-model-status.enum';

class ValidationModelService extends BaseViewService<
  ValidationModelsRestService,
  ValidationModel,
  ValidationModelCreateRequest,
  ValidationModelUpdateRequest
> {
  /**
   * @inheritdoc
   */
  public createBody(): ValidationModelCreateRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const createBody: ValidationModelCreateRequest = {
      name: this.current.name!,
      module_id: this.current.module!.id,
      is_active: true,
    };

    return createBody;
  }

  /**
   * @inheritdoc
   */
  public updateBody(): ValidationModelUpdateRequest | undefined {
    const createBody = this.createBody();
    if (!createBody || !this.current?.id) {
      return;
    }

    return {
      ...createBody,
      id: this.current.id,
    };
  }

  formSubmitted = false; //Used to show the errors only if the form was submitted
  public get validated(): boolean {
    this.formSubmitted = true;

    this.validateForm();

    if (!this.current?.name || !this.current.module || !this._isNameUnique(this.current.name)) {
      return false;
    } else {
      return true;
    }
  }

  get validationModels() {
    return this._data;
  }

  private get _modelNames() {
    //Used when you check if the name is unique
    return this.validationModels.map((model) => model.name);
  }

  private _isNameUnique(name: string) {
    return !this._modelNames.includes(name);
  }

  constructor(ts: TranslationService) {
    super({
      Api: ValidationModelsRestService,
      ts,
      fetchAllFunction: 'getAll',
      overviewRouteName: 'validations',
      createRouteName: 'newValidationModel',
      updateRouteName: 'updateValidationModel',
      confirmPopUpGroup: 'validationModels',
    });
  }

  //#region Form validation
  private _fieldErrors: Partial<FieldErrors<ValidationModelCreateRequest>> = {}; //To handle validation in the frontend

  /**
   * Validates the form and sets the errors
   */
  validateForm() {
    this._resetErrors();
    if (this.formSubmitted) {
      if (!this.current?.name) {
        this._fieldErrors.name = {
          error: true,
          messageKey: 'required',
        };
      } else if (!this._isNameUnique(this.current.name)) {
        this._fieldErrors.name = {
          error: true,
          messageKey: 'notUnique',
        };
      }

      if (!this.current?.module) {
        this._fieldErrors.module_id = {
          error: true,
          messageKey: 'required',
        };
      }
    }
  }
  private _resetErrors() {
    this._formValidationService.resetErrors(); //Reset the errors from the backend

    this._fieldErrors = {
      //Reset the errors from the frontend
      name: {
        error: false,
        messageKey: undefined,
      },
      module_id: {
        error: false,
        messageKey: undefined,
      },
      is_active: {
        error: false,
        messageKey: undefined,
      },
    };
  }
  closeDialog() {
    this._resetErrors();
    this.formSubmitted = false;
    this.closeCrudComponent();
  }

  get fieldErrors() {
    return this._fieldErrors;
  }

  //#endregion

  //#region Delete validation model

  /**
   * Deletes the validation model
   * @param validationModelId The id of validation model to delete
   */

  private _deleteCallback(id: number) {
    this._isLoadingCrudComponent = true;
    this._isLoadingOverview = true;
    this._restService
      .delete(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this._reloadPage();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.deleteFailed());
      })
      .finally(() => {
        this._isLoadingCrudComponent = false;
      });
  }
  async deleteValidationModel(validationModelId: number) {
    if (validationModelId == null) {
      return;
    }

    this._confirmService.confirmDelete({
      group: this.confirmPopUpGroup,
      callback: () => this._deleteCallback(validationModelId),
      message: this._ts.tModule('dialog.confirmDelete'),
      header: this._ts.tModule('dialog.deleteHeader'),
      acceptLabel: this._ts.tGlobal('delete'),
      rejectLabel: this._ts.tGlobal('cancel'),
      icon: '',
    });
  }

  //#endregion

  goToCollectionsRoute = (id: number) => {
    this._router.push({
      name: 'validationCollections',
      params: {
        modelId: id.toString(),
      },
    });
  };

  //#region Update validation model
  goToUpdateRoute = (id: number) => {
    this._router.push({
      name: this._updateRouteName,
      params: {
        id: id.toString(),
      },
    });
  };

  //#endregion

  //#region Duplicate validation model

  /**
   * Duplicates the validation model
   * @param validationModelId The id of validation model to duplicate
   */

  duplicateValidationModel(validationModelIds: [number]) {
    this._isLoadingOverview = true;
    this._restService
      .duplicate({ validation_models: validationModelIds })
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.duplicateSuccess());
        this._reloadPage();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.duplicateFailed());
      })
      .finally(() => {
        this._isLoadingOverview = false;
      });
  }

  //#endregion

  //#region Activate/Pause validation model

  /**
   * Change the status of the validation model
   * @param validationModelIds The id of validation model to change the status of
   * @param status The status to change to
   */

  changeStatus(validationModelIds: number[], status: ValidationModelStatusEnum) {
    this._isLoadingOverview = true;
    this._restService
      .changeStatus(validationModelIds, status)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.tModule('statusChangeSuccessfull'));
        this._reloadPage();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('statusChangeFailed'));
      })
      .finally(() => {
        this._isLoadingOverview = false;
      });
  }

  //#endregion
}

export default ValidationModelService;
