<script setup lang="ts">
import { SharedViewService } from '@/general/services/overview-service/modules/shared-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import SharedByMeTable from 'supplier/modules/shared/components/shared-by-me-table.vue';
import OverView from '@/general/ui/views/over-view/over-view.vue';
import { sharedServiceKey } from 'supplier/modules/shared/ts/keys/shared.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import { provide, ref, Ref } from 'vue';
import SharedWithMeTable from 'supplier/modules/shared/components/shared-with-me-table.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import ShareApproveModal from 'supplier/modules/shared/components/share-approve-modal.vue';

const ts = ref(new TranslationService('supplier', 'shared'));
const service = ref(new SharedViewService(ts.value as TranslationService));
provide(sharedServiceKey, service as Ref<SharedViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView :has-search="false" platform="supplier" module-name="shared-by-me" :service="service">
    <template #content>
      <SharedByMeTable v-if="service.isSharedByMe" />
      <SharedWithMeTable v-else />
    </template>
    <template #crudComponent>
      <pSidebar
        :visible="service.crudComponent"
        class="p-sidebar-md"
        position="right"
        @update:visible="service.closeCrudComponent()"
      >
        <SidebarSkeleton v-if="service.isLoadingCrudComponent" />
        <RouterView v-else />
      </pSidebar>
    </template>
    <template #others>
      <pConfirmDialog group="share" />
      <ShareApproveModal />
    </template>
  </OverView>
</template>
