import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Incomplete Product | Incomplete Products',

  productName: 'Product Name',
  noEmptyFieldsModule:
    'There are no fields to display. This is likely because there are no export mappings for the selected channel,',
  contact_productsup: 'contact Productsup.',
  noUploads:
    'The presence of images affect the completeness score. Make sure that each product contains' +
    'at least 1 image. Images can be added on the ',
  productsPage: 'product page',
};

const tranlation: Translation = {
  incompleteProducts: translationObject,
};

export default tranlation;
