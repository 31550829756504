<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import useFormValidation from 'composables/form-validation';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import {
  DatamodelsRestService,
  Datamodel,
  UpdateDatamodelRequest,
} from 'platform-unit2-api/datamodels';
import { isNegative } from '@/general/utils/isNegative';

/** Props */
export interface Props {
  datamodel?: Datamodel;
}

const props = withDefaults(defineProps<Props>(), {
  datamodel: undefined,
});

/** Emits */
const emits = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Services */
const ts = new TranslationService('admin', 'datamodels');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const datamodelApi = new DatamodelsRestService();

/** Composables */
const route = useRoute();
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Constants */
const loading = ref(false);
const saving = ref(false);
const editorDatamodel = ref<Datamodel>();

const hideDetails = () => {
  emits('hide');
};

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();

  if (editorDatamodel.value) {
    try {
      saving.value = true;

      const datamodel: UpdateDatamodelRequest = {
        name: editorDatamodel.value?.name,
        owner_id: editorDatamodel.value?.owner?.id ?? undefined,
        is_odm: editorDatamodel.value?.is_odm ?? undefined,
        id: editorDatamodel.value?.id,
      };

      await datamodelApi.update(editorDatamodel.value.id, datamodel);

      toastService.displaySuccessToast(ts.updateSuccess(editorDatamodel.value?.name));

      emits('refresh');
      emits('hide');
    } catch (err) {
      parseFormError(err, () => {
        toastService.displayErrorToast(ts.updateFailed());
      });
    } finally {
      saving.value = false;
    }
  } else {
    toastService.displayErrorToast(ts.updateFailed());
  }
};

const deleteDatamodel = async (id: number) => {
  loading.value = true;
  try {
    await datamodelApi.delete(id);

    toastService.displaySuccessToast(ts.deleteSuccess());

    emits('refresh');
    emits('hide');
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    hideDetails();
  }

  loading.value = false;
};

const confirmDatamodelDelete = (event: PointerEvent, title?: string) => {
  if (isNegative(editorDatamodel.value?.id)) {
    toastService.displayErrorToast(ts.deleteFailed());
    return;
  }

  confirmService.confirmDelete({
    event: event,
    message: ts.deleteConfirm(title),
    callback: () => deleteDatamodel(Number(route.params.id)),
    group: 'datamodel-properties',
  });
};

/** Lifecycle */
onMounted(() => {
  editorDatamodel.value = props.datamodel;
});

watch(
  () => props.datamodel,
  () => {
    editorDatamodel.value = props.datamodel;
  },
);
</script>
<template>
  <div class="h-full">
    <SidebarSkeleton v-if="loading" />
    <div v-else class="flex flex-column h-full">
      <div class="field mb-3">
        <label for="name">{{ ts.tGlobal('name') }}</label>
        <p-input-text
          v-if="editorDatamodel"
          id="name"
          v-model="editorDatamodel.name"
          class="w-full"
          :class="{ 'p-invalid': hasError('name') }"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>

      <div
        class="bg-white border-100 border-top-1 bottom-0 flex justify-content-between mt-5 mt-auto py-3 sticky"
      >
        <p-button
          icon="mdi mdi-delete-outline"
          :label="ts.tGlobal('delete')"
          text
          severity="danger"
          rounded
          @click="confirmDatamodelDelete($event, editorDatamodel?.name)"
        />
        <div>
          <p-button :label="ts.tGlobal('cancel')" text plain class="mr-2" @click="hideDetails" />
          <p-button
            :disabled="saving"
            :label="ts.tGlobal('save')"
            :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
            icon-pos="right"
            @click="handleSubmit"
          />
        </div>
      </div>
      <p-confirm-popup group="datamodel-properties" />
    </div>
  </div>
</template>
