import { FeaturePermission } from 'platform-unit2-api/roles';
import ProductDetailsView from 'retailer/modules/products/views/product-details.view.vue';
import ProductAttributes from 'retailer/modules/products/views/product-attributes.view.vue';
import ProductFilters from 'retailer/modules/products/views/product-filters.view.vue';
import ProductAssets from 'retailer/modules/products/views/product-assets.view.vue';
import ProductStory from 'retailer/modules/products/views/product-story.view.vue';
import ProductCategory from 'retailer/modules/products/views/product-category.view.vue';
import Products from 'retailer/modules/products/views/products.view.vue';
import Describe from 'retailer/modules/tasks/components/add-task/describe.view.vue';
import Assign from 'retailer/modules/tasks/components/add-task/assign.view.vue';
import Settings from 'retailer/modules/tasks/components/add-task/settings.view.vue';
import Channel from 'retailer/modules/products/views/export/channel.view.vue';
import Contact from 'retailer/modules/products/views/export/contact.view.vue';
import SettingsExport from 'retailer/modules/products/views/export/settings-export.view.vue';
import ScheduleExport from 'retailer/modules/products/views/export/schedule.view.vue';
import InfoExport from 'retailer/modules/products/views/export/info-export.view.vue';
import Type from 'retailer/modules/products/views/export-generic/type.view.vue';
import GenericExport from 'retailer/modules/products/views/export-generic/generic.view.vue';
import ProductOverviewGenericExport from 'retailer/modules/products/views/export-generic/product-overview.view.vue';
import ProductAttributesV2 from 'retailer/modules/products/views/product-attributes.viewV2.vue';
import ProductDetailsViewV2 from 'retailer/modules/products/views/product-details.viewV2.vue';
import { RouteRecordRaw } from 'vue-router';

import { RouteFactory } from '@/core/router/route.factory';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'products',
});

export async function getProductRoutes(): Promise<RouteRecordRaw[]> {
  const featureFlagService = FeatureFlagService.getInstance();

  const isOn = await featureFlagService.isOn(featureFlagEnum.FIELDV2);

  return [
    routeFactory.createAppRoute({
      path: '/products',
      name: 'products',
      component: Products,
      rights: [FeaturePermission.READ_PRODUCTS],
      title: 'Products',
      children: [
        routeFactory.createChildRoute({
          path: 'task/add',
          name: 'products-new-task',
          redirect: { name: 'products-new-task-describe' },
        }),
        routeFactory.createChildRoute({
          path: 'task/add/describe',
          name: 'products-new-task-describe',
          component: Describe,
        }),
        routeFactory.createChildRoute({
          path: 'task/add/assign',
          name: 'products-new-task-assign',
          component: Assign,
        }),
        routeFactory.createChildRoute({
          path: 'task/add/settings',
          name: 'products-new-task-settings',
          component: Settings,
        }),
        routeFactory.createChildRoute({
          name: 'export-channel',
          path: 'export/channel',
          component: Channel,
          title: 'Select Channel for Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-settings',
          path: 'export/settings',
          component: SettingsExport,
          title: 'Select Settings for Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-schedule',
          path: 'export/schedule',
          component: ScheduleExport,
          title: 'Schedule the Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-contact',
          path: 'export/contact',
          component: Contact,
          title: 'Select Contact for Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-info',
          path: 'export/info',
          component: InfoExport,
          title: 'Confirm the Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-type',
          path: 'export/type',
          component: Type,
          title: 'Select Type of Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-generic',
          path: 'export/generic',
          component: GenericExport,
          title: 'Export Type generic',
        }),
        routeFactory.createChildRoute({
          name: 'export-product-overview',
          path: 'export/products',
          component: ProductOverviewGenericExport,
          title: 'Product overview',
        }),
      ],
    }),
    routeFactory.createAppRoute({
      path: '/products/:id',
      name: 'product-details',
      component: isOn ? ProductDetailsViewV2 : ProductDetailsView,
      redirect: { name: 'product-attributes' },
      title: 'Product',
      rights: [FeaturePermission.READ_PRODUCTS],
      children: [
        routeFactory.createChildRoute({
          path: 'attributes',
          name: 'product-attributes',
          component: isOn ? ProductAttributesV2 : ProductAttributes,
          title: 'Product attributes',
        }),
        routeFactory.createChildRoute({
          path: 'filters',
          name: 'product-filters',
          component: ProductFilters,
          title: 'Product filters',
        }),
        routeFactory.createChildRoute({
          path: 'assets',
          name: 'product-assets',
          component: ProductAssets,
          title: 'Product assets',
        }),
        routeFactory.createChildRoute({
          path: 'story',
          name: 'product-story',
          component: ProductStory,
          title: 'Product story',
        }),
        routeFactory.createChildRoute({
          path: 'category',
          name: 'product-category',
          component: ProductCategory,
          title: 'Product categories',
        }),
      ],
    }),
  ];
}

export default getProductRoutes;
