<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DashboardRestService } from 'platform-unit2-api/dashboard';

/** Services */
const ts = new TranslationService('admin', 'dashboard');
const toastService = ToastService.getInstance();
const dashboardApi = new DashboardRestService();

/** Constants */
const stats = ref<any>();
const loading = ref<boolean>(false);

/** Lifecycle Methods */
onMounted(async () => {
  loading.value = true;
  try {
    stats.value = await dashboardApi.getDashboardAdminData();
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
});
</script>
<template>
  <div class="bg-white border-round px-4 shadow-1">
    <div>
      <p-message p-message severity="warn" :closable="false">
        {{ ts.tModule('info') }} 24.2.0.1
      </p-message>
      <div v-if="loading" class="cursor-pointer mt-3 p-2">
        <div class="grid">
          <div v-for="i in 6" :key="i" class="col-12 mb-2 md:col-4 mt-2 p-1">
            <div class="p-3 shadow-1">
              <p-skeleton width="100%" height="250px" class="mb-2" />
              <div class="align-items-center flex justify-content-between mb-3">
                <div class="mr-3 w-full"></div>
              </div>
              <div class="align-items-center flex justify-content-center">
                <p-skeleton width="40%" height="3rem" border-radius="2rem" class="mb-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="grid">
        <div
          v-for="stat in stats"
          :key="stat.id"
          class="col-12 mb-3 md:col-4 md:mb-0 md:w-4 sm:col-6"
        >
          <p-card class="h-full text-center">
            <template #subtitle>
              {{ stat.title + 's' }}
            </template>
            <template #content>
              <h3 class="font-bold mb-5 text-3xl">
                {{ stat.count }}
              </h3>
              <p v-if="stat.recently_updated" class="font-bold mb-3 text-lg">
                {{ ts.tModule('recently_updated') }}:
              </p>
              <p v-else>
                <p-inline-message severity="info" class="mb-3">
                  {{
                    ts.tModule('no_update', {
                      params: {
                        title: `${stat.title}(s)`,
                      },
                    })
                  }}
                </p-inline-message>
              </p>
              <div v-for="recent in stat.recently_updated" :key="recent.id">
                <p-button
                  class="p-button-link"
                  :label="recent.name"
                  @click="
                    $router.push({
                      name: 'edit-' + stat.title.toLowerCase(),
                      params: { id: recent.id },
                    })
                  "
                />
              </div>
            </template>
            <template #footer>
              <p-button
                icon="mdi mdi-plus"
                :label="`${ts.createTitle} ${stat.title}`"
                @click="$router.push({ name: 'new-' + stat.title.toLowerCase() })"
              />
            </template>
          </p-card>
        </div>
      </div>
    </div>
  </div>
</template>
