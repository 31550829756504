<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import ProductSaveModal from 'retailer/modules/products/components/product-save-modal.vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ProductStatus, ProductStatusesRestService } from 'platform-unit2-api/product-statuses';
import { MenuItem } from 'primevue/menuitem';

/**Emits */
const emit = defineEmits<{
  (e: 'deleted'): void;
  (e: 'refresh'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const confirmService = new ConfirmService();
const productStatusApi = new ProductStatusesRestService();

/**Consts */
const store = useStore();
const statuses = ref<ProductStatus[]>([]);
let splitButtonActions: MenuItem[] = [];
const isSaving = ref(false);
const isDeleting = ref(false);
const isLoadingProductDetails = ref(false);
const productSaveModalActive = ref(false);
const productSaveModalOpen = ref(false);
const visible = ref(false);

const isLoading = computed(() => {
  return isSaving.value || isDeleting.value || isLoadingProductDetails.value;
});

const editingProductFields = computed(() => store.getters['products/editingProductFields']);
const currentProduct = computed(() => store.getters['products/currentProduct']);

const transitionState = async (id: number) => {
  try {
    await store.dispatch('products/UPDATE_PRODUCT', {
      productId: currentProduct.value?.id,
      data: {
        product_status_id: id,
      },
    });
    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (ex) {
    toastService.displayErrorToast(ts.updateFailed());
  }
};

const loadAsyncData = async (): Promise<void> => {
  if (currentProduct.value) {
    visible.value = currentProduct.value.public;
    try {
      statuses.value = (await productStatusApi.getAll()).data;
      splitButtonActions = statuses.value.map((status) => ({
        ...status,
        command: () => {
          transitionState(status.id);
        },
      }));
    } catch (err) {
      toastService.displayErrorToast(ts.loadFailed());
    }
  }
};

const productSaveModalVisible = (value: boolean) => {
  productSaveModalActive.value = value;
};

const saveProduct = async (overrides: { attributes: number[]; variants: number[] }) => {
  isSaving.value = true;

  try {
    await store.dispatch('products/SAVE_EDITING_PRODUCT_FIELDS', overrides);

    store.dispatch('products/CANCEL_EDITING_FIELD_DATA');

    store.dispatch('products/SET_LOADING_COMPLETENESS', true);

    try {
      await store.dispatch('products/GET_PRODUCT_DETAILS', currentProduct.value?.id);

      emit('refresh');
    } catch (ex) {
      toastService.displayErrorToast(
        ts.loadFailed(
          ts.tModule('product_details.product_status_bar.calculate_completeness_failed'),
        ),
      );
    } finally {
      store.dispatch('products/SET_LOADING_COMPLETENESS', false);
    }

    currentProduct.value.status = statuses.value.filter(
      (item) => item.label == ts.tGlobal('saved'),
    )[0];
    toastService.displaySuccessToast(ts.updateSuccess(currentProduct.value.name));
  } catch (ex) {
    toastService.displayErrorToast(ts.updateFailed(currentProduct.value.name));
  } finally {
    isSaving.value = false;
    productSaveModalOpen.value = false;
  }
};

const saveConfirm = async () => {
  if (currentProduct.value?.variant_name == null) {
    //If it's Master Data, check for the variants
    try {
      const variants = await store.dispatch(
        'products/GET_PRODUCT_VARIANTS',
        currentProduct.value.variant_uuid,
      );
      variants?.length > 1 //other variants except from the current product
        ? (productSaveModalActive.value = true)
        : saveProduct({ variants: [], attributes: [] });
    } catch (ex) {
      saveProduct({ variants: [], attributes: [] });
    }
  } else {
    saveProduct({ variants: [], attributes: [] });
  }
};

const resetProductHandler = (event: PointerEvent) => {
  confirmService.confirmDelete({
    event: event,
    callback: () => store.dispatch('products/RESET_PRODUCT'),
    group: 'revert-changes',
    message: ts.tModule('product_details.product_status_bar.reset_product'),
  });
};

onMounted(async () => {
  loadAsyncData();
});
</script>
<template>
  <div
    class="bg-white flex justify-content-between p-3"
    :style="{
      boxShadow: '0 -10px 10px -10px rgba(0, 0, 0, 0.12)',
    }"
  >
    <ProductSaveModal
      v-if="currentProduct && productSaveModalActive"
      :is-active="productSaveModalActive"
      :product-id="currentProduct?.id"
      @saved="saveProduct"
      @hide="productSaveModalVisible(false)"
    />
    <div class="align-items-center flex">
      <div
        v-for="(status, index) in statuses"
        :key="status?.id"
        class="align-items-center flex ml-1"
      >
        <span
          :style="{
            color: currentProduct.status?.id === status?.id ? '#ffffff' : '#0089d7',
            backgroundColor: currentProduct.status?.id === status?.id ? '#0089d7' : '#E0F0FF',
          }"
          class="align-items-center border-circle flex flex-shrink-0 h-2rem justify-content-center text-lg w-2rem"
          >{{ index + 1 }}</span
        >
        <p class="mx-2">{{ status.label }}</p>
        <p-divider v-if="index < statuses?.length - 1" class="mx-2" />
      </div>
    </div>
    <div class="align-items-center flex">
      <p-confirm-popup group="product-delete" />
      <p-confirm-dialog group="revert-changes"></p-confirm-dialog>

      <p v-if="currentProduct && (editingProductFields ?? []).length" class="text-gray-400">
        {{ ts.tModule('product_details.product_status_bar.unsaved_changes') }}
      </p>
      <p-button
        v-if="currentProduct && (editingProductFields ?? []).length"
        text
        severity="danger"
        label="Discard changes"
        icon="mdi mdi-delete-outline"
        :disabled="isLoading"
        class="ml-3 underline"
        @click="resetProductHandler"
      />

      <p-button
        v-if="currentProduct && (editingProductFields ?? []).length"
        :label="ts.tGlobal('save')"
        :disabled="isLoading || (store.getters['products/errorProductFields'] ?? []).length"
        :loading="isSaving"
        class="ml-3 p-button-success"
        @click="saveConfirm()"
      />

      <p-split-button
        v-if="currentProduct && !editingProductFields.length"
        :disabled="isLoading || (store.getters['products/errorProductFields'] ?? []).length > 0"
        class="p-button-success"
        :label="currentProduct.status && currentProduct.status.label"
        :model="splitButtonActions"
      />
    </div>
  </div>
</template>
