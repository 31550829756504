import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import {
  AssetType,
  AssetTypeRestService,
  CreateAssetTypeRequest,
  UpdateAssetTypeRequest,
} from 'platform-unit2-api/asset-types';

/**
 * Service for the asset type view.
 */
export class AssetTypeViewService extends BaseViewService<
  AssetTypeRestService,
  AssetType,
  CreateAssetTypeRequest,
  UpdateAssetTypeRequest
> {
  /**
   * @inheritdoc
   * Check if the current asset type is valid. Name is required.
   */
  public get validated(): boolean {
    if (this.current?.name) {
      return true;
    }

    return false;
  }

  /**
   * @inheritdoc
   * Create CreateAssetTypeRequest from current asset type.
   */
  public createBody(): CreateAssetTypeRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const body: CreateAssetTypeRequest = {
      name: this.current.name!,
    };

    return body;
  }

  /**
   * @inheritdoc
   * Create UpdateAssetTypeRequest from current asset type.
   */
  public updateBody(): UpdateAssetTypeRequest | undefined {
    if (this.current == null || !this.validated || this.current?.id == null) {
      return;
    }

    const body: UpdateAssetTypeRequest = {
      id: this.current.id!,
      name: this.current.name!,
    };

    return body;
  }

  /**
   * Get asset types, this.data is assigned to asset types for readabilty.
   */
  public get assetTypes(): AssetType[] {
    return this._data;
  }

  /**
   * @inheritdoc
   */
  constructor(ts: TranslationService) {
    super({
      Api: AssetTypeRestService,
      fetchAllFunction: 'getAll',
      ts: ts,
      overviewRouteName: 'asset-types',
      createRouteName: 'create-asset-type',
      updateRouteName: 'update-asset-type',
      confirmPopUpGroup: 'asset-types',
    });
  }
}
