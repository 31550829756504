<script setup lang="ts">
import { ref, onMounted } from 'vue';
import useFormValidation from 'composables/form-validation';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Datamodel, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { isNegative } from '@/general/utils/isNegative';

/** Emits */
const emits = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const route = useRoute();
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'datamodels');
const toastService = ToastService.getInstance();
const datamodelApi = new DatamodelsRestService();
/** Constants */
const loading = ref(false);
const saving = ref(false);
const editorDatamodel = ref<Datamodel>();

const handleSubmit = async (): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.duplicateFailed());
    return;
  }

  resetFormErrors();

  try {
    saving.value = true;

    editorDatamodel.value?.name &&
      (await datamodelApi.duplicateOdmDatamodel(Number(route.params.id), {
        name: editorDatamodel.value?.name,
      }));

    emits('refresh');
    emits('hide');

    toastService.displaySuccessToast(ts.duplicateSuccess());
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.duplicateFailed());
    });
  } finally {
    saving.value = false;
  }
};

/** Lifecycle */
onMounted(async () => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.loadFailed());
    return;
  }

  loading.value = true;

  editorDatamodel.value = await datamodelApi.get(Number(route.params.id));

  loading.value = false;
});
</script>
<template>
  <CrudSidebar
    :title="ts.moduleDuplicateTitle"
    :subtitle="ts.moduleDuplicateSubTitle"
    :saving="saving"
    @cancel="emits('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <div class="field mb-3">
        <label for="name">{{ ts.tGlobal('name') }}</label>
        <p-input-text
          v-if="editorDatamodel"
          id="name"
          v-model="editorDatamodel.name"
          class="w-full"
          :class="{ 'p-invalid': hasError('name') }"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
    </template>
  </CrudSidebar>
</template>
