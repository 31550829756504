<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';

/** Constants */
const props = defineProps<{
  modelValue?: string;
}>();

/** Emit */
const emit = defineEmits<{
  (e: 'update:modelValue', value?: string): void;
}>();

/** Constants */
const ts = new TranslationService('general', 'module');
const color = ref();
const toastService = ToastService.getInstance();

const copyColorCode = () => {
  navigator.clipboard.writeText(color.value).then(
    () => {
      toastService.displaySuccessToast(ts.tModule('colorPicker.copy_successful'));
    },
    () => {
      toastService.displayErrorToast(ts.tModule('colorPicker.copy_failed'));
    },
  );
};

/** Lifecycles */
onMounted(() => {
  color.value = props.modelValue;
});

//color picker is typed like the primevue file
</script>
<template>
  <span class="align-items-center flex">
    <p-color-picker
      id="color"
      v-model="color"
      format="hex"
      default-color="ffffff"
      @update:model-value="(value: any) => emit('update:modelValue', value)"
    />
    <div class="ml-2 p-inputgroup w-16rem">
      <p-input-text
        id="color"
        v-model="color"
        class="w-6"
        type="text"
        @update:model-value="(value: string|undefined) => emit('update:modelValue', value)"
      />
      <p-button
        class="p-button-outlined p-button-sm"
        icon="mdi mdi-content-copy"
        @click.stop.prevent="copyColorCode"
      />
    </div>
  </span>
</template>
