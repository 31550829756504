<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { CreateExport } from 'retailer/modules/products/products.types';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { RouterLink, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import cronstrue from 'cronstrue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product, ProductMinified } from 'platform-unit2-api/products';

/** Props */
interface Props {
  formData: CreateExport;
}
const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (
    e: 'complete',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

/** Consts */
const toastService = ToastService.getInstance();

const ts = new TranslationService('retailer', 'products');
const tsAttribute = new TranslationService('retailer', 'attributes');

const store = useStore();
const router = useRouter();
const allowed = ref([]);
const denied = ref([]);
const loading = ref(true);
const loadingButton = ref(false);

const complete = () => {
  loadingButton.value = true;
  emit('complete', {
    pageIndex: 4,
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 4,
  });
};

const deniedIds = computed(() => {
  return denied.value.map((item: Product) => item.id);
});

const cronTranslate = computed(() => {
  return cronstrue.toString(props.formData.exportCron);
});

const loadAsyncData = async () => {
  loading.value = true;

  const result = await store.dispatch(
    'products/GET_ALLOWED_TO_PUBLISH',
    props.formData?.product_ids,
  );

  allowed.value = result['allowed'];
  denied.value = result['denied'];

  loading.value = false;
};

const refresh = () => {
  loadAsyncData();
};

const publishProductHandler = async (id: number) => {
  try {
    await store.dispatch('products/PUBLISH_PRODUCT_ID', id);
    toastService.displaySuccessToast(ts.tModule('export_modal.publishing_success'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('export_modal.publishing_failed'));
  } finally {
    refresh();
  }
};

onMounted(async () => {
  setTimeout(async () => {
    loadAsyncData();
  }, 500);
});
</script>

<template>
  <LoadingIndicator v-if="loading" />
  <div v-else>
    <div class="mt-5 p-1">
      <div class="grid mb-5 px-4">
        <div class="bg-blue-50 col">
          <div class="align-items-center flex justify-content-between mb-1">
            <label class="flex font-bold">{{ ts.tGlobal('channel') }}</label>
            <p-button
              plain
              text
              :label="ts.tGlobal('edit')"
              class="h-2rem ml-2"
              icon="mdi mdi-pencil-outline"
              icon-pos="left"
              @click="router.push('channel')"
            />
          </div>

          <div class="align-items-center flex justify-content-between mb-2">
            <label class="mr-2 underline">{{ ts.tGlobal('languages') }}: </label>
            <div class="flex ml-1">
              <span :class="'mr-1 fi fi-' + formData?.selectedLocale.format + ' fis'" />
              <p>{{ formData?.selectedLocale.language }}</p>
            </div>
          </div>

          <div class="align-items-center flex justify-content-between mb-2">
            <label class="mr-2 underline">{{ ts.tGlobal('channel') }}:</label>
            <p>{{ formData?.selectedModule.name }}</p>
          </div>
        </div>

        <p-divider class="hidden md:flex" layout="vertical"></p-divider>

        <div class="bg-blue-50 col">
          <div class="align-items-center flex justify-content-between mb-1">
            <label class="flex font-bold">{{ ts.tGlobal('settings') }}</label>
            <p-button
              plain
              text
              :label="ts.tGlobal('edit')"
              class="h-2rem ml-2"
              icon="mdi mdi-pencil-outline"
              icon-pos="left"
              @click="router.push('settings')"
            />
          </div>

          <div>
            <div class="align-items-center flex justify-content-between mb-1">
              <label class="mr-2 underline"
                >{{ ts.tModule(`export_modal.overview.selected_sources`) }}:</label
              >
              <p>
                <template v-if="formData.includeAssets">{{
                  ts.tGlobal('assets', { choice: 2 })
                }}</template>
                <template v-if="formData.includeData && formData.includeAssets">, </template>
                <template v-if="formData.includeData">{{ tsAttribute.titlePlural }}</template>
              </p>
            </div>
            <div class="flex justify-content-between mb-1">
              <label class="mr-2 underline"
                >{{ ts.tModule('export_modal.overview.scheduled') }}:</label
              >
              <label>{{ formData.isScheduled ? ts.tGlobal('yes') : ts.tGlobal('no') }}</label>
            </div>
          </div>
        </div>

        <p-divider class="hidden md:flex" layout="vertical"></p-divider>

        <div class="bg-blue-50 col">
          <div class="align-items-center flex justify-content-between mb-1">
            <label class="flex font-bold">{{ ts.tModule('export_modal.contact.title') }}:</label>
            <p-button
              plain
              text
              :label="ts.tGlobal('edit')"
              class="h-2rem ml-2"
              icon="mdi mdi-pencil-outline"
              icon-pos="left"
              @click="router.push('contact')"
            />
          </div>
          <div style="max-width: 30rem">
            <div class="flex mb-1">
              <label class="mr-2 underline"
                >{{ ts.tModule('export_modal.contact.email_to') }}:
              </label>
              <div v-for="(receiver, index) in formData.mailTo" :key="index">
                <label> {{ receiver }} , </label>
              </div>
            </div>
            <div>
              <label class="underline">{{ ts.tModule('export_modal.contact.message') }}: </label>
              <p>{{ formData.customMessage }}</p>
            </div>
          </div>
        </div>

        <p-divider v-if="formData.isScheduled" class="hidden md:flex" layout="vertical"></p-divider>

        <div v-if="formData.isScheduled" class="bg-blue-50 col">
          <div class="align-items-center flex justify-content-between mb-1">
            <label class="flex font-bold">{{ ts.tModule('export_modal.schedule.title') }}:</label>
            <p-button
              v-if="formData.isScheduled"
              plain
              text
              :label="ts.tGlobal('edit')"
              class="h-2rem ml-2"
              icon="mdi mdi-pencil-outline"
              icon-pos="left"
              @click="router.push('schedule')"
            />
          </div>
          <div v-if="formData.isScheduled" style="max-width: 30rem">
            <div class="flex mb-1">
              <label class="mr-2 underline">{{ ts.tGlobal('name') }}: </label>
              <label>{{ formData.exportName }}</label>
            </div>
            <div>
              <label class="underline">{{ ts.tGlobal('time') }}: </label>
              <label>{{ cronTranslate }}</label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="px-4">
        <div class="align-items-center flex flex-column justify-content-center">
          <i class="mdi mdi-loading pi-spin text-6xl text-primary"></i>
          <p class="text-base">{{ ts.tGlobal('loading') }}</p>
        </div>
      </div>

      <div v-else class="px-4">
        <div class="flex flex-row">
          <div class="col-6 flex flex-column mr-2 pl-0">
            <label class="align-items-center flex font-bold mb-2" for="allowed"
              >{{ ts.tModule(`export_modal.overview.included_products`) }}
              <i
                v-tooltip.bottom="{
                  value: ts.tModule(`export_modal.overview.included_info`),
                  disabled: false,
                  class: 'text-sm',
                }"
                class="mdi mdi-information-outline ml-2 text-2xl"
              />
            </label>

            <pListbox id="allowed" class="pt-0" :options="allowed" list-style="height:200px">
              <template #option="{ option }: { option: ProductMinified }">
                <div>
                  {{ option.title }}
                  <pBadge
                    v-if="option.variant_name != null"
                    :value="option.variant_name"
                    class="pup-ml-2"
                  />
                </div>
              </template>
            </pListbox>
          </div>

          <div class="col-6 flex flex-column">
            <div class="flex justify-content-between">
              <label class="align-items-center flex font-bold mb-2" for="denied"
                >{{
                  ts.tModule('export_modal.overview.excluded_products', {
                    params: {
                      count: denied.length,
                    },
                  })
                }}
                <i
                  v-tooltip.bottom="{
                    value: ts.tModule('export_modal.overview.excluded_info'),
                    disabled: false,
                    class: 'text-sm',
                  }"
                  class="mdi mdi-information-outline ml-2 text-2xl"
                />
              </label>
              <div
                class="align-items-center cursor-pointer flex justify-content-center"
                @click="refresh"
              >
                <span class="mr-1">{{ ts.tGlobal('refresh') }}</span>
                <i class="mdi mdi-refresh text-xl"></i>
              </div>
            </div>
            <pListbox
              id="denied"
              class="mt-auto"
              :options="denied"
              list-style="height:200px"
              option-label="title"
            >
              <template #option="{ option }: { option: ProductMinified }">
                <div class="align-items-center flex w-full">
                  <router-link
                    target="_blank"
                    class="text-800 w-full"
                    :to="{
                      name: `product-details`,
                      params: {
                        id: option.id,
                      },
                    }"
                  >
                    <div>
                      {{ option.title }}
                      <pBadge
                        v-if="option.variant_name != null"
                        :value="option.variant_name"
                        class="pup-ml-2"
                      />
                    </div>
                  </router-link>

                  <p-button
                    :label="ts.tGlobal('publish')"
                    class="align-items-center flex justify-content-center p-button-sm"
                    style="height: 1.5rem"
                    @click="publishProductHandler(option.id)"
                  />
                </div>
              </template>
            </pListbox>

            <router-link
              v-if="deniedIds.length > 1"
              target="_blank"
              class="hover:text-primary ml-1 mt-1 text-800 w-7rem"
              :to="{
                name: 'products',
                query: { product_ids: JSON.stringify(deniedIds) },
              }"
            >
              {{ ts.tModule(`export_modal.overview.go_to_products`) }}
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="flex flex-row justify-content-between px-4 py-4"
        style="background-color: #f5f7fa"
      >
        <div>
          <p-button
            :label="ts.tGlobal('cancel')"
            plain
            text
            class="mr-2"
            @click="emit('cancel-export')"
          />
        </div>
        <div>
          <p-button
            :label="ts.tGlobal('previous')"
            severity="secondary"
            class="mr-2"
            icon="mdi mdi-chevron-left"
            icon-pos="left"
            @click="previousPage()"
          />

          <p-button
            :label="ts.tGlobal('start')"
            :loading="loadingButton"
            :disabled="loadingButton"
            icon-pos="right"
            @click="complete()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-chips :deep(.p-chips-multiple-container) {
  width: 100%;
}
</style>
