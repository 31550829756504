<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { ref } from 'vue';

interface Props {
  defaultUnit?: string;
  values?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  defaultUnit: undefined,
  values: () => [],
});

const emit = defineEmits<{
  (e: 'update:default-unit', value: string): void;
  (e: 'update:values', value: string[]): void;
}>();

const ts = new TranslationService('supplier', 'datamodels');

const options = ref<string[]>(props.values);
</script>

<template>
  <div class="field mb-3">
    <label class="font-bold text-sm">{{
      ts.tModule('datamodels_overwrite.form.input_select_values')
    }}</label>
    <p-chips
      :model-value="values"
      style="width: 100%"
      separator=","
      @update:model-value="(selectedValues: string[]) => {
        options = selectedValues;
        emit('update:values', options);
      }"
    />

    <div v-if="values" class="flex flex-column mt-4">
      <label class="font-bold text-sm">{{
        ts.tModule('datamodels_overwrite.form.choose_default_unit')
      }}</label>
      <pDropdown
        :model-value="defaultUnit"
        class="mt-2"
        :options="options"
        @update:model-value="(unit: string) => emit('update:default-unit', unit)"
      />
    </div>
  </div>
</template>
