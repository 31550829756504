<script setup lang="ts">
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import useAttributeOption from 'utils/attribute-option';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute, AttributeOptions } from 'platform-unit2-api/attributes';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  moduleId?: number;
  options?: AttributeOptions;
  attribute?: Attribute;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  moduleId: undefined,
  options: undefined,
  attribute: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const { getFieldDefinitionOption } = useAttributeOption();
const unit = ref();
const number = ref();
const errorMessage = ref();

const matchValuesSeperately = () => {
  if (props.modelValue != null) {
    unit.value = props.modelValue.split(' ')[1];
    number.value = props.modelValue.split(' ')[0];
  }
};

const selectValues = computed(() => {
  return getFieldDefinitionOption(props.options, 'selectValues', props.moduleId) || [];
});

const defaultUnit = computed(() => {
  return getFieldDefinitionOption(props.options, 'default_unit', props.moduleId) || '';
});

const getRegexMatch = (value: string) => {
  //this regular exppresion checks if value does not contain any letters and allows comma's and dots.
  return value.match(/^[0-9]*([,.][0-9]*)?$/);
};

const setDefaultValue = () => {
  if (defaultUnit.value) {
    if (!selectValues.value.includes(defaultUnit.value)) selectValues.value.push(defaultUnit.value);
  }

  if (props.modelValue == null) {
    unit.value = selectValues.value[0];
  }
};

const validateData = () => {
  const errorMessages: string[] = [];

  if (
    number.value == null ||
    (number.value === '' && !props.attribute?.required && !props.attribute?.options?.required)
  ) {
    emit('error', errorMessages);
    return;
  }

  if (number.value != null && !getRegexMatch(number.value)) {
    errorMessages.push(
      ts.tModule('financialField.invalidNumber', {
        params: {
          amount: number.value,
        },
      }),
    );
  }

  if (selectValues.value.indexOf(unit.value) === -1) {
    errorMessages.push(
      ts.tModule('financialField.invalidUnit', {
        params: {
          currency: selectValues.value,
        },
      }),
    );
  }

  emit('error', errorMessages);
};

const emitValue = () => {
  validateData();

  const value = number.value + ' ' + unit.value;

  emit('update:modelValue', value);
};

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue === '' || props.modelValue == null) {
      number.value = '';
      unit.value = selectValues.value[0];
      return;
    }
  },
);

watchEffect(() => {
  if (props.modelValue) {
    setDefaultValue();
    matchValuesSeperately();
  }
});

onMounted(() => {
  setDefaultValue();
  matchValuesSeperately();
});
</script>
<template>
  <p-input-text v-model="number" :disabled="disabled" @update:model-value="emitValue()" />
  <span class="addon-suffix p-0 p-inputgroup-addon" :class="errorMessage ? 'border-pink-400' : ''">
    <p-dropdown
      v-model="unit"
      class="bg-white border-none min-w-min"
      :options="selectValues"
      :disabled="disabled"
      @change="emitValue()"
    />
  </span>
</template>

<style lang="scss" scoped>
.p-inputgroup :deep(.p-inputgroup-addon.addon-suffix) {
  border: 0;
}

.p-inputgroup :deep(.p-inputgroup-addon.addon-suffix .p-dropdown) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #e9ecef;
}

.p-inputgroup :deep(.p-inputtext) {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
