import { Role, UpdateRole, RolesRestService } from 'platform-unit2-api/roles';
import { ActionTree } from 'vuex';

const rolesApi = new RolesRestService();

export const actions: ActionTree<{}, {}> = {
  async UPDATE_ROLE({ dispatch, rootGetters }, updateRole: UpdateRole): Promise<Role> {
    const role = (await rolesApi.update(updateRole.id, updateRole)) as Role;

    // Permissions of current user might have changed, if so get new permissions
    const currentUser = rootGetters['users/currentUser'];
    if (currentUser.roles.includes(updateRole.name)) {
      dispatch('users/GET_CURRENT_USER_DETAILS', null, { root: true });
    }

    return role;
  },
};
