<script setup lang="ts">
import { useStore } from 'vuex';
import { inject, computed } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ProductAttributeViewService } from '../../services/product-attribute-view.service';
import { Datamodel } from 'platform-unit2-api/datamodels';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';

/** Props */
export interface Props {
  selectedDatamodel?: Datamodel;
}

defineProps<Props>();

/** Constants */
const store = useStore();

/** Services */
const ts = new TranslationService('supplier', 'products');
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;
const productAttributeViewService = new ProductAttributeViewService();

const showMissingFields = computed(() => {
  return store.getters['products/showMissingFields'];
});
</script>

<template>
  <!-- When there is no export mapping for the channel -->
  <EmptyState
    v-if="
      selectedDatamodel &&
      selectedDatamodel.attributes.length === 0 &&
      productAttributeFieldService.searchQuery == null &&
      !productAttributeFieldService.loading &&
      productAttributeFieldService.currentProduct?.module_id != null
    "
    :translation-service="ts"
    :icon-name="'channels'"
    :empty-state-title="ts.tModule('product_attributes.no_export_mappings_title')"
    :empty-state-subtitle="ts.tModule('product_attributes.no_export_mappings_subtitle')"
    :button-label="ts.tGlobal('contactSales')"
    :button-icon="'mdi mdi-send'"
    @clicked="productAttributeViewService.contactSales()"
  />

  <!-- When there are no attributes attached to the datamodel -->
  <EmptyState
    v-else-if="
      selectedDatamodel &&
      selectedDatamodel.attributes.length === 0 &&
      productAttributeFieldService.searchQuery == null &&
      !productAttributeFieldService.loading &&
      productAttributeFieldService.currentProduct?.module_id == null
    "
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('product_attributes.no_attributes_attached_title')"
    :empty-state-subtitle="ts.tModule('product_attributes.no_attributes_attached')"
    :button-visible="false"
  />

  <!-- When the empty fields mode returns no fields to fill in -->
  <EmptyState
    v-else-if="
      showMissingFields &&
      !productAttributeFieldService.hasEmptyFields &&
      !productAttributeFieldService.loading &&
      productAttributeFieldService.emptyFields.length === 0
    "
    :empty-state-subtitle="''"
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('product_attributes.no_empty_fields')"
    :button-visible="false"
  />

  <!-- When there are are attributes attached to the datamodel and the user is searching -->
  <EmptyState
    v-else-if="
      productAttributeFieldService.attributeFields.length === 0 &&
      productAttributeFieldService.searchQuery != null &&
      !productAttributeFieldService.loading
    "
    :empty-state-subtitle="''"
    :translation-service="ts"
    :icon-name="'master-data'"
    :empty-state-title="ts.tModule('no_fields_found')"
    :button-visible="false"
  />
</template>
