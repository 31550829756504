<script setup lang="ts">
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;
</script>

<template>
  <pMessage
    v-if="productTableService.missingGtins.length"
    class="w-full"
    severity="warn"
    :closable="true"
    ><div class="mb-1">
      <b>
        {{
          productTableService.ts.tModule('undifined_gtins', {
            params: { amount: productTableService.missingGtins.length },
          })
        }}</b
      >
    </div>
    <ul class="overflow-y-auto w-full" style="max-height: 10rem">
      <li v-for="(gtin, index) in productTableService.missingGtins" :key="gtin">
        {{ gtin
        }}{{
          productTableService.missingGtins.length > 1 &&
          productTableService.missingGtins.length !== index + 1
            ? ','
            : ''
        }}
      </li>
    </ul>
  </pMessage>
</template>
