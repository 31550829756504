import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import {
  BrandsRestService,
  Brand,
  CreateBrandRequest,
  UpdateBrandRequest,
} from 'platform-unit2-api/brands';

/**
 * Service for the Brand type view.
 */
export class BrandViewService extends BaseViewService<
  BrandsRestService,
  Brand,
  CreateBrandRequest,
  UpdateBrandRequest
> {
  public uploading = false;

  public get isSearchResultEmpty() {
    return this.brands.length === 0 && this._fetchVariables.query !== '';
  }

  public get hasNoBrands() {
    return this.brands.length === 0 && this.query === '';
  }

  /**
   * @inheritdoc
   * Check if the current brand is valid. Name is required.
   */
  public get validated(): boolean {
    if (this.uploading) {
      return false;
    }

    if (!this.current?.name) {
      return false;
    }

    if (this.current.name.length < 1) {
      return false;
    }

    return true;
  }

  /**
   * @inheritdoc
   * Create CreateBrandRequest from current brand.
   */
  public createBody(): CreateBrandRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const body: CreateBrandRequest = {
      name: this.current.name!,
      description: this.current.description,
      thumbnail_id: this.current.thumbnail?.id,
      owner_id: this.current.owner_id,
    };

    return body;
  }

  /**
   * @inheritdoc
   * Create UpdateBrandRequest from current brand.
   */
  public updateBody(): UpdateBrandRequest | undefined {
    if (this.current == null || !this.validated || this.current?.id == null) {
      return;
    }

    const body: UpdateBrandRequest = {
      id: this.current.id!,
      name: this.current.name!,
      description: this.current.description!,
      thumbnail_id: this.current.thumbnail?.id ?? null,
      owner_id: this.current.owner_id,
    };

    return body;
  }

  /**
   * Get brands, this.data is assigned to brands for readabilty.
   */
  public get brands(): Brand[] {
    return this._data;
  }

  /**
   * @inheritdoc
      title: 'Productsup Platform - Brands',
   */
  constructor(ts: TranslationService) {
    super({
      Api: BrandsRestService,
      fetchAllFunction: 'getAll',
      ts: ts,
      overviewRouteName: 'brands',
      createRouteName: 'new-brand',
      updateRouteName: 'edit-brand',
      confirmPopUpGroup: 'brands',
    });
  }
}
