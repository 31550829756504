<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { LocaleDetails } from '../../../locales/locales.types';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  locale?: LocaleDetails;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  locale: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const inputNumber = ref<number | null>();

const validateData = () => {
  if (inputNumber.value) {
    if (isNaN(parseFloat(inputNumber.value.toString()))) {
      emit('error', [
        ts.tModule('numberField.notNumeric', { params: { value: inputNumber.value } }),
      ]);
    }
  }
};

const valueUpdated = () => {
  if (inputNumber.value) {
    emit('update:modelValue', inputNumber.value.toString());
  } else {
    emit('update:modelValue', '');
  }

  validateData();
};

onMounted(() => {
  if (props.modelValue) {
    inputNumber.value = parseFloat(props.modelValue);
  }

  validateData();
});

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      inputNumber.value = parseFloat(props.modelValue);
    } else {
      inputNumber.value = null;
    }
  },
);
</script>
<template>
  <p-input-number
    v-model="inputNumber"
    show-buttons
    :step="1"
    :use-grouping="false"
    class="border-right-none w-full"
    :placeholder="ts.tModule('numberField.placeholder')"
    :disabled="disabled"
    @update:model-value="valueUpdated"
    @input="valueUpdated"
  />
</template>
