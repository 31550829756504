<script setup lang="ts">
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import { onMounted, ref } from 'vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

/** Props */
interface Props {
  isActive: boolean;
  locales: LocaleDetails[];
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  locales: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'update:locales', locales: LocaleDetails[]): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const selectedLocales = ref<LocaleDetails[]>([]);

const addLocale = () => {
  emit('update:locales', selectedLocales.value);
  emit('hide');
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const saveButton = new CreateButton({
  label: ts.tGlobal('save'),
  position: CrudButtonPosition.RIGHT,
  onClick: addLocale,
  isEnabled: () => selectedLocales.value.length > 0,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, saveButton],
});

onMounted(() => {
  if (props.locales) {
    selectedLocales.value = props.locales;
  }
});
</script>
<template>
  <BaseDialog
    :visible="isActive"
    :title="ts.tModule('add_language')"
    size="small"
    :buttons-options="buttonsOptions"
    @update:visible="emit('hide')"
  >
    <div class="align-items-center field mb-3">
      <div class="field">
        <label>{{ ts.tGlobal('languages', { choice: 2 }) }}</label>
        <LocaleSelect v-model="selectedLocales" multiselect hide-label />
      </div>
    </div>
  </BaseDialog>
</template>
