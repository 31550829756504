import { createApp } from 'vue';
import App from '@/core/app/App.vue';
import store from '@/core/store';
import VueGtag from 'vue-gtag';
import i18n from '@/core/i18n';

import '/node_modules/flag-icons/css/flag-icons.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'prismjs/themes/prism.min.css';

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import { installPrimeVue } from './core/main/primeVue.install';
import { installSentry } from './core/main/sentry.install';
import { installFeatureFlag } from './core/main/featureFlagVue.install';
import { installRouter } from './core/main/router.install';
import { RouterService } from './core/router/router.service';
import vAutofocus from './general/utils/directives/v-autofocus';
import vClickOutside from './general/utils/directives/v-click-outside';
import { KeycloakService } from '@/core/security/keycloak.service';
import { registerWarnHandler } from './core/main/registerWarnHandler';
import vOnFocus from './general/utils/directives/v-on-focus';
import { DebugLogger } from './core/main/debug.logger';

const app = createApp(App);

app.config.globalProperties.window = window;

app.use(i18n);
app.use(store);

app.directive('click-outside', vClickOutside);
app.directive('autofocus', vAutofocus);
app.directive('on-focus', vOnFocus);

app.use(VueGtag, {
  config: { id: 'GTM-TTV939G' },
  enabled: true,
});

app.use(ConfirmationService);
app.use(ToastService);

const initializeApp = async () => {
  installFeatureFlag(app);
  registerWarnHandler(app);
  installPrimeVue(app);
  installRouter(app);

  const router = RouterService.getInstance().router;
  installSentry(app, router);

  await DebugLogger.init();

  await KeycloakService.getInstance().resolveDebug();

  DebugLogger.info('Application version: ', import.meta.env.VITE_APP_VERSION);

  router.isReady().then(() => {
    app.mount('#app');
  });
};

// Initialize authentication service (keycloak)
await KeycloakService.getInstance().init(initializeApp);
