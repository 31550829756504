<script setup lang="ts">
import { computed, inject, onMounted, ref, watch, watchEffect } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Attribute } from 'platform-unit2-api/attributes';
import { ProductDatamodelSelectModel } from '../ts/products.types';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ProductDatamodelSelectV2 from './product-datamodel-selectV2.vue';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';

const props = withDefaults(
  defineProps<{
    datamodels: ProductDatamodelSelectModel[];
    selectedDatamodel?: ProductDatamodelSelectModel;
    fields: ProductField[];
    loading: boolean;
  }>(),
  {
    datamodels: () => [],
    selectedDatamodel: undefined,
    fields: () => [],
    loading: true,
  },
);

const emit = defineEmits<{
  (e: 'setSelectedDatamodel', datamodel: Datamodel): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');
const tsAttributes = new TranslationService('retailer', 'attributes');
const productAttributeFieldService = inject(productAttributeFieldsServiceKey);

const router = useRouter();
const store = useStore();

//** Constants */
const foundDatamodels = ref<ProductDatamodelSelectModel[]>([]);
const selectedDatamodelToShow = ref<Datamodel>();

const selectDatamodel = (datamodel: Datamodel): void => {
  emit('setSelectedDatamodel', datamodel);
};

const currentProduct = computed(() => {
  return (
    store.getters['products/currentProduct'] ??
    store.dispatch('products/GET_PRODUCT_DETAILS', router.currentRoute.value.params.id)
  );
});

watch(
  () => productAttributeFieldService?.value.searchQuery,
  () => {
    if (!productAttributeFieldService?.value.searchQuery) {
      foundDatamodels.value = props.datamodels;
    } else {
      foundDatamodels.value = [];
      props.datamodels.forEach((datamodel) => {
        const foundAttributeKeys: Attribute[] = datamodel.attributes.filter((attribute) =>
          attribute.key
            .trim()
            .toLocaleLowerCase()
            .includes(
              productAttributeFieldService?.value.searchQuery ?? ''.trim().toLocaleLowerCase(),
            ),
        );
        foundAttributeKeys.length !== 0 && foundDatamodels.value.push(datamodel);
      });
    }
  },
);

watchEffect(() => (foundDatamodels.value = props.datamodels));

watchEffect(() => (selectedDatamodelToShow.value = props.selectedDatamodel));

watch(
  () => foundDatamodels.value,
  () => {
    foundDatamodels.value.length &&
      productAttributeFieldService?.value.searchQuery &&
      selectDatamodel(foundDatamodels.value[0]);
  },
);

onMounted(() => {
  foundDatamodels.value = props.datamodels;
  selectedDatamodelToShow.value = props.selectedDatamodel;
});
</script>
<template>
  <div class="col-fixed h-full overflow-y-auto w-19rem" style="padding-top: 0 !important">
    <div class="bg-gray-50 pt-3 sticky top-0 z-1">
      <div class="p-3">
        <h4 class="mb-3">{{ ts.tModule('product_details.sidebar_navigation.title') }}:</h4>
        <ul class="list-style-none text-lg">
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-blue-300 mb-2 py-1 text-bluegray-500"
            :class="[$route.name === 'product-attributes' && 'text-primary']"
            :to="{ name: 'product-attributes' }"
          >
            <i class="mdi mdi-format-list-bulleted mr-2"></i>
            <span class="2xl text-xl">{{ tsAttributes.tModule('title', { choice: 2 }) }}</span>
          </router-link>
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-blue-300 mb-2 py-1 text-bluegray-500"
            :class="[$route.name === 'product-assets' && 'text-primary']"
            :to="{ name: 'product-assets' }"
          >
            <i class="mdi mdi-image mr-2"></i>
            <span class="2xl text-xl">{{ ts.tGlobal('assets', { choice: 2 }) }}</span>
          </router-link>
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-blue-300 mb-2 py-1 text-bluegray-500"
            :class="[$route.name === 'product-story' && 'text-primary']"
            :to="{ name: 'product-story' }"
          >
            <i class="mdi mdi-format-size mr-2 text-2xl"></i>
            <span class="text-xl">{{ ts.tModule('story') }}</span>
          </router-link>
          <!-- Category -->

          <router-link
            v-if="currentProduct.module_id"
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-blue-300 mb-2 py-1 text-bluegray-500"
            :class="[$route.name === 'product-category' && 'text-primary']"
            :to="{ name: 'product-category' }"
          >
            <i class="mdi mdi-shape mr-2 text-2xl"></i>
            <span class="text-xl">{{ ts.tGlobal('category', { params: { count: 2 } }) }}</span>
          </router-link>
        </ul>
      </div>
      <div v-if="$route.name === 'product-attributes'" class="p-3 pb-0">
        <!-- is done to showcase how many data models are connected
        previously it was dm + all atributes button now its only dm's -->
        <h4
          v-if="foundDatamodels.length - 1 > 0"
          v-badge="foundDatamodels.length - 1"
          class="inline-block mb-3"
        >
          {{ ts.tModule('product_details.sidebar_navigation.attribute_groups') }}:
        </h4>
        <h4 v-else class="inline-block mb-3">
          {{ ts.tModule('product_details.sidebar_navigation.attribute_groups') }}:
        </h4>
        <!-- Search input -->
        <pIconField icon-position="left">
          <pInputIcon class="pi pi-search" />
          <pInputText
            v-model="productAttributeFieldService!.searchQuery"
            :placeholder="ts.tModule('attribute_search_placeholder')"
            :disabled="loading"
            class="w-full"
          />
        </pIconField>
      </div>
    </div>

    <div v-if="$route.name === 'product-attributes'" class="p-3 sticky top-auto">
      <!-- DATAMODELS SELECTORS -->
      <span v-if="loading">
        <p-skeleton class="mb-5" width="100%" height="8rem" border-radius="10px" />
        <p-skeleton class="mb-5" width="100%" height="8rem" border-radius="10px" />
        <p-skeleton class="mb-5" width="100%" height="8rem" border-radius="10px" />
      </span>
      <template v-for="(datamodel, index) in foundDatamodels" v-else :key="datamodel.id">
        <ProductDatamodelSelectV2
          :datamodel="datamodel"
          :is-selected="selectedDatamodelToShow && selectedDatamodelToShow.id === datamodel.id"
          @click="selectDatamodel(datamodel)"
        />
        <p-divider v-if="index == 0" class="border-1 border-white" />
      </template>
    </div>
  </div>
</template>
