<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import apiImage from '@/assets/images/setting-developers/api.svg';

/* Emits */
defineEmits<(e: 'generate-new-key') => void>();

/* Services */
const ts = new TranslationService('supplier', 'settings');
</script>

<template>
  <div class="align-items-center flex flex-column gap-5 pt-6">
    <p-image :src="apiImage" alt="API"></p-image>
    <h3>{{ ts.tModule('developers.no_key_title') }}</h3>
    <p class="text-500">
      {{ ts.tModule('developers.no_key_description') }}
    </p>
    <p-button
      icon="mdi mdi-plus"
      class="p-button-rounded"
      :label="ts.tModule('developers.generate_btn')"
      @click="$emit('generate-new-key')"
    />
  </div>
</template>
<style lang="scss" scoped></style>
