import {
  PipelineStatusLevel,
  PipelineStatusColor,
  Pipeline,
  PipelinesRestService,
} from 'platform-unit2-api/pipelines';
import { Enumerated } from 'platform-unit2-api/core';
import { FilterMatchMode } from 'primevue/api';
import { RouteLocationRaw, RouteParamsRaw, useRouter } from 'vue-router';
import { DataTableFilterEvent } from 'primevue/datatable';
import { ServiceExportFilter } from 'retailer/modules/export/ts/interfaces/service-export-filter.interface';
import { ChannelFilter } from 'retailer/modules/export/ts/interfaces/channel-filter.interface';
import { PipelineMessageType } from 'retailer/modules/export/ts/enums/pipeline-messages.enum';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

export default class ExportService {
  private toastService = ToastService.getInstance();
  private ts = new TranslationService('supplier', 'exports');
  private pipelineApi = new PipelinesRestService();
  private router = useRouter();

  /**
   * Converts string to uppercase
   * @param name string representing value that we want to format
   */
  formatName = (name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  /**
   * Converts non-formatted API response values to UI friendly labels
   * @param statusList object containing all available statues from API
   */
  formatStatus = (statusList: any) => {
    Object.entries(statusList).find(([key, value]) => {
      statusList[key] = this.formatName((value as string).toLowerCase().replace('_', ' '));
    });
  };

  /**
   * Formatting a specific status
   * @param statusId targeted status id
   */
  processStatus = (statusId?: number) => {
    if (statusId) {
      switch (statusId) {
        case 1:
          return PipelineStatusColor.SUCCESS;
        case 2:
          return PipelineStatusColor.PARTIAL_SUCCESS;
        case 3:
          return PipelineStatusColor.FAILED;
        case 4:
          return PipelineStatusColor.PROCESSING;
        case 5:
          return PipelineStatusColor.PENDING;
        default:
          return '';
      }
    }

    return '';
  };

  /**
   * Returns the ID of the current value used for filtering
   * @param object Project containing all available GTINS from API
   * @param compareValue gtin used to match
   */
  getIds = (object: any, compareValue: string) => {
    Object.entries(object.value).forEach(([key, value]) => {
      if (value === compareValue) {
        compareValue = key;
      }
    });

    return compareValue;
  };

  /**
   * Method used to get ID of the pipeline status
   * @param status
   */
  getStatusId = (status: string) => {
    switch (status) {
      case 'Success':
        return PipelineStatusLevel.SUCCESS;
      case 'Partial success':
        return PipelineStatusLevel.PARTIAL_SUCCESS;
      case 'Failed':
        return PipelineStatusLevel.FAILED;
      case 'Processing':
        return PipelineStatusLevel.PROCESSING;
      case 'Pending':
        return PipelineStatusLevel.PENDING;
      default:
        return PipelineStatusLevel.SUCCESS;
    }
  };

  /**
   * Method used to copy text to clipboard
   * Note: Using deprecated document.execCommand since clipboardApi is not fully supported in all browsers
   * @param text of type string
   */
  copyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.textContent = text;
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');
  };

  /**
   * Method used to restart a pipeline
   * @param id
   */
  restartExport = (id: number | undefined) => {
    try {
      this.router.push({
        name: 'restart-export',
        params: {
          id: id,
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    } catch (error) {
      this.toastService.displayErrorToast(this.ts.tModule('tooltips.missingPipelineId'));
    }
  };

  /**
   * Method used to fetch all pipeline messages
   * @param payload
   */
  getPipelineMessages = async (payload: any) => {
    return await this.pipelineApi.getPipelineMessages(
      payload.page,
      payload.pipelineId,
      payload.filterParam,
    );
  };

  /**
   * Method used to reset filters to empty selection on mount and after pressing clear button in filters menu
   * @param filterList Array of filter field names to reset
   */
  resetFilters = (filterList?: string[]) => {
    const filters = new Map();
    filterList?.forEach((filter) => {
      filters.set(filter, { value: [], matchMode: FilterMatchMode.EQUALS });
    });
    return Object.fromEntries(filters);
  };

  /**
   * Method used to filter pipelines by status, channel and username
   * Returns an object containing individual filters
   * and a complete filter string used to filter pipelines
   * @param reset
   * @param selectedItems
   * @param tableFilters
   * @param filterObject
   * @param usernameOptionsRaw
   * @param event
   */
  applyFilter = (
    reset: boolean | undefined,
    tableFilters: DataTableFilterEvent | undefined,
    filterObject: ServiceExportFilter | undefined,
    usernameOptionsRaw: Enumerated | unknown,
    selectedItems?: ChannelFilter[] | undefined,
    event?: DataTableFilterEvent,
  ) => {
    if (filterObject) {
      let resetFilter = true;
      // Mapping selected channel IDs
      const moduleIDs = selectedItems?.map(({ id }) => id);
      // Setting object values to empty
      for (const key in filterObject) {
        filterObject[key] = '';
      }

      // Setting table filters to datatableFilerEvent if triggered
      // Will skip if the custom channel filter is used
      if (event) {
        tableFilters = event;
      }

      if (tableFilters) {
        Object.entries(tableFilters.filters).forEach(([key, value]) => {
          Object.entries(value).forEach(([k, v]) => {
            if (v && v.length > 0 && k === 'value') {
              resetFilter = false;
              if (key === 'status') {
                v = this.getStatusId(v);
                filterObject.filterStatus = 'filter[' + key + ']=' + v;
              }

              if (key === 'userName') {
                v = this.getIds(usernameOptionsRaw, v);
                filterObject.filterUsername = 'filter[user_id]=' + v;
              }

              if (key === 'gtin') {
                filterObject.filterGtin = 'filter[' + key + ']=' + v;
              }
            }
          });
        });
      }

      if (moduleIDs?.length) {
        filterObject.filterChannel = 'filter[module_ids]=' + moduleIDs;
      }

      if (reset || (resetFilter && !moduleIDs)) {
        Object.keys(filterObject).reduce((filter, key) => {
          filter[key as keyof typeof filter] = '';
          return filter;
        }, filterObject);
        this.resetFilters(['status', 'userName', 'gtin']);
        return filterObject;
      }

      if (filterObject.filterStatus) {
        filterObject.filterValue = filterObject.filterStatus;
      }

      if (filterObject.filterUsername) {
        if (filterObject.filterValue) {
          filterObject.filterUsername = '&' + filterObject.filterUsername;
        }

        filterObject.filterValue += filterObject.filterUsername;
      }

      if (filterObject.filterChannel) {
        if (filterObject.filterValue) {
          filterObject.filterChannel = '&' + filterObject.filterChannel;
        }

        filterObject.filterValue += filterObject.filterChannel;
      }

      if (filterObject.filterGtin) {
        if (filterObject.filterValue) {
          filterObject.filterGtin = '&' + filterObject.filterGtin;
        }

        filterObject.filterValue += filterObject.filterGtin;
      }

      return filterObject;
    }

    return undefined;
  };

  processMessageType = (messageType: number) => {
    switch (messageType) {
      case 1:
        return PipelineMessageType.UNKNOWN;
      case 2:
        return PipelineMessageType.MISSING_ATTRIBUTE;
      case 3:
        return PipelineMessageType.VALUE_TOO_SHORT;
      case 4:
        return PipelineMessageType.VALUE_TOO_LONG;
      case 5:
        return PipelineMessageType.CONNECTION_PROBLEM;
      case 6:
        return PipelineMessageType.INVALID_ENUMERATED_VALUE;
      case 7:
        return PipelineMessageType.INVALID_CHECKSUM;
      case 8:
        return PipelineMessageType.INVALID_PATTERN;
      default:
        return PipelineMessageType.UNKNOWN;
    }
  };

  /**
   * Method used to count the number of products in a pipeline
   * @param productIds
   */
  getProductCount = (productIds: number[]) => {
    return productIds.length;
  };

  /**
   * Method used to open the download modal
   * @param selectedPipeline
   */
  openDownload = (selectedPipeline: Pipeline | undefined) => {
    try {
      window.open(
        import.meta.env.BASE_URL.toString().replace(/\/$/, '') + selectedPipeline?.public_url,
        '_blank',
      );
    } catch (error) {
      this.toastService.displayErrorToast(this.ts.tModule('tooltips.missingPipeline'));
    }
  };

  /**
   * Method used to navigate to the products page
   * @param id
   */
  goToProducts = (id?: number | undefined) => {
    try {
      this.router.push({
        name: 'products',
        query: { pipelineId: id } as RouteParamsRaw,
      } as RouteLocationRaw);
    } catch (error) {
      this.toastService.displayErrorToast(this.ts.tModule('tooltips.missingPipelineId'));
    }
  };
}
