import { RouteFactory } from '@/core/router/route.factory';
import { RouteRecordRaw } from 'vue-router';

const statusNotificationsRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/status-notifications',
    name: 'statusNotifications',
    component: () =>
      import('admin/modules/status-notifications/views/status-notifications.view.vue'),
    translations: {
      module: 'statusNotifications',
      platform: 'admin',
    },
  }),
];

export default statusNotificationsRoutes;
