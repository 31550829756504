export class ConfirmPopupPhraseService {
  public refPopupPhrase: any | undefined;

  public typedPhrase = '';
  public phrase = '';

  public get isPhraseMatched(): boolean {
    return this.phrase === this.typedPhrase;
  }

  public get popupTargetTopOffset(): number {
    if (this.refPopupPhrase == null) {
      return 0;
    }

    return this.refPopupPhrase.$data.confirmation?.target.offsetTop ?? 0;
  }

  public get popupIsVisible(): boolean {
    return this.refPopupPhrase != null && this.refPopupPhrase?.visible;
  }

  constructor(_phrase: string) {
    this.phrase = _phrase;
  }

  public setPopupPhraseRef(ref: any) {
    if (this.refPopupPhrase != null || ref == null) {
      return;
    }

    this.refPopupPhrase = ref;
  }

  public cleanUp() {
    this.typedPhrase = '';
  }
}
