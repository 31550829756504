<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import useAttributeOption from 'utils/attribute-option';
import { TranslationService } from '@/general/services/translations/translation.service';
import { FinancialFieldOptions } from 'supplier/modules/attributes/ts/attributes.types';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  moduleId?: number;
  options?: FinancialFieldOptions;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  moduleId: undefined,
  options: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const { getFieldDefinitionOption } = useAttributeOption();

const currencies = computed(() => {
  return getFieldDefinitionOption(props.options, 'currencies', props.moduleId) || [];
});

const defaultUnit = computed(() => {
  return getFieldDefinitionOption(props.options, 'default_unit', props.moduleId) || '';
});

const defaultCurrency = computed(() => {
  if (defaultUnit.value) {
    return defaultUnit.value;
  } else return currencies.value[0];
});
const selectedCurrency = ref(defaultCurrency.value);
const inputAmount = ref();

const currencyField = computed(() => {
  return `${selectedCurrency.value} ${inputAmount.value}`;
});

const isEmpty = computed(() => {
  return inputAmount.value === '' || inputAmount.value == null;
});

const emitValue = () => {
  if (isEmpty.value) {
    emit('update:modelValue', '');
    return;
  }

  emit('update:modelValue', currencyField.value);
};

const getRegexMatch = (value: string) => {
  return value.match(/(?<currency>[\D]+)\s(?<amount>[\d]+\.[\d]+|[\d]+)$/);
};

const validateData = () => {
  const errorMessages: string[] = [];

  if (isEmpty.value) {
    emit('error', errorMessages);
    return;
  }

  const match = getRegexMatch(currencyField.value);
  if (!match) {
    errorMessages.push(
      ts.tModule('financialField.invalidNumber', {
        params: {
          amount: inputAmount.value,
        },
      }),
    );
  }

  if (currencies.value.indexOf(selectedCurrency.value) === -1) {
    errorMessages.push(
      ts.tModule('financialField.invalidCurrency', {
        params: {
          currency: selectedCurrency.value,
        },
      }),
    );
  }

  emit('error', errorMessages);
};

const prepareInputValue = () => {
  if (props.modelValue === '' || props.modelValue == null) {
    return;
  }

  const match = getRegexMatch(props.modelValue);
  if (match && match.groups) {
    selectedCurrency.value =
      match.groups.currency !== '' ? match.groups.currency : defaultCurrency.value;
    inputAmount.value = parseFloat(match.groups.amount);
  } else {
    inputAmount.value = parseFloat(props.modelValue);
  }

  validateData();
};

const inputAmountUpdated = () => {
  validateData();
  emitValue();
};

const currencyUpdated = (unit?: string) => {
  if (unit === undefined) {
    return;
  }

  selectedCurrency.value = unit;
  validateData();
  emitValue();
};

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue === '' || props.modelValue == null) {
      inputAmount.value = undefined;
      selectedCurrency.value = undefined;
      return;
    }

    if (props.modelValue === currencyField.value) {
      return;
    } else {
      prepareInputValue();
    }
  },
);

onMounted(() => {
  if (currencies.value.length > 0 && defaultUnit.value) currencies.value.push(defaultUnit.value);
  prepareInputValue();
});
</script>
<template>
  <span class="p-0 p-inputgroup-addon prefix-addon">
    <pDropdown
      v-model="selectedCurrency"
      class="bg-white border-noround min-w-min"
      :options="currencies"
      :disabled="disabled"
      :placeholder="defaultCurrency"
      @update:model-value="currencyUpdated"
    />
  </span>
  <p-input-number
    v-model="inputAmount"
    class="w-full"
    mode="decimal"
    :disabled="disabled"
    :min-fraction-digits="2"
    :max-fraction-digits="2"
    :placeholder="ts.tModule('numberField.placeholder')"
    @update:model-value="inputAmountUpdated"
  />
</template>

<style lang="scss" scoped>
.p-inputgroup :deep(.p-inputgroup-addon.prefix-addon) {
  border: 0;
}
.p-inputgroup :deep(.p-inputgroup-addon.prefix-addon .p-dropdown) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #e9ecef;
}

.p-inputgroup :deep(.p-inputnumber.prefix-addon .p-inputtext) {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
