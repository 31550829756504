<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { ref, onMounted, watch } from 'vue';

/** Props */
const props = defineProps<{
  modelValue: string;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', selectedDefinition?: AttributeTypeEnum): void;
}>();

/** Services */
const ts = new TranslationService('admin', 'fields');

/** Constants */
const selectedDefinition = ref<AttributeTypeEnum>();
const fieldTypes = [
  AttributeTypeEnum.DEFAULT,
  AttributeTypeEnum.GTIN_FIELD,
  AttributeTypeEnum.KOAG_FIELD,
  AttributeTypeEnum.TEXT_AREA,
  AttributeTypeEnum.BRAND_FIELD,
  AttributeTypeEnum.CATEGORY_FIELD,
  AttributeTypeEnum.RICHT_TEXT_FIELD,
  AttributeTypeEnum.INPUT_SELECT_FIELD,
  AttributeTypeEnum.SWITCH_FIELD,
  AttributeTypeEnum.NUMBER_FIELD,
  AttributeTypeEnum.TAG_FIELD,
  AttributeTypeEnum.CHOICE_FIELD,
  AttributeTypeEnum.MULTIPLE_CHOICE_FIELD,
  AttributeTypeEnum.FINANCIAL_FIELD,
  AttributeTypeEnum.DATE_TIME_FIELD,
  AttributeTypeEnum.KEY_VALUE_FIELD,
  AttributeTypeEnum.LIST_FIELD,
  AttributeTypeEnum.LIST_GROUP,
  AttributeTypeEnum.GROUP_FIELD,
  AttributeTypeEnum.TAB_FIELD,
];

const change = (event: DropdownChangeEvent) => {
  selectedDefinition.value = event.value ?? undefined;
  emit('update:modelValue', selectedDefinition.value);
};

/** Lifecylce */
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      selectedDefinition.value = props.modelValue as AttributeTypeEnum;
    }
  },
);

onMounted(() => {
  if (props.modelValue) {
    selectedDefinition.value = props.modelValue as AttributeTypeEnum;
  }
});
</script>
<template>
  <pDropdown
    v-model="selectedDefinition"
    :options="fieldTypes"
    :placeholder="ts.placeholder"
    display="chip"
    @change="(event: DropdownChangeEvent) => change(event)"
  />
</template>
