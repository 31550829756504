<script setup lang="ts">
// Types
import { lockableKey } from './inject.keys';

// Services
import { AttributeFieldServiceInterface } from '@/general/services/attribute-fields/interfaces/attribute-field-service.interface';
import { authorizationMethods } from '@/general/composables/authorization.plugin';

// Components
import Flag from 'ui/components/attribute-fields/flag.vue';
import MaxLengthComponent from './max-length-component.vue';

import { inject } from 'vue';
import { MaxLengthAttributeFieldService } from '@/general/services/attribute-fields/max-length-attribute-field.service';
import { FieldErrorEnum } from 'platform-unit2-api/attribute-fields';
import { UserPermission } from 'platform-unit2-api/roles';

/** Props */
interface Props {
  attributeFieldService: AttributeFieldServiceInterface<any>;
}

defineProps<Props>();

const hasLockable = inject(lockableKey, true);

const { hasUserPermission } = authorizationMethods;
</script>

<template>
  <section class="mb-4 mt-2">
    <div class="align-items-center flex w-full">
      <div id="wrapper-group" class="p-inputgroup">
        <Flag
          :locale="attributeFieldService.locale"
          :global="attributeFieldService.attributeField.attribute.global_attribute"
          class="max-h-3rem"
        />
        <slot />
        <MaxLengthComponent
          v-if="(attributeFieldService as MaxLengthAttributeFieldService).maxLength != null"
          :max-length="(attributeFieldService as MaxLengthAttributeFieldService).maxLength!"
          :text-area-length="(attributeFieldService as MaxLengthAttributeFieldService).textAreaLength()"
          :error-type="attributeFieldService.getErrorTypeClass()"
        />
      </div>
      <p-button
        v-if="hasLockable"
        text
        plain
        :disabled="
          !attributeFieldService.productAttributeField?.id ||
          !hasUserPermission(UserPermission.UPDATE_PRODUCT) ||
          attributeFieldService.productAttributeField.errors.filter(
            (err) => err.severity === FieldErrorEnum.ERROR,
          ).length ||
          attributeFieldService.hasChanges
        "
        :icon="
          attributeFieldService.productAttributeField.locked
            ? 'mdi mdi-lock-outline'
            : 'mdi mdi-lock-open-outline'
        "
        class="mx-1 text-2xl"
        @click="attributeFieldService.toggleLock()"
      />
    </div>
    <div class="flex">
      <div v-if="attributeFieldService.productAttributeField.errors" class="mr-auto mt-1">
        <p
          v-for="(error, index) in attributeFieldService.productAttributeField.errors"
          :key="index"
          class="mr-2"
        >
          <span
            :class="{
              'text-red-400': error?.severity === FieldErrorEnum.ERROR,
              'text-orange-500': error?.severity === FieldErrorEnum.WARNING,
            }"
          >
            {{ error.message }}</span
          >
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@mixin border-left-none {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.no-flag {
  :slotted(
      .p-inputtext,
      #brand-multi-select :deep(.p-dropdown),
      .p-dropdown,
      .p-inputwrapper :deep(.p-multiselect-label-container),
      .p-inputwrapper :deep(.p-inputtext),
      .p-inputgroup-addon,
      .editor-wrapper
    ) {
    @include border-left-none;
  }
}
</style>
