<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute } from 'platform-unit2-api/attributes';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  attribute?: Attribute;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  attribute: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();
/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const inputText = ref('');
const allowedLengths = [8, 12, 13, 14];

const validateData = (value?: string) => {
  const errorMessages: string[] = [];

  if (
    value == null ||
    (value === '' && !props.attribute?.required && !props.attribute?.options?.required)
  ) {
    emit('error', errorMessages);
    return;
  }

  if (!new RegExp('^[0-9]+$').test(value)) {
    errorMessages.push(ts.tModule('gtinField.notNumeric'));
  }

  if (allowedLengths.indexOf(value.length) === -1) {
    errorMessages.push(
      ts.tModule('lengthOfGTIN.error', { params: { length: allowedLengths.join(', ') } }),
    );
  }

  emit('error', errorMessages);
};

const valueUpdated = () => {
  validateData(inputText.value);
  emit('update:modelValue', inputText.value);
};

watchEffect(() => {
  props.modelValue && validateData(props.modelValue);
  inputText.value = props.modelValue ?? '';
});

onMounted(() => {
  validateData(props.modelValue);
  inputText.value = props.modelValue ?? '';
});
</script>

<template>
  <p-input-text
    v-model="inputText"
    :disabled="disabled"
    class="border-right-none w-full"
    :class="inputText.length > 14 ? 'border-pink-400' : ''"
    @update:model-value="valueUpdated"
  />
  <span
    class="bg-white min-w-max p-inputgroup-addon"
    :class="inputText.length > 14 ? 'border-pink-400' : ''"
  >
    {{ inputText ? inputText.length : 0 }} / {{ 14 }}</span
  >
</template>
