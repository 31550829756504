import { ProductsRestService, ProductHierarchy, Product } from 'platform-unit2-api/products';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { useRoute } from 'vue-router';

/**
 * @description The service that holds the logic for the product hierarchies
 */

export class ProductHierarchyService {
  private route = useRoute();
  private _toastService: ToastService;
  private _translationService: TranslationService;

  private _productAPI: ProductsRestService;
  private _allHierarchies: ProductHierarchy[] = [];
  private _excludedProductsids: number[] = []; //To exclude from the product list in 'link product' modal -select product dropdown-
  private _selectedHierarchyData: ProductHierarchy | undefined;
  private _hierarchyOptions: string[] = [];

  private _selectedHierarchy: string | undefined;
  private _isLinkedProductsModalVisible = false;

  private _currentProduct: Product | undefined;

  private _loading = false;

  constructor() {
    this._productAPI = new ProductsRestService();
    this._toastService = ToastService.getInstance();
    this._translationService = new TranslationService('supplier', 'products');
  }

  /**
   * @description initializes the service. gets the current product id and gets all the hierarchies of the product and sets the selected hierarchy data
   */
  async init() {
    this._loading = true;
    const productId = this._getCurrentProductId();
    if (productId) {
      try {
        this._currentProduct = await this._getCurrentProduct(productId);
        const allHierarchies = await this._getAllHierarchies(productId);
        this._allHierarchies = allHierarchies;
        this._setSelectedHierarchyData();
        this._setHierarchyOptions();
        this._selectedHierarchy = this._hierarchyOptions[0];
        this._setExcludedProductsIds();
      } catch (error) {
        this._toastService.displayErrorToast(
          this._translationService.tModule('hierarchies.fetch_failed'),
        );
      } finally {
        this._loading = false;
      }
    }
  }

  private _getCurrentProductId(): number {
    return +this.route.params.id;
  }

  private async _getCurrentProduct(productId: number) {
    try {
      return await this._productAPI.getProduct(productId);
    } catch (error) {
      throw error;
    }
  }

  private async _getAllHierarchies(productId: number) {
    try {
      return await this._productAPI.getProductHierarchies(productId);
    } catch (error) {
      throw error;
    }
  }

  private _setExcludedProductsIds() {
    //we need all the parents + product itself + all the direct children
    const parentsIds = [
      ...new Set(this._allHierarchies.map((hierarchy) => this._findParentIds(hierarchy)).flat()),
    ]; //Set to remove duplicates

    const directChildren = this.currentProduct?.childs.map((child) => child.product.id) || [];

    this._excludedProductsids = [...parentsIds, ...directChildren];
  }

  private _findParentIds(node: ProductHierarchy, parentIds: number[] = []): number[] {
    // If this is the current product node, return the current parent node ids plus the current node id
    if (node.current) {
      return parentIds.concat([node.data.product.id]);
    } else {
      // If this is not the current product node, add the current node id to the list of parent node ids and recursively search its children
      const newParentIds: number[] = parentIds.concat([node.data.product.id]);

      for (const child of node.children ?? []) {
        const parentIdsFromChild = this._findParentIds(child, newParentIds);
        // If the target id is found in the children, return the list of parent node ids from the current level of recursion
        if (parentIdsFromChild.length > 0) {
          return parentIdsFromChild;
        }
      }

      return [];
    }
  }

  /**
   *
   * @@description set the options for the select hierarchy dropdown
   */
  private _setSelectedHierarchyData(index = 0) {
    this._selectedHierarchyData = this._allHierarchies[index];
  }

  private _setHierarchyOptions() {
    this._hierarchyOptions = this._allHierarchies.map(
      (hierarchy, index) => 'hierarchy ' + (index + 1),
    );
  }

  /**
   * @description all the products in a hierarchy
   */
  get selectedHierarchyData() {
    return this._selectedHierarchyData;
  }

  /**
   * @description all the hierarchies of the selected product
   */
  get allHierarchies() {
    return this._allHierarchies;
  }

  get hierarchyOptions() {
    return this._hierarchyOptions;
  }

  get selectedHierarchy() {
    return this._selectedHierarchy;
  }

  set selectedHierarchy(value: string | undefined) {
    this._selectedHierarchy = value;
    value && this._setSelectedHierarchyData(this._hierarchyOptions.indexOf(value));
  }

  get isLinkedProductsModalVisible() {
    return this._isLinkedProductsModalVisible;
  }

  set isLinkedProductsModalVisible(value: boolean) {
    this._isLinkedProductsModalVisible = value;
  }

  get currentProduct() {
    return this._currentProduct;
  }

  get loading() {
    return this._loading;
  }

  get excludedProductsids() {
    return this._excludedProductsids;
  }
}
