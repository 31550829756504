import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Dashboard',
  completeness: 'Completeness',
  info: 'You are now in ADMIN MODE. Here you can create organisations, workspaces and many more.',
  recently_updated: 'Recently Updated',
  no_update: 'No recently updated {title} found',
};

const tranlation: Translation = {
  dashboard: translationObject,
};

export default tranlation;
