import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create retailer',
  subTitle: 'Here you can create a new retailer.',
};

const update: TranslationObjectAction = {
  title: 'Edit retailer',
  subTitle: 'Here you can edit a retailer.',
};

const translationObject: TranslationObject = {
  title: 'Retailer | Retailers',
  placeholder: 'Select a retailer | Select retailers',

  create: create,
  update: update,
};

const tranlation: Translation = {
  retailers: translationObject,
};

export default tranlation;
