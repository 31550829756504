import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Datamodel',
  subTitle: 'Here you can create a new Datamodel.',
};

const update: TranslationObjectAction = {
  title: 'Edit Datamodel',
  subTitle: 'Here you can edit a Datamodel.',
};

const duplicate: TranslationObjectAction = {
  title: 'Duplicate Datamodel',
  subTitle: 'Here you can duplicate a Datamodel.',
};

const translationObject: TranslationObject = {
  title: 'Datamodel | Datamodels',
  placeholder: 'Select a datamodel | Select datamodels',

  create: create,
  update: update,
  duplicate: duplicate,

  reorderingFailed: 'Failed to reorder the row. Please try again.',
  attachAttributesSuccess: 'Successfully attached attribute(s)',
  attachAttributesFailed: 'Failed to attached attribute(s)',
  detachAttributesSuccess: 'Successfully detached attribute(s)',
  detachAttributesFailed: 'Failed to detached attribute(s)',
  noAttributes: `You don't have any attributes in the datamodel yet. Please add one first`,

  no_attributes_in_selected_datamodel: 'There is no attributes in the selected datamodel(s)',
  bulk_delete: '{count} attribute | {count} attributes',
  bulk_delete_message: 'Delete',
  confirmDetach:
    'Are you sure you want to remove {count} Attribute from the datamodel? | Are you sure you want to remove {count} Attributes from the datamodel?',
  delete_selected: 'Delete selected',
};

const tranlation: Translation = {
  datamodels: translationObject,
};

export default tranlation;
