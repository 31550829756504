import { BaseViewService } from '../view/base-view.service';
import {
  CreateUserRequest,
  UpdateUserRequest,
  User,
  UserRestService,
} from 'platform-unit2-api/users';
import { RoleCompact } from 'platform-unit2-api/roles';
import { Client } from 'platform-unit2-api/clients';
import { TranslationService } from '../../translations/translation.service';

class UserViewService extends BaseViewService<
  UserRestService,
  User,
  CreateUserRequest,
  UpdateUserRequest
> {
  public uploading = false;

  public get users() {
    return this._data;
  }

  public get ts() {
    return this._ts;
  }

  public get isSearchResultEmpty() {
    return this.users.length === 0 && this._fetchVariables.query !== '';
  }

  public get hasNoUsers() {
    return this.users.length === 0 && this.query === '';
  }

  /**
   * @inheritdoc
   */
  constructor(ts: TranslationService) {
    super({
      Api: UserRestService,
      ts: ts,
      fetchAllFunction: 'getAll',
      overviewRouteName: 'users',
      createRouteName: 'new-user',
      updateRouteName: 'edit-user',
      confirmPopUpGroup: 'users',
    });
  }

  public createBody(): CreateUserRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const body: CreateUserRequest = {
      name: this.current.name ?? '',
      thumbnail_id: this.current.thumbnail?.id,
      email: this.current.email,
      mobilephone: this.current.mobilephone,
      address: this.current.address,
      position: this.current.position,
      workspace_id: this.current.workspace?.id,
      country: this.current.country,
      roles: (this.current.roles ?? []).map((role: RoleCompact) => role.id) ?? [],
      client_ids: (this.current.clients ?? []).map((client: Client) => client.id) ?? [],
      locale_id: this.current.locale?.id,
    };

    return body;
  }
  public updateBody(): UpdateUserRequest | undefined {
    if (this.current == null || !this.validated || this.current?.id == null) {
      return;
    }

    const body: UpdateUserRequest = {
      id: this.current.id!,
      name: this.current.name,
      mobilephone: this.current.mobilephone,
      address: this.current.address,
      thumbnail_id: this.current.thumbnail?.id ?? null,
      position: this.current.position,
      workspace_id: this.current.workspace?.id,
      country: this.current.country,
      roles: (this.current.roles ?? []).map((role: RoleCompact) => role.id) ?? [],
      client_ids: (this.current.clients ?? []).map((client: Client) => client.id) ?? [],
      locale_id: this.current.locale?.id,
    };

    return body;
  }
  public get validated(): boolean {
    if (
      this.current?.name == null ||
      this.current?.email == null ||
      this.current?.workspace?.id == null ||
      this.uploading
    ) {
      return false;
    }

    return true;
  }
}

export default UserViewService;
