<script setup lang="ts">
//Core
import { watch, watchEffect } from 'vue';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { useService } from '@/general/composables/useService/useService';
import ValidationModelService from 'admin/modules/validation-rules/services/validation-models.service';

//Components
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import ErrorMessageInput from '@/general/ui/components/error-message-input.vue';
import { serviceKey } from 'admin/modules/validation-rules/ts/keys/validation-model-key';

const service = useService(serviceKey, ValidationModelService);

defineProps<{
  ts: TranslationService;
}>();

watch(
  //To revalidate the form when the input has changed
  () => [service.partialObject.name, service.partialObject.module],
  () => {
    service.validateForm();
  },
);

watchEffect(() => {
  //To not run validation when the dialog is first opened
  if (service.crudComponent === false) {
    service.formSubmitted = false;
  }
});
</script>
<template>
  <BaseDialog
    :visible="service.crudComponent"
    :maximizable="true"
    :style="{ width: '50vw' }"
    close-on-escape
    @update:visible="service.closeDialog()"
  >
    <template #header>
      <div>
        <h2 class="text-gray-800">
          {{
            ts.tModule(`${service.isCreating ? 'createValidationModel' : 'updateValidationModel'}`)
          }}
        </h2>
      </div>
    </template>

    <!-- Validation model Name -->
    <!--
      fieldErrors checks the error in the FE and hasError checks the error sent by backend
    -->
    <div
      class="flex flex-column gap-2 mb-3 p-input-icon-right"
      :class="{ error: service.fieldErrors.name?.error || service.hasError('name') }"
    >
      <label for="name">{{ ts.tModule('validationModelName') }}*</label>
      <i v-if="service.isLoadingCrudComponent" class="mt-auto pi pi-spin pi-spinner" />
      <pInputText id="name" v-model.lazy.trim="service.partialObject.name" />

      <ErrorMessageInput v-if="service.fieldErrors.name?.error">
        {{ ts.tModule(`validationErrors.${service.fieldErrors.name?.messageKey}`) }}
      </ErrorMessageInput>
      <!-- Backend error is an array -->
      <div v-else-if="service.fieldErrorMessage('name').length">
        <ErrorMessageInput v-for="(error, index) in service.fieldErrorMessage('name')" :key="index">
          {{ error }}
        </ErrorMessageInput>
      </div>
    </div>

    <!-- Validation channel  -->
    <div class="flex flex-column gap-2" :class="{ error: service.fieldErrors.module_id?.error }">
      <label for="channel">{{ ts.tModule('linkedChannel') }}*</label>
      <ModuleSelect id="channel" v-model="service.partialObject.module" type="export" hide-label />
      <ErrorMessageInput v-if="service.fieldErrors.module_id?.error">
        {{ ts.tModule(`validationErrors.${service.fieldErrors.module_id?.messageKey}`) }}
      </ErrorMessageInput>
    </div>
    <template #footer>
      <!-- Cancel -->
      <pButton
        :label="ts.tGlobal('cancel')"
        class="ml-auto text-normal"
        severity="secondary"
        text
        @click="service.closeDialog()"
      />
      <!-- Create -->
      <pButton
        :label="ts.tGlobal(`${service.isCreating ? 'create' : 'save'}`)"
        :loading="service.isLoadingCrudComponent"
        @click="service.save()"
      />
    </template>
  </BaseDialog>
</template>
<style scoped lang="scss">
.error {
  input,
  input:focus,
  input:hover,
  :deep(.p-dropdown),
  :deep(.p-dropdown:hover) {
    border-color: #ac110c;
  }
}
.p-button :deep(.p-button-label) {
  font-weight: normal !important;
}
</style>
