import { NotificationPreferencesExtended } from '@/platforms/supplier/modules/settings/views/notification-preferences.vue';
import { ToastService } from '../toasts/toast.service';
import { TranslationService } from '../translations/translation.service';
import { useStore } from 'vuex';
import {
  UserNotificationPreferencesUpdate,
  UserPreferenceRestService,
} from 'platform-unit2-api/user-preference';
import { User } from 'platform-unit2-api/users';

export class NotificationPreferencesService {
  private _toastService: ToastService;
  private _ts: TranslationService;
  private _userPreferencesService: UserPreferenceRestService;
  private _store = useStore();

  public notificationPreferences: NotificationPreferencesExtended[] = [];
  private _currentUser: User;

  constructor(ts: TranslationService) {
    this._toastService = ToastService.getInstance();
    this._ts = ts;
    this._userPreferencesService = new UserPreferenceRestService();
    this._currentUser = this._store.getters['users/currentUser'];
  }

  public loadNotificationPreferences() {
    if (this._currentUser.preferences != null) {
      this.notificationPreferences = [
        {
          key: 'send_mail_action_created',
          title: this._ts.tModule('notifications.email.taskCreated'),
          description: this._ts.tModule('notifications.email.taskCreatedDescription'),
          value: this._currentUser.preferences?.['send_mail_action_created'],
        },
        {
          key: 'send_mail_action_commented',
          title: this._ts.tModule('notifications.email.comments'),
          description: this._ts.tModule('notifications.email.commentsDescription'),
          value: this._currentUser.preferences?.['send_mail_action_commented'],
        },
        {
          key: 'send_mail_action_updated',
          title: this._ts.tModule('notifications.email.statusChange'),
          description: this._ts.tModule('notifications.email.statusChangeDescription'),
          value: this._currentUser.preferences?.['send_mail_action_updated'],
        },
      ];
    }
  }

  public updateNotificationPreferences() {
    const updatePreferenceBody: UserNotificationPreferencesUpdate =
      {} as UserNotificationPreferencesUpdate;
    this.notificationPreferences.forEach((preference: NotificationPreferencesExtended) => {
      updatePreferenceBody[preference.key] = preference.value ?? false;
    });

    if (this._currentUser?.id != null) {
      this._userPreferencesService
        .updateUserNotificationPreferences(this._currentUser?.id, updatePreferenceBody)
        .then(() => {
          this._toastService.displaySuccessToast(this._ts.updateSuccess());
          this._store.dispatch('users/GET_CURRENT_USER_DETAILS');
        })
        .catch(() => this._toastService.displayErrorToast(this._ts.updateFailed()));
    }
  }
}
