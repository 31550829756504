<script setup lang="ts">
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import CrudButtons from '@/general/ui/components/crud-buttons/crud-buttons.vue';
import { ref } from 'vue';
import { useService } from '@/general/composables/useService';
import { DatamodelAttributesService } from '@/general/services/overview-service/modules/datamodel-attributes.service';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

const service = useService(datamodelAttributeServiceKey, DatamodelAttributesService);
const ts = service.ts;
const createButton = new CreateButton({
  label: ts.tGlobal('save'),
  styleClasses: 'p-button mr-3',
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => service.isTouched,
  onClick: () => service.saveUserDatamodel(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [createButton],
});
</script>

<template>
  <div class="align-content-center bg-gray-50 w-full">
    <CrudButtons
      v-if="buttonsOptions"
      :options="buttonsOptions"
      additional-classes="align-items-center"
    />
  </div>
</template>
