<script setup lang="ts">
import { ref } from 'vue';
import useFormValidation from 'composables/form-validation';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import { useOrganisationService } from 'admin/modules/organisations/organisations.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CreateOrganisation, Organisation } from 'platform-unit2-api/organisations';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const { resetFormErrors, fieldErrorMessage, parseFormError, hasError } = useFormValidation();
const organisationService = useOrganisationService();

/** Services */
const ts = new TranslationService('admin', 'organisations');

const editorOrganisation = ref<Organisation>({} as Organisation);
const saving = organisationService.savingCreate;

/** Add new organisation */
const handleSubmit = async (): Promise<void> => {
  resetFormErrors();

  const organisation: CreateOrganisation = {
    name: editorOrganisation?.value?.name ?? '',
    description: editorOrganisation?.value?.description ? editorOrganisation.value.description : '',
  };

  const result = await organisationService.createOrganisation(organisation, parseFormError);

  if (result.passed) {
    emit('refresh');
    emit('hide');
  }
};
</script>
<template>
  <CrudSidebar
    :title="ts.moduleCreateTitle"
    :subtitle="ts.moduleCreateSubTitle"
    :saving="saving"
    @cancel="emit('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <!-- Name -->
      <div class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          v-if="editorOrganisation"
          id="name"
          v-model="editorOrganisation.name"
          :class="{ 'p-invalid': hasError('name') }"
          class="w-full"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <!-- Description -->
      <div class="field mb-3">
        <label for="description">{{ ts.tGlobal('description') }}</label>
        <p-textarea
          v-if="editorOrganisation"
          id="description"
          v-model="editorOrganisation.description"
          :class="{ 'p-error block': hasError('description') }"
          class="w-full"
          rows="5"
          cols="60"
        />
        <small
          v-if="hasError('description')"
          :class="{ 'p-error block': hasError('description') }"
          class="hidden"
          >{{ fieldErrorMessage('description').toString() }}</small
        >
      </div>
    </template>
  </CrudSidebar>
</template>
