<script setup lang="ts">
import { useStore } from 'vuex';
import { ref, watch, onBeforeMount } from 'vue';
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import tags from 'language-tags';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Services */
const ts = new TranslationService('supplier', 'products');

/**Props */
interface Props {
  isActive: boolean;
  locales: LocaleDetails[];
  filteredLocales: LocaleDetails[];
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  locales: () => [],
  filteredLocales: () => [],
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'update:filteredLocales', locales: LocaleDetails[]): void;
}>();

/**Consts */
const store = useStore();
const selectedLocales = ref<LocaleDetails[]>([]);
const filteredLocalesRef = ref<LocaleDetails[]>([]);
const showMissingFields = ref(false);

const generateFlag = (format?: string): string => {
  if (format == null) {
    return '';
  }

  return 'fi fi-' + format + ' fis mr-1';
};

const toggleMissingFields = () => {
  store.dispatch('products/SET_MISSING_FIELDS');
};

watch(
  () => props.locales,
  () => {
    if (props.locales) {
      filteredLocalesRef.value = props.filteredLocales;
      emit('update:filteredLocales', filteredLocalesRef.value);
    }
  },
);

onBeforeMount(async () => {
  showMissingFields.value = store.getters['products/showMissingFields'];
  filteredLocalesRef.value = props.filteredLocales;
  selectedLocales.value = props.locales;
  selectedLocales.value.forEach((locale) => {
    const language = tags(locale.value)?.language()?.descriptions();
    const region = tags(locale.value)?.region()?.descriptions();

    locale.format = tags(locale.value)?.region()?.format().toLowerCase();
    locale.language = language?.[0];
    locale.region = region?.[0];
  });
});
</script>

<template>
  <pSidebar
    :visible="isActive"
    class="p-sidebar-sm"
    :dismissable="true"
    :modal="false"
    position="right"
    :header="ts.tModule('filters')"
    @update:visible="$emit('hide')"
  >
    <div>
      <div class="align-items-center field flex mb-3">
        <label for="missingFields" class="mb-0 mr-3">{{ ts.tModule('show_missing_fields') }}</label>
        <p-input-switch
          id="missingFields"
          v-model="showMissingFields"
          @change="toggleMissingFields"
        />
      </div>
    </div>
    <div v-for="locale of selectedLocales" :key="locale.id" class="field-checkbox">
      <p-checkbox
        :id="locale.id"
        v-model="filteredLocalesRef"
        name="locale"
        :value="locale"
        :class="
          filteredLocalesRef.length === 1 && filteredLocalesRef[0].id === locale.id
            ? 'cursor-auto'
            : ''
        "
        :disabled="filteredLocalesRef.length === 1 && filteredLocalesRef[0].id === locale.id"
        @change="$emit('update:filteredLocales', filteredLocalesRef)"
      />
      <label :for="locale.id.toString()" class="flex">
        <span :class="generateFlag(locale?.format)" class="mr-2" />
        <div>
          <p>
            {{ locale.language }}
          </p>
          <small>({{ locale.region }})</small>
        </div>
      </label>
    </div>
  </pSidebar>
</template>
