<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { Locale, LocaleDetails, LocalesRestService } from 'platform-unit2-api/locales';
import { ref, onBeforeMount } from 'vue';
import { getLocaleDetails } from '../../../utils/get-locale-details';
import store from '@/core/store';
import { DefaultSelectProps } from './ts/default-select-props.interface';

interface Props extends DefaultSelectProps<Locale> {
  doNotPutValueOnMounted?: boolean;
  options?: Locale[];
}

const props = withDefaults(defineProps<Props>(), {
  doNotPutValueOnMounted: false,
  options: undefined,
});
const emit = defineEmits<{
  (e: 'update:modelValue', locale: Locale | Locale[] | undefined): void;
  (e: 'fetchFinished'): void;
}>();
const options = ref<LocaleDetails[]>(
  (props.options ?? []).map((locale) => getLocaleDetails(locale)),
);
const localeRestService = new LocalesRestService();

const remove = (id: number) => {
  const newValues = (props.modelValue as LocaleDetails[]).filter((item) => item.id !== id);
  emit('update:modelValue', newValues);
};

onBeforeMount(async () => {
  options.value = (await localeRestService.getAll()).data.map((locale) => getLocaleDetails(locale));
  if (props.doNotPutValueOnMounted) {
    emit('fetchFinished');
    return;
  }

  const preferredLocale: LocaleDetails = store.getters['users/currentUser']?.locale;
  if (preferredLocale != null && props.modelValue == null) {
    const finalPreferred = props.multiselect
      ? [getLocaleDetails(preferredLocale)]
      : getLocaleDetails(preferredLocale);
    emit('update:modelValue', finalPreferred);
    emit('fetchFinished');
    return;
  }

  if (props.modelValue == null) {
    const finalFirstValue =
      props.multiselect && options.value[0] != null ? [options.value[0]] : options.value[0];
    emit('update:modelValue', finalFirstValue);
    emit('fetchFinished');
    return;
  }

  const final = Array.isArray(props.modelValue)
    ? props.modelValue.map((val) => getLocaleDetails(val))
    : getLocaleDetails(props.modelValue);

  emit('update:modelValue', final);
  emit('fetchFinished');
});
</script>
<template>
  <BaseSelect
    skip-fetch-on-show
    :rest-service="LocalesRestService"
    display="chip"
    option-label="language"
    module-name="locale"
    v-bind="{ ...$attrs, ...props }"
    :filter="false"
    :options="options"
    @update:model-value="(value: Locale | Locale[] | undefined) => emit('update:modelValue', value)"
  >
    <template #value="{ value }">
      <div v-if="!multiselect && value != null" class="flex">
        <span v-if="value.format" :class="'mr-3 fi fi-' + value.format + ' fis'" />
        <span v-else class="mdi mdi-web mr-3" />
        <p>{{ value.language ?? value.value }}</p>
      </div>
      <div v-for="option of value" v-else :key="option" class="p-multiselect-token">
        <span
          style="position: inherit !important"
          :class="'mx-2 fi fi-' + option.format + ' fis'"
        />
        <div>{{ option.language }}</div>
        <span
          class="mdi mdi-close-circle-outline p-multiselect-token-icon"
          @click.stop="remove(option.id)"
        />
      </div>
    </template>
    <template #option="{ option }">
      <div class="flex">
        <div class="flex">
          <span v-if="option.format" :class="'mr-3 fi fi-' + option.format + ' fis'" />
          <span v-else class="mdi mdi-web mr-3" />
        </div>
        <div>
          <p class="font-bold text-sm">
            {{ option.language ?? option.value }}
          </p>
          <p v-if="option.region">
            <small class="text-xs">({{ option.region }})</small>
          </p>
        </div>
      </div>
    </template>
  </BaseSelect>
</template>
