<script setup lang="ts">
import { Attribute } from 'platform-unit2-api/attributes';
import { FieldPath } from '@/general/services/field-path.class';

/** Props */
interface Props {
  modelValue?: string;
  disabled: boolean;
  global: boolean;
  fieldPath?: FieldPath;
  index?: number;
  attribute?: Attribute;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  fieldPath: undefined,
  index: undefined,
  attribute: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'delete:modelValue', groupIndex?: FieldPath): void;
}>();

/** Consts */
const valueDeleted = () => {
  emit('delete:modelValue', props.fieldPath);
};
</script>
<template>
  <p-accordion v-if="attribute?.options?.collapsible" class="group-field-accordion w-full">
    <p-accordion-tab class="flex w-full">
      <template #header>
        <span
          style="cursor: grab"
          :class="`mdi mdi-drag-vertical mr-2 group-drag-handle text-2xl`"
        ></span>
        <slot name="header"></slot>
        <div class="absolute right-0">
          <p-button
            text
            rounded
            severity="danger"
            icon="mdi mdi-delete-outline"
            @click="valueDeleted"
          />
        </div>
      </template>
      <slot name="items"></slot>
    </p-accordion-tab>
  </p-accordion>
  <div
    v-else
    class="border-1 border-gray-100 border-right-round flex p-2 w-full"
    style="background: #f5f7fa; min-height: 8rem"
  >
    <slot name="items"></slot>
    <p-button
      text
      rounded
      severity="danger"
      icon="mdi mdi-delete-outline"
      class="m-4"
      @click="valueDeleted"
    />
  </div>
</template>

<style scoped lang="scss">
.p-accordion.group-field-accordion:deep(.p-accordion-header-link) {
  background: #ffffff !important;
  height: 3rem;
  color: var(--text-color) !important;
}

// reset parent styling as important
.p-accordion.group-field-accordion:deep(.p-accordion-header .p-accordion-header-link) {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.p-accordion.group-field-accordion:deep(
    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link
  ) {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 0px !important;
}

.p-accordion.group-field-accordion:deep(.p-accordion-content) {
  padding: 1.25rem !important;
}

/*
  Move
 */
.drag-handle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.drag-handle:active {
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grabbing; /* Chrome 1-21, Safari 4+ */
  cursor: -moz-grabbing; /* Firefox 1.5-26 */
  cursor: grabbing; /* W3C standards syntax, should come least */
}
.drag-handle .disabledHandle {
  cursor: not-allowed !important;
}

.p-accordion.group-field-accordion:deep(.p-accordion-header-link) {
  background: #ffffff !important;
  height: 3rem;
  color: var(--text-color) !important;
}

// reset parent styling as important
.p-accordion.group-field-accordion:deep(.p-accordion-header .p-accordion-header-link) {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.p-accordion.group-field-accordion:deep(
    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link
  ) {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 0px !important;
}

.p-accordion.group-field-accordion:deep(.p-accordion-content) {
  padding: 1.25rem !important;
}
</style>
