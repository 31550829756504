<script setup lang="ts">
import AttributeEditor from 'retailer/modules/products/components/attribute-editor.vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { IncompleteProductsAuditorEnum } from 'platform-unit2-api/incomplete-products';
import { IncompleteProduct } from '../ts/incomplete-product.types';

import { IncompleteProductVueService } from 'retailer/modules/incomplete-products/incomplete-product-vue.service';
import { LocaleDetails } from 'platform-unit2-api/locales';
import { ProductsRestService, Product, UpdateProductField } from 'platform-unit2-api/products';

//TODO TYPES
// Props
const props = defineProps<{
  product: IncompleteProduct;
  id: number;
}>();

// Emits
const emit = defineEmits<{ (e: 'close'): void }>();

/** Composables */
const store = useStore();
const route = useRoute();

/** Services */
const ts = new TranslationService('retailer', 'incompleteProducts');
const toastService = ToastService.getInstance();
const incompleteProductService = new IncompleteProductVueService();
const productsApi = new ProductsRestService();

// Constants
const saving = ref(false);
const fieldData = ref<any[]>([]);
const auditor = ref<IncompleteProductsAuditorEnum>();

const products = ref<Product[]>([]);

async function saveFieldData(): Promise<void> {
  saving.value = true;
  const fields: UpdateProductField[] = fieldData.value.map((field) => {
    return {
      attribute_id: field.field_id,
      value: field.value,
      locale_id: field.locale_id,
      overwrite: false,
    } as UpdateProductField;
  });

  try {
    await productsApi.setProductFields(props.product.id, fields);

    toastService.displaySuccessToast(ts.updateSuccess());

    emit('close');

    incompleteProductService.loadAsyncFields(props.product.id);
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    saving.value = false;
  }
}

const updateFieldData = (fieldId: number, localeId: number, value: any) => {
  fieldData.value.push({
    field_id: fieldId,
    locale_id: localeId,
    value: value,
  });
};

const findLocaleDetails = (localeId: number): LocaleDetails | undefined => {
  return incompleteProductService.allLocales.find((locale) => locale.id === localeId);
};

// Lifecycle hooks
onMounted(async () => {
  if (route.name === 'retailer-products-incomplete') {
    auditor.value = IncompleteProductsAuditorEnum.RETAILERS;
  } else if (route.name === 'brand-products-incomplete') {
    auditor.value = IncompleteProductsAuditorEnum.BRANDS;
  } else if (route.name === 'module-products-incomplete') {
    auditor.value = IncompleteProductsAuditorEnum.MODULES;
  } else {
    emit('close');
  }

  incompleteProductService.allLocales = (
    await store.dispatch('locales/SEARCH_LOCALES', { query: '' })
  ).data;

  incompleteProductService.loadAsyncFields(props.product.id);
});
</script>

<template>
  <div>
    <div v-if="incompleteProductService.isLoading.value">
      <div class="mb-5">
        <p-skeleton height="1rem" width="10rem" class="mb-2" />
        <p-skeleton height="2rem" />
      </div>
      <div>
        <p-skeleton height="1rem" width="10rem" class="mb-2" />
        <p-skeleton height="10rem" />
      </div>
    </div>

    <p-message
      v-if="
        !incompleteProductService.isLoading.value &&
        incompleteProductService.emptyFields.value.length === 0
      "
      severity="warn"
      :closable="false"
    >
      <p class="font-bold">
        {{ ts.tGlobal('warning') }}
      </p>
      {{ ts.tModule('noEmptyFieldsModule') }}
      <a href="mailto:rpxsupport@productsup.com">{{ ts.tModule('contact_productsup') }}</a>
    </p-message>

    <p-message
      v-if="product.uploads_count === 0 && !incompleteProductService.isLoading.value"
      severity="warn"
      :closable="false"
    >
      <p class="font-bold">
        {{ ts.tGlobal('warning') }}
      </p>
      {{ ts.tModule('noUploads') }}
      <router-link
        :to="{
          name: 'product-details',
          params: {
            id: product.id,
          },
        }"
      >
        {{ ts.tModule('productsPage') }}
      </router-link>
    </p-message>

    <div v-for="field in incompleteProductService.emptyFields.value" :key="field.attribute.key">
      <div class="mt-3 p-2">
        <strong>{{ field.attribute.key }}</strong>
      </div>
      <div v-for="locale in field.localeIds" :key="locale">
        <attribute-editor
          :key="field.attribute.id"
          :value="fieldData[field.attribute.id]"
          :current-product="products[0]"
          :field-data="field.product_field"
          :attribute="field.attribute"
          :lockable="false"
          :locale="findLocaleDetails(locale)"
          @input="(value) => updateFieldData(field.attribute.id, locale, value)"
        />
      </div>
    </div>

    <p-button
      v-if="incompleteProductService.emptyFields.value.length > 0"
      class="flex ml-auto"
      :label="ts.tGlobal('save')"
      :loading="saving"
      @click="saveFieldData"
    />
  </div>
</template>
