<script setup lang="ts">
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product } from 'platform-unit2-api/products';
import { ModuleDetail } from 'platform-unit2-api/modules';
import { DatamodelCompact } from 'platform-unit2-api/datamodels';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';

/** Props */
withDefaults(
  defineProps<{
    isActive: boolean;
  }>(),
  {
    isActive: false,
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'saved', id: number): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const store = useStore();
const route = useRoute();
const variants = ref([]);
const copyDataFrom = ref<Product>();
const copyAssetsFrom = ref<Product>();
const selectedModule = ref<ModuleDetail>();
const selectedDatamodel = ref<DatamodelCompact>();
const gtin = ref('');
const variantName = ref('');
const loading = ref(true);
const hideDetails = () => {
  emit('hide');
};

const getVariantData = async () => {
  if (!store.getters['products/currentProduct'].variant_uuid) {
    return;
  }

  loading.value = true;
  gtin.value = store.getters['products/currentProduct'].gtin;
  try {
    variants.value = await store.dispatch(
      'products/GET_PRODUCT_VARIANTS',
      store.getters['products/currentProduct'].variant_uuid,
    );
  } catch (ex) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const reset = () => {
  gtin.value = '';
  selectedModule.value = undefined;
  copyDataFrom.value = undefined;
  copyAssetsFrom.value = undefined;
  variantName.value = '';
};

const handleSubmit = async () => {
  loading.value = true;
  try {
    const response = await store.dispatch('products/CREATE_PRODUCT_VARIANT', {
      productId: copyDataFrom.value ? copyDataFrom.value.id : route.params.id,
      data: {
        variant_name: variantName.value,
        gtin: gtin.value,
        module_id: selectedModule.value ? selectedModule.value.id : undefined,
        replicate_data_id: copyDataFrom.value ? copyDataFrom.value.id : undefined,
        replicate_assets_id: copyAssetsFrom.value ? copyAssetsFrom.value.id : undefined,
        datamodel_id: selectedDatamodel.value?.id ?? undefined,
      },
    });
    emit('saved', response.id);
    reset();
    toastService.displaySuccessToast(ts.createSuccess());
  } catch (ex) {
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    loading.value = false;
  }
};

const loadAsyncData = async () => {
  await getVariantData();
};

watch(
  () => selectedModule.value,
  () => {
    let result = variantName.value;
    if (selectedModule.value) {
      result = `${selectedModule.value.name}`;
    }

    variantName.value = result;
  },
);
onMounted(() => {
  loadAsyncData();
});
</script>

<template>
  <pSidebar
    :visible="isActive"
    class="p-sidebar-md"
    :dismissable="false"
    position="right"
    :header="ts.tModule('product_details.create_variant.title')"
    @update:visible="$emit('hide')"
  >
    <div class="h-full">
      <SidebarSkeleton v-if="loading" />
      <div v-else class="flex flex-column h-full">
        <div class="field mb-3">
          <label for="variant-name">{{ ts.tGlobal('name') }}</label>
          <p-input-text
            id="variant-name"
            v-model="variantName"
            class="w-full"
            :placeholder="ts.tModule('product_details.create_variant.name.placeholder')"
            type="text"
          />
        </div>

        <div class="field mb-3">
          <label for="gtin">{{ ts.tGlobal('gtin') }}</label>
          <p-input-text id="gtin" v-model="gtin" class="w-full" type="text" />
        </div>

        <div class="field mb-3">
          <ClientModuleSelect v-model="selectedModule" type="export" />
        </div>

        <div
          v-if="
            selectedModule != null &&
            selectedModule.client?.workspace_type?.type === ClientTypeEnum.RETAILER
          "
          class="field mb-3"
        >
          <DatamodelSelect v-model="selectedDatamodel" :channel-id="selectedModule.id" />
        </div>

        <div class="field mb-3">
          <label for="variant-name">{{
            ts.tModule('product_details.create_variant.copy_data_from.label')
          }}</label>
          <pDropdown
            v-model="copyDataFrom"
            option-label="variant_name"
            class="w-full"
            :show-clear="true"
            :options="variants"
          >
            <template #value="slotProps: { value: Product }">
              {{
                slotProps.value
                  ? slotProps.value.variant_name ?? ts.tGlobal('master_data')
                  : ts.tModule('product_details.create_variant.select_product')
              }}
            </template>
            <template #option="slotProps">
              {{ slotProps.option.variant_name ?? ts.tGlobal('master_data') }}
            </template>
          </pDropdown>
        </div>
        <div class="field mb-3">
          <label for="variant-name">
            {{ ts.tModule('product_details.create_variant.copy_assets_from.label') }}
          </label>
          <pDropdown
            v-model="copyAssetsFrom"
            option-label="variant_name"
            class="w-full"
            :show-clear="true"
            :options="variants"
          >
            <template #value="slotProps: { value: Product }">
              {{
                slotProps.value
                  ? slotProps.value.variant_name ?? ts.tGlobal('master_data')
                  : ts.tModule('product_details.create_variant.select_product')
              }}
            </template>
            <template #option="slotProps: { option: Product }">
              {{ slotProps.option.variant_name ?? ts.tGlobal('master_data') }}
            </template>
          </pDropdown>
        </div>
        <div
          class="bg-white border-100 border-top-1 bottom-0 flex justify-content-between mt-5 mt-auto py-3 sticky"
        >
          <p-button
            plain
            text
            :label="ts.tGlobal('cancel')"
            class="ml-auto mr-2"
            @click="hideDetails"
          />
          <p-button :label="ts.tGlobal('save')" icon-pos="right" @click="handleSubmit()" />
        </div>
      </div>
    </div>
  </pSidebar>
</template>
