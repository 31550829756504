<script setup lang="ts">
import { inject } from 'vue';

import { tsKey } from '@/general/services/translations/translation.key';
import { fieldServiceKey } from '../../ts/keys/fields.keys';
import { KeyValueOptions } from 'platform-unit2-api/attributes';
import FieldTypeSelect from 'admin/modules/fields/components/field-type-select.vue';
import ParentFieldSelect from '../parent-field-select.vue';

/**
 * If this table is to be used in another view, the service must be provided.
 *
 * Or this component can be rewritten to also use props.
 * Service should then based on typed "TableService | OverViewService" that implement a common interface.
 */
const ts = inject(tsKey)!;
const service = inject(fieldServiceKey)!;
</script>

<template>
  <div class="field mb-3">
    <label for="name">{{ ts.tForms('name') }}</label>
    <p-input-text
      id="name"
      v-model="service.partialObject.key"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('key') }"
      type="text"
    />
    <small
      v-if="service.hasError('key')"
      :class="{ 'p-error block': service.hasError('key') }"
      class="hidden"
    >
      {{ service.fieldErrorMessage('key').toString() }}
    </small>
  </div>
  <div class="field mb-3">
    <label for="type">{{ ts.tForms('type') }}</label>
    <FieldTypeSelect
      class="w-full"
      :model-value="service.options.type!"
      @update:model-value="(e) => (service.options.type = e)"
    />
    <small
      v-if="service.hasError('options.type')"
      :class="{ 'p-error block': service.hasError('options.type') }"
      class="hidden"
    >
      {{ service.fieldErrorMessage('options.type').toString() }}
    </small>
  </div>
  <div class="field mb-3">
    <label>{{ ts.tForms('required') }}</label>
    <div>
      <p-input-switch
        :model-value="service.partialObject.required"
        @update:model-value="service.partialObject.required = JSON.parse($event)"
      />
    </div>
  </div>
  <!-- Temporarily removed until backend is reworked-->
  <div class="field mb-3">
    <label>{{ ts.tModule('globalField') }}</label>
    <div>
      <p-input-switch
        :model-value="service.partialObject.global_attribute"
        @update:model-value="service.partialObject.global_attribute = JSON.parse($event)"
      />
      <small
        v-if="service.hasError('global_attribute')"
        :class="{ 'p-error block': service.hasError('global_attribute') }"
        class="hidden"
      >
        {{ service.fieldErrorMessage('global_attribute').toString() }}
      </small>
    </div>
  </div>

  <div class="field mb-3">
    <label>{{ ts.tModule('helpText') }}</label>
    <div>
      <p-textarea v-model="service.options.helpText" rows="2" class="w-full" :auto-resize="true" />
    </div>
  </div>
  <div class="field mb-3">
    <label for="name">{{ ts.tModule('articleLink') }}</label>
    <p-input-text
      id="name"
      v-model="service.options.articleLink"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('key') }"
      type="text"
    />
  </div>

  <div class="field mb-3">
    <label>{{ ts.tForms('maxLength') }}</label>
    <div>
      <p-input-text v-model="service.options.maxLength" rows="2" class="w-full" />
    </div>
  </div>

  <div class="field mb-3">
    <label>{{ ts.tGlobal('parent') }}</label>
    <div>
      <ParentFieldSelect
        class="w-full"
        :model-value="service.parent"
        :exclude="service.partialObject"
        @update:model-value="(e) => (service.parent = e)"
      />
      <small
        v-if="service.hasError('parent_id')"
        :class="{ 'p-error block': service.hasError('parent_id') }"
        class="hidden"
      >
        {{ service.fieldErrorMessage('parent_id').toString() }}
      </small>
    </div>
  </div>

  <div v-if="service.parent != null" class="field mb-3">
    <label>{{ ts.tModule('groupOrder') }}</label>
    <div>
      <p-input-text v-model="service.options.group_order" rows="2" class="w-full" />
    </div>
  </div>

  <div v-if="service.parent != null" class="field mb-3">
    <label>{{ ts.tModule('groupSize') }}</label>
    <div>
      <pDropdown
        id="type"
        v-model="service.options.group_size"
        :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
        :options-label="ts.tModule('groupSize')"
        :placeholder="ts.tModule('groupSize')"
        :class="{ 'p-invalid': service.hasError('groupSize') }"
        class="w-full"
      />
    </div>
  </div>

  <!-- Renders multiple option on group-->
  <div v-if="service.conditionallyRender('GROUP_FIELD')" class="field mb-3">
    <label>{{ ts.tModule('multiple') }}</label>
    <div>
      <p-input-switch v-model="service.options.multiple" />
    </div>
  </div>

  <div v-if="service.conditionallyRender('GROUP_FIELD')" class="field mb-3">
    <label>{{ ts.tModule('collapsible') }}</label>
    <div>
      <p-input-switch v-model="service.options.collapsible" />
    </div>
  </div>
  <!-- Renders additional choices fields when the field type is choice field or multiple choice field-->
  <div
    v-if="
      service.conditionallyRender('MULTIPLE_CHOICE_FIELD') ||
      service.conditionallyRender('CHOICE_FIELD')
    "
    class="field mb-3"
  >
    <label>{{ ts.tModule('choices') }}</label>
    <p-chips v-model="service.options.choices" style="width: 100%" />
  </div>

  <!-- Renders additional select values input -->
  <div v-if="service.conditionallyRender('INPUT_SELECT_FIELD')" class="field mb-3">
    <label>{{ ts.tModule('inputSelectValues') }}</label>
    <p-chips v-model="service.options.selectValues" style="width: 100%" separator="," />

    <div v-if="service.options.selectValues" class="flex flex-column mt-4">
      <label>{{ ts.tModule('chooseDefaultUnit') }}</label>
      <pDropdown
        v-model="service.options.default_unit"
        class="mt-2"
        :options="service.options.selectValues"
      ></pDropdown>
    </div>
  </div>

  <!-- Renders additional currencies input -->
  <div v-if="service.conditionallyRender('FINANCIAL_FIELD')" class="field mb-3">
    <label>{{ ts.tModule('currencies') }}</label>
    <p-chips v-model="service.options.currencies" style="width: 100%" separator="," />

    <div v-if="service.options.currencies" class="flex flex-column mt-4">
      <label>{{ ts.tModule('chooseDefaultCurrency') }}</label>
      <pDropdown
        v-model="service.options.default_unit"
        class="mt-2"
        :options="service.options.currencies"
      ></pDropdown>
    </div>
  </div>

  <!-- Renders additional key and value input fields -->
  <div v-if="service.conditionallyRender('KEY_VALUE_FIELD')">
    <div class="field mb-3 w-full">
      <div class="mb-2 w-full">
        <label>{{ ts.tModule('selectType') }}</label>
      </div>
      <p-input-switch
        :model-value="service.options.multiSelect"
        @update:model-value="service.options.multiSelect = JSON.parse($event)"
      />
    </div>
    <div class="field mb-3 w-full">
      <label>{{ ts.tModule('preDefinedOptions') }}</label>
      <div class="overflow-y-scroll pt-3 relative">
        <div
          v-for="(choice, index) in service.options.choices"
          :key="index"
          class="align-items-center flex mb-3"
        >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">{{ ts.tModule('keyValueField.key') }}</span>
            <p-input-text
              v-model="(service.choices[index] as KeyValueOptions).key"
              :placeholder="ts.tModule('keyValueField.key')"
            />
          </div>
          <span style="color: #708da9" class="mx-2">
            <i class="mdi mdi-arrow-right"></i>
          </span>
          <div class="mr-1 p-inputgroup">
            <span class="p-inputgroup-addon">{{ ts.tModule('keyValueField.value') }}</span>
            <p-input-text
              v-model="(service.choices[index] as KeyValueOptions).value"
              :placeholder="ts.tModule('keyValueField.value')"
            />
          </div>
          <p-button
            text
            severity="danger"
            rounded
            class="flex-shrink-0 p-button-small"
            icon="mdi mdi-delete-outline"
            @click="service.deleteChoice(index)"
          />
        </div>
        <p-button
          style="color: #708da9"
          outlined
          severity="secondary"
          class="bg-white block border-dashed bottom-0 mt-3 sticky w-full"
          :label="ts.tModule('keyValueField.addPair')"
          icon="mdi mdi-plus"
          @click="service.addChoice()"
        ></p-button>
      </div>
    </div>
  </div>

  <!-- Renders the selected options as a json in the textarea -->
  <div class="field mb-3">
    <label for="key">{{ ts.tForms('options') }}</label>
    <p-textarea
      v-model="service.editorFieldValue"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('options') }"
      :auto-resize="true"
      rows="5"
    />
    <small :class="{ 'p-error block': !service.isValidJson }" class="hidden">{{
      ts.tModule('keyValueField.notJson')
    }}</small>
  </div>
</template>
