<script setup lang="ts">
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import { ref, watch } from 'vue';
import useFormValidation from 'composables/form-validation';
import { useRoute } from 'vue-router';
import { useOrganisationService } from 'admin/modules/organisations/organisations.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { UserCompact } from 'platform-unit2-api/users';
import { UpdateOrganisationRequest } from 'platform-unit2-api/organisations';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const route = useRoute();
const { resetFormErrors, fieldErrorMessage, hasError, parseFormError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'organisations');
const confirmService = new ConfirmService();

/** Constants */
const saving = ref(false);

/** Organisation service */
const organisationService = useOrganisationService();
const loadingOrganisation = organisationService.loadingOrganisationDetails;
const loadingUsers = organisationService.loadingOrganisationUsers;
/** Find organisation details to edit */
const editorOrganisation = organisationService.showOrganisationDetails(+route.params.id);
const organisationUsers = organisationService.showOrganisationUsers(+route.params.id);
const selectedBillingUsers = ref<UserCompact[]>([]);

/**‌ Add new organisation */
const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  saving.value = true;

  const organisation: UpdateOrganisationRequest = {
    id: editorOrganisation.value!.id,
    name: editorOrganisation.value?.name,
    description: editorOrganisation.value?.description ? editorOrganisation.value.description : '',
    friendly_payment_reminder: editorOrganisation.value?.friendly_payment_reminder ?? false,
    urgent_payment_reminder: editorOrganisation.value?.urgent_payment_reminder ?? false,
    is_demo: editorOrganisation.value?.is_demo,
  };

  const result = await organisationService.updateOrganisation(
    parseInt(route.params.id.toString()),
    organisation,
    parseFormError,
  );

  if (
    editorOrganisation.value?.friendly_payment_reminder ||
    editorOrganisation.value?.urgent_payment_reminder
  ) {
    organisationService.updateBillingUsers(
      parseInt(route.params.id.toString()),
      selectedBillingUsers.value?.map((user) => user.id),
    );
  }

  if (result.passed) {
    emit('refresh');
    emit('hide');
  }

  saving.value = false;
};

/** Delete Organisation */
const confirmOrganisationDelete = (event: PointerEvent, id?: number) => {
  if (id == null) {
    return;
  }

  confirmService.confirmDelete({
    event: event,
    group: 'organisation-edit',
    message: ts.deleteConfirm(),
    callback: () => deleteOrganisation(id),
  });
};

const deleteOrganisation = async (id: number): Promise<void> => {
  organisationService.remove(id);
  emit('refresh');
  emit('hide');
};

watch(
  () => editorOrganisation.value,
  () => {
    selectedBillingUsers.value = editorOrganisation.value?.billing_users ?? [];
  },
);
</script>
<template>
  <SidebarSkeleton v-if="loadingOrganisation || loadingUsers || editorOrganisation == null" />
  <CrudSidebar
    v-else
    :title="ts.moduleUpdateTitle"
    :subtitle="ts.moduleUpdateSubTitle"
    :saving="saving"
    @cancel="emit('hide')"
    @save="handleSubmit"
    @delete="confirmOrganisationDelete($event, editorOrganisation?.id)"
  >
    <template #sidebar-data>
      <!-- Name -->
      <div class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          id="name"
          v-model="editorOrganisation.name"
          :class="{ 'p-invalid': hasError('name') }"
          class="w-full"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <!-- Description -->
      <div class="field mb-3">
        <label for="description">{{ ts.tGlobal('description') }}</label>
        <p-textarea
          id="description"
          v-model="editorOrganisation.description"
          :class="{ 'p-error block': hasError('description') }"
          class="w-full"
          rows="5"
          cols="60"
        />
        <small
          v-if="hasError('description')"
          :class="{ 'p-error block': hasError('description') }"
          class="hidden"
          >{{ fieldErrorMessage('description').toString() }}</small
        >
      </div>
      <!-- Workspaces -->
      <div class="field mb-5">
        <ClientSelect
          v-if="editorOrganisation"
          v-model="editorOrganisation.workspaces"
          disabled
          multiselect
        />
        <small class="block mb-2">{{ ts.tModule('workspaceInfo') }}</small>
      </div>

      <!-- Workflow Labels -->
      <div class="field mb-5">
        <label for="timeline">{{ ts.tModule('workflowLabels') }}</label>
        <small class="block">{{ ts.tModule('workflowLabelsInfo') }}</small>
        <p-timeline
          id="timeline"
          :value="editorOrganisation.statuses"
          layout="horizontal"
          align="top"
        >
          <template #content="slotProps">
            <p>{{ slotProps.item.label }}</p>
          </template>
          <template #marker="slotProps">
            <div class="p-timeline-event-marker" :style="'border-color: ' + slotProps.item.color" />
          </template>
        </p-timeline>
      </div>
      <p-divider></p-divider>
      <!-- #region Payment reminders -->
      <h3 class="mb-3">
        {{ ts.tModule('paymentReminders', { choice: 2 }) }}
      </h3>
      <p-message severity="warn" icon="null" :closable="false">
        <div class="field mb-3">
          <label class="block font-bold w-full">{{
            ts.tModule('friendlyPaymentReminder.title')
          }}</label>
          <small class="block mb-2 w-full">{{
            ts.tModule('friendlyPaymentReminder.description')
          }}</small>
          <p-input-switch
            v-model="editorOrganisation.friendly_payment_reminder"
            :disabled="editorOrganisation.urgent_payment_reminder"
          ></p-input-switch>
        </div>
      </p-message>
      <p-message severity="error" icon="null" :closable="false">
        <div class="field mb-3">
          <label class="block font-bold w-full">{{
            ts.tModule('urgentPaymentReminder.title')
          }}</label>
          <small class="block mb-2 w-full">{{
            ts.tModule('urgentPaymentReminder.description')
          }}</small>
          <p-input-switch
            v-model="editorOrganisation.urgent_payment_reminder"
            :disabled="editorOrganisation.friendly_payment_reminder"
          ></p-input-switch>
        </div>
      </p-message>
      <pMultiselect
        v-model="selectedBillingUsers"
        class="w-full"
        display="chip"
        :options="organisationUsers?.data ?? []"
        data-key="id"
        option-label="name"
        :placeholder="ts.tModule('selectUsersPlaceholder')"
        :disabled="
          !editorOrganisation.friendly_payment_reminder &&
          !editorOrganisation.urgent_payment_reminder
        "
      />
      <!-- #region Demo account -->
      <h3 class="mb-3 mt-3">
        {{ ts.tModule('demoAccount.title') }}
      </h3>
      <p-message severity="info" icon="null" :closable="false">
        <div class="field mb-3">
          <label class="block font-bold w-full">{{ ts.tModule('demoAccount.bannerTitle') }}</label>
          <small class="block mb-2 w-full">{{ ts.tModule('demoAccount.bannerDescription') }}</small>
          <p-input-switch v-model="editorOrganisation.is_demo"></p-input-switch>
        </div>
      </p-message>
      <!-- #endregion -->
      <p-confirm-popup group="organisation-edit" />
    </template>
  </CrudSidebar>
</template>
