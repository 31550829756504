//need to be split
import { ModuleDetail } from 'platform-unit2-api/modules';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Locale, LocaleDetails } from 'platform-unit2-api/locales';
import { Product } from 'platform-unit2-api/products';
import { ExportChangedProduct } from 'platform-unit2-api/exports';

export interface CreateExport {
  pageIndex?: number;
  mailTo: string[];
  customMessage: string;
  selectedModule: ModuleDetail;
  product_ids: number[];
  selectedLocale: LocaleDetails;
  changed_products: ExportChangedProduct;
  includeData: boolean;
  includeAssets: boolean;
  exportName: string;
  exportCron: string;
  isScheduled: boolean;
  scheduleType: 'Daily' | 'Weekly' | 'Monthly';
  dailySchedule: number;
  time: Date;
  weekDays: number[];
  daysOfMonth: string[];
  exportTypeIsGeneric: boolean;
  products: Product[];
  useOverwrittenNames?: boolean;
  ignoreProductStatus: boolean;
}

export enum PageIndexEnum {
  CHANNEL_VIEW_PAGE = 0,
  SETTING_EXPORT_VIEW_PAGE = 1,
  SCHEDULE_VIEW_PAGE = 2,
  CONTACT_VIEW_PAGE = 3,
  INFO_EXPORT_VIEW_PAGE = 4,
  //Generic Flow
  GENERIC_VIEW_PAGE = 0,
  PRODUCT_OVERVIEW_VIEW_PAGE = 1,
}

export interface PageObject {
  pageIndex: number;
  exportTypeIsGeneric: boolean;
}

export interface LanguageMenu {
  label: string;
  icon: string;
  value: Locale;
  command: (e: any) => void;
}

export interface ProductDatamodelSelectModel extends Datamodel {
  hide_options: boolean;
}

export interface UpdateProductFieldData {
  attribute_id: number;
  value: string | undefined;
  locale_id: number;
  overwrite: boolean;
  path: string | undefined;
  delete_paths: string[];
  reorder_paths: string[];
}

export interface ProductCalendarQuery {
  date: string;
  type: string;
}

export interface Status {
  id: number;
  name: string | undefined;
  label?: string;
}
