import { ref, unref } from 'vue';
import { UseShowProps } from './types';
import { useDispatch } from '@/general/composables/UseDispatch';
import { DispatchSuccess, DisplayToastSetting } from '@/general/composables/UseDispatch/types';
import { handleSuccess } from '@/general/composables/UseDispatch/helpers';
import { TranslationService } from '@/general/services/translations/translation.service';

export function useShow<T>({ action, options }: UseShowProps<T>) {
  const { dispatch, loading } = useDispatch();
  const ts = new TranslationService('general', 'components');
  const data = ref<T>();

  const show = async (id: number) => {
    dispatch(unref(action), {
      parameters: { id: id },
      toast: {
        rejectMessage: options.rejectMessage ?? ts.loadFailed(),
        displaySetting: DisplayToastSetting.NONE,
      },
      successCallback: (success: DispatchSuccess<T>) => {
        data.value = success.data;
        handleSuccess(options, data.value);
      },
      rejectCallback: options.reject ?? undefined,
    });
  };

  options.autoLoad || options.autoLoad === undefined;

  return { loading, show, data };
}
