<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { Attribute } from 'platform-unit2-api/attributes';
import { DatamodelService } from 'admin/modules/datamodels/ts/services/datamodel.service';
import TemplateService from 'admin/modules/templates/ts/services/template.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { Template } from 'platform-unit2-api/templates';
import {
  TemplateEndpoint,
  CreateTemplateEndpointRequest,
} from 'platform-unit2-api/template-endpoints';
import useFormValidation from 'composables/form-validation';

/** Props */
export interface TemplateTableProps {
  template?: Template;
  manualTemplate?: Template;
}

const props = defineProps<TemplateTableProps>();

/** Emits */
const emit = defineEmits<{
  (e: 'update:manual-template', template?: Template): void;
}>();

/** Composables */
const { resetFormErrors, parseFormError, hasError } = useFormValidation();

/** Services */
const datamodelService = new DatamodelService();
const templateService = new TemplateService();
const ts = new TranslationService('admin', 'templates');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
/** Constants */
const newEndpoint = ref<CreateTemplateEndpointRequest>({} as CreateTemplateEndpointRequest);

const attributes = ref<Attribute[]>([]);

const createEndpoint = () => {
  resetFormErrors();
  if (!props.manualTemplate?.name) {
    return;
  }

  const endpoint: TemplateEndpoint = {
    template_id: props.manualTemplate.id,
    attribute_id: newEndpoint.value.attribute_id,
    source: newEndpoint.value.source,
    source_index: newEndpoint.value.source_index,
    label: newEndpoint.value.label,
    key: newEndpoint.value.key,
    description: newEndpoint.value.description,
    maxLength: newEndpoint.value.maxLength,
    required: newEndpoint.value.required ?? false,
  } as TemplateEndpoint;

  templateService
    .createEndpoint(endpoint as TemplateEndpoint)
    .then((res) => {
      const updatedTemp = props.manualTemplate;
      updatedTemp?.endpoints.push(res);
      emit('update:manual-template', updatedTemp);
      newEndpoint.value.label = '';
      newEndpoint.value.key = '';
      newEndpoint.value.description = '';
      newEndpoint.value.maxLength = undefined;
      newEndpoint.value.required = false;

      toastService.displaySuccessToast(ts.createSuccess());
    })
    .catch((err) => {
      parseFormError(err, () => {
        toastService.displayErrorToast(ts.createFailed());
      });
    });
};

const confirmEndpointDelete = (event: PointerEvent, templateEndpoint: TemplateEndpoint) => {
  confirmService.confirmDelete({
    event: event,
    group: 'template-edit',
    message: ts.deleteConfirm(),
    callback: () => removeEndpoint(templateEndpoint),
  });
};

const removeEndpoint = (templateEndpoint: TemplateEndpoint) => {
  if (!props.manualTemplate) {
    return;
  }

  templateService
    .deleteEndpoints(templateEndpoint)
    .then(() => {
      const updatedTemp = props.manualTemplate;
      updatedTemp?.endpoints.splice(updatedTemp?.endpoints.indexOf(templateEndpoint), 1);
      toastService.displaySuccessToast(ts.deleteSuccess());
      emit('update:manual-template', updatedTemp);
    })
    .catch(() => {
      toastService.displayErrorToast(ts.deleteFailed());
    });
};

/** Lifecycle Methods */
onBeforeMount(async () => {
  attributes.value = await datamodelService.getAllODMAttributes().catch(() => {
    toastService.displayErrorToast(ts.loadFailed());
    return [];
  });
});
</script>
<template>
  <label v-if="template?.endpoints?.length">
    {{ ts.tModule('edit.endpoints') }}
    <p-badge
      v-if="template?.endpoints?.length ?? manualTemplate?.endpoints?.length"
      :value="template ? template.endpoints?.length : manualTemplate?.endpoints?.length"
      class="ml-3"
    />
  </label>
  <pDataTable
    :value="template?.endpoints ? template?.endpoints : manualTemplate?.endpoints"
    edit-mode="row"
    data-key="id"
    responsive-layout="scroll"
  >
    <p-column field="key" :header="ts.tModule('tableHeaders.key')">
      <template #body="slotProps">
        <p-input-text v-model="slotProps.data.key" class="w-full" :disabled="true" />
      </template>
      <template v-if="template == undefined && manualTemplate != undefined" #footer>
        <p-input-text
          v-model="newEndpoint.key"
          class="w-full"
          placeholder="Key"
          :class="{ 'p-invalid': hasError('key') }"
        />
      </template>
    </p-column>
    <p-column field="label" :header="ts.tModule('tableHeaders.label')">
      <template #body="slotProps">
        <p-input-text v-model="slotProps.data.label" class="w-full" />
      </template>
      <template v-if="template == undefined && manualTemplate != undefined" #footer>
        <p-input-text
          v-model="newEndpoint.label"
          placeholder="Label"
          class="w-full"
          :class="{ 'p-invalid': hasError('label') }"
        />
      </template>
    </p-column>
    <p-column field="description" :header="ts.tGlobal('description')">
      <template #body="slotProps">
        <p-input-text v-model="slotProps.data.description" class="w-full" />
      </template>
      <template v-if="template == undefined && manualTemplate != undefined" #footer>
        <p-input-text v-model="newEndpoint.description" class="w-full" placeholder="Description" />
      </template>
    </p-column>

    <p-column field="maxLength" :header="ts.tForms('maxLength')">
      <template #body="slotProps">
        <p-input-number
          v-model="slotProps.data.maxLength"
          class="w-full"
          mode="decimal"
          :use-grouping="false"
        />
      </template>
      <template v-if="template == undefined && manualTemplate != undefined" #footer>
        <p-input-number
          v-model="newEndpoint.maxLength"
          class="w-full"
          mode="decimal"
          placeholder="255"
          :use-grouping="false"
        />
      </template>
    </p-column>
    <p-column field="required" header="Required">
      <template #body="slotProps">
        <p-input-switch v-model="slotProps.data.required" />
      </template>
      <template v-if="template == undefined && manualTemplate != undefined" #footer>
        <p-input-switch v-model="newEndpoint.required" />
      </template>
    </p-column>
    <p-column>
      <template v-if="template == undefined && manualTemplate != undefined" #body="slotProps">
        <p-button
          icon="mdi mdi-delete-outline"
          severity="danger"
          rounded
          text
          @click="confirmEndpointDelete($event, slotProps.data)"
        />
      </template>
      <template v-if="template == undefined && manualTemplate != undefined" #footer>
        <p-button icon="mdi mdi-plus" text rounded @click="createEndpoint()" />
      </template>
    </p-column>
    <template #empty>
      <div class="full">
        <p-message severity="info" :closable="false">
          {{ ts.tModule('edit.addEndpoint') }}
        </p-message>
      </div>
    </template>
  </pDataTable>
  <p-confirm-popup group="template-edit" />
</template>

<style lang="scss" scoped>
.p-datatable-wrapper {
  padding-bottom: 0px !important;
}

:deep .p-datatable-table .p-datatable-tbody .p-datatable-emptymessage {
  height: unset !important;
}
</style>
