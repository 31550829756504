import AttributeTranslations from 'admin/modules/attributes/translation/en';
import ClientTranslations from 'admin/modules/clients/translation/en';
import DashboardTranslations from 'admin/modules/dashboard/translation/en';
import DatamodelTranslations from 'admin/modules/datamodels/translation/en';
import FieldsTranslations from 'admin/modules/fields/translation/en';
import LocaleTranslations from 'admin/modules/locales/translation/en';
import ModuleTranslations from 'admin/modules/modules/translation/en';
import OrganisationTranslations from 'admin/modules/organisations/translation/en';
import RetailerTranslations from 'admin/modules/retailers/translation/en';
import RoleTranslations from 'admin/modules/roles/translation/en';
import SettingTranslations from 'admin/modules/settings/translation/en';
import TemplateTranslations from 'admin/modules/templates/translation/en';
import ThumbnailTranslations from 'admin/modules/thumbnails/translation/en';
import UserTranslations from 'admin/modules/users/translation/en';
import validationTranslations from 'admin/modules/validation-rules/translations/en';
import statusNotificationsTranslation from 'admin/modules/status-notifications/translations/en';

export default {
  admin: {
    ...AttributeTranslations,
    ...ClientTranslations,
    ...DashboardTranslations,
    ...DatamodelTranslations,
    ...FieldsTranslations,
    ...LocaleTranslations,
    ...ModuleTranslations,
    ...OrganisationTranslations,
    ...RetailerTranslations,
    ...RoleTranslations,
    ...SettingTranslations,
    ...TemplateTranslations,
    ...ThumbnailTranslations,
    ...UserTranslations,
    ...validationTranslations,
    ...statusNotificationsTranslation,
  },
};
