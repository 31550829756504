import { RouteFactory } from '@/core/router/route.factory';
import AdminDashboard from 'admin/modules/dashboard/views/admin-dashboard.view.vue';
import { RouteRecordRaw } from 'vue-router';

const dashboardRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/dashboard',
    name: 'dashboard',
    title: 'Dashboard',
    component: AdminDashboard,
    translations: {
      platform: 'admin',
      module: 'dashboard',
    },
  }),
];

export default dashboardRoutes;
