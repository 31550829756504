<script setup lang="ts">
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import { ref, watch } from 'vue';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { CategoryCompact } from 'platform-unit2-api/categories';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

/** Consts */
const selectedCategory = ref<CategoryCompact>({ name: props.modelValue } as CategoryCompact);
const updateCategory = (event: DropdownChangeEvent) => {
  emit('update:modelValue', event.value.name);
};

watch(
  () => props.modelValue,
  () => {
    selectedCategory.value = { name: props.modelValue } as CategoryCompact;
  },
);
</script>

<template>
  <div class="w-full">
    <CategorySelect
      v-model="selectedCategory"
      :disabled="disabled"
      :show-clear="false"
      hide-label
      data-key="name"
      class="h-full"
      @change="(event: DropdownChangeEvent) => updateCategory(event)"
    />
  </div>
</template>
