import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create role',
  subTitle: 'Here you can create a new role.',
};

const update: TranslationObjectAction = {
  title: 'Edit role',
  subTitle: 'Here you can edit a role.',
};

const translationObject: TranslationObject = {
  title: 'Role | Roles',
  placeholder: 'Select a role | Select roles',

  permissionGroupsLabel: 'Permission groups',
  permissionGroupsPlaceholder: 'Select permission groups',
  permissionsLabel: 'Permissions',
  permissionsPlaceholder: 'Select permissions',
  permissionGroupsNotFound: 'No permission groups found.',
  permissionsNotFound: 'No permissions found.',

  create: create,
  update: update,
};

const tranlation: Translation = {
  roles: translationObject,
};

export default tranlation;
