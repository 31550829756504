import {
  DataTableFilterInterface,
  DataTableFilterService,
  FilterPageKey,
} from '@/general/services/data-table-filter-service/data-table-filter.service';
import ProductFilters from '@/platforms/supplier/modules/products/ts/product-data-table-filter-utils';
import { TableColumnInterface, productTableColumns } from './product-table.constants';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';
import { useRoute, useRouter } from 'vue-router';
import { GetProductsRequest, Product, ProductsRestService } from 'platform-unit2-api/products';
import TableColumnSelectInterface from '@/general/ui/components/table-column-select-sidebar/table-column-select.interface';
import { ProductCalendarQuery } from '../ts/products.types';
import { FilterMatchMode } from 'primevue/api';
import { Store, useStore } from 'vuex';
import { ProductStatusesRestService } from 'platform-unit2-api/product-statuses';
import { MetaGtinResponse, PaginationObject, CrudDataFilterOption } from 'platform-unit2-api/core';

export default class ProductTableService
  extends RefetchService
  implements TableColumnSelectInterface
{
  public checkedProductRows: Product[] = [];
  public selectedProductsIds: number[] = [];
  public productCalendarQuery: ProductCalendarQuery | undefined;
  public pipelineId: number | undefined;
  public excludedProductIds: number[] = [];

  public getSelectedProductIds(): number[] {
    return this.checkedProductRows.map((product) => product.id);
  }

  private _productsRestService = new ProductsRestService();

  public get isAllChecked() {
    return this.products.every((product) =>
      this.checkedProductRows.map((product) => product.id).includes(product.id),
    );
  }
  public set isAllChecked(value: boolean) {
    this._toggleCheckAll(value);
  }

  public loadingProductIds: string[] = [];
  private _loadingData = true;
  private _data: Product[] = [];
  private _workspaceName: string;
  private _tableColumns: TableColumnInterface[] = [];
  public get tableAllColumns(): TableColumnInterface[] {
    return this._tableColumns;
  }

  public set tableColumns(columns: TableColumnInterface[]) {
    this._tableColumns = columns;
  }

  public get tableSelectedColumns(): TableColumnInterface[] {
    return this._tableColumns.filter((column) => column.selected);
  }

  public get products(): Product[] {
    return this._data;
  }
  public get loading(): boolean {
    return this._loadingData;
  }

  public get hasFilteredGtins(): boolean {
    return this.validGtins.length > 0;
  }
  public productTableFilterService = new DataTableFilterService<ProductFilters>(
    FilterPageKey.PRODUCT_OVERVIEW,
  );

  public productFilters: DataTableFilterInterface<ProductFilters> =
    {} as DataTableFilterInterface<ProductFilters>;

  //#region TableColumnSelectInterface
  private _isColumnSelectOpen = false;

  get isColumnSelectOpen(): boolean {
    return this._isColumnSelectOpen;
  }
  get isColumnSelectLoading(): boolean {
    return false;
  }

  public readonly ts = new TranslationService('supplier', 'products');
  //#endregion TableColumnSelectInterface
  public readonly confirmService = new ConfirmService();

  public readonly toastService = ToastService.getInstance();

  public readonly router = useRouter();

  private _store: Store<any>;

  private _productStatusApi = new ProductStatusesRestService();

  public gtinsSearch = '';
  public missingGtins: string[] = [];

  public productQueryIds: number[] = [];

  public validGtins: string[] = [];
  public invalidGtins: string[] = [];

  public createdVariant = false;

  private _route = useRoute();

  private _productIds: number[] = [];

  constructor(workspaceName: string, excludeIds: number[], productIds: number[]) {
    super();
    this._store = useStore();
    this.refetch = this.getProducts as any;
    this.excludedProductIds = excludeIds;
    this._productIds = productIds;
    this._workspaceName = workspaceName;
    this.setFiltersFromLocalStorage();
    this.getColumnFromLocalStorage();
  }

  private _shouldGtinSearchBeApplied(): boolean {
    return (
      this.productFilters.gtinsSearch !== '' &&
      this.productFilters.gtinsSearch !== this.gtinsSearch &&
      this.productFilters.gtinsSearch !== null &&
      typeof this.productFilters.gtinsSearch !== 'object'
    );
  }

  private _handleGtinSearchFromStorage() {
    if (this._shouldGtinSearchBeApplied()) {
      this.gtinsSearch = this.productFilters.gtinsSearch;
      this.applyGtinFilter();
    }
  }

  private setFiltersFromLocalStorage(): void {
    const filters = this.productTableFilterService.getFiltersForWorkspace(this._workspaceName);
    this.productFilters = filters;
  }
  private getColumnFromLocalStorage(): void {
    const columns = this.productTableFilterService.getFiltersForWorkspace(
      this._workspaceName,
    ).columns;

    if (columns && columns.length > 0) {
      this._tableColumns = columns;
      return;
    }

    this._tableColumns = productTableColumns;
  }
  public saveColumns(): void {
    const filters = this.productTableFilterService.getFiltersForWorkspace(this._workspaceName);
    filters.columns = this._tableColumns;
    this.productTableFilterService.setFilters(this._workspaceName, filters);
  }

  public async resetFilters() {
    this.productFilters = this.productTableFilterService.resetFilters(this._workspaceName);
    this.tableColumns = this.productFilters.columns;
    await this.checkUserPreferences(true);
    this.setTableSortFromLocalStorage();

    this.productTableFilterService.setFilters(this._workspaceName, this.productFilters);

    this.gtinsSearch = '';
    this.missingGtins = [];
    this.validGtins = [];

    //setting this triggers an refetch by the refetch service
    this._fetchVariables = {
      ...this._fetchVariables,
      page: 1,
      query: '',
    };

    this.refetch();
  }

  //#region TableColumnSelectInterface
  public openColumnSelect(): void {
    this._isColumnSelectOpen = true;
  }

  public closeColumnSelect(): void {
    this._isColumnSelectOpen = false;
  }
  //#endregion TableColumnSelectInterface

  private _showDetails(product: Product) {
    this.router.push({
      name: 'product-attributes',
      params: {
        id: product.id,
      },
    });
  }

  private _toggleCheckAll(checked: boolean) {
    const productIds = this.products.map((product) => product.id);

    if (checked) {
      this.checkedProductRows = [
        ...this.checkedProductRows,
        ...this.products.filter(
          (product) => !this.checkedProductRows.map((prod) => prod.id).includes(product.id),
        ),
      ];
    } else {
      this.checkedProductRows = this.checkedProductRows.filter(
        (row: Product) => !productIds.includes(row.id),
      );
    }
  }

  /* Checks whether an image is clicked within one of the colums to display the preview
it always works when you click on the grey part of the preview, however if you click on
the svg inside the preview it will redirect to the PDP. to prevent this there is a check
inplace to see if the target is a SVG if so return
second is for the checkbox, if you clicked miss you would redirect. now it checks if the cell is
a p-checkbox. frozen column are also not able to redirect. */
  public onRowClick(event: DataTableRowClickEvent) {
    const eventTarget = event.originalEvent.target as Element;
    if (
      eventTarget instanceof SVGElement ||
      eventTarget?.id === 'select-checkbox' ||
      eventTarget?.classList.contains('p-frozen-column') ||
      eventTarget.firstElementChild?.firstElementChild?.classList.contains('p-checkbox')
    ) {
      return;
    } else {
      this._showDetails(event.data);
    }
  }

  public getProducts() {
    this._loadingData = true;

    this._handleGtinSearchFromStorage();

    const filters = this.productTableFilterService.getFiltersForWorkspace(this._workspaceName);
    const brandIds = (filters as DataTableFilterInterface<ProductFilters>).brand_id?.value?.map(
      (brand: CrudDataFilterOption) => {
        return brand.id as number;
      },
    );

    const categoryIds = (filters as DataTableFilterInterface<ProductFilters>).category?.value?.map(
      (group: CrudDataFilterOption) => {
        return String(group.id);
      },
    );

    const dataModelIds = (
      filters as DataTableFilterInterface<ProductFilters>
    ).datamodel_id?.value?.map((datamodel: CrudDataFilterOption) => {
      return datamodel.id;
    });

    const variantIds = (
      filters as DataTableFilterInterface<ProductFilters>
    ).variant_name?.value?.map((group: CrudDataFilterOption) => {
      return String(group.id);
    });

    const moduleIds = (filters as DataTableFilterInterface<ProductFilters>).module_id?.value?.map(
      (item: CrudDataFilterOption) => {
        return String(item.id);
      },
    );

    const statusLabels = (
      filters as DataTableFilterInterface<ProductFilters>
    ).product_status_id?.value?.map((item: CrudDataFilterOption) => {
      return item.name ? item.name : (item.label as string);
    });

    this._productsRestService
      .searchProducts({
        page: this.page,
        limit: this.limit,
        query: this.query,
        sortBy: this.sortBy,
        brand_ids: brandIds,
        category_ids: categoryIds,
        datamodel_ids: dataModelIds as any,
        variants: variantIds,
        pipeline_id: this.pipelineId,
        gtins: this.validGtins,
        module_ids: moduleIds as any,
        include_ids: this.selectedProductsIds,
        owner_ids: [],
        exclude_ids: this.excludedProductIds,
        status_labels: statusLabels,
        audit_date: this.productCalendarQuery?.date ?? '',
        audit_date_type: this.productCalendarQuery?.type ?? '',
        gln_ids: this._store.getters.glnFilter,
        created_from_date: ((filters.created_at?.value ?? []) as any)[0] ?? null,
        created_to_date: ((filters.created_at?.value ?? []) as any)[1] ?? null,
      })
      .then((response) => {
        this._data = response.data;
        this.total = response.meta?.total;
        this._store.dispatch('products/SET_PRODUCTS', response);
        this._store.dispatch('products/SET_PAGINATION', {
          page: this.page,
          limit: this.limit,
          query: this.query,
          sortBy: this.sortBy,
        } as PaginationObject);
      })
      .catch(() => {
        this.toastService.displayErrorToast(
          this.ts.loadFailed(this.ts.tModule('snackbars.loadingFailed')),
        );
      })
      .finally(() => {
        this._loadingData = false;
      });
  }

  private async _deleteProduct(id: number, variantUuid: string) {
    this.loadingProductIds.push(variantUuid);
    try {
      await this._productsRestService.deleteProduct(id);
      this.page = 1;
      this.toastService.displaySuccessToast(this.ts.deleteSuccess());
    } catch (err: any) {
      this.toastService.displayErrorToast(this.ts.deleteFailed());
    } finally {
      this.loadingProductIds = this.loadingProductIds.filter((uuid) => uuid !== variantUuid);
    }
  }

  public confirmProductDelete(event: PointerEvent, product: Product) {
    this.confirmService.confirmDelete({
      event: event,
      group: 'products',
      message: this.ts.deleteConfirm(),
      callback: () => this._deleteProduct(product.id, product.variant_uuid),
    });
  }

  public applyFilter(refresh = true, filtered = false) {
    const productFilters: DataTableFilterInterface<ProductFilters> = {
      variant_name: {
        value: this.productFilters.variant_name?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      shared_retailer: {
        value: this.productFilters.shared_retailer?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      datamodel_id: {
        value: this.productFilters.datamodel_id?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      owner_id: {
        value: this.productFilters.owner_id?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      brand_id: {
        value: this.productFilters.brand_id?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      images_included: {
        value: this.productFilters.images_included?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      category: {
        value: this.productFilters.category?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      product_status_id: {
        value: this.productFilters.product_status_id?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      module_id: {
        value: this.productFilters.module_id?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },
      id: {
        value: this.productFilters.id?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },

      created_at: {
        value: this.productFilters.created_at?.value ?? [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: filtered,
      },

      table_sort: {
        fieldName: this.sortField ?? null,
        sortOrder: this.sortOrder ?? null,
      },
      columns: this._tableColumns,
      gtinsSearch: this.productFilters.gtinsSearch ?? '',
    };

    this.productTableFilterService.setFilters(this._workspaceName, productFilters);

    if (refresh) {
      this.page = 1;
    }
  }

  public setTableSortFromLocalStorage() {
    this.sortField = this.productFilters.table_sort?.fieldName ?? '';
    this.sortOrder =
      this.productFilters.table_sort?.sortOrder != null
        ? this.productFilters.table_sort?.sortOrder > 0
          ? 1
          : -1
        : undefined;
    this.sortBy = (this.sortOrder < 0 ? '-' : '') + this.sortField;
  }

  public onModifiedTableSort(event: DataTableSortEvent, refetch = true) {
    this.sortField = (event.sortField as string) ?? '';
    this.sortOrder = event.sortOrder != null ? (event.sortOrder > 0 ? 1 : -1) : undefined;
    this.sortBy = (this.sortOrder < 0 ? '-' : '') + this.sortField;

    if (refetch) {
      this.page = 1;
    }
  }

  public async checkUserPreferences(reset = false) {
    if (!this.productFilters.product_status_id?.filtered || reset) {
      if (!this.productFilters.product_status_id) {
        this.productFilters.product_status_id = {
          value: [{ id: '', name: '' }],
          filtered: false,
        };
      }

      this.productFilters.product_status_id.value =
        (await this._productStatusApi.getAllStatuses()) as any[];
    }

    if (!this.productFilters.variant_name?.filtered || reset) {
      if (this._store.getters['users/currentUser'].preferences?.default_master_data_filter) {
        if (!this.productFilters?.variant_name) {
          this.productFilters.variant_name = {
            value: [{ id: '', name: '' }],
            filtered: false,
          };
        }

        this.productFilters.variant_name.value = [{ id: 'Master Data', name: 'Master Data' }];
      }
    }

    if (!this.productFilters.product_status_id?.filtered || reset) {
      if (this._store.getters['users/currentUser'].preferences?.default_archived_not_visible) {
        if (!this.productFilters.product_status_id) {
          this.productFilters.product_status_id = {
            value: [{ id: '', name: '' }],
            filtered: false,
          };
        }

        this.productFilters.product_status_id.value =
          this.productFilters.product_status_id?.value.filter(
            (item: any) => item.label !== 'Archived',
          );
      }
    }

    this.applyFilter(
      false,
      this.productFilters.product_status_id?.filtered ??
        this.productFilters.product_status_id?.filtered,
    );
  }

  public getMissingGtins(): Promise<MetaGtinResponse> {
    const glnIds = this._store.getters.glnFilter;

    const filters = this.productTableFilterService.getFiltersForWorkspace(this._workspaceName);
    const brandIds = (filters as DataTableFilterInterface<ProductFilters>).brand_id?.value?.map(
      (brand: CrudDataFilterOption) => {
        return brand.id as number;
      },
    );

    const categoryIds = (filters as DataTableFilterInterface<ProductFilters>).category?.value?.map(
      (group: CrudDataFilterOption) => {
        return group.id as string;
      },
    );

    const dataModelIds = (
      filters as DataTableFilterInterface<ProductFilters>
    ).datamodel_id?.value?.map((datamodel: CrudDataFilterOption) => {
      return datamodel.id as number;
    });

    const variantIds = (
      filters as DataTableFilterInterface<ProductFilters>
    ).variant_name?.value?.map((group: CrudDataFilterOption) => {
      return group.id as string;
    });

    const statusLabels = (
      filters as DataTableFilterInterface<ProductFilters>
    ).product_status_id?.value?.map((item: CrudDataFilterOption) => {
      return item.name ? item.name : (item.label as string);
    });

    return this._productsRestService
      .getMissingGtins({
        brand_ids: brandIds,
        group_ids: categoryIds,
        datamodel_ids: dataModelIds,
        variants: variantIds,
        product_ids: this.productQueryIds.length > 0 ? this.productQueryIds : undefined,
        gtins: this.validGtins,
        owner_ids: [],
        status_labels: statusLabels,
        gln_ids: glnIds,
      } as GetProductsRequest)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  public async loadAsyncMissingGtins() {
    try {
      this.missingGtins = (await this.getMissingGtins()).missing;
    } catch (err) {
      this.toastService.displayErrorToast(this.ts.loadFailed(this.ts.tGlobal('data')));
    }
  }

  public async applyGtinFilter(clear = false) {
    if (clear) {
      this.productFilters.gtinsSearch = '';
      this.gtinsSearch = '';
      this.validGtins = [];
      this.missingGtins = [];
      this.invalidGtins = [];
      this.productTableFilterService.setFilters(this._workspaceName, this.productFilters);
      this.page = 1;

      return;
    }

    const gtinsArray = this.gtinsSearch.split('\n');
    this.validGtins = gtinsArray.filter(
      (gtin) => gtin.length == 8 || gtin.length == 12 || gtin.length == 13 || gtin.length == 14,
    );
    this.invalidGtins = gtinsArray.filter((gtin) => !this.validGtins.includes(gtin));
    this.productFilters.gtinsSearch = this.gtinsSearch;
    this.applyFilter(false, true);
    this.page = 1;
    await this.loadAsyncMissingGtins();
  }

  public onBeforeMountInit() {
    if (this._route.query.pipelineId != null) {
      this.pipelineId = parseInt(this._route.query.pipelineId.toString());
    } else if (this._route.query.date != null && this._route.query.type != null) {
      this.productCalendarQuery = {
        date: new Date(this._route.query.date.toString()).toISOString(),
        type: this._route.query.type.toString(),
      };
    } else {
      this.productQueryIds = this._productIds ?? [];
    }

    this.router.replace({ query: undefined });

    if (this._route.query.productsIds?.length !== 0) {
      this.selectedProductsIds =
        this._route.query.productsIds
          ?.toString()
          .split(',')
          .map((id) => parseInt(id)) ?? [];
    }

    this.setTableSortFromLocalStorage();
    this.checkUserPreferences();
  }

  public formatText() {
    const input = this.gtinsSearch;
    const rawValueArray: string[] = input.split('\n'); //each line => one element in the array
    const numbersArray: string[] = rawValueArray.map((el) =>
      el.replace(/\D/g, ' ').replace(/\s\s+/g, ' '),
    ); //removing letters and replacing multiple spaces with single space
    const number2dArray: string[][] = numbersArray.map((number) => number.split(' ')); //each element is an array of different numbers
    const converted2dArray: string[] = ([] as string[]).concat(...number2dArray); // convert 2d array to 1d array. we have an array of numbers
    const gtinsArray = converted2dArray.filter((el) => el !== '' && el.length > 5); // removing empty elements and unrelated numbers
    this.gtinsSearch = gtinsArray.join('\n');
  }

  public resetColumnFiltersOfDeselectedColumns() {
    this._tableColumns.forEach((column) => {
      if (!column.selected) {
        this.productTableFilterService.resetFilterByKey(
          this._workspaceName,
          column.field as keyof ProductFilters,
        );

        if (column.field === this.sortField) {
          this.onModifiedTableSort({ sortField: '', sortOrder: null } as DataTableSortEvent, false);
          this.applyFilter(false, true);
        }

        this.checkedProductRows = [];
      }
    });

    this.page = 1;
  }
}
