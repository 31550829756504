import {
  AttributeField,
  ProductAttributeField,
  FieldErrorEnum,
} from 'platform-unit2-api/attribute-fields';
import { ProductFieldsRestService } from 'platform-unit2-api/product-fields';
import { UpdateProductField } from 'platform-unit2-api/products';
import { TranslationService } from 'services/translations/translation.service';
import { LocaleDetails } from 'platform-unit2-api/locales';
import { getLocaleDetails } from '@/general/utils/get-locale-details';
import { FieldFunctions } from './interfaces/field-functions.interface';

export abstract class AttributeFieldService<T> {
  protected _attributeField: AttributeField<T>;
  protected _productAttributeField: ProductAttributeField<T>;
  protected _ts: TranslationService;
  private _productFieldApi;
  private _fieldFunctions: FieldFunctions;

  constructor(
    attributeField: AttributeField<T>,
    productAttributeField: ProductAttributeField<T>,
    fieldFunctions: FieldFunctions,
    ts = new TranslationService('global'),
  ) {
    this._attributeField = attributeField;
    this._fieldFunctions = fieldFunctions;
    this._productAttributeField = productAttributeField;
    this._productFieldApi = new ProductFieldsRestService();
    this._ts = ts;

    this._fieldFunctions.handleIncomingErrorsFromField(this._productAttributeField);
  }

  public get ts() {
    return this._ts;
  }

  //check if the field has changes
  public get hasChanges(): boolean {
    return this._fieldFunctions.hasChanges(this._attributeField, this.createPayload());
  }

  // Getter method for _attributeField
  get attributeField(): AttributeField<T> {
    return this._attributeField;
  }

  // Getter method for _productAttributeField
  get productAttributeField(): ProductAttributeField<T> {
    return this._productAttributeField;
  }

  // Getter method for _productAttributeField.value. Sets null values to undefined to be used in the view
  get productAttributeFieldValue(): T | undefined {
    return this._productAttributeField.value ?? undefined;
  }
  // Setted method for _productAttributeField.value. Sets undefined fields to null to be send to the backend
  set productAttributeFieldValue(value: T) {
    this._productAttributeField.value = value;
    this.updateValue(this._productAttributeField.value);
  }

  /**
   * Getter method for the locale details
   * creates the locale details object from the locale value
   * with the help of the language-tags library
   * @returns LocaleDetails
   */
  get locale(): LocaleDetails {
    return getLocaleDetails(this.productAttributeField.locale);
  }

  /**
   * Create an UpdateProductField object and send it to the ProductAttributeFieldService
   * UpdateProductField request can be extended/overwritten in the respective attribute field services
   * If value is undefined or empty the backend should recieve null
   * @param updatedProductAttributeField
   */
  protected createPayload(): UpdateProductField {
    let productAttrFieldValue = this._productAttributeField.value;
    if (
      productAttrFieldValue === undefined ||
      productAttrFieldValue === '' ||
      productAttrFieldValue === '<p></p>'
    ) {
      productAttrFieldValue = null;
    }

    return {
      attribute_id: this._productAttributeField.attribute_id,
      value: productAttrFieldValue,
      locale_id: this._productAttributeField.locale.id,
      path: this._productAttributeField?.path ?? null,
      // TBD
      overwrite: false,
    };
  }

  /**
   * Remove the value of the field
   * @param productAttributeField
   */
  public clear(): void {
    this._productAttributeField.value = null;

    this._fieldFunctions.addToUpdatedFields(this.createPayload());
    this._fieldFunctions.handleDirtyState(
      this._attributeField,
      this.createPayload(),
      this.productAttributeFieldValue,
    );
  }

  /**
   * Lock a field to make it uneditable
   * @param productAttributeField, including the locked parameter
   */
  public async toggleLock(): Promise<void> {
    this._productAttributeField.locked = !this._productAttributeField.locked;

    try {
      await this._productFieldApi.setFieldLockState(
        this._productAttributeField.id,
        this._productAttributeField.locked,
        this._productAttributeField.locale.id,
      );
    } catch (err) {
      this._productAttributeField.locked = !this._productAttributeField.locked;
    }
  }

  /**
   * Update the field value and save it in the FieldFunctions updatedFields[]
   * @param value the updated value of the input
   * @param productAttributeField the product attribute field that has to be updated
   */
  public updateValue(value: T | null): void {
    this._productAttributeField.value = value;
    this.validate();
    this._fieldFunctions.addToUpdatedFields(this.createPayload());
    this._fieldFunctions.handleDirtyState(
      this._attributeField,
      this.createPayload(),
      this.productAttributeFieldValue,
    );
  }

  /**
   * Generic validation for required field
   * Should be extended within the field services with their specific validation
   * Validation errors that are of type @param FieldErrorEnum.WARNING allow the user to save a field and therefore the validation function @returns true
   * Validation errors that are of type @param FieldErrorEnum.ERROR restrain the user from saving a field and therefore the validation function @returns false
   * @param productAttributeField the product attribute field that has to be validated
   */
  public validate(): boolean {
    this._productAttributeField.errors = [];

    if (
      this._attributeField.attribute.required &&
      (this._productAttributeField.value == null || this._productAttributeField.value === '')
    ) {
      this._productAttributeField.errors.push({
        message: this.ts.t('validation.required'),
        severity: FieldErrorEnum.WARNING,
      });
    }

    return true;
    // What about overrides
  }

  public getErrorTypeClass(): string {
    if (this._productAttributeField.errors.length === 0) {
      return '';
    }

    if (
      this.productAttributeField.errors.some((error) => error.severity === FieldErrorEnum.ERROR)
    ) {
      return 'p-invalid';
    }

    return 'p-warning';
  }
}
