<script setup lang="ts">
import { IncompleteProduct } from '../incomplete-products.types';
import { ProductField } from 'platform-unit2-api/product-fields';

//Components
import AttributeEditor from 'supplier/modules/products/components/attribute-editor.vue';

//Core
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { IncompleteProductsAuditorEnum } from 'platform-unit2-api/incomplete-products';
import { IncompleteProductVueService } from 'supplier/modules/incomplete-products/incomplete-product-vue.service';
import { ProductsRestService, UpdateProductField, Product } from 'platform-unit2-api/products';
// Props
const props = defineProps<{
  product: IncompleteProduct;
  id: number;
}>();

// Emits
const emit = defineEmits<{ (e: 'close'): void }>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'incompleteProducts');
const incompleteProductService = new IncompleteProductVueService();
const productsApi = new ProductsRestService();

// Constants
const store = useStore();
const route = useRoute();
const saving = ref(false);
const fieldData = ref<Partial<ProductField>[]>([]);
const auditor = ref<IncompleteProductsAuditorEnum>({} as IncompleteProductsAuditorEnum);

const products = ref<Product[]>([]);

const saveFieldData = async () => {
  saving.value = true;
  const fields: UpdateProductField[] = fieldData.value.map((field) => {
    return {
      attribute_id: field.id,
      value: field.value,
      locale_id: field.locale_id,
      overwrite: false,
    };
  }) as UpdateProductField[];

  try {
    await productsApi.setProductFields(props.product.id, fields);

    toastService.displaySuccessToast(ts.updateSuccess());
    emit('close');

    incompleteProductService.loadAsyncFields(props.product.id);
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    saving.value = false;
  }
};

const updateFieldData = (fieldId: number, localeId: number, value: string | null) => {
  fieldData.value.push({
    id: fieldId,
    locale_id: localeId,
    value: value,
  });
};

const findLocaleDetails = (localeId: number) => {
  return incompleteProductService.allLocales.find((locale) => locale.id === localeId);
};

// Lifecycle hooks
onMounted(async () => {
  if (route.name === 'retailer-products-incomplete') {
    auditor.value = IncompleteProductsAuditorEnum.RETAILERS;
  } else if (route.name === 'brand-products-incomplete') {
    auditor.value = IncompleteProductsAuditorEnum.BRANDS;
  } else if (route.name === 'module-products-incomplete') {
    auditor.value = IncompleteProductsAuditorEnum.MODULES;
  } else {
    emit('close');
  }

  incompleteProductService.allLocales = (
    await store.dispatch('locales/SEARCH_LOCALES', { query: '' })
  ).data;

  incompleteProductService.loadAsyncFields(props.product.id);
});
</script>

<template>
  <div>
    <div v-if="incompleteProductService.isLoading.value">
      <div class="mb-5">
        <p-skeleton height="1rem" width="10rem" class="mb-2" />
        <p-skeleton height="2rem" />
      </div>
      <div>
        <p-skeleton height="1rem" width="10rem" class="mb-2" />
        <p-skeleton height="10rem" />
      </div>
    </div>
    <p-message
      v-if="
        !incompleteProductService.isLoading.value &&
        incompleteProductService.emptyFields.value.length === 0
      "
      severity="warn"
      :closable="false"
    >
      <p class="font-bold">
        {{ ts.tGlobal('warning') }}
      </p>
      {{ ts.tModule('no_empty_fields_module') }}
      <a href="mailto:rpxsupport@productsup.com">{{ ts.tModule('contact_productsup') }}</a>
    </p-message>

    <p-message
      v-if="product.uploads_count === 0 && !incompleteProductService.isLoading.value"
      severity="warn"
      :closable="false"
    >
      <p class="font-bold">
        {{ ts.tGlobal('warning') }}
      </p>
      {{ ts.tModule('no_uploads') }}
      <router-link
        :to="{
          name: 'product-details',
          params: {
            id: product.id,
          },
        }"
      >
        {{ ts.tModule('products_page') }} </router-link
      >.
    </p-message>

    <!-- <div v-for="field in fields">
      <div class="mt-3 p-2">
        <strong>{{ field.key }}</strong>
      </div>
      <div v-for="(locale, index) in allLocales" :key="locale.id">
        <attribute-editor
          :key="field.id"
          :value="fieldData[field.id]"
          :current-product="products"
          :field-data="field.product_field"
          :attribute="field"
          :lockable="false"
          :locale="findLocaleDetails(locale.id)"
          @input="(value) => updateFieldData(field.id, locale, value)"
        />
      </div>
    </div> -->

    <div v-for="field in incompleteProductService.emptyFields.value" :key="field.attribute.key">
      <div class="mt-3 p-2">
        <strong>{{ field.attribute.key }}</strong>
      </div>
      <div v-for="locale in field.localeIds" :key="locale">
        <attribute-editor
          :key="field.attribute.id"
          :value="fieldData[field.attribute.id]"
          :current-product="products[0]"
          :field-data="field.product_field"
          :attribute="field.attribute"
          :lockable="false"
          :locale="findLocaleDetails(locale)"
          @input="(value) => updateFieldData(field.attribute.id, locale, value)"
        />
      </div>
    </div>

    <p-button
      v-if="incompleteProductService.emptyFields.value.length > 0"
      class="flex ml-auto mt-3"
      :label="ts.tGlobal('save')"
      :loading="saving"
      @click="saveFieldData"
    />
  </div>
</template>
