<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import { onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Module } from '../dashboard.types';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import { DashboardRestService } from 'platform-unit2-api/dashboard';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'dashboard');
const dashboardApi = new DashboardRestService();

/** Constants */
const modules = ref<Module[]>([]);
const loading = ref(true);

const startDataFetching = async () => {
  loading.value = true;
  try {
    modules.value = (await dashboardApi.getDashboardModules()).modules;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

/** Handles the click on empty state to send a mail to WOC */
const onClicked = () => {
  const emailLink = document.createElement('a');
  emailLink.href = 'mailto:rpxsupport@productsup.com';
  emailLink.click();
};

/** Lifecycle Methods */
onMounted(async () => {
  await startDataFetching();
});
</script>
<template>
  <p-card v-if="loading">
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.module_completeness.title') }}</h4>
      <TableSkeleton />
    </template>
  </p-card>
  <p-card v-else>
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.module_completeness.title') }}</h4>
      <div v-if="!modules.length">
        <EmptyState
          :translation-service="ts"
          :icon-name="'channels'"
          :empty-state-title="ts.tModule('emptyState.channels.title')"
          :empty-state-subtitle="ts.tModule('emptyState.channels.subtitle')"
          :button-label="ts.tGlobal('contactSales')"
          :button-icon="'mdi mdi-send'"
          @clicked="onClicked"
        />
      </div>
      <div v-else class="h-30rem overflow-y-scroll">
        <div
          v-for="data in modules"
          :key="data.id"
          class="align-items-center cursor-pointer flex flex-row hover:surface-50 mb-3 p-3 w-11"
          @click="
            $router.push({
              name: 'module-products-incomplete',
              params: { id: data.id.toString() },
            })
          "
        >
          <upload :upload="data.retailer?.thumbnail" class="mr-3 w-6rem" />
          <ProgressBar :progress="Math.round(data.completeness ?? 0) ?? 0" />
        </div>
      </div>
    </template>
  </p-card>
</template>
<style scoped lang="scss">
.table-wrapper {
  position: relative;
  min-height: 200px;
}
.ui-datatable tr.ui-datatable-even:hover,
.ui-datatable tr.ui-datatable-odd:hover {
  background: #78bcff;
}
.p-progressbar {
  flex-grow: 1;
  height: 0.5rem;
}
</style>
