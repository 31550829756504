<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { DefaultFieldService } from '../ts/services/default-fields.service';
import AttributeEditor from '../../products/components/attribute-editor.vue';
import DefaultFieldOptionsDropdown from './default-field-options-dropdown.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { onMounted } from 'vue';

/** Props */
export interface Props {
  service: DefaultFieldService;
}

const props = defineProps<Props>();

const ts = new TranslationService('supplier', 'datamodelsConfig');

/** Lifecycle */
onMounted(() => {
  props.service.init();
});
</script>

<template>
  <div v-if="service.loading" class="h-20rem m-auto w-20rem">
    <LoadingIndicator />
  </div>

  <div v-else>
    <p class="my-5">
      {{ ts.tModule('configuration.default.informationText') }}
    </p>
    <div class="w-full">
      <p class="font-bold">{{ ts.tModule('configuration.default.fieldHeader') }}</p>
      <div
        v-for="locale in service.workspaceLocales"
        :key="locale.id"
        class="align-items-end flex mb-3 w-full"
      >
        <AttributeEditor
          class="mr-3 w-8"
          :attribute="service.attribute"
          :lockable="false"
          :locale="service.getLocaleDetail(locale)"
          :field-data="({ value: service.defaultField(locale.id)?.value } as any)"
          @input="($event) => service.setValue($event, locale.id)"
        />
        <div class="flex w-4">
          <DefaultFieldOptionsDropdown
            class="flex-grow-1 overflow-hidden"
            :service="service"
            :locale="locale"
          />
          <div class="align-items-center flex ml-2">
            <p-button
              plain
              text
              :disabled="!service.defaultField(locale.id)?.id"
              icon="mdi mdi-delete-outline"
              @click="service.deleteDefaultField($event, locale.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <p-confirm-popup group="delete-defaults" />
  </div>
</template>
