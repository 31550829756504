import { CreateOptions, CreateResult } from '@/general/composables/useCreate/types';
import { useDispatch } from '@/general/composables/UseDispatch';
import { TranslationService } from '@/general/services/translations/translation.service';
/**
 * UseCreate returns the loading ref that is used in the UseDispatch. This reference is returned along with the create function.
 * <T> is the type of the return object
 * @returns returns update callback
 */
export function useCreate() {
  // Get Loading ref and dispatch function
  const ts = new TranslationService('general', 'components');

  const { loading, dispatch } = useDispatch();

  /**
   * Update objects through the use of the store action.
   * @param action store.action name
   * @param options create data, properties that set behaivior of the use create (dispatch)
   * @returns object with type of T
   */
  async function create<T>({
    action = '',
    options = {} as CreateOptions<T>,
  }): Promise<CreateResult> {
    //execute dispatch and return result.
    return await dispatch<T>(action, {
      parameters: options.parameters,
      toast: {
        displaySetting: options.toast?.displaySetting,
        successMessage:
          options.toast?.successMessage ?? ts.tModule('composables.defaultCreateSuccess'),
        rejectMessage:
          options.toast?.rejectMessage ?? ts.tModule('composables.defaultUpdateReject'),
      },
      successCallback: options.successCallback,
      rejectCallback: options.rejectCallback,
    });
  }

  //Return update function and loading ref.
  return { create, loading };
}
