import { ChannelFilter } from 'retailer/modules/export/ts/interfaces/channel-filter.interface';
import { BaseViewService } from '../view/base-view.service';
import {
  ExportParameters,
  ExportPipelineFilters,
  ExportPipelineMessagesFilters,
  Pipeline,
  PipelineMessage,
  PipelinePayload,
  PipelineStageEnum,
  PipelinesRestService,
  PipelineStatusLevel,
} from 'platform-unit2-api/pipelines';
import { TranslationService } from '../../translations/translation.service';
import store from '@/core/store';
import { User } from 'platform-unit2-api/users';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { RouteParamsRaw, RouteLocationRaw } from 'vue-router';
import {
  ExportChangedProduct,
  RestartExportRequest,
  ExportRestService,
} from 'platform-unit2-api/exports';
import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
import { capitalize } from 'lodash';
import { DataTableFilterEvent, DataTableFilterMetaData } from 'primevue/datatable';
import { StatusSeverity } from '@/general/ui/components/status-chip.vue';
import { PipelineMessageType } from '@/platforms/supplier/modules/export/ts/enums/pipeline-messages.enum';

/**
 * Service for the Export type view.
 */
export class ExportViewService extends BaseViewService<
  PipelinesRestService,
  Pipeline,
  PipelinePayload,
  PipelinePayload & { id: number }
> {
  public currentUser: User | undefined;
  private exportRestService;
  public restartPipelineChangedProducts: ExportChangedProduct[] = [];
  public restartPipelineChangedProductType: { value: string } = { value: 'always' };
  public restartPipelineDates: Date[] = [];
  public isDetailsModalVisible = false;
  public pipelineDetails?: Pipeline;
  public statusFilterOptions: { id: number; name: string }[] = [];
  public userFilterOptions: { id: string; name: string }[] = [];
  public timer: ReturnType<typeof setInterval> | undefined;

  public filter = '';
  public filters = {
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    user: { value: null, matchMode: FilterMatchMode.EQUALS },
    'module.name': { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  public filterParams: ExportPipelineFilters = { type: PipelineStageEnum.EXPORT };

  public togglePipelineDetails(visible: boolean, pipeline?: Pipeline) {
    this.isDetailsModalVisible = visible;
    if (pipeline != null) {
      this.pipelineDetails = pipeline;
    }
  }

  constructor(ts: TranslationService) {
    super({
      Api: PipelinesRestService,
      fetchAllFunction: 'getAll',
      ts: ts,
      overviewRouteName: 'export',
      createRouteName: '',
      updateRouteName: '',
      confirmPopUpGroup: 'export',
    });

    this.currentUser = store.getters['users/currentUser'];
    this.exportRestService = new ExportRestService();

    this.assignTimer();
    this.loadFilters();
  }

  public fetchAll(): void {
    this._isLoadingOverview = true;

    this._restService
      .getAll(this._fetchVariables, this.filterParams)
      .then((response) => {
        this._data = response.data;
        this.total = response.meta?.total;
        this.getAllCallback?.();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._data = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.isInitialized = true;
      });
  }

  /**
   * Get pipelines
   */
  public get pipelines(): Pipeline[] {
    return this._data;
  }

  /**
   * Formatting a specific status
   * @param statusId targeted status id
   */
  public processStatus = (statusId?: number) => {
    PipelineStatusLevel;
    if (statusId) {
      switch (statusId) {
        case 1:
          return ActionStatusSeverityEnum.SUCCESS;
        case 2:
          return ActionStatusSeverityEnum.PARTIAL_SUCCESS;
        case 3:
          return ActionStatusSeverityEnum.FAILED;
        case 4:
          return ActionStatusSeverityEnum.PROCESSING;
        case 5:
          return ActionStatusSeverityEnum.PENDING;
        default:
          return '';
      }
    }

    return '';
  };

  /**
   * Method used to navigate to the products page
   * @param id
   */
  public async goToProducts(id?: number) {
    try {
      await this._router.push({
        name: 'products',
        query: { pipelineId: id } as RouteParamsRaw,
      } as RouteLocationRaw);
    } catch (error) {
      this._toastService.displayErrorToast(this._ts.tModule('tooltips.missingPipelineId'));
    }
  }

  /**
   * Method used to open the download modal
   * @param selectedPipeline
   */
  public openDownload(selectedPipeline?: Pipeline) {
    try {
      window.open(
        import.meta.env.BASE_URL.toString().replace(/\/$/, '') + selectedPipeline?.public_url,
        '_blank',
      );
    } catch (error) {
      this._toastService.displayErrorToast(this._ts.tModule('tooltips.missingPipeline'));
    }
  }

  public confirmExportDelete(id: number) {
    this._confirmService.confirmDelete({
      callback: () => this.deleteExport(id),
      group: 'export-row',
      message: this._ts.deleteConfirm(),
    });
  }

  private deleteExport(id: number) {
    this._restService
      .delete(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this.refetch();
      })
      .catch(() =>
        this._toastService.displayErrorToast(this._ts.tModule('tooltips.missingPipelineId')),
      );
  }

  public createBody(): PipelinePayload | undefined {
    throw new Error('Method not implemented.');
  }
  public updateBody(): (PipelinePayload & { id: number }) | undefined {
    throw new Error('Method not implemented.');
  }
  public get validated(): boolean {
    if (this.partialObject.module == null || this.partialObject.locale == null) {
      return false;
    }

    return true;
  }

  public get disableSave(): boolean {
    return !this.validated;
  }

  // Stop timer (refetching) when the restart sidebar is open
  public openCrudComponent(object?: Pipeline | undefined, redirect?: boolean): void {
    super.openCrudComponent(object, redirect);
    this.crudComponent
      ? clearInterval(this.timer as ReturnType<typeof setInterval>)
      : this.assignTimer();
  }

  // Start timer (refetching) when the restart sidebar is closed
  public closeCrudComponent(): void {
    super.closeCrudComponent();

    this.crudComponent
      ? clearInterval(this.timer as ReturnType<typeof setInterval>)
      : this.assignTimer();
  }

  public save(): void {
    const request = this.createRestartRequest();
    if (request) {
      this.restartExport(request);
    }
  }

  private createRestartRequest(): RestartExportRequest | undefined {
    this.restartPipelineChangedProducts = [
      {
        beforeDate:
          this.restartPipelineChangedProductType.value === 'restartPipelineDates'
            ? this.restartPipelineDates[1]
              ? moment(this.restartPipelineDates[1]).toDate().toUTCString()
              : undefined
            : undefined,
        afterDate:
          this.restartPipelineChangedProductType.value === 'restartPipelineDates'
            ? this.restartPipelineDates[0]
              ? moment(this.restartPipelineDates[0]).toDate().toUTCString()
              : undefined
            : undefined,
        afterLastExport: this.restartPipelineChangedProductType.value === 'export',
      },
    ];

    if (this.partialObject?.id == null) {
      return;
    }

    const data: RestartExportRequest = {
      id: this.partialObject?.id,
      mail_to: (this.partialObject.payload?.parameters as ExportParameters).mail_to,
      custom_message: (this.partialObject.payload?.parameters as ExportParameters).custom_message,
      module_id: this.partialObject.module?.id ?? 0,
      locale_id: this.partialObject.locale?.id ?? 0,
      changed_products: this.restartPipelineChangedProducts,
      include_data:
        (this.partialObject.payload?.parameters as ExportParameters).include_data ?? false,
      include_assets:
        (this.partialObject.payload?.parameters as ExportParameters).include_assets ?? false,
      product_ids: (this.partialObject?.payload?.parameters as ExportParameters).product_ids,
      files: this.partialObject?.payload?.parameters.files ?? undefined,
    };

    return data;
  }

  private async restartExport(request: RestartExportRequest) {
    this._isLoadingCrudComponent = true;

    this.exportRestService
      .restartExport(request)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.exportSuccess());
        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.exportFailed());
      })
      .finally(() => {
        this._isLoadingCrudComponent = false;
        this.closeCrudComponent();
      });
  }

  public loadFilters() {
    this._restService
      .getFilterUsernames()
      .then((res) => {
        this.userFilterOptions = Object.entries(res.data).map(([key, value]) => ({
          id: key,
          name: value,
        }));
      })
      .catch(() => this._toastService.displayErrorToast(this._ts.loadFailed()));

    this.statusFilterOptions = Object.entries(PipelineStatusLevel)
      .map(([key, value]) => {
        return {
          id: Number(key),
          name: capitalize(value.toString().toLowerCase().replace('_', ' ')),
        };
      })
      .filter((status) => !isNaN(status.id));
  }

  public loadAsyncData() {
    this._isLoadingCrudComponent = true;

    try {
      if (this.current?.payload?.parameters?.module_id == null) {
        return;
      }

      this.restartPipelineChangedProducts = (
        this.current.payload?.parameters as ExportParameters
      ).changed_products;

      if (
        !this.restartPipelineChangedProducts ||
        !this.restartPipelineChangedProducts[0].beforeDate
      ) {
        this.restartPipelineChangedProductType.value =
          this.restartPipelineChangedProducts &&
          this.restartPipelineChangedProducts[0].afterLastExport
            ? 'export'
            : 'always';
      } else {
        this.restartPipelineChangedProductType.value = 'restartPipelineDates';
        this.restartPipelineDates[0] = moment(
          this.restartPipelineChangedProducts[0].afterDate,
        ).toDate();
        this.restartPipelineDates[1] = moment(
          this.restartPipelineChangedProducts[0].beforeDate,
        ).toDate();
      }
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.loadFailed());
    } finally {
      this._isLoadingCrudComponent = false;
    }
  }

  public applyFilter(event: DataTableFilterEvent) {
    this.filterParams = {
      ...this.filterParams,
      user_id: (event.filters.user as DataTableFilterMetaData).value?.id,
      module_ids: (event.filters['module.name'] as DataTableFilterMetaData).value?.map(
        (module: ChannelFilter) => module.id,
      ),
      status: (event.filters.status as DataTableFilterMetaData).value?.id,
    };

    this.refetch();
  }

  public assignTimer() {
    if (this.timer != null) {
      clearInterval(this.timer as ReturnType<typeof setInterval>);
    }

    this.timer = setInterval(this.pollWithoutLoading.bind(this), 5000);
  }

  public pollWithoutLoading() {
    this._restService.getAll(this._fetchVariables, this.filterParams).then((res) => {
      this._data = res.data;
      this.total = res.meta?.total;
    });
  }

  public getExportStatus(pipeline: Pipeline): { label: string; severity: StatusSeverity } {
    const pipelineHasErrors = pipeline.statuses?.some((status) => status.level === 'error');

    return {
      label: pipelineHasErrors ? 'Failed' : PipelineStatusLevel[pipeline.current_status],
      severity: pipelineHasErrors
        ? ActionStatusSeverityEnum.FAILED
        : ActionStatusSeverityEnum[
            PipelineStatusLevel[pipeline.current_status] as keyof typeof ActionStatusSeverityEnum
          ],
    };
  }

  // Message logs
  public pipelineMessages: PipelineMessage[] = [];
  public totalLogs = 0;
  public filterAllGtins: string[] = [];
  public messagesFilters = {
    gtin: { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  public messagesFiltersParams: ExportPipelineMessagesFilters = {};
  public messagesLoading = false;

  /**
   * Loads all pipeline messages for the given pipeline.
   */
  public getPipelineMessages(pipelineId: number, page: number) {
    this.messagesLoading = true;
    this._restService
      .getPipelineMessages(page, pipelineId, this.messagesFiltersParams)
      .then((response) => {
        this.pipelineMessages = response.data;
        this.totalLogs = response?.meta?.total ?? 0;
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
      })
      .finally(() => {
        this.messagesLoading = false;
      });
  }

  public loadGtinFilter(pipelineId: number) {
    this._restService
      .getFilterGtins(pipelineId)
      .then((res) => {
        this.filterAllGtins = res.data.map((item) => item.gtin);
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
      });
  }

  public applyMessagesFilter(event: DataTableFilterEvent, pipelineId: number) {
    this.messagesFiltersParams = {
      ...this.messagesFiltersParams,
      gtin: (event.filters.gtin as DataTableFilterMetaData).value,
    };

    this.getPipelineMessages(pipelineId, 1);
  }

  processMessageType = (messageType: number) => {
    switch (messageType) {
      case 1:
        return PipelineMessageType.UNKNOWN;
      case 2:
        return PipelineMessageType.MISSING_ATTRIBUTE;
      case 3:
        return PipelineMessageType.VALUE_TOO_SHORT;
      case 4:
        return PipelineMessageType.VALUE_TOO_LONG;
      case 5:
        return PipelineMessageType.CONNECTION_PROBLEM;
      case 6:
        return PipelineMessageType.INVALID_ENUMERATED_VALUE;
      case 7:
        return PipelineMessageType.INVALID_CHECKSUM;
      case 8:
        return PipelineMessageType.INVALID_PATTERN;
      default:
        return PipelineMessageType.UNKNOWN;
    }
  };

  /**
   * Method used to copy text to clipboard
   * Note: Using deprecated document.execCommand since clipboardApi is not fully supported in all browsers
   * @param text of type string
   */
  public copyToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.textContent = text;
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');
    this._toastService.displaySuccessToast(this._ts.tModule('tooltips.copyToClipboardSuccess'));
  }
}
