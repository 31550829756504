<script setup lang="ts">
import useFormValidation from 'composables/form-validation';
import { ref } from 'vue';
import TemplateCard from 'admin/modules/templates/components/add-template-card.vue';
import TemplateTable from 'admin/modules/templates/components/template-table.vue';

import CrudButtons from '@/general/ui/components/crud-buttons/crud-buttons.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { UpdateButton } from '@/general/ui/components/crud-buttons/ts/classes/update-crud-button.class';
import TemplateService from 'admin/modules/templates/ts/services/template.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Template, UpdateTemplateRequest } from 'platform-unit2-api/templates';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Composables */
const { resetFormErrors, parseFormError } = useFormValidation();

/** Services */
const templateService = new TemplateService();
const ts = new TranslationService('admin', 'templates');
const toastService = ToastService.getInstance();

/** Constants */
const manualTemplateName = ref('');
const manualTemplateSheets = ref<number[]>([]);
const isApi = ref(false);
const isTemplateCreated = ref(false);
const importedTemplate = ref<Template>();
const manualTemplate = ref<Template>();

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.LEFT,
  onClick: () => {
    emit('hide');
  },
});
const createButton = new CreateButton({
  label: ts.createButton,
  isEnabled: () => false,
  position: CrudButtonPosition.CENTER,
  onClick: () => handleCreate(),
});

const crudButtonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, createButton],
});

const setTemplate = ($event: Template) => {
  importedTemplate.value = $event;
  isTemplateCreated.value = true;
};

const setUpdateImportedTemplateButton = () => {
  const updateButton = new UpdateButton({
    label: ts.updateButton(),
    position: CrudButtonPosition.CENTER,
    onClick: () => handleUpdate(importedTemplate.value),
  });
  crudButtonsOptions.value.buttons[1] = updateButton;
};

const setUpdateManualTemplateButton = () => {
  const updateButton = new UpdateButton({
    label: ts.updateButton(),
    position: CrudButtonPosition.CENTER,
    onClick: () => handleUpdate(manualTemplate.value),
  });
  crudButtonsOptions.value.buttons[1] = updateButton;
};

const enableCreateButton = (templateName: string, enable: boolean) => {
  manualTemplateName.value = templateName;
  crudButtonsOptions.value.buttons[1].isEnabled = () => enable;
};

const toggleApi = (val: boolean) => {
  isApi.value = val;
};

const changeSheets = (newSheets: number[]) => {
  manualTemplateSheets.value = [...newSheets].map((val) => +val);
};

const setLoadingState = (isUploading: boolean) => {
  crudButtonsOptions.value.saving = isUploading;
};

const handleCreate = async () => {
  const newTemplate: Partial<UpdateTemplateRequest> = {
    name: manualTemplateName.value,
    type: isApi.value ? 'api' : 'template',
    sheets: manualTemplateSheets.value,
  };
  manualTemplate.value = await templateService
    .createTemplate(newTemplate as UpdateTemplateRequest)
    .then((res) => {
      res.endpoints = [];
      isTemplateCreated.value = true;
      setUpdateManualTemplateButton();
      toastService.displaySuccessToast(ts.createSuccess());
      return res;
    })
    .catch(() => {
      toastService.displayErrorToast(ts.createFailed());
      return undefined;
    });
};

const handleUpdate = (template?: Template) => {
  crudButtonsOptions.value.saving = true;
  resetFormErrors();
  if (template != undefined) {
    templateService
      .saveChangesToTemplate(template)
      .then(() => {
        toastService.displaySuccessToast(ts.updateSuccess());
      })
      .catch((err) => {
        parseFormError(err, () => {
          toastService.displayErrorToast(ts.updateFailed());
        });
      });
  }

  crudButtonsOptions.value.saving = false;
};

const setManualTemplate = (template?: Template) => {
  manualTemplate.value = template;
};
</script>
<template>
  <div class="h-full">
    <div class="relative">
      <div class="ml-5">
        <h3 class="font-bold mb-1 text-2xl">
          {{ ts.moduleCreateTitle }}
        </h3>
        <p class="mb-5 text-lg">
          {{ ts.moduleCreateSubTitle }}
        </p>
      </div>

      <div
        class="flex flex-column h-full w-full"
        :class="[!isApi ? 'template-table' : 'mt-3']"
      ></div>

      <div style="background-color: white" class="h-20rem"></div>
      <p-divider class="m-0 p-0" />
      <div style="background-color: rgb(245, 247, 250)" class="h-15rem"></div>

      <TemplateCard
        class="absolute ml-5"
        style="top: 5rem; width: 50%"
        :manual-template="manualTemplate"
        @change:template-name="enableCreateButton"
        @change:sheets="changeSheets"
        @change:api="toggleApi"
        @template:created="setTemplate($event), setUpdateImportedTemplateButton()"
        @uploading="setLoadingState"
      />
    </div>

    <div>
      <TemplateTable
        :template="importedTemplate"
        :manual-template="manualTemplate"
        @update:manual-template="setManualTemplate"
      ></TemplateTable>
    </div>

    <CrudButtons class="bg-white bottom-0 p-3 sticky" :options="crudButtonsOptions" />
  </div>
</template>
