<script setup lang="ts">
import { computed, ref } from 'vue';

import { FeaturePermission, UserPermission } from 'platform-unit2-api/roles';
import useFormValidation from 'composables/form-validation';
import { enumToArray } from '@/general/utils/enumToArray';
import { featurePermissionMap } from 'platform-unit2-api/roles';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { RolesRestService, Role } from 'platform-unit2-api/roles';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Composables */
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'roles');
const toastService = ToastService.getInstance();
const rolesApi = new RolesRestService();

/** Constants */
const saving = ref(false);

const editorRole = ref<Role>({} as Role);

const oldFeaturePermissions = ref<FeaturePermission[]>([]);

const filteredFeaturePermissions = computed((): FeaturePermission[] => {
  return enumToArray(FeaturePermission).map((item) => Object.assign({ name: item }));
});

const filteredUserPermissions = computed(() => {
  return enumToArray(UserPermission).map((item) => Object.assign({ name: item }));
});

const getFeaturePermissions = computed(() => {
  const featurePermissions = [];
  for (const permission of enumToArray(FeaturePermission)) {
    const hasPermission = featurePermissionMap[permission].every((userPermission) => {
      return editorRole.value.permissions?.includes(userPermission);
    });

    if (hasPermission) {
      featurePermissions.push(permission);
    }
  }

  return featurePermissions;
});

const setFeaturePermissions = (featurePermissions: FeaturePermission[]) => {
  let permissions = editorRole.value.permissions ?? [];

  // Get a list of all unique user permissions previously selected
  const oldUserPermissions = [
    ...new Set(
      oldFeaturePermissions.value
        .map((featurePermission) => featurePermissionMap[featurePermission])
        .flat(),
    ),
  ];

  // Remove those permissions from the current list of user permissions
  permissions = permissions.filter((permission) => !oldUserPermissions.includes(permission));

  // Add the user permissions corresponding to the selected feature permissions
  for (const permission of featurePermissions) {
    permissions.push(...featurePermissionMap[permission]);
  }

  oldFeaturePermissions.value = [...featurePermissions];
  // Set the editor role permissions to be a unique set of the new permissions
  editorRole.value.permissions = [...new Set(permissions)];
};

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const role = {
      name: editorRole.value.name,
      permissions: editorRole.value.permissions,
    };

    await rolesApi.post(role);
    emit('refresh');
    emit('hide');
    toastService.displaySuccessToast(ts.createSuccess(editorRole.value.name));
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.createFailed(editorRole.value.name));
    });
  } finally {
    saving.value = false;
  }
};
</script>

<template>
  <CrudSidebar
    :title="ts.moduleCreateTitle"
    :subtitle="ts.moduleCreateSubTitle"
    :saving="saving"
    @cancel="emit('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <div class="field mb-3">
        <p-input-text
          v-model="editorRole.name"
          class="w-full"
          :class="{ 'p-invalid': hasError('name') }"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <label>{{ ts.tModule('permissionGroupsLabel') }}</label>
        <pMultiselect
          :model-value="getFeaturePermissions"
          :options="filteredFeaturePermissions"
          :placeholder="ts.tModule('permissionGroupsPlaceholder')"
          display="chip"
          option-label="name"
          option-value="name"
          class="w-full"
          :empty-filter-message="ts.tModule('permissionGroupsNotFound')"
          :filter="true"
          :class="{ 'p-error block': hasError('permissions') }"
          @update:model-value="(event: any) => setFeaturePermissions(event)"
        />
        <small
          v-if="hasError('permissions')"
          :class="{ 'p-error block': hasError('permissions') }"
          class="hidden"
          >{{ fieldErrorMessage('permissions').toString() }}
        </small>
      </div>

      <div class="field mb-3">
        <label>{{ ts.tModule('permissionsLabel') }}</label>
        <pMultiselect
          v-if="editorRole"
          v-model="editorRole.permissions"
          :options="filteredUserPermissions"
          :placeholder="ts.tModule('permissionsPlaceholder')"
          display="chip"
          option-label="name"
          option-value="name"
          :empty-filter-message="ts.tModule('permissionsNotFound')"
          class="w-full"
          :class="{ 'p-invalid': hasError('permissions') }"
          :filter="true"
        />
        <small
          v-if="hasError('permissions')"
          :class="{ 'p-error block': hasError('permissions') }"
          class="hidden"
          >{{ fieldErrorMessage('permissions').toString() }}</small
        >
      </div>
    </template>
  </CrudSidebar>
</template>
