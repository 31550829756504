<script setup lang="ts">
import { ref, onMounted } from 'vue';
import moment from 'moment';
import DynamicInputGenerator from '@/general/ui/components/dynamic-input-generator.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CreateExport } from '../../ts/products.types';

/** Props */
interface Props {
  formData: CreateExport;
}
const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (
    e: 'nextPage',
    pageObject: {
      pageIndex: number;
      includeData: boolean;
      includeAssets: boolean;
      isScheduled: boolean;
      changed_products: {
        beforeDate?: string;
        afterDate?: string;
        afterLastExport: boolean;
      };
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

//** Constants */
const includeAssets = ref(false);
const includeData = ref(false);
const changedProductType = ref({ value: 'always' });
const dates = ref<Date[]>([]);
const isScheduled = ref<boolean>(false);
const valid = ref(true);
const childValidated = ref(props.formData.selectedModule?.settings_fields?.prompt_fields == null);

const nextPage = () => {
  emit('nextPage', {
    pageIndex: 1,
    includeData: includeData.value,
    includeAssets: includeAssets.value,
    isScheduled: isScheduled.value,
    changed_products: {
      beforeDate:
        changedProductType.value.value === 'dates'
          ? dates.value[1]
            ? moment(dates.value[1]).add(1, 'days').toDate().toUTCString()
            : undefined
          : undefined,
      afterDate:
        changedProductType.value.value === 'dates'
          ? dates.value[0]
            ? moment(dates.value[0]).toDate().toUTCString()
            : undefined
          : undefined,
      afterLastExport: changedProductType.value.value === 'export' ? true : false,
    },
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 1,
  });
};

const formValidation = () => {
  valid.value =
    !(includeData.value === false && includeAssets.value === false) && childValidated.value;
};

const formValidationPrompt = (promptValid: boolean) => {
  childValidated.value = promptValid;

  formValidation();
};

onMounted(() => {
  if (props.formData.includeData) {
    includeData.value = props.formData.includeData;
  } else if (props.formData.includeData == null) {
    includeData.value = true;
  }

  if (props.formData.includeAssets) {
    includeAssets.value = props.formData.includeAssets;
  } else if (props.formData.includeAssets == null) {
    includeAssets.value = true;
  }

  if (props.formData.isScheduled) {
    isScheduled.value = props.formData.isScheduled;
  }

  formValidation();
});
</script>
<template>
  <div class="mt-5">
    <div class="px-4">
      <div v-if="!valid" class="field">
        <p-message severity="warn">
          {{ ts.tModule('export_modal.settings.source_warning') }}
        </p-message>
      </div>
      <div class="font-bold mb-3">
        <label>{{ ts.tModule('export_modal.settings.select_source') }}</label>
      </div>

      <div class="flex">
        <div class="field-checkbox mr-4">
          <p-checkbox
            id="attribute_checkbox"
            v-model="includeData"
            :binary="true"
            @change="formValidation"
          />
          <label for="attribute_checkbox">{{
            ts.tModule('export_modal.settings.attributes')
          }}</label>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            id="asset_checkbox"
            v-model="includeAssets"
            :binary="true"
            @change="formValidation"
          />
          <label for="asset_checkbox">{{ ts.tModule('export_modal.settings.assets') }}</label>
        </div>
      </div>

      <div class="field mt-4">
        <label class="block font-bold">{{
          ts.tModule('export_modal.settings.date_limit_label')
        }}</label>
        <pDropdown
          v-model="changedProductType"
          :options="[
            {
              value: 'always',
              label: ts.tModule('export_modal.settings.date_limit_always'),
            },
            {
              value: 'export',
              label: ts.tModule('export_modal.settings.date_limit_export'),
            },
            {
              value: 'dates',
              label: ts.tModule('export_modal.settings.date_limit_dates'),
            },
          ]"
          option-label="label"
          data-key="value"
          display="chip"
          :filter="true"
        />
      </div>

      <div v-if="changedProductType.value === 'dates'" class="field">
        <label class="block font-bold">{{
          ts.tModule('export_modal.settings.select_date_range')
        }}</label>
        <p-calendar
          id="range"
          v-model="dates"
          class="mb-1"
          :placeholder="ts.tModule('export_modal.settings.select_date')"
          :max-date="new Date()"
          selection-mode="range"
          :manual-input="false"
          :show-button-bar="true"
          :show-icon="true"
        />
      </div>

      <div class="field-checkbox">
        <p-checkbox
          id="schedule_export_checkbox"
          v-model="isScheduled"
          :binary="true"
          @change="formValidation"
        />
        <label for="schedule_export_checkbox">
          {{ ts.tModule('export_modal.settings.schedule_export') }}
          <span class="p-text-secondary">
            ( {{ ts.tModule('export_modal.settings.schedule_description') }} )
          </span>
        </label>
      </div>
      <div
        v-if="
          formData.selectedModule?.settings_fields?.prompt_fields != null &&
          formData.selectedModule?.settings_fields.prompt_fields.length > 0
        "
        class="field-checkbox"
      >
        <DynamicInputGenerator
          :module="formData.selectedModule"
          @prompt-validated="(value) => formValidationPrompt(value)"
        ></DynamicInputGenerator>
      </div>
    </div>
    <div
      class="border-round-bottom-md flex flex-row justify-content-between px-4 py-4"
      style="background-color: #f5f7fa"
    >
      <div>
        <p-button
          :label="ts.tGlobal('cancel')"
          plain
          text
          class="mr-2"
          @click="emit('cancel-export')"
        />
      </div>
      <div>
        <p-button
          :label="ts.tGlobal('previous')"
          severity="secondary"
          class="mr-2"
          icon="mdi mdi-chevron-left"
          icon-pos="left"
          @click="previousPage()"
        />
        <p-button
          :label="ts.tGlobal('next')"
          :disabled="!valid"
          icon="mdi mdi-chevron-right"
          icon-pos="right"
          @click="nextPage"
        />
      </div>
    </div>
  </div>
</template>
