<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { taskViewServiceKey } from '../../ts/keys/task-view-service.key';

/** Services */
const service = inject(taskViewServiceKey);
const ts = new TranslationService('retailer', 'tasks');
</script>
<template>
  <div v-if="service != null" class="flex flex-column h-full">
    <div class="px-4">
      <pMessage severity="info" :closable="false" class="my-5">
        {{ ts.tModule('addTask.selectOption') }}
      </pMessage>
      <div class="flex flex-row justify-content-center mb-3">
        <pCalendar
          v-model="service.partialObject.date"
          class="mb-1 w-full"
          placeholder="Select due date"
          selection-mode="single"
          :manual-input="false"
          :show-icon="true"
          :show-time="true"
          :min-date="new Date()"
        />
      </div>
    </div>
  </div>
</template>
