import Profile from 'admin/modules/settings/views/profile.vue';
import Security from 'admin/modules/settings/views/security.vue';
import Preferences from 'admin/modules/settings/views/preferences.vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';
import { RouterService } from '@/core/router/router.service';

const settingsRouteFactory = new RouteFactory({
  platform: 'admin',
  module: 'settings',
});

const accountRouteFactory = new RouteFactory({
  tabs: [
    {
      label: 'Personal info',
      key: 'personal-info',
      command: () => {
        RouterService.getInstance().router.push({ name: 'personal-info' });
      },
    },
    {
      label: 'Security',
      key: 'security',
      command: () => {
        RouterService.getInstance().router.push({ name: 'security' });
      },
    },
  ],
});

const settingsRoutes: RouteRecordRaw[] = [
  {
    name: 'settings',
    path: '/settings',
    redirect: { name: 'preferences' },
  },

  settingsRouteFactory.createAppRoute({
    name: 'profile-preferences',
    path: '/settings/preferences',
    component: Preferences,
    title: 'Preferences',
  }),
  //Account routes
  accountRouteFactory.createAppRoute({
    name: 'Account',
    path: '/account',
    redirect: { name: 'personal-info' },
  }),

  accountRouteFactory.createAppRoute({
    name: 'personal-info',
    title: 'Personal info',
    path: '/account/personal-info',
    component: Profile,
  }),
  accountRouteFactory.createAppRoute({
    name: 'security',
    path: '/account/security',
    title: 'Personal info',
    component: Security,
  }),
];

export default settingsRoutes;
