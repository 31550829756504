import { RouterService } from './../router/router.service';
import { App } from 'vue';

export async function installRouter(app: App) {
  const routerService = RouterService.getInstance();

  await routerService.resolveRoutes();

  app.use(routerService.router);
}
