import axios, { Method } from 'axios';
import { ActionTree } from 'vuex';
import { TemplatesRestService } from 'platform-unit2-api/templates';
import { SignFileResponse } from 'platform-unit2-api/core';

const templatesApi = new TemplatesRestService();

export const actions: ActionTree<{}, {}> = {
  async UPLOAD_TEMPLATE({}, file): Promise<SignFileResponse> {
    const sign = await templatesApi.signTemplate(file);
    await axios.request({
      url: sign.url,
      method: sign.method as Method,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
    });
    return sign;
  },
};
