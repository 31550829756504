import { actions } from 'admin/modules/templates/store/templates.actions';
import { Module } from 'vuex';

const namespaced = true;
export const state = {};

export const templatesStore: Module<{}, {}> = {
  namespaced,
  actions,
};
