import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Validation | Validations',
  emptyStateTitle: 'There are no validation models yet',
  emptyStateSubTitle:
    'Start by creating your first validation model and link it to a channel. When a validation model is created it will show here.',
  createValidationModel: 'Create validation model',
  updateValidationModel: 'Update validation model',
  validationModelName: 'Validation model name',
  linkedChannel: 'Linked channel',
  status: 'Status',
  lastEdited: 'Last edited',
  errors: 'Errors',
  createSuccess: 'Validation model created successfully',
  createFailed: 'Validation model could not be created',
  activate: 'Activate',
  statusChangeSuccessfull: 'The status has been changed successfully',
  statusChangeFailed: 'Failed to change the status. please try again',
  validationErrors: {
    required: 'Required Field',
    notUnique: 'This name is already in use',
  },
  dialog: {
    deleteHeader: 'Delete validation model',
    confirmDelete:
      'You are about to delete a validation model. This will permanently remove all nested collections and associated rules, including the connection to the channel. This action cannot be undone.',
  },
  collections: {
    title: 'collection | collections',
    modelNotFound: 'The Model you are trying to access is not found',
    createCollection: 'Create collection',
    updateCollection: 'Update collection',
    emptyStateTitle: 'There are no collections yet',
    emptyStateSubTitle:
      'Start by creating your first collection and link it to an attribute. When a collection is created it will show here.',
    collectionName: 'Collection name',
    linkedAttributeField: 'Linked attribute field',
    optional: 'Optional',
    rules: 'Rules',
    deleteHeader: 'Delete collection',
    confirmDelete:
      'You are about to delete a collection. This will permenantly remove all nested rules. This action cannot be undone.',
    deleteSuccess: 'Collection deleted successfully',
    deleteFailed: 'Collection could not be deleted',
    duplicateSuccess: 'Collection duplicated successfully',
    duplicateFailed: 'Collection could not be duplicated',
  },
  rules: {
    title: 'Rule | Rules',
    createRule: 'Create rule',
    updateRule: 'Update rule',
    ruleName: 'Rule name',
    selectSeverity: 'Select severity',
    errorMessage: 'Error message',
    setToPaused: 'Set to paused',
    setToActive: 'Set to active',
    working: 'Working',
    severityTooltip:
      'Choose whether you want the rule to stop the product from exporting (error) or only show a warning without interrupting any processes (warning) ',
    errorMessageTooltip: 'Create a message with a solution on how to resolve the validation rule',
    emptyStateTitle: 'There are no rules yet',
    emptyStateSubTitle:
      'Start by creating your first rule. You can create as many rules as you need. When a rule is created it will show here.',
    backToOverview: 'Back to overview',
    deleteHeader: 'Delete Rule',
    confirmDelete:
      'You are about to delete the rule. By removing the rule, changes will be lost. This action cannot be undone.',
    deleteSuccess: 'Rule has been deleted successfully',
    deleteFailed: 'Rule could not be deleted',
    statusChangeSuccessful: 'Status has been changed successfully',
    statusChangeFailed: 'Status could not be changed',
    validationLogic: 'Validation logic',
    showAll: 'Show all',
    duplicateSuccess: 'Rule duplicated successfully',
    duplicateFailed: 'Rule could not be duplicated',
  },
};

const translation: Translation = {
  validationRules: translationObject,
};

export default translation;
