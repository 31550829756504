import { ProductFieldsRestService } from 'platform-unit2-api/product-fields';
import { ProductField } from 'platform-unit2-api/product-fields';

export class ProductFieldsService {
  private _api = new ProductFieldsRestService();
  // #region rest api

  public getProductFields = async (productId: number, locales: number[]): Promise<ProductField[]> =>
    await this._api.getProductFields(productId, locales);

  // #endregion
}
