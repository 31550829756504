<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute, AttributeOptions } from 'platform-unit2-api/attributes';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  moduleId?: number;
  options: AttributeOptions;
  attribute?: Attribute;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  moduleId: undefined,
  attribute: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const inputField = ref<any>(null);

const validateData = (value: string | null) => {
  if (
    value == null ||
    (value === '' && !props.attribute?.required && !props.attribute?.options?.required)
  ) {
    emit('error', '');
    return;
  }

  if (!Date.parse(value)) {
    emit('error', ts.tModule('dateTimeField.invalid_date', { params: { date: value } }));
  }
};

const valueUpdated = () => {
  if (inputField.value == null || inputField.value === '') {
    inputField.value = '';
  } else {
    inputField.value = new Date(inputField.value);
  }

  validateData(inputField.value);
  emit('update:modelValue', inputField.value);
};

const getInitialDate = () => {
  if (props.modelValue && props.modelValue != null && props.modelValue !== '') {
    inputField.value = new Date(props.modelValue);
  } else {
    inputField.value = '';
  }

  validateData(props.modelValue);
};

onMounted(() => {
  getInitialDate();
});
</script>
<template>
  <p-calendar
    v-if="options"
    v-model="inputField"
    :disabled="disabled"
    :show-time="true"
    date-format="dd/mm/yy"
    show-button-bar
    @update:model-value="valueUpdated"
  />
  <p-calendar
    v-else
    v-model="inputField"
    :disabled="disabled"
    date-format="dd/mm/yy"
    show-button-bar
    @update:model-value="valueUpdated"
  />
</template>
