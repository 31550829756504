import {
  AttributeField,
  ProductAttributeField,
  FieldErrorEnum,
  ProductAttributeFieldError,
} from 'platform-unit2-api/attribute-fields';
import { AttributeFieldService } from './attribute-field.service';
import { InputSelectType } from './interfaces/input-select.interface';
import { FieldFunctions } from './interfaces/field-functions.interface';

export abstract class InputSelectAttributeFieldService extends AttributeFieldService<InputSelectType> {
  constructor(
    attributeField: AttributeField<InputSelectType>,
    productAttributeField: ProductAttributeField<InputSelectType>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);

    if (this.productAttributeField.value == null && attributeField.attribute.options.default_unit) {
      this.productAttributeField.value = {
        unit: attributeField.attribute?.options?.default_unit,
      };
    }

    this.validate();
  }

  get productAttributeFieldValue(): InputSelectType {
    // always the case in this kind of field
    if (this._productAttributeField.value == null) {
      this._productAttributeField.value = {};
    }

    return this._productAttributeField.value;
  }

  get decimal(): number | undefined {
    return this._productAttributeField.value?.decimal;
  }

  set decimal(value: number | undefined) {
    this.productAttributeFieldValue.decimal = value;
    this.updateValue(this.productAttributeField.value);
  }

  get unit(): string | undefined {
    return this._productAttributeField.value?.unit;
  }

  set unit(value: string | undefined) {
    this.productAttributeFieldValue.unit = value;
    this.updateValue(this.productAttributeField.value);
  }

  protected invalidUnitMessage(): string {
    return this.ts.t('validation.fields.invalidUnit');
  }

  public validate(): boolean {
    this._productAttributeField.errors = [];
    return this.validateRequired() && this.validateSelectedUnit();
  }

  public validateRequired(): boolean {
    if (
      this._attributeField.attribute.required &&
      (this.productAttributeField.value?.unit == null ||
        this.productAttributeField.value?.decimal == null)
    ) {
      this._productAttributeField.errors.push({
        message: this.ts.t('validation.required'),
        severity: FieldErrorEnum.WARNING,
      } as ProductAttributeFieldError);
    }

    return true;
  }

  /**
   * Check if field is required and both inputs have been filled in
   */
  public validateSelectedUnit(): boolean {
    if (
      this.productAttributeField.value?.decimal != null &&
      (this.attributeField.attribute.options?.selectValues?.indexOf(
        this.productAttributeField.value?.unit ?? '',
      ) === -1 ||
        this.attributeField.attribute.options?.currencies?.indexOf(
          this.productAttributeField.value?.unit ?? '',
        ) === -1)
    ) {
      this.productAttributeField.errors.push({
        message: this.invalidUnitMessage(),
        severity: FieldErrorEnum.ERROR,
      } as ProductAttributeFieldError);
      return false;
    }

    return true;
  }
}
