import { ValidationModel, ValidationModelsRestService } from 'platform-unit2-api/validation-models';
import { RefetchService } from '../overview-service/refetch.service';
import { chain, groupBy } from 'lodash';
import { ValidationRule } from 'platform-unit2-api/validation-rules';
import { ValidationSet } from 'platform-unit2-api/validation-sets';
import store from '@/core/store';

interface ValidationRulesGroupedByErrorAndAttributeName {
  [severity: string]: {
    [attributeName: string]: ValidationRule[];
  };
}

export class ProductAttributesValidationService extends RefetchService {
  private validationModelsRestService = new ValidationModelsRestService();
  private _validationModels: ValidationModel[] = [];
  public selectedValidationModel?: ValidationModel;
  public filterByErrors = false;
  public filterByWarnings = false;
  public validationPanelVisible = false;
  public validationModelSelectDialogOpen = false;
  public loading = false;

  public get validationSets() {
    return this.selectedValidationModel?.validation_sets ?? [];
  }

  public get validationRulesBySeverityAndAttributeName(): ValidationRulesGroupedByErrorAndAttributeName {
    const validationRulesBySeverityAndAttributeName = this.groupBySeverityAndAttributeName(
      this.validationSets,
    );

    if (this.filterByErrors) {
      delete validationRulesBySeverityAndAttributeName['Warning'];
    }

    if (this.filterByWarnings) {
      delete validationRulesBySeverityAndAttributeName['Error'];
    }

    return validationRulesBySeverityAndAttributeName;
  }

  public get validationModelsSelectOpenCondition() {
    return !this.loading && this.validationPanelVisible && this.validationModelSelectDialogOpen;
  }

  public get validationModels() {
    return this._validationModels;
  }

  public get selectedModule() {
    return store.getters['products/currentProduct']?.module;
  }

  public get showFilters(): boolean {
    return (
      this.validationRulesBySeverityAndAttributeName['Error'] != null &&
      this.validationRulesBySeverityAndAttributeName['Warning'] != null
    );
  }

  public get errorsCount(): number {
    return (
      Object.keys(this.groupBySeverityAndAttributeName(this.validationSets)?.['Error'] ?? {})
        .length ?? 0
    );
  }
  public get warningsCount(): number {
    return (
      Object.keys(this.groupBySeverityAndAttributeName(this.validationSets)?.['Warning'] ?? {})
        .length ?? 0
    );
  }
  public get validationRulesCount(): number {
    return this.errorsCount + this.warningsCount;
  }

  toggleValidationPanel() {
    this.validationPanelVisible = !this.validationPanelVisible;
    this.validationModelSelectDialogOpen = false;

    if (this.validationPanelVisible && this.selectedValidationModel == null) {
      this.fetchAllValidationModels();
    }
  }

  public async assignValidationModelToProduct(chosenValidationModelId: number | null) {
    if (store.getters['products/currentProduct']?.id == null) {
      return;
    }

    // has to go through the store in order to set the product anew
    await store.dispatch('products/UPDATE_PRODUCT', {
      productId: store.getters['products/currentProduct'].id,
      data: {
        validation_model_id: chosenValidationModelId,
      },
    });
  }

  async fetchValidationModelFromProduct() {
    if (store.getters['products/currentProduct']?.validation_model?.id == null) {
      return;
    }

    const response = await this.validationModelsRestService.get(
      store.getters['products/currentProduct']?.validation_model?.id,
    );

    // fetch all regardless because of other functionalities
    await this.fetchValidationModelFromVariants();

    this.selectedValidationModel = response;

    if (store.getters['products/currentProduct']?.validation_model?.id !== response.id) {
      await this.assignValidationModelToProduct(response.id);
    }
  }

  async fetchValidationModelFromVariants() {
    const response = await this.validationModelsRestService.getAll(this._fetchVariables, {
      module_id: store.getters['products/currentProduct']?.module?.id,
    });
    this._validationModels = response.data;
    if (this._validationModels.length === 1 && this.selectedValidationModel == null) {
      this.selectedValidationModel = this._validationModels[0];
      this.assignValidationModelToProduct(this.selectedValidationModel.id);
    }
  }

  fetchAllValidationModels() {
    this.loading = true;
    if (
      store.getters['products/currentProduct']?.id != null &&
      store.getters['products/currentProduct']?.validation_model?.id != null &&
      store.getters['products/currentProduct']?.module?.id ===
        store.getters['products/currentProduct']?.validation_model?.module?.id
    ) {
      this.fetchValidationModelFromProduct().finally(() => (this.loading = false));
      return;
    }

    if (store.getters['products/currentProduct']?.module?.id == null) {
      this.loading = false;
      return;
    }

    this.fetchValidationModelFromVariants().finally(() => (this.loading = false));
  }

  toggleFilterByErrors(value: boolean) {
    this.filterByWarnings = false;
    this.filterByErrors = value;
  }

  toggleFilterByWarnings(value: boolean) {
    this.filterByErrors = false;
    this.filterByWarnings = value;
  }

  toggleShowAll() {
    this.toggleFilterByErrors(false);
    this.toggleFilterByWarnings(false);
  }

  resetSelectedValidationModel() {
    this.selectedValidationModel = undefined;
    this.validationModelSelectDialogOpen = true;
  }

  async onApplyClick(intermediateSelectedValidationModel?: ValidationModel) {
    this.selectedValidationModel = intermediateSelectedValidationModel;
    this.validationModelSelectDialogOpen = false;
    if (
      store.getters['products/currentProduct']?.validation_model?.id !==
      intermediateSelectedValidationModel?.id
    ) {
      this.loading = true;
      await this.assignValidationModelToProduct(intermediateSelectedValidationModel?.id ?? null);
      this.loading = false;
    }
  }

  getAttributeValidationRulesCountString(
    currentPage: number,
    severity: string,
    attributeName: string,
  ) {
    const rules = this.validationRulesBySeverityAndAttributeName[severity][attributeName];
    return rules.length > 1 ? `(${currentPage + 1}/${rules.length})` : ``;
  }

  // Function to group validation rules by severity using reduce
  private groupBySeverityAndAttributeName(
    data: ValidationSet[],
  ): ValidationRulesGroupedByErrorAndAttributeName {
    let newData = chain(data)
      .flatMap((validationSet) =>
        validationSet.validation_rules.map((rule) => ({
          ...rule,
          attributeName: validationSet.name,
        })),
      )
      .groupBy('severity')
      .mapValues((value) => groupBy(value, 'attributeName'))
      .value();

    // Having errors on top instead of warnings
    newData = {
      Error: newData['Error'],
      Warning: newData['Warning'],
    };

    return newData;
  }
}
