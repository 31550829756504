<script setup lang="ts">
import AttributeView from 'retailer/modules/products/components/attribute-view.vue';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Product } from 'platform-unit2-api/products';
import { Attribute } from 'platform-unit2-api/attributes';
import { LocaleDetails } from 'platform-unit2-api/locales';
import { AttributeViewFields } from '../products.types';

const props = withDefaults(
  defineProps<{
    loading: boolean;
    fields: AttributeViewFields[];
    selectedDatamodel: Datamodel;
    locales: LocaleDetails[];
    filteredLocales: LocaleDetails[];
    searchedWord: string;
    product?: Product;
  }>(),
  {
    loading: false,
    fields: () => [],
    locales: () => [],
    filteredLocales: () => [],
    searchedWord: '',
    selectedDatamodel: () => ({} as Datamodel),
    product: undefined,
  },
);

/** Services */
const ts = new TranslationService('retailer', 'products');

//** Constants */
const store = useStore();

const showMissingFields = computed(() => {
  return store.getters['products/showMissingFields'];
});

const isLoading = computed(() => {
  return props.loading;
});

const attributeVisibility = (attributeId: number): boolean => {
  const filledFields: ProductField[] = props.fields.filter(
    (field: ProductField) => field.attribute_id === attributeId,
  );

  return filledFields.length !== props.locales.length ? false : true;
};

const noEmptyFields = computed((): boolean => {
  let locales;
  if (props.filteredLocales.length) {
    const filteredLocaleIds = props.filteredLocales.map((locale) => locale.id);
    locales = props.locales.filter((locale) => filteredLocaleIds.includes(locale.id));
  } else {
    locales = props.locales;
  }

  const localesId = locales.map((locale) => locale.id);

  const allFilled = props.selectedDatamodel.attributes.every((attribute) => {
    const filledFields: ProductField[] = props.fields.filter(
      (field: ProductField) =>
        field.attribute_id === attribute.id && localesId.includes(field.locale_id),
    );
    return filledFields.length !== locales.length ? false : true;
  });

  return allFilled;
});

/** get attributes */
const attributes = ref<Attribute[]>([]);
const getAttributeList = () => {
  attributes.value = [];
  attributes.value = props.searchedWord
    ? props.selectedDatamodel.attributes.filter((attr) =>
        attr.key.toLocaleLowerCase().includes(props.searchedWord.toLocaleLowerCase()),
      )
    : props.selectedDatamodel.attributes;
};

watch(
  () => [props.searchedWord, props.selectedDatamodel],
  () => getAttributeList(),
);
</script>
<template>
  <div>
    <div v-if="isLoading || !props.product">
      <div class="flex mb-5">
        <p-skeleton height="2.5rem" width="8rem" />
      </div>
      <div class="mb-5">
        <p-skeleton height="1rem" width="10rem" class="mb-2" />
        <p-skeleton height="2rem" />
      </div>
      <div>
        <p-skeleton height="1rem" width="10rem" class="mb-2" />
        <p-skeleton height="10rem" />
      </div>
    </div>

    <div v-else>
      <div v-if="selectedDatamodel">
        <div v-if="showMissingFields && noEmptyFields">
          <p-message severity="info">There are no empty fields</p-message>
        </div>
        <div v-else-if="attributes.length === 0 && !showMissingFields && !noEmptyFields">
          <p-message severity="info">{{ ts.tModule('no_fields_found') }}</p-message>
        </div>

        <div
          v-for="(definition, index) in attributes"
          v-else
          :key="index"
          :style="{
            display: showMissingFields
              ? attributeVisibility(definition.id)
                ? 'none'
                : 'block'
              : 'block',
          }"
          class="mb-4"
        >
          <AttributeView
            :attribute="definition"
            :current-product="props.product"
            :fields="fields"
            :locales="locales"
            :selected-datamodel="selectedDatamodel"
          >
          </AttributeView>
        </div>
      </div>
    </div>
  </div>
</template>
