import { Attribute } from 'platform-unit2-api/attributes';
import {
  DatamodelsRestService,
  CreateDatamodelAttributeDefaultRequest,
  Datamodel,
  DatamodelAttributeDefaultDTO,
  DatamodelAttributeConfig,
} from 'platform-unit2-api/datamodels';
import { LocalesRestService, Locale, LocaleDetails } from 'platform-unit2-api/locales';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Defaults } from '@/general/utils/constants/defaults';
import tags from 'language-tags';

export interface DefaultFieldWithCheckBoxes extends DatamodelAttributeDefaultDTO {
  allSettingBoxValue: boolean;
  existingSettingBoxValue: boolean;
  isExistingDisabled: boolean;
  emptySettingBoxValue: boolean;
  editedSettingsBoxValue: boolean;
}

export class DefaultFieldService {
  /**
   * Loading state.
   */
  private _loading = false;

  /**
   * Toast service. used to display toasts.
   */
  private _toastService: ToastService = ToastService.getInstance();

  /**
   * public getter for the loading state
   */
  public get loading(): boolean {
    return this._loading;
  }

  /**
   * Locales of the workspace.
   */
  private _locales: Locale[] = [];

  /**
   * public getter for the locales of the workspace.
   */
  public get workspaceLocales(): Locale[] {
    return this._locales;
  }

  /**
   * Default values for the attribute of the datamodel.
   */
  private _defaultValues: DatamodelAttributeDefaultDTO[] = [];

  /**
   * Locale rest service for the fetching of the locales.
   */
  private _localeRestService = new LocalesRestService();

  /**
   * Datamodel rest service for the fetching of the default values.
   */
  private _datamodelRestService = new DatamodelsRestService();

  /**
   * Datamodel of the attribute.
   */
  private _datamodel: Datamodel;

  /**
   * Translation service for the translations.
   */
  private _ts: TranslationService;

  /**
   * Confirm service for the confirmation of the deletion of the default values.
   */
  private _confirmService = new ConfirmService();

  /**
   * Attribute of the default values.
   */
  private _attribute: Attribute;

  /**
   * public getter for the attribute of the default values.
   */
  public get attribute(): Attribute {
    return this._attribute;
  }

  /**
   * Array of new default fields with the additional checkboxes, those checkboxes are used in the frontend and send to the backend
   */
  private _newDefaultFieldArray: DefaultFieldWithCheckBoxes[] = [];

  /**
   * Array of updated default fields with the additional checkboxes, those checkboxes are used in the frontend and send to the backend
   */
  private _updateDefaultFieldArray: DefaultFieldWithCheckBoxes[] = [];

  /**
   *  Constructor for the default field service.
   * @param attribute the attribute of the default values
   * @param datamodel the datamodel of the default values
   * @param _close function to close the modal
   */
  constructor(
    attribute: Attribute,
    datamodel: Datamodel,
    private _close: () => void,
    override?: DatamodelAttributeConfig,
  ) {
    this._loading = true;
    this._attribute = attribute;
    this._datamodel = datamodel;
    this._ts = new TranslationService('supplier', 'datamodelsConfig');
    if (override) {
      this._attribute.options = { ...override.options };
    }
  }

  /**
   * Function that does the initialisation of the default field service.
   */
  public init() {
    this._loading = true;
    this.flushUpateAndNewArray();
    this._getWorkspaceLocales().then(() => {
      this._getDatamodelAttributeDefaults()
        .then(() => {
          this._mapLocalesToDefaults();
        })
        .finally(() => (this._loading = false));
    });
  }

  /**
   * Function that returns the default field with the checkboxes based on a given locale id.
   * @param localeId  the id of the locale
   * @returns @type DefaultFieldWithCheckBoxes the default field with the checkboxes
   */
  public defaultField(localeId: number): DefaultFieldWithCheckBoxes | undefined {
    const foundInNew = this._newDefaultFieldArray.find(
      (defaultField) => defaultField.locale_id === localeId,
    );

    if (foundInNew) {
      return foundInNew;
    }

    const foundInUpdate = this._updateDefaultFieldArray.find(
      (defaultField) => defaultField.locale_id === localeId,
    );

    if (foundInUpdate) {
      return foundInUpdate;
    }

    return undefined;
  }

  /**
   * Function that flushes the update and new array.
   */
  private flushUpateAndNewArray() {
    this._newDefaultFieldArray = [];
    this._updateDefaultFieldArray = [];
  }

  /**
   * function that maps the locales to a default field with the checkboxes.
   * based on the given data from the backend
   */
  private _mapLocalesToDefaults() {
    this._locales.forEach((locale) => {
      const defaultField = this._defaultValues.find((defaultField) => {
        return defaultField.locale_id === locale.id;
      });

      const defaultFieldWithBoxes = defaultField
        ? this._initDefaultCheckBoxes(defaultField)
        : this._createEmptyDTO(locale.id);

      if (defaultFieldWithBoxes.id == null) {
        this._newDefaultFieldArray.push(defaultFieldWithBoxes);
      } else {
        this._updateDefaultFieldArray.push(defaultFieldWithBoxes);
      }
    });
  }

  /**
   *  Function that initialises the default field with the checkboxes.
   * this function is used when there is already data from the backend
   * @param defaultField the default field to be mapped to a default field with the checkboxes
   * @returns  @type DefaultFieldWithCheckBoxes the default field with the checkboxes
   */
  private _initDefaultCheckBoxes(
    defaultField: DatamodelAttributeDefaultDTO,
  ): DefaultFieldWithCheckBoxes {
    return {
      attribute_id: defaultField.attribute_id,
      datamodel_id: defaultField.datamodel_id,
      id: defaultField.id,
      locale_id: defaultField.locale_id,
      value: defaultField.value,
      allSettingBoxValue: false,
      existingSettingBoxValue: false,
      emptySettingBoxValue: false,
      isExistingDisabled: false,
      editedSettingsBoxValue: false,
    };
  }

  /**
   *  Function that creates an empty default field with the checkboxes.
   *  this function is used when there is no data from the backend.
   * @param localeId  the id of the locale
   * @returns  @type DefaultFieldWithCheckBoxes the default field with the checkboxes
   */
  private _createEmptyDTO(localeId: number): DefaultFieldWithCheckBoxes {
    return {
      id: undefined,
      datamodel_id: this._datamodel.id,
      attribute_id: this._attribute.id,
      locale_id: localeId,
      value: null,
      isExistingDisabled: true,
      emptySettingBoxValue: false,
      existingSettingBoxValue: false,
      allSettingBoxValue: false,
      editedSettingsBoxValue: false,
    };
  }

  /**
   *  Function that returns the locale detail based on a given locale.
   * @param locale  the locale to be mapped to a locale detail
   * @returns  @type LocaleDetails the locale detail
   */
  public getLocaleDetail(locale: Locale): LocaleDetails {
    const language = tags(locale.value)?.language()?.descriptions();
    const region = tags(locale.value)?.region()?.descriptions();
    const format = tags(locale.value)?.region()?.format();

    return {
      id: locale.id,
      supported: locale.supported,
      value: locale.value,
      language: language?.[0],
      region: region?.[0],
      format: format?.toLowerCase(),
    };
  }

  /**
   * Function that gets all the locales from the workspace of the user.
   */
  private async _getWorkspaceLocales() {
    await this._localeRestService
      .getAll({
        page: 1,
        limit: Defaults.REQUEST_LIMIT,
      })
      .then((res) => {
        this._locales = res.data;
      });
  }

  /**
   * Function that gets the default values for the attribute of the datamodel.
   */
  private async _getDatamodelAttributeDefaults() {
    await this._datamodelRestService
      .getDefaultValuesForAttributeOfDatamodel(this._datamodel.id, this._attribute.id)
      .then((res) => {
        this._defaultValues = res;
      });
  }

  /**
   * Function that sets the state of the default field with the checkboxes.
   * @param localeId  the id of the locale
   * @param selectedOptions the selected options
   */
  public setState(localeId: number, selectedOptions: string[]) {
    const foundLocale = this.defaultField(localeId);

    if (foundLocale) {
      const defaultValueOptions: string[] = [
        'editedSettingsBoxValue',
        'emptySettingBoxValue',
        'existingSettingBoxValue',
      ];
      defaultValueOptions.forEach((option) => {
        Object.assign(foundLocale, { [option]: selectedOptions.includes(option) });
      });
    }
  }

  /**
   * Function that sets the value of the default field.
   * @param value value to be set
   * @param localeId locale id of the default field
   * @returns void
   */
  public setValue(value: any, localeId: number) {
    const foundLocale = this.defaultField(localeId);

    if (!foundLocale) return;

    foundLocale.value = value;
  }

  /**
   * function that save or update the default fields.
   * @returns void
   */
  private async _saveDefaultFields(): Promise<void> {
    if (this._updateDefaultFieldArray.length === 0 && this._newDefaultFieldArray.length === 0) {
      return;
    }

    try {
      if (this._newDefaultFieldArray.length > 0 || this._updateDefaultFieldArray.length > 0) {
        if (this._newDefaultFieldArray.length > 0) {
          await this._datamodelRestService.createDefaultValuesForAttributeOfDatamodel(
            this._datamodel.id,
            this._attribute.id,
            this._formatBody(
              this._newDefaultFieldArray.filter((defaultField) => defaultField.value),
            ),
          );
        }

        if (this._updateDefaultFieldArray.length > 0) {
          await this._datamodelRestService.updateDefaultValuesForAttributeOfDatamodel(
            this._datamodel.id,
            this._attribute.id,
            this._formatBody(this._updateDefaultFieldArray),
          );
        }

        this._toastService.displaySuccessToast(
          this._ts.tModule('configuration.default.succes_update'),
        );
      }
    } catch {
      this._toastService.displayErrorToast(this._ts.tModule('configuration.default.delete_failed'));
    }
  }

  /**
   * function that calls the saveDefaultFields function and closes the modal.
   */
  public async createOrUpdateDefaultFields(): Promise<void> {
    try {
      this._loading = true;
      await this._saveDefaultFields();
    } finally {
      this.init();
      this._close();
    }
  }

  /**
   * Function that formats the body of the default fields.
   * @param arrayToFormat array to be formatted
   * @returns @type CreateDatamodelAttributeDefaultRequest[] the formatted body
   */
  private _formatBody(
    arrayToFormat: DefaultFieldWithCheckBoxes[],
  ): CreateDatamodelAttributeDefaultRequest[] {
    return arrayToFormat
      .filter((defaultField) => defaultField.value != null)
      .map((defaultField) => {
        return {
          locale_id: defaultField.locale_id,
          value: defaultField.value,
          overwrites: {
            overwrite_edited: defaultField.editedSettingsBoxValue,
            overwrite_empty: defaultField.emptySettingBoxValue,
            overwrite_old_defaults: defaultField.existingSettingBoxValue,
          },
        };
      });
  }

  /**
   *  Function that deletes the default field.
   * @param event any event given by an browser event
   * @param localeId the id of the locale
   * @returns  void
   */
  public deleteDefaultField(event: any, localeId: number) {
    const foundDefault = this.defaultField(localeId);

    if (foundDefault?.id == null) {
      this._toastService.displayErrorToast(
        this._ts.tModule('configuration.default.delete_not_found'),
      );
      return;
    }

    this._confirmService.confirmDelete({
      event: event,
      group: 'delete-defaults',
      header: this._ts.tModule('configuration.default.delete_confirm_header'),
      message: this._ts.tModule('configuration.default.delete_confirm_message'),
      callback: async () => {
        this._datamodelRestService
          .deleteDefaultValuesForAttributeOfDatamodel([foundDefault.id!])
          .then(() => {
            this._toastService.displaySuccessToast(
              this._ts.tModule('configuration.default.succes_delete', {
                params: {
                  locale: this.getLocaleDetail(
                    this._locales.find((locale) => locale.id === localeId)!,
                  )
                    .value.toLocaleLowerCase()
                    .replace('-', ' '),
                },
              }),
            );
            this.init();
          })
          .catch(() => {
            this._toastService.displayErrorToast(
              this._ts.tModule('configuration.default.delete_failed'),
            );
          });
      },
    });
  }
}
