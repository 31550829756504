import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { RouterService } from '@/core/router/router.service';
import useStorage from 'utils/storage';

export class AdminModeToggleService {
  private featureFlagService = FeatureFlagService.getInstance();

  private _urlMap = new Map<string, string>([
    ['localhost', 'http://localhost:8088'],
    ['dev.worldofcontent.com', 'https://dev-retail-admin.productsup.dev'],
    ['staging.worldofcontent.com', 'https://staging-retail-admin.productsup.dev'],
    ['retail.productsup.com', 'https://retail-admin.productsup.com/'],
  ]);

  private async _isAdminFeatureFlagOn(): Promise<boolean> {
    return await this.featureFlagService.isOn(featureFlagEnum.STANDALONE_ADMIN_PANEL);
  }

  public async toggleAdminMode(status: boolean): Promise<void> {
    if (!(await this._isAdminFeatureFlagOn())) {
      this._toggleOldAdminPanel(status);
      return;
    }

    this._switchToNewAdminPanel(status);
  }

  private _storeWorkspaceAdminStatus(status: boolean): void {
    const storage = useStorage();
    const workspace = storage.getStorageItem('workspace');
    if (workspace) {
      workspace.admin = status;
      storage.setStorageItem('workspace', workspace);
    }
  }

  private _toggleOldAdminPanel(status: boolean): void {
    this._storeWorkspaceAdminStatus(status);

    RouterService.getInstance().go({ name: 'default' });
  }

  private _switchToNewAdminPanel(status: boolean): void {
    if (!status) {
      this.toggleAdminMode(false);
      return;
    }

    const url = this._urlMap.get(window.location.hostname);

    if (!url) return;

    this._storeWorkspaceAdminStatus(status);

    window.location.href = url;
  }
}
