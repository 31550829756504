<script setup lang="ts">
import { useStore } from 'vuex';
import { User } from 'platform-unit2-api/users';
import UserAvatar from 'ui/components/user-avatar.vue';
import { computed, onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { logout } from '@/general/composables/auth/logout';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { MenuItem } from 'primevue/menuitem';
import { KeycloakService } from '@/core/security/keycloak.service';
import { useRouter } from 'vue-router';

const store = useStore();
const currentUser = computed((): User => store.getters['users/currentUser']);
const ts = new TranslationService('general', 'components');
const router = useRouter();

const menu = ref();
const items = ref<MenuItem[]>([
  {
    to: '/account/personal-info',
    label: ts.tGlobal('profile'),
    icon: 'mdi mdi-account-circle-outline',
  },
  {
    separator: true,
  },
  {
    to: '/settings/preferences',
    label: ts.tGlobal('settings'),
    icon: 'mdi mdi-cog-outline',
  },
]);
const toggle = (event: MouseEvent) => {
  menu.value.toggle(event);
};

router.beforeResolve((to, from, next) => {
  if (to.fullPath === '/productsup') {
    window.open('https://platform.productsup.com', '_blank');
  } else {
    next();
  }
});

onMounted(async () => {
  const service = FeatureFlagService.getInstance();
  const isCrossPlatformLinkEnabled = await service.isOn(featureFlagEnum.CROSS_NAVIGATION);
  const hasAccessToAdvertisementPX = KeycloakService.getInstance().hasAccessToResource('platform');

  if (isCrossPlatformLinkEnabled && hasAccessToAdvertisementPX) {
    items.value.push(
      {
        separator: true,
      },
      {
        to: '/productsup',
        label: ts.tModule('applicationHeader.account.projects'),
        key: 'Project',
        icon: 'mdi mdi-open-in-new',
      },
    );
  }
});
</script>

<template>
  <div>
    <pButton
      text
      class="avatar pup-p-0"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      @click="toggle"
    >
      <UserAvatar
        :url="currentUser.thumbnail?.public_thumbnail_url ?? currentUser.thumbnail?.public_url"
        :username="currentUser?.name"
        size="normal"
        class="cursor-pointer pup-h-3 pup-w-3"
      />
    </pButton>
    <pMenu
      id="overlay_menu"
      ref="menu"
      :model="items"
      :popup="true"
      class="align-items-center pup-p-7 w-25rem"
    >
      <template #start>
        <div class="align-items-center flex flex-column pup-mb-6">
          <UserAvatar
            :url="currentUser.thumbnail?.public_thumbnail_url ?? currentUser.thumbnail?.public_url"
            :username="currentUser?.name"
            size="xlarge"
            class="pup-mb-6"
          />
          <h4 class="font-bold pup-mb-2">{{ currentUser?.name }}</h4>
          <span class="text-gray-400">{{ currentUser?.email }}</span>
        </div>
      </template>
      <template #item="{ item }">
        <routerLink v-if="item.key !== 'Project'" :to="item.to">
          <pButton
            text
            plain
            :label="item.label"
            :icon="item.icon"
            :to="item.to"
            icon-class="m-0"
            class="menuitems pup-p-4 w-full"
          />
        </routerLink>
        <routerLink v-else :to="item.to">
          <pButton text plain :label="item.label" class="menuitems pup-p-4 w-full">
            <p-image class="h-1rem w-1rem" src="/src/assets/images/small-pup-logo.svg" />
            <label class="p-button-label">
              {{ item.label }}<i class="ml-2" :class="item.icon" />
            </label>
          </pButton>
        </routerLink>
      </template>

      <template #end>
        <div class="flex justify-content-end mt-3 w-full">
          <pButton
            text
            :label="ts.tGlobal('sign_out')"
            icon="mdi mdi-arrow-right"
            icon-pos="right"
            @click="logout()"
          />
        </div>
      </template>
    </pMenu>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/theme/settings/spacing.scss';
.p-button.p-button-text.avatar {
  &:hover,
  &:active {
    transform: scale(1.06);
    background: white !important;
    border-color: transparent;
  }
}

.p-menu :deep(.p-menu-list) {
  margin: 10px;
}

.p-button.menuitems :deep(.p-button-label) {
  flex: none;
  margin-left: $spacing-05;
}
</style>
