import { useConfirm } from 'primevue/useconfirm';
import { useStore } from 'vuex';
import { TranslationService } from '../services/translations/translation.service';

export default function (): any {
  const store = useStore();
  const ts = new TranslationService('general', 'components');
  const confirm = useConfirm();
  const dirtyStateDialog = async (onContinue: () => void, onLeave: () => void) => {
    if (store.getters['products/editingProductFields']?.length) {
      confirm.require({
        group: 'dirty-state',
        message: ts.tModule('dirtyState.unsavedItemsMessage', {
          params: {
            amount: store.getters['products/editingProductFields'].length,
          },
        }),
        header: ts.tModule('dirtyState.unsavedItems'),
        icon: 'mdi mdi-alert-outline',
        acceptLabel: ts.tGlobal('leave'),
        rejectLabel: ts.tGlobal('continue'),
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-text p-button-plain',
        accept: async () => {
          await store.dispatch('products/CANCEL_EDITING_FIELD_DATA');
          onLeave();
        },
        reject: async () => {
          onContinue();
        },
      });
    } else {
      onLeave();
    }
  };

  return {
    dirtyStateDialog,
  };
}
