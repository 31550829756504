<script setup lang="ts">
import { ref, provide, Ref } from 'vue';
import OverView from 'ui/views/over-view/over-view.vue';
import OverViewSideBar from 'ui/components/over-view/overview-sidebar.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';
import { AdminFieldService } from '../ts/services/fields-view.service';
import { fieldServiceKey } from '../ts/keys/fields.keys';
import FieldsTable from '../components/overview/fields-table.vue';
import FieldsForm from '../components/overview/fields-form.vue';
import FieldsTableSkeleton from '../components/overview/field-table-empty-state.vue';

const ts = ref(new TranslationService('admin', 'fields'));
const service = ref(new AdminFieldService());

provide(fieldServiceKey, service as Ref<AdminFieldService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="admin" module-name="fields" :service="service">
    <template #skeleton> <FieldsTableSkeleton /></template>
    <template #content>
      <FieldsTable />
    </template>
    <template #crudComponent>
      <OverViewSideBar :service="service">
        ><template #form> <FieldsForm /> </template>
      </OverViewSideBar>
    </template>
  </OverView>

  <p-confirm-popup group="fields" />
</template>

<style scoped lang="scss"></style>
