<script setup lang="ts">
import { computed } from 'vue';
import emptyStateImage from '@/assets/images/illustration_nutrients.svg';

/** Props */
export interface Props {
  col?: string;
  height?: '8rem' | '15rem' | '20rem' | '25rem' | '30rem';
  headerText: string;
}

const props = withDefaults(defineProps<Props>(), {
  col: '12',
  height: '8rem',
});

/** Emits */
const emits = defineEmits<{
  (e: 'clicked'): void;
}>();

/** Constants */
const columns = computed(() => 'col-' + props.col);

/** Lifecycle */
</script>

<template>
  <div :class="[columns]" @click="emits('clicked')">
    <div class="border-100 border-solid grid grid-nogutter" :style="{ height: height }">
      <div class="m-auto">
        <div class="flex w-full">
          <img class="align-self-center mr-5" alt="Empty state image" :src="emptyStateImage" />
          <div class="align-self-center">
            <h3 class="pb-1">{{ headerText }}</h3>
            Start adding them below
          </div>
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
