<script setup lang="ts">
import AddProductVariantModal from 'retailer/modules/products/components/add-product-variant-modal.vue';
import ProductSingleVariant from 'retailer/modules/products/components/product-single-variant.vue';
import useDirtyState from 'composables/dirty-state';
import { useStore } from 'vuex';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { ProductCompact } from 'platform-unit2-api/products';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');

/** Consts */
const store = useStore();
const router = useRouter();
const route = useRoute();
const { dirtyStateDialog } = useDirtyState();
const currentUser = store.getters['users/currentUser'];
const loading = ref(true);
const showVariantModal = ref(false);

const variants = ref<ProductCompact[]>([]);
const productId = ref<number | number[]>();

const variantUuid = computed(() => {
  return store.getters['products/currentProduct'].variant_uuid;
});

const sortedVariants = computed(() => {
  const sortedVariants = variants.value
    .map((item) => item)
    .sort((product: ProductCompact) => {
      if (product.variant_name == null) {
        return -1;
      }

      return 0;
    });
  return sortedVariants.sort((a: ProductCompact, b: ProductCompact) => {
    if (a.variant_name && b.variant_name) {
      if (a.variant_name < b.variant_name) {
        return -1;
      }

      if (a.variant_name > b.variant_name) {
        return 1;
      }

      return 0;
    } else {
      return 0;
    }
  });
});

const getVariantData = async () => {
  if (!variantUuid.value) {
    return;
  }

  loading.value = true;
  try {
    variants.value = await store.dispatch('products/GET_PRODUCT_VARIANTS', variantUuid.value);
  } catch {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const openVariantModal = () => {
  showVariantModal.value = true;
};

const closeVariantModal = () => {
  showVariantModal.value = false;
};

const navigateToVariant = (productId: number) => {
  if (route.params.id == productId.toString()) return;

  store.dispatch('products/GET_PRODUCT_DETAILS', productId);
  dirtyStateDialog(
    () => {
      return;
    },
    () => {
      router.push({
        name: route.name,
        params: {
          id: productId.toString(),
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    },
  );
};

const onDelete = async () => {
  await getVariantData();
  const masterProductId = variants.value.filter(
    (item: ProductCompact) => item.variant_name == null,
  )[0].id;

  router.push({
    name: route.name,
    params: {
      id: masterProductId.toString(),
    } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const variantCreated = async (productId: number) => {
  if (!variantUuid.value) {
    return;
  }

  showVariantModal.value = false;

  variants.value = (await store.dispatch('products/GET_PRODUCT_VARIANTS', variantUuid.value)).sort(
    (product: ProductCompact) => {
      if (product.variant_name == null) {
        return -1;
      }

      return 0;
    },
  );
  navigateToVariant(productId);
};

watch(
  () => variantUuid.value,
  () => {
    getVariantData();
  },
);

watch(
  () => route.params.id,
  () => {
    productId.value = +route.params.id;
  },
);

onMounted(async () => {
  productId.value = +route.params.id;
  await getVariantData();
});
</script>

<template>
  <div>
    <AddProductVariantModal
      v-if="showVariantModal"
      :is-active="showVariantModal"
      @hide="closeVariantModal"
      @saved="variantCreated"
    />
    <div class="align-items-center flex flex-row">
      <p v-badge="variants.length" class="font-bold mr-3 text-lg">
        {{ ts.tGlobal('variants', { choice: 2 }) }}:
      </p>
      <div class="mr-3 overflow-x-auto px-4 py-2 w-full white-space-nowrap">
        <div v-if="loading" class="flex flex-row">
          <p-skeleton
            v-for="i in 5"
            :key="i"
            width="10rem"
            height="3rem"
            border-radius="1rem"
            class="flex-shrink-0 mr-3"
          />
        </div>

        <ProductSingleVariant
          v-for="variant in sortedVariants"
          v-else
          :key="variant.id"
          :variant="variant"
          :is-selected="variant.id == parseInt(route.params.id.toString())"
          @click="navigateToVariant(variant.id)"
          @deleted="onDelete()"
        />
      </div>

      <p-button
        v-if="currentUser.workspace.workspace_type?.type !== ClientTypeEnum.RETAILER"
        text
        class="flex-shrink-0 ml-auto"
        icon="mdi mdi-plus"
        :label="ts.tModule('add_variant')"
        @click="openVariantModal"
      />
    </div>
  </div>
</template>
