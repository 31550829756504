<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import DatamodelProperties from 'admin/modules/datamodels/components/tabs/datamodel-properties.vue';
import DatamodelAttributes from 'admin/modules/datamodels/components/tabs/datamodel-attributes.vue';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Datamodel, DatamodelsRestService } from 'platform-unit2-api/datamodels';

//vue relative
import { DatamodelElement } from '../ts/interfaces/datamodel-element.interface';
import { ModelEnum } from '../ts/enums/model.enum';
import { isPositive } from '@/general/utils/isPositive';

/** Emits */
const emit = defineEmits<{
  (e: 'refresh'): void;
  (e: 'hide'): void;
}>();

/** Composables */
const router = useRouter();
const route = useRoute();

/** Services */
const ts = new TranslationService('admin', 'datamodels');
const attributeTs = new TranslationService('admin', 'attributes');
const datamodelApi = new DatamodelsRestService();

const linkList: DatamodelElement[] = [
  {
    title: 'Properties',
    name: 'Properties',
    model: ModelEnum.PROPERTIES,
  },
  {
    title: 'Attributes',
    name: 'Attributes',
    model: ModelEnum.ATTRIBUTES,
  },
  {
    title: 'Inherited',
    name: 'Inherited',
    model: ModelEnum.INHERITED,
  },
];

const editorDatamodel = ref({});
const currentModel = ref<DatamodelElement>(linkList[0]);
const activeIndex = ref(0);

const changeData = (index: number) => {
  if (index <= linkList.length - 1) {
    currentModel.value = linkList[index];
    router.push({
      name: 'edit-datamodel',
      params: {
        id: route.params.id,
        model: currentModel.value.title.toLowerCase(),
      } as RouteParamsRaw,
    } as RouteLocationRaw);
  }
};

const hideDetails = () => {
  emit('hide');
};

const refresh = () => {
  emit('refresh');
};

watch(
  () => route.params.model,
  () => {
    if (route.path.includes('fields')) {
      router.push({
        name: 'edit-field',
        params: {
          id: route.params.id,
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    } else {
      if (currentModel.value.title !== route.params.model) {
        activeIndex.value = linkList.findIndex(
          (item) => item.title.toLowerCase() == route.params.model,
        );
        changeData(activeIndex.value);
      }
    }
  },
);

onMounted(async () => {
  if (isPositive(route.params.id)) {
    editorDatamodel.value = await datamodelApi.get(Number(route.params.id));
  }

  if (route.params.model) {
    activeIndex.value = linkList.findIndex(
      (item) => item.title.toLowerCase() === route.params.model,
    );
    currentModel.value = linkList[activeIndex.value];
  } else {
    changeData(0);
  }
});
</script>
<template>
  <div class="h-full">
    <h3 class="font-bold mb-1 text-2xl">
      {{ ts.moduleUpdateTitle }}
    </h3>
    <p class="mb-5 text-lg">
      {{ ts.moduleUpdateSubTitle }}
    </p>

    <p-tab-view :active-index="activeIndex" @update:active-index="changeData">
      <p-tab-panel header="Properties">
        <DatamodelProperties
          :datamodel="(editorDatamodel as Datamodel)"
          @hide="hideDetails"
          @refresh="refresh"
        />
      </p-tab-panel>
      <p-tab-panel :header="attributeTs.titlePlural">
        <DatamodelAttributes />
      </p-tab-panel>
    </p-tab-view>
    <p-confirm-popup group="datamodel-properties" />
  </div>
</template>
