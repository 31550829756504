<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import moment from 'moment';
import { Activity } from 'platform-unit2-api/activities';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  activity: Activity[];
}>();

// Services
const ts = new TranslationService('retailer', 'activities');

/** constants */
const activities = ref<Activity[]>([]);

onMounted(() => {
  activities.value = props.activity;
});
</script>

<template>
  <div v-if="activities?.length" class="flex">
    <!-- #region: label -->
    <div class="flex flex-column">
      <div
        class="align-items-center bg-red-600 border-circle flex font-bold justify-content-center text-white text-xl"
        :style="{ width: '40px', aspectRatio: '1' }"
      >
        {{ activities[0]?.user?.charAt(0).toLocaleUpperCase() }}
      </div>
      <div class="flex flex-grow-1 justify-content-center line py-1">
        <div class="border-1 border-gray-200" :style="{ width: '0px' }"></div>
      </div>
    </div>
    <!--  #endregion -->

    <!-- #region: activity details -->
    <div class="body flex-grow-1 px-3 text-gray-600">
      <!-- Title -->
      <div class="mb-3">
        {{ activities[0]?.user }}
        <span class="font-bold text-gray-800">{{ ts.tModule('userDeletedStory') }}:</span>
      </div>
      <!-- details -->
      <div class="mb-3">
        {{ activities[0]?.modified?.data?.old }}
      </div>
      <!-- Time -->
      <div class="mb-4 text-gray-500 text-sm time">
        {{ moment(activities[0]?.created_at).format('DD MMM Y, HH:mm') }}
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>
