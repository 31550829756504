<script setup lang="ts">
import { Retailer, RetailersRestService } from 'platform-unit2-api/retailers';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<Retailer>>();
const retailerRestService = RetailersRestService;
</script>
<template>
  <BaseSelect
    :rest-service="retailerRestService"
    option-label="name"
    class="w-full"
    module-name="retailer"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
