<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  maxLength: number;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const inputText = ref('');

watchEffect(() => {
  inputText.value = props.modelValue ?? '';
});

onMounted(() => {
  inputText.value = props.modelValue ?? '';
});

const valueUpdated = () => {
  const errorMessages = [];

  if (props.maxLength && inputText.value.length > props.maxLength) {
    errorMessages.push(ts.tModule('baseField.error', { params: { value: props.maxLength } }));
  }

  emit('error', errorMessages);
  emit('update:modelValue', inputText.value);
};
</script>

<template>
  <p-textarea
    v-model="inputText"
    :auto-resize="true"
    rows="5"
    class="w-full"
    :class="maxLength && inputText.length > maxLength ? 'border-pink-400' : ''"
    :placeholder="ts.tModule('textAreaField.placeholder')"
    :disabled="disabled"
    @update:model-value="valueUpdated"
  />
  <span
    v-if="maxLength"
    class="bg-white min-w-max p-inputgroup-addon"
    :class="inputText.length > maxLength ? 'border-pink-400' : ''"
  >
    {{ inputText ? inputText.length : 0 }} / {{ maxLength }}</span
  >
</template>
