<script setup lang="ts">
import { inject } from 'vue';
import { editImageServiceKey } from '../../ts/keys/supplier-ai.keys';
import MediaPicker from 'supplier/modules/media/components/media-picker.vue';
import { Upload } from 'platform-unit2-api/uploads';

const service = inject(editImageServiceKey)!;
</script>
<template>
  <div>
    <div class="font-bold mb-4 text-3xl">Edit images</div>
    <div class="align-items-center flex justify-content-between w-full">
      <div class="field flex flex-column mr-3 w-4">
        <label for="prompt">Prompt <span class="font-bold text-pink-500">&ast;</span> </label>
        <pInputText
          id="prompt"
          v-model="service.promptRequest.prompt"
          :disabled="service.isLoading"
          type="text"
        />
      </div>
      <div class="field flex flex-column mr-3 w-2">
        <label
          v-tooltip="
            'Chosen image has to have a transparant background, this will be filled in with AI.'
          "
          for="number"
          >Image
          <i class="mdi mdi-information-outline" />
          <span class="font-bold text-pink-500">&ast;</span></label
        >
        <pButton
          :label="service.sourceImage ? service.sourceImage.filename : 'Select image'"
          :disabled="service.isLoading"
          @click="service.showSourcePicker()"
        />
        <MediaPicker
          v-model:visible="service.sourcePickerVisible"
          :selected-ids="[]"
          :multiple="false"
          accept-files="image/png"
          @hide="service.hideSourcePicker()"
          @choose-multiple-images="(event: Upload[]) => service.setSourceImage(event[0])"
        />
      </div>
      <div class="field flex flex-column mr-3 w-2">
        <label for="number">Amount </label>
        <pDropdown
          id="number"
          v-model="service.promptRequest.amount"
          :disabled="service.isLoading"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          type="text"
        />
      </div>
      <div class="field flex flex-column mr-3 w-2">
        <label for="number">Prompt tags</label>
        <pMultiselect
          v-model="service.tags"
          :disabled="service.isLoading"
          :options="service.tagOptions"
          type="text"
        />
      </div>
      <div class="field flex flex-column mr-3 w-2">
        <label for="size">Size</label>
        <pDropdown
          id="size"
          v-model="service.promptRequest.size"
          :disabled="service.isLoading"
          :options="['256x256', '512x512', '1024x1024']"
          type="text"
        />
      </div>
      <pButton
        class="h-3rem mt-2 w-3"
        label="Edit image"
        :disabled="
          service.isLoading || service.sourceImage == null || !service.promptRequest.prompt
        "
        @click="service.generateImages()"
      />
    </div>
  </div>
</template>
