import { Navigation } from 'platform-unit2-api/core';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { FeaturePermission } from 'platform-unit2-api/roles';

async function getSupplierNavigation(): Promise<Navigation[]> {
  const service = FeatureFlagService.getInstance();
  const aiNavigations = await service.isOn(featureFlagEnum.AI_ENHANCEMENTS);

  let navigations: Navigation[] = [
    {
      title: 'dashboard',
      to: '/dashboard',
      icon: 'mdi-view-dashboard-outline',
      group: 'dashboard',
    },
    {
      title: 'products',
      to: '/products',
      icon: 'mdi-shopping-outline',
      group: 'products',
    },
    {
      title: 'imports',
      to: '/import',
      group: 'import',
      icon: 'mdi-import',
      permission: FeaturePermission.IMPORT_MEDIA_BULK,
    },
    {
      title: 'exports',
      to: '/export',
      group: 'export',
      icon: 'mdi-export',
    },
    {
      title: 'shared',
      icon: 'mdi mdi-share-all',
      group: 'shared',
      to: '/shared',

      children: [
        {
          title: 'shared_by_me',
          to: '/shared-by-me',
        },
        {
          title: 'shared_with_me',
          to: '/shared-with-me',
        },
      ],
    },
    {
      title: 'task',
      to: '/tasks',
      icon: 'mdi-clipboard-list-outline',
    },
    {
      title: 'media',
      to: '/media',
      icon: 'mdi-image-multiple-outline',
      group: 'media',
    },
    {
      title: 'users',
      to: '/users',
      icon: 'mdi-account-outline',
      permission: FeaturePermission.MANAGE_USERS,
    },
    {
      title: 'brands',
      to: '/brands',
      icon: 'mdi-tag-outline',
      permission: FeaturePermission.MANAGE_BRANDS,
    },
    {
      title: 'datamodels',
      to: '/datamodels',
      icon: 'mdi-format-list-text',
      permission: FeaturePermission.MANAGE_DATAMODELS,
    },
    {
      title: 'mappings',
      to: '/mappings',
      icon: 'mdi-shuffle',
      group: 'mappings',
      permission: FeaturePermission.MANAGE_MAPPINGS,
    },
    {
      title: 'channels',
      to: '/channels',
      icon: 'mdi-share-variant',
      permission: FeaturePermission.MANAGE_MODULES,
    },
    {
      title: 'categories',
      to: '/categories',
      icon: 'mdi-folder-multiple-outline',
      permission: FeaturePermission.MANAGE_CATEGORIES,
    },
    {
      title: 'tools',
      to: '/tools/editor',
      icon: 'mdi-tools',
      group: 'tools',
    },
  ];

  if (aiNavigations) {
    navigations = [
      ...navigations,
      {
        title: 'AI+',
        to: '/ai-enhancements',
        icon: 'mdi-robot',
        group: 'ai-enhancements',
      },
    ];
  }

  return navigations;
}

export default getSupplierNavigation;
