<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';
import { onMounted, ref, watch } from 'vue';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import usePagination from 'composables/usePagination/pagination';
import useSortable from 'composables/sortable';
import { PaginationObject } from 'platform-unit2-api/core';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ModuleDetail, ModulesRestService } from 'platform-unit2-api/modules';
import { DataTablePageEvent } from 'primevue/datatable';
import useDebounce from 'utils/debounce';

/** Composables */
const router = useRouter();
const route = useRoute();
const { debounce } = useDebounce();

const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const { sort } = useSortable();

/** Services */
const ts = new TranslationService('admin', 'modules');
const toastService = ToastService.getInstance();
const moduleApi = new ModulesRestService();

/** Constants */
const loading = ref(false);
const showSidebar = ref(false);
const modules = ref<ModuleDetail[]>([]);
const total = ref(0);
let searchCallback: any = undefined;

const loadAsyncData = async (searchQuery?: string): Promise<void> => {
  loading.value = true;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    const response = await moduleApi.getAll({
      query: query.value,
      page: page.value,
      limit: perPage.value,
      sortBy: sort.value,
    } as PaginationObject);

    modules.value = response.data;
    total.value = response.meta?.total ?? 0;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }

  loading.value = false;
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData(query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

const editModule = async (id: number): Promise<void> => {
  router.push({
    name: 'edit-module',
    params: { id: id.toString() } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const hideDetails = () => {
  router.push({
    name: 'modules',
  } as RouteLocationRaw);
};

onMounted(() => {
  if (route.query && route.query['amazon-setup'] != null) {
    if (route.query['amazon-setup'] == 'success') {
      toastService.displaySuccessToast(ts.tModule('setupSuccess'));
    }

    if (route.query['amazon-setup'] == 'failed') {
      toastService.displayErrorToast(ts.tModule('setupFailed'));
    }
  }

  if (
    route.name === 'new-module' ||
    route.name === 'edit-module' ||
    route.name === 'new-credentials'
  ) {
    showSidebar.value = true;
  }

  loadAsyncData();
});

watch(
  () => route,
  () => {
    if (
      route.name === 'new-module' ||
      route.name === 'edit-module' ||
      route.name === 'new-credentials'
    ) {
      showSidebar.value = true;
    }

    if (route.name === 'modules') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};
</script>
<template>
  <section class="flex flex-column h-full justify-content-between pt-3 px-4 relative">
    <pIconField icon-position="left" class="my-3">
      <pInputIcon class="pi pi-search" />
      <pInputText
        :placeholder="ts.tGlobal('search')"
        @update:model-value="(value: string) => search(value)"
      />
    </pIconField>

    <CardSkeleton v-if="loading" />

    <div v-else class="align-content-start card-grid flex-grow-1 grid pb-2">
      <div v-for="channel in modules" :key="channel.id" class="col-3 h-23rem">
        <p-card class="card cursor-pointer h-full" @click="editModule(channel.id)">
          <template #header>
            <div class="align-items-center flex flex-column justify-content-center">
              <Upload class="h-7rem w-7rem" :upload="channel.retailer?.thumbnail" />
              <p class="font-bold mb-2 mt-4 text-xl">
                {{ channel.name }}
              </p>
            </div>
          </template>
          <template #content>
            <div class="align-items-center flex flex-column justify-content-center">
              <p class="capitalize text-lg">{{ channel.type }}</p>
              <p class="font-italic mt-1 text-gray-600">{{ channel.key }}</p>
            </div>
          </template>
        </p-card>
      </div>
    </div>

    <p-paginator
      v-if="total && total > perPage"
      class="bottom-0 sticky"
      :first="(page - 1) * perPage"
      :rows="perPage"
      :total-records="total"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      @page="total && total > perPage && onPageChange($event)"
    ></p-paginator>

    <router-view v-slot="{ Component }">
      <p-sidebar
        v-model:visible="showSidebar"
        class="p-sidebar-md sidebar-crud"
        :dismissable="false"
        position="right"
        style="z-index: 10"
      >
        <transition>
          <component :is="Component" @hide="hideDetails" @refresh="loadAsyncData" />
        </transition>
      </p-sidebar>
    </router-view>

    <p-confirm-popup group="modules" />
  </section>
</template>

<style lang="scss" scoped>
.p-card :deep(.p-card-content) {
  padding: 0;
}
.p-card :deep(.p-card-body) {
  padding: 0;
}
</style>
