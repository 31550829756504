<script setup lang="ts">
import { ref } from 'vue';
import TemplateSelect from '@/general/ui/components/selects/template-select.vue';
import RetailerSelect from '@/general/ui/components/selects/retailer-select.vue';
import Tiptap from 'supplier/modules/editor/components/tiptap.vue';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import useFormValidation from 'composables/form-validation';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ModulesRestService, ModuleDetail, UpdateModuleRequest } from 'platform-unit2-api/modules';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';

const emit = defineEmits<{ (e: 'hide'): void; (e: 'refresh'): void }>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'modules');
const tsChannels = new TranslationService('supplier', 'modules');
const moduleApi = new ModulesRestService();

//** Constants */
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

const saving = ref(false);

const editorModule = ref<ModuleDetail>({
  file_per_product: false,
  export_resize_enabled: false,
  filename_template: '{%field:GTIN%}_{%number:meta__order%}',
  media_template: '{%field:GTIN%}_{%number:meta__order%}',
} as ModuleDetail);

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const module: UpdateModuleRequest = {
      id: editorModule.value.id,
      name: editorModule.value.name,
      key: editorModule.value.key,
      type: editorModule.value.type,
      template_id: editorModule.value.template?.id,
      retailer_id: editorModule.value.retailer?.id,
      client_id: editorModule.value.client?.id,
      filename_template: editorModule.value.filename_template,
      media_template: editorModule.value.media_template,
      media_instructions: editorModule.value.media_instructions,
      file_per_product: editorModule.value.file_per_product,
      export_resize_enabled: editorModule.value.export_resize_enabled,
    };

    if (editorModule.value.export_resize_enabled == true) {
      module.export_resize_width = editorModule.value.export_resize_width;
      module.export_resize_height = editorModule.value.export_resize_height;
      module.export_resize_format = editorModule.value.export_resize_format;
    }

    await moduleApi.post(module);

    emit('refresh');
    emit('hide');

    toastService.displaySuccessToast(ts.createSuccess());
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.createFailed());
    });
  } finally {
    saving.value = false;
  }
};
</script>
<template>
  <CrudSidebar
    :title="tsChannels.moduleCreateTitle"
    :subtitle="tsChannels.moduleCreateSubTitle"
    :saving="saving"
    @cancel="emit('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <div class="field mb-3">
        <label for="name">{{ tsChannels.tModule('table.name') }}</label>
        <p-input-text
          id="name"
          v-model="editorModule.name"
          :class="{ 'p-invalid': hasError('name') }"
          class="w-full"
          type="text"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <div class="field mb-3">
        <label for="key">{{ tsChannels.tModule('table.key') }}</label>
        <p-input-text
          id="key"
          v-model="editorModule.key"
          :class="{ 'p-invalid': hasError('key') }"
          class="w-full"
          type="text"
        />
        <small
          v-if="hasError('key')"
          :class="{ 'p-error block': hasError('key') }"
          class="hidden"
          >{{ fieldErrorMessage('key').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <label>{{ tsChannels.tModule('table.type') }}</label>
        <pDropdown
          v-model="editorModule.type"
          :options="['import', 'export']"
          placeholder="Select type"
          display="chip"
          class="w-full"
          :class="{ 'p-invalid': hasError('type') }"
        />

        <small
          v-if="hasError('type')"
          :class="{ 'p-error block': hasError('type') }"
          class="hidden"
          >{{ fieldErrorMessage('type').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <TemplateSelect
          v-model="editorModule.template"
          :class="{ 'p-invalid': hasError('template_id') }"
        />
        <small
          v-if="hasError('template_id')"
          :class="{ 'p-error block': hasError('template_id') }"
          class="hidden"
          >{{ fieldErrorMessage('template_id').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <RetailerSelect
          v-model="editorModule.retailer"
          :class="{ 'p-invalid': hasError('retailer_id') }"
        />
        <small
          v-if="hasError('retailer_id')"
          :class="{ 'p-error block': hasError('retailer_id') }"
          class="hidden"
          >{{ fieldErrorMessage('retailer_id').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <ClientSelect v-model="editorModule.client" />
      </div>

      <div class="field mb-3">
        <label for="filename_template">{{ tsChannels.tModule('fields.export_template') }}</label>
        <p-input-text
          id="filename_template"
          v-model="editorModule.filename_template"
          class="w-full"
          type="text"
          :class="{ 'p-invalid': hasError('filename_template') }"
        />
        <small
          v-if="hasError('filename_template')"
          :class="{ 'p-error block': hasError('filename_template') }"
          class="hidden"
          >{{ fieldErrorMessage('filename_template').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <label for="media_template">{{ tsChannels.tModule('fields.media_template') }}</label>
        <p-input-text
          id="media_template"
          v-model="editorModule.media_template"
          class="w-full"
          type="text"
          :class="{ 'p-invalid': hasError('media_template') }"
        />
        <small
          v-if="hasError('media_template')"
          :class="{ 'p-error block': hasError('media_template') }"
          class="hidden"
          >{{ fieldErrorMessage('media_template').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <label for="export_template">{{ tsChannels.tModule('fields.media_instructions') }}</label>

        <div class="border-1 border-200 editor-wrapper w-full">
          <Tiptap
            :model-value="editorModule.media_instructions"
            :allow-images="false"
            :allow-videos="false"
            :class="{ 'p-invalid': hasError('media_instructions') }"
            @update:model-value="($event) => (editorModule.media_instructions = $event)"
          ></Tiptap>
        </div>
        <small
          v-if="hasError('media_instructions')"
          :class="{ 'p-error block': hasError('media_instructions') }"
          class="hidden"
          >{{ fieldErrorMessage('media_instructions').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <label for="file_per_product">{{ tsChannels.tModule('fields.file_per_product') }}</label>
        <p-checkbox
          v-model="editorModule.file_per_product"
          :binary="true"
          class="w-full"
          :class="{ 'p-invalid': hasError('file_per_product') }"
        />
        <small
          v-if="hasError('file_per_product')"
          :class="{ 'p-error block': hasError('file_per_product') }"
          class="hidden"
          >{{ fieldErrorMessage('file_per_product').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <label for="export_resize_enabled">{{
          tsChannels.tModule('fields.export_resize_enabled')
        }}</label>
        <p-checkbox
          v-model="editorModule.export_resize_enabled"
          :binary="true"
          class="w-full"
          :class="{ 'p-invalid': hasError('export_resize_enabled') }"
        />
        <small
          v-if="hasError('export_resize_enabled')"
          :class="{ 'p-error block': hasError('export_resize_enabled') }"
          class="hidden"
          >{{ fieldErrorMessage('export_resize_enabled').toString() }}</small
        >
      </div>

      <div v-if="editorModule.export_resize_enabled" class="border-1 border-200 field mb-3 p-3">
        <div class="field mb-3">
          <label for="export_resize_enabled">{{
            tsChannels.tModule('fields.export_resize_width')
          }}</label>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-inputgroup">
                <p-input-text
                  v-model="editorModule.export_resize_width"
                  class="w-full"
                  type="text"
                  :class="{ 'p-invalid': hasError('export_resize_width') }"
                />
                <span class="p-inputgroup-addon">{{ tsChannels.tModule('fields.pixels') }}</span>
              </div>
              <small :class="{ 'p-error block': hasError('export_resize_width') }" class="hidden">
                {{ fieldErrorMessage('export_resize_width').toString() }}
              </small>
            </div>
          </div>
        </div>

        <div class="field mb-3">
          <label for="export_resize_enabled">{{
            tsChannels.tModule('fields.export_resize_height')
          }}</label>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-inputgroup">
                <p-input-text
                  v-model="editorModule.export_resize_height"
                  class="w-full"
                  type="text"
                  :class="{ 'p-invalid': hasError('export_resize_height') }"
                />
                <span class="p-inputgroup-addon">{{ tsChannels.tModule('fields.pixels') }}</span>
              </div>
              <small
                :class="{ 'p-error block': hasError('export_resize_height') }"
                class="hidden"
                >{{ fieldErrorMessage('export_resize_height').toString() }}</small
              >
            </div>
          </div>
        </div>

        <div class="field mb-3">
          <label>{{ tsChannels.tModule('fields.export_resize_format') }}</label>
          <pDropdown
            v-model="editorModule.export_resize_format"
            :options="['png', 'jpg', 'tiff']"
            placeholder="Select type"
            display="chip"
            class="w-full"
            :class="{ 'p-error block': hasError('export_resize_format') }"
          />
          <small :class="{ 'p-error block': hasError('export_resize_format') }" class="hidden">{{
            fieldErrorMessage('export_resize_format').toString()
          }}</small>
        </div>
      </div>
    </template>
  </CrudSidebar>
</template>
