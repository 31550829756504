<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import usePagination from 'composables/usePagination/pagination';
import useSortable from 'composables/sortable';
import { RouteLocationRaw, RouteParamsRaw, RouterView, useRoute, useRouter } from 'vue-router';
import { MenuItem } from 'primevue/menuitem';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';

import { Datamodel, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { DataTablePageEvent, DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';
import useDebounce from 'utils/debounce';
import { AdminRestService } from 'platform-unit2-api/admin';

/** Composables */
const router = useRouter();
const route = useRoute();
const { debounce } = useDebounce();
let searchCallback: any = undefined;

const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const { sort, onSortChange: $onSortChange } = useSortable();

/** Services */
const ts = new TranslationService('admin', 'datamodels');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const adminApi = new AdminRestService();
const datamodelApi = new DatamodelsRestService();

//** Constants */
const loading = ref(false);
const showSidebar = ref(false);
const selectedDatamodel = ref<Datamodel>();
const datamodels = ref<Datamodel[]>([]);
const total = ref();
const menu = ref();

const menuItems = (): MenuItem[] => [
  {
    label: ts.updateTitle,
    icon: 'mdi mdi-pencil',
    command: () => {
      showDetails();
    },
  },
  {
    label: ts.downloadTitle,
    icon: 'mdi mdi-tray-arrow-down',
    command: () => {
      downloadDatamodelExcel();
    },
  },
  {
    label: ts.duplicateTitle,
    icon: 'mdi mdi-content-copy',
    command: () => {
      duplicateDatamodel();
    },
  },
  {
    separator: true,
  },
  {
    label: ts.deleteTitle,
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      confirmDatamodelDelete();
    },
  },
];

const loadAsyncData = async (searchQuery?: string): Promise<void> => {
  loading.value = true;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    const response = await adminApi.getDatamodelsAdmin({
      pagination: {
        query: query.value,
        page: page.value,
        sortBy: sort.value,
        limit: perPage.value,
      },
    });

    datamodels.value = response.data;
    total.value = response.meta?.total ?? 0;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }

  loading.value = false;
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData(query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

const showDetails = async (datamodel?: Datamodel): Promise<void> => {
  if (datamodel) selectedDatamodel.value = datamodel;
  router.push({
    name: 'edit-datamodel',
    params: { id: selectedDatamodel.value?.id.toString() } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const hideDetails = () => {
  showSidebar.value = false;
  router.push({
    name: 'datamodels',
  } as RouteLocationRaw);
};

const deleteDatamodel = async (id?: number): Promise<void> => {
  loading.value = true;
  try {
    if (id == null) {
      toastService.displayErrorToast(ts.deleteFailed());

      return;
    }

    await datamodelApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    loading.value = false;
  }
};

const downloadDatamodelExcel = async (): Promise<void> => {
  if (!selectedDatamodel.value) {
    return;
  }

  await datamodelApi.downloadAttributes(selectedDatamodel.value.id).then((res) => {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(res);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', selectedDatamodel.value?.name ?? '');
    link.click();
  });
};

const duplicateDatamodel = async (): Promise<void> => {
  router.push({
    name: 'duplicate-datamodel',
    params: { id: selectedDatamodel.value?.id.toString() } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const toggleActionMenu = (event: PointerEvent, datamodel: Datamodel) => {
  selectedDatamodel.value = datamodel;
  menu.value.toggle(event);
};

const confirmDatamodelDelete = () => {
  confirmService.confirmDelete({
    group: 'datamodels',
    message: ts.deleteConfirm(selectedDatamodel.value?.name),
    callback: () => deleteDatamodel(selectedDatamodel.value?.id),
  });
};

watch(
  () => route,
  () => {
    if (
      route.name === 'new-datamodel' ||
      route.name === 'edit-datamodel' ||
      route.name === 'duplicate-datamodel'
    ) {
      showSidebar.value = true;
    }

    if (route.name === 'datamodels') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

onMounted(() => {
  if (
    route.name === 'new-datamodel' ||
    route.name === 'edit-datamodel' ||
    route.name === 'duplicate-datamodel'
  ) {
    showSidebar.value = true;
  }

  loadAsyncData();
});

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

const onSortChange = (event: DataTableSortEvent) => {
  if (event.sortOrder == null) {
    return;
  }

  $onSortChange((event.sortOrder < 0 ? '-' : '') + event.sortField, loadAsyncData);
};
</script>
<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <pIconField icon-position="left" class="my-3">
      <pInputIcon class="pi pi-search" />
      <pInputText
        :placeholder="ts.tGlobal('search')"
        @update:model-value="(value: string) => search(value)"
      />
    </pIconField>

    <pDataTable
      scrollable
      scroll-height="flex"
      :value="datamodels"
      removable-sort
      :loading="loading"
      :row-hover="true"
      :lazy="true"
      :paginator="total > perPage"
      :rows="perPage"
      :total-records="total"
      :first="(page - 1) * perPage"
      @row-click="(event: DataTableRowClickEvent) => showDetails(event.data)"
      @page="(event: DataTablePageEvent) => onPageChange(event)"
      @sort="(event: DataTableSortEvent) => onSortChange(event)"
    >
      <p-column field="name" header="Name" sortable />
      <p-column>
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <p-button
              icon="mdi mdi-dots-vertical"
              text
              rounded
              plain
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="(event: PointerEvent) =>toggleActionMenu(event, slotProps.data)"
            ></p-button>
            <p-menu
              id="overlay_menu"
              ref="menu"
              class="w-auto"
              :model="menuItems()"
              :popup="true"
            />
          </div>
        </template>
      </p-column>
      <template #empty>
        <p-message p-message severity="info" :closable="false">
          {{ ts.notFoundWarning }}
        </p-message>
      </template>
    </pDataTable>

    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-lg sidebar-crud"
      position="right"
      :dismissable="false"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="loadAsyncData" />
    </pSidebar>
    <p-confirm-dialog group="datamodels" />
  </section>
</template>
