import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const viewsTranslationObject: TranslationObject = {
  title: '',
  systemStatus: 'System status',
  languageSelect: 'Select a language',
  installInstruction: 'Download and install the Google Authenticator app',
  login: 'Log in',
  scanInstruction: 'Scan this QR code in the Google Authenticator app',
  scanHelpText:
    'If you are unable to scan the QR code, please enter the code below manually into the app.',
  successfullyConfigured: 'Two Factor Authentication is successfully configured',
  invalidCode: 'Code is invalid',
  enableInstruction: 'Enable your Google Authenticator',
  authenticationCode: 'Authentication Code',

  auth: {
    previousRoute: 'Go back',
    noPermission: {
      title: 'No permission',
      description:
        "Sorry but it looks like you don't have access to this page. Please contact your workspace admin to access this page",
      errorCodes: {
        ssoNotConfigured:
          'It looks like SSO has not been configured for your organisation yet. Please contact Productsup support to enable SSO for your organisation.',
        userNotInvited: `It looks like you don't have access. Please contact your Workspace Admin to get access to the platform.`,
      },
    },
    notFound: {
      title: 'Not found',
      description:
        'The page you are looking for might have been removed, had its name changed or is temporarly available',
    },
  },

  notifications: {
    wrongPassword:
      '<small>Sorry, these credentials might not be right. We can help you <a href="/forgot-password?email={email}">recover your account</a>.</small>',
    wrongCode:
      '<small>Sorry, these code might not be right. Please check your authenticator app for the right code. Have you lost your code. Please contact us as soon as possible <a href="/forgot-password?email={email}">recover your account</a>.</small>',
    passwordMustBeResetted:
      '<small>We have recently changed the way authentication works, to regain access to your account you have to <a href="/forgot-password?email={email}">reset your password</a>.</small>',
    accountNotActivated:
      '<small>Your account has to be activated before you can login, if you have not received instructions we can help you <a href="/forgot-password?email={email}">reset your password</a>.</small>',
    successfulPasswordReset:
      'If your e-mail address "{email}" exists in our database, you will receive further instructions in your inbox.',
  },

  authentication: {
    requestPassword: 'Request password',
    knowYourPassword: 'Remember your password? Log in',
    forgotPassword: 'Forgot password?',
    twoFactorAuthHelp:
      'Enter the code from the Google Authenticator-app on your mobile device. If you have lost your device, please contact us to recover your account.',
    password: 'Password',
    resetPassword: 'Reset password',
    newPassword: 'New password',
    eightChars: 'Minimum 8 characters',
    lowercaseChar: 'At least one lowercase',
    uppercaseChar: 'At least one uppercase',
    numericChar: 'At least one numeric',
    specialChar: "At least one special character: {'@$!%*?&#'}",
    passwordConfirmation: 'Confirm password',
    returnToLogin: 'Return to log in',
  },
};

const tranlation: Translation = {
  views: viewsTranslationObject,
};

export default tranlation;
