<script setup lang="ts">
import { useStore } from 'vuex';
import useDebounce from 'utils/debounce';
import { ref, onMounted, computed } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import {
  ProductSearchesRestService,
  SavedQuery,
  Search,
} from 'platform-unit2-api/product-searches';
import { TableColumn } from '@/general/ui/components/data-table-column/data-table-column.interface';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { CrudDataFilterOption } from 'platform-unit2-api/core';
import {
  DataTableFilterService,
  FilterPageKey,
} from '@/general/services/data-table-filter-service/data-table-filter.service';
import ProductFilters from 'supplier/modules/products/ts/product-data-table-filter-utils';

/** Props */
interface Props {
  columns: TableColumn[];
}

const props = withDefaults(defineProps<Props>(), {
  columns: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', search: string): void;
  (e: 'refresh:filters'): void;
  (e: 'gtinFilter', gtins: string): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'search');
const confirmService = new ConfirmService();
const searchApi = new ProductSearchesRestService();
const productFilterService = new DataTableFilterService<ProductFilters>(
  FilterPageKey.PRODUCT_OVERVIEW,
);

/** Constants */
const store = useStore();
const op = ref();
const selectedSearch = ref('');
const searchName = ref('');
const inputValue = ref('');
const searches = ref<Search[]>([]);
const { debounce } = useDebounce();
const gtins = ref('');
let searchCallback: any = undefined;
const workspaceName = store.getters['users/currentUser'].workspace?.name;

//TODO need to use the filter from local storage
const queryString = computed(() => [
  'query=' + selectedSearch.value,
  `${
    store.getters['products/variantFilter']
      ? 'variants=' +
        encodeURIComponent(
          store.getters['products/variantFilter']?.map((item: CrudDataFilterOption) => {
            return item.id;
          }),
        )
      : ''
  }`,
  `${
    store.getters['products/ownerFilter']
      ? 'owner_ids=' +
        encodeURIComponent(
          store.getters['products/ownerFilter']?.map((item: CrudDataFilterOption) => {
            return item.id;
          }),
        )
      : ''
  }`,
  `${
    store.getters['products/dataModelFilter']
      ? 'datamodel_ids=' +
        encodeURIComponent(
          store.getters['products/dataModelFilter']?.map((item: CrudDataFilterOption) => {
            return item.id;
          }),
        )
      : ''
  }`,
  `${
    store.getters['products/brandFilter']
      ? 'brand_ids=' +
        encodeURIComponent(
          store.getters['products/brandFilter']?.map((item: CrudDataFilterOption) => {
            return item.id;
          }),
        )
      : ''
  }`,
  `${
    store.getters['products/gtinFilter']
      ? 'gtins=' + encodeURIComponent(store.getters['products/gtinFilter'])
      : ''
  }`,
]);

const columnArray = computed(() =>
  props.columns
    ? props.columns
        .filter(function (object: TableColumn) {
          if (object.selected) {
            return object;
          }

          return;
        })
        .map((item: TableColumn) => {
          return item.field;
        })
    : [],
);

const loadAsyncData = async () => {
  try {
    const response = await searchApi.getAll();
    searches.value = response.data;
  } catch (error) {
    toastService.displayErrorToast(ts.loadFailed());
  }
};

/** Searching from with saved search */
const pickSearch = async (event: DropdownChangeEvent) => {
  if (event == null) {
    return;
  }

  const search: SavedQuery = JSON.parse(
    '{"' +
      decodeURI(event.value.query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
      '"}',
  );

  if (search.gtins != null && search.gtins !== '') {
    await store.dispatch(
      'products/UPDATE_GTIN_FILTER',
      decodeURIComponent(search.gtins).split(','),
    );
  } else {
    await store.dispatch('products/UPDATE_GTIN_FILTER', []);
  }

  if (search.variants != null && search.variants !== '') {
    await store.dispatch(
      'products/UPDATE_VARIANT_FILTER',
      decodeURIComponent(search.variants)
        .split(',')
        .map((item) => {
          return { id: item };
        }),
    );
  } else {
    await store.dispatch('products/UPDATE_VARIANT_FILTER', null);
  }

  if (search.brand_ids != null && search.brand_ids !== '') {
    await store.dispatch(
      'products/UPDATE_BRAND_FILTER',
      decodeURIComponent(search.brand_ids)
        .split(',')
        .map((item) => {
          return { id: parseInt(item) };
        }),
    );
  } else {
    await store.dispatch('products/UPDATE_BRAND_FILTER', []);
  }

  if (search.datamodel_ids != null && search.datamodel_ids !== '') {
    await store.dispatch(
      'products/UPDATE_DATAMODEL_FILTER',
      decodeURIComponent(search.datamodel_ids)
        .split(',')
        .map((item) => {
          return { id: parseInt(item) };
        }),
    );
  } else {
    await store.dispatch('products/UPDATE_DATAMODEL_FILTER', []);
  }

  if (search.owner_ids != null && search.owner_ids !== '') {
    await store.dispatch(
      'products/UPDATE_OWNER_FILTER',
      decodeURIComponent(search.owner_ids)
        .split(',')
        .map((item) => {
          return { id: parseInt(item) };
        }),
    );
  } else {
    await store.dispatch('products/UPDATE_OWNER_FILTER', []);
  }

  if (event.value.columns != null && event.value.columns !== '') {
    const cols = await store.getters['products/columns'].map((i: TableColumn) => {
      if (event.value.columns.some((e: string) => e === i.field)) {
        i.selected = true;
        return i;
      }

      i.selected = false;
      return i;
    });
    await store.dispatch('products/SET_COLUMNS', cols);
  } else {
    const allCols = store.getters['products/columns'].map((i: TableColumn) => {
      i.selected = true;
      return i;
    });
    await store.dispatch('products/SET_COLUMNS', allCols);
  }

  emit('update:modelValue', search.query);
  selectedSearch.value = search.query;
};

const clearFilters = () => {
  productFilterService.resetFilters(workspaceName);
  selectedSearch.value = '';
  emit('update:modelValue', '');
  emit('refresh:filters');
};

/** Searching through the input field */
const search = (event: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(function () {
    inputValue.value = event;
    emit('update:modelValue', event);
  }, 500);

  searchCallback();
};

/** Saving a search */
const save = async () => {
  try {
    const searchQuery = {
      query: queryString.value.filter((value) => value).join('&'),
      name: searchName.value,
      columns: columnArray.value,
    };
    await searchApi.post(searchQuery);
    await loadAsyncData();

    toastService.displaySuccessToast(ts.createSuccess(searchName.value));
  } catch (error) {
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    searchName.value = '';
  }
};

const confirmSave = (event: PointerEvent) => {
  confirmService.confirmSave({
    event: event,
    callback: () => save(),
    group: 'save-searches',
    message: ts.saveConfirm(),
  });
};

const confirmDelete = (event: MouseEvent, search: Search) => {
  if (search.id != null) {
    confirmService.confirmDelete({
      event: event as PointerEvent, // Fix service
      callback: () => remove(search.id!),
      group: 'delete-searches',
      message: ts.deleteConfirm(search.name),
    });
  }
};

const remove = async (id: number) => {
  try {
    await searchApi.delete(id);
    search('');
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (error) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    selectedSearch.value = '';
    loadAsyncData();
  }
};

const toggle = (event: PointerEvent): void => {
  op.value.toggle(event);
};

const formatText = () => {
  const input = gtins.value;
  const rawValueArray: string[] = input.split('\n'); //each line => one element in the array
  const numbersArray: string[] = rawValueArray.map((el) =>
    el.replace(/\D/g, ' ').replace(/\s\s+/g, ' '),
  ); //removing letters and replacing multiple spaces with single space
  const number2dArray: string[][] = numbersArray.map((number) => number.split(' ')); //each element is an array of different numbers
  const converted2dArray: string[] = ([] as string[]).concat(...number2dArray); // convert 2d array to 1d array. we have an array of numbers
  const gtinsArray = converted2dArray.filter((el) => el !== '' && el.length > 5); // removing empty elements and unrelated numbers
  gtins.value = gtinsArray.join('\n');
};

const applyGtinFilter = async () => {
  emit('gtinFilter', gtins.value);
  op.value.hide();
};

onMounted(async () => {
  await loadAsyncData();
  selectedSearch.value = store.getters['products/getSearch'];
});
</script>

<template>
  <div>
    <div class="p-inputgroup">
      <!-- #region filter input / dropdown saved searches-->
      <pDropdown
        v-model="selectedSearch"
        :options="searches"
        option-label="name"
        :editable="true"
        :placeholder="ts.tModule('advanced_search_placeholder')"
        @input="search($event.target.value)"
        @change="(event: DropdownChangeEvent) => pickSearch(event)"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <div>
              {{ slotProps.value.split('query=').pop().split('&')[0] }}
            </div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <!-- deleting saved searches -->
          <div class="flex justify-content-between">
            <p>{{ slotProps.option.name }}</p>
            <i
              class="mdi mdi-close text-xl"
              @click.stop="confirmDelete($event, slotProps.option)"
            ></i>
          </div>
        </template>
      </pDropdown>
      <pButton
        class="bg-white border-100 p-button-sm p-inputgroup-addon text-800"
        @click="confirmSave($event)"
        ><i class="mdi mdi-content-save-outline text-xl" />
      </pButton>
      <p-button
        class="bg-white border-100 p-button-sm p-inputgroup-addon text-800"
        aria:haspopup="true"
        aria-controls="overlay_panel"
        @click="toggle"
        ><i class="mdi mdi-filter-outline text-xl"></i
      ></p-button>

      <!-- Gtin filter Overlay -->
      <p-overlayPanel
        id="overlay_panel"
        ref="op"
        append-to="body"
        :show-close-icon="true"
        style="width: 450px"
        :breakpoints="{ '960px': '75vw' }"
      >
        <div>
          <h3 class="font-bold mb-3 text-2xl">Filters</h3>
          <div class="field mb-3">
            <label class="mb-1" for="eans">GTINS</label>
            <p-textarea id="eans" v-model="gtins" class="w-full" rows="12" @change="formatText()" />
            <small class="flex justify-content-end"
              ><span :class="gtins.split('\n').length > 500 ? 'text-pink-500 font-bold' : ''">
                {{ gtins ? gtins.split('\n').length : 0 }}</span
              >
              / {{ 500 }}</small
            >
          </div>
          <div
            class="bg-white border-100 border-top-1 bottom-0 justify-content-between mt-5 py-3 sticky"
          >
            <div class="flex justify-content-between ml-auto mr-0">
              <p-button plain text :label="ts.tGlobal('clear')" class="mr-2" @click="gtins = ''" />
              <p-button label="Apply" @click="applyGtinFilter" />
            </div>
          </div>
        </div>
      </p-overlayPanel>

      <p-button
        class="bg-white border-100 p-button-sm p-inputgroup-addon text-800"
        @click="clearFilters"
        ><i class="mdi mdi-filter-remove-outline text-xl"></i
      ></p-button>
    </div>
    <p-confirm-dialog group="delete-searches" />
    <p-confirm-popup group="save-searches">
      <template #message="slotProps">
        <div class="flex flex-column p-4">
          <div class="align-items-center flex mb-3">
            <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
            <p class="pl-2">{{ slotProps.message.message }}</p>
          </div>
          <p-input-text
            id="name"
            v-model="searchName"
            :placeholder="ts.tModule('save_search_name')"
            class="w-full"
            type="text"
          />
        </div>
      </template>
    </p-confirm-popup>
  </div>
</template>
