<script setup lang="ts">
import { capitalize } from 'lodash';

export type StatusSeverity =
  | 'primary'
  | 'success'
  | 'error'
  | 'danger'
  | 'info'
  | 'warning'
  | 'paused'
  | 'dark'
  | 'progress'
  | 'action'
  | 'addition';

/**Props */
interface Props {
  label: string;
  severity?: StatusSeverity;
  iconOnly?: boolean;
  labelOnly?: boolean;
}

const formatLabel = (label: string): string => {
  return capitalize(label).replace(/_/g, ' ');
};

const props = withDefaults(defineProps<Props>(), {
  label: '',
  severity: 'primary',
  iconOnly: false,
  labelOnly: false,
});

// Fixed icons that correspond to the color of the badge
// To keep badges consistent custom icons are not provided as a prop
const getIcon = (): string => {
  switch (props.severity) {
    case 'dark':
      return 'mdi mdi-minus-circle';
    case 'progress':
      return 'mdi mdi-refresh-circle';
    case 'error':
    case 'danger':
      return 'mdi mdi-close-circle';
    case 'paused':
      return 'mdi mdi-pause-circle';
    case 'warning':
      return 'mdi mdi-alert-circle';
    case 'info':
      return 'mdi mdi-clock-time-three';
    case 'success':
    case 'primary':
      return 'mdi mdi-check-circle';
    default:
      return 'mdi mdi-circle-small';
  }
};
</script>
<template>
  <pChip v-if="iconOnly" unstyled>
    <i :class="[`badge-${severity} icon-chip`, getIcon()]" />
    <span v-if="label" class="ml-2">{{ label }}</span>
  </pChip>
  <pChip
    v-else
    :class="`badge-${severity} ${labelOnly ? '' : 'iconPadding'}`"
    :icon="labelOnly ? '' : getIcon()"
    :label="formatLabel(label)"
  />
</template>
<style scoped lang="scss">
@import '@/assets/theme/settings/typography.scss';
@import '@/assets/theme/settings/spacing.scss';
.p-chip {
  padding: 0 $spacing-03;
}

.iconPadding {
  padding: 0 $spacing-03 0 $spacing-02;
}
.p-chip :deep(.p-chip-icon) {
  margin: 0;
  margin-right: 0.3rem !important;
  font-size: 13px;
  margin: 4px 0 4px 0;
}
.p-chip :deep(.p-chip-text) {
  @include label-2;
  letter-spacing: 0;
  margin: 0;
}

.icon-chip {
  background-color: transparent !important;
}
</style>
