<script setup lang="ts">
import { inject, ref } from 'vue';
import { TranslationService } from 'services/translations/translation.service';
import { FinancialFieldService } from 'services/attribute-fields/field-services/financial-field.service';
import WrapperField from './wrapper-field.v2.vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { InputSelectType } from '@/general/services/attribute-fields/interfaces/input-select.interface';

/** Props */
interface Props {
  attributeField: AttributeField<InputSelectType>;
  productAttributeField: ProductAttributeField<InputSelectType>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const financialFieldService = ref(
  new FinancialFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);

/** Services */
const ts = new TranslationService('retailer', 'products');
</script>
<template>
  <WrapperField :attribute-field-service="financialFieldService">
    <pInputGroup
      :class="
        financialFieldService.getErrorTypeClass() === ''
          ? 'input-group-focus'
          : `${financialFieldService.getErrorTypeClass()} border-1`
      "
    >
      <pInputGroupAddon>
        <pDropdown
          v-model="financialFieldService.unit"
          class="bg-white border-noround border-right-none h-full min-w-min"
          :show-clear="
            financialFieldService.productAttributeField.value?.unit != null &&
            !financialFieldService.productAttributeField.locked
          "
          :options="financialFieldService.attributeField.attribute.options.currencies"
          :disabled="financialFieldService.productAttributeField.locked"
          :pt="{
            input: {
              class: 'align-items-center flex',
            },
          }"
        />
      </pInputGroupAddon>
      <pInputNumber
        v-model="financialFieldService.decimal"
        mode="decimal"
        input-class="border-noround-left border-left-none "
        :disabled="financialFieldService.productAttributeField.locked"
        :min-fraction-digits="2"
        :max-fraction-digits="2"
        :placeholder="ts.tModule('numberField.placeholder')"
      />
    </pInputGroup>
  </WrapperField>
</template>
<style lang="scss" scoped>
.input-group-focus:focus-within {
  border: 1px solid $focus-border-color;
}
</style>
