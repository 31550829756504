<script setup lang="ts">
import { MenuItem } from 'primevue/menuitem';
import { onMounted, ref, watch, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { LocaleDetails } from 'platform-unit2-api/locales';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

const props = withDefaults(
  defineProps<{
    modelValue?: string[];
    disables?: boolean;
    maxLength?: number;
    showEditDialog?: boolean;
    global?: boolean;
    name?: string;
    locale: LocaleDetails;
  }>(),
  {
    modelValue: () => [],
    disables: false,
    global: false,
    maxLength: 0,
    showEditDialog: false,
    name: '',
  },
);

const emit = defineEmits<{
  (e: 'changeindex', index: number): void;
  (e: 'update:modelValue', value: string[]): void;
  (e: 'delete:modelValue', index: number): void;
  (
    e: 'duplicateTab',
    indexes: { copyFromIndex: number; copyToIndex: number; localeId: number },
  ): void;
  (e: 'switchTabs', indexes: { fromIndex: number; toIndex: number }): void;
}>();

interface Tab {
  id: number;
  value: string;
  deleted: boolean;
}

/** Services */
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();

//** Constants */
const tabMap = ref<{ index: number; tab: Tab }[]>([]);
const tabs = ref<Tab[]>([]);
const index = ref(0);
const actions = ref();
const editDialog = ref<boolean>(false);
const editedTabName = ref<string>('');

const menuItems: MenuItem[] = [
  {
    label: ts.tGlobal('edit'),
    icon: 'mdi mdi-pencil-outline',
    command: () => showEditDialogFunction(),
  },

  {
    label: ts.tGlobal('duplicate'),
    icon: 'mdi mdi-content-copy',
    command: () => duplicateTab(),
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => deleteTabChild(),
  },
];

const store = useStore();

const duplicateTab = () => {
  const copyFromIndex = index.value;
  const tabToEdit = tabMap.value.find((map) => {
    return map.index === index.value && !map.tab.deleted;
  })?.tab;

  if (tabToEdit) {
    //create new tab
    const tabsNumber = tabNames.value.length;
    index.value = tabsNumber ? tabsNumber : 0;

    //find the last id in overall tabs created
    const tabIds = tabMap.value.map((map) => map.tab.id);

    const newId = tabIds.length ? Math.max(...tabIds) + 1 : 0;
    const tab = { id: newId, value: tabToEdit.value + ' copy', deleted: false };
    tabs.value.push(tab);
    tabMap.value.push({ index: index.value, tab: tab });
    const copyToIndex = newId;
    emit('duplicateTab', { copyFromIndex, copyToIndex, localeId: props.locale.id });
    emit('update:modelValue', getTabs());
    nextTick(() => {
      emit('changeindex', newId);
    });
  }
};

const showEditDialogFunction = async (inx?: number): Promise<void> => {
  if (!inx) {
    inx = index.value;
  }

  editedTabName.value =
    tabMap.value.find((map) => {
      return map.index == inx && !map.tab.deleted;
    })?.tab.value ?? tabs.value[inx].value;

  editDialog.value = true;
};

const hideEditDialog = async () => {
  emit('update:modelValue', getTabs());
  editDialog.value = false;
};

const toggleMenu = async (event: PointerEvent, _index: number) => {
  index.value = _index;
  const id = tabMap.value.find((map) => !map.tab.deleted && map.index === _index)?.tab.id;
  emit('changeindex', id ?? _index);
  await actions.value[_index].toggle(event);
};

const onTabChange = (e: { index: number }): void => {
  const id =
    tabMap.value.find((map) => {
      return map.index == e.index && !map.tab.deleted;
    })?.tab.id ?? e.index;

  emit('changeindex', id);
  index.value = e.index;

  //save incomplete/empty tab if exists
  isCreatingNewTab.value && updateTabName();
};

const original = ref<string[]>();

const setup = (activeIndex = 0) => {
  if (props.modelValue) {
    tabs.value = [];
    tabMap.value = [];

    ((props.modelValue as string[]) ?? []).forEach((value: string, i: number) => {
      const tab: Tab = { id: i, value: value, deleted: false };
      original.value = props.modelValue;
      tabs.value.push(tab);
      tabMap.value.push({ index: i, tab: tab });
    });

    const activeTabId =
      tabMap.value.find((map) => map.index == activeIndex && !map.tab.deleted)?.tab.id ??
      index.value;
    emit('changeindex', activeTabId);
  }

  index.value = activeIndex;
};

onMounted(() => {
  setup();
});

watch(
  () => props.modelValue,
  () => {
    if (store.getters['products/editingProductFields'].length == 0) {
      setup();
    }
  },
  {
    deep: true,
  },
);

const getTabs = () => {
  const tabNames: string[] = [];
  tabs.value.forEach((item) => {
    if (!item.deleted) {
      tabNames.push(item.value);
    }
  });
  return tabNames;
};

const tabNames = computed(() => {
  const tabs = getTabs();

  return tabs;
});

/** Delete tab */
const deleteTab = () => {
  const tab = tabMap.value.find((map) => {
    return map.index == index.value && !map.tab.deleted;
  });

  if (tab) {
    tab.tab.deleted = true;

    emit('update:modelValue', getTabs());
    emit('delete:modelValue', tab.tab.id);
  }

  emit('update:modelValue', getTabs());

  setDeletedIndex();

  focusoutIsPrevented.value = true;
};

const setDeletedIndex = () => {
  const visibleTabs = tabMap.value.filter((map) => {
    return !map.tab.deleted;
  });
  let newIndex: number;
  if (visibleTabs.length) {
    const tabsAfterDeletedOne = tabMap.value.filter((map) => {
      return map.index > index.value && !map.tab.deleted;
    });

    if (tabsAfterDeletedOne.length) {
      tabsAfterDeletedOne.forEach((map) => {
        map.index = map.index - 1;
      });
      newIndex =
        tabMap.value.find((map) => {
          return map.index == index.value;
        })?.index ?? index.value;
    } else {
      //Deleted the last tab
      newIndex = visibleTabs[visibleTabs.length - 1].index;
    }
  } else {
    //Last tab has been removed
    newIndex = 0;
  }

  index.value = newIndex;
  const newId =
    tabMap.value.find((map) => map.index === index.value && !map.tab.deleted)?.tab.id ?? 0;
  emit('changeindex', newId);
};

const deleteTabChild = () => {
  confirmService.confirmDelete({
    callback: () => deleteTab(),
    message: ts.deleteConfirm(),
  });
};

/** Create a new tab */
const focusoutIsPrevented = ref(false);
const isCreatingNewTab = ref(false); //To show the check icon

const createNewTab = () => {
  isCreatingNewTab.value = true;
  focusoutIsPrevented.value = false;

  const tabsNumber = tabNames.value.length;
  index.value = tabsNumber ? tabsNumber : 0;

  //find the last id in overall tabs created
  const tabIds = tabMap.value.map((map) => map.tab.id);

  const newId = tabIds.length ? Math.max(...tabIds) + 1 : 0;
  const tab = { id: newId, value: '', deleted: false };
  tabs.value.push(tab);
  tabMap.value.push({ index: index.value, tab: tab });

  emit('update:modelValue', getTabs());
  nextTick(() => {
    emit('changeindex', newId);
  });
};

/** Update tab name */
const updateTabName = () => {
  focusoutIsPrevented.value = true;

  if (isCreatingNewTab.value && !editedTabName.value) {
    editedTabName.value = 'new Tab';
  }

  const tabToEdit = tabMap.value.find((map) => {
    return map.index === index.value && !map.tab.deleted;
  })?.tab;

  if (tabToEdit) {
    tabToEdit.value = editedTabName.value;
  }

  editedTabName.value = '';
  emit('update:modelValue', getTabs());

  isCreatingNewTab.value = false;
  hideEditDialog();
};

const updateTabNameOnFocusout = () => {
  if (focusoutIsPrevented.value) {
    return;
  }

  if (isCreatingNewTab.value && !editedTabName.value) {
    editedTabName.value = 'new Tab';
  }

  const tabToEdit = tabMap.value.find((map) => {
    return map.index === index.value && !map.tab.deleted;
  })?.tab;

  if (tabToEdit) {
    tabToEdit.value = editedTabName.value;
  }

  editedTabName.value = '';
  emit('update:modelValue', getTabs());

  isCreatingNewTab.value = false;
};

/** Cancel creating a new tab */
const cancelCreateTab = () => {
  focusoutIsPrevented.value = true;
  deleteTab();
  isCreatingNewTab.value = false;
};

/** custom directive for auto focus */
const vFocus = {
  mounted: (el: HTMLElement) => {
    el.focus();
  },
};

/** switching tabs by dragg and drop */
const handleDragStart = (e: DragEvent, itemIndex: number) => {
  (e.target as HTMLElement).style.opacity = '0.5';
  e.dataTransfer!.dropEffect = 'move';
  e.dataTransfer!.effectAllowed = 'move';
  e.dataTransfer?.setData('fromIndex', itemIndex.toString());
};

const handleDragEnd = (e: DragEvent) => {
  (e.target as HTMLElement).style.opacity = '1';
};

const handleDragEnter = (e: DragEvent, itemIndex: number) => {
  e.dataTransfer?.setData('toIndex', itemIndex.toString());
  (e.target as HTMLElement).classList.add('tab-over');
};

const handleDragLeave = (e: DragEvent) => {
  (e.target as HTMLElement).classList.remove('tab-over');
};

const handleDrop = (e: DragEvent, itemIndex: number) => {
  if (itemIndex !== undefined) {
    const fromIndex = +e.dataTransfer!.getData('fromIndex');
    const toIndex = itemIndex;

    changeTheIndexInTabMap(fromIndex, toIndex);
  }
};

const changeTheIndexInTabMap = (from: number, to: number) => {
  let fromTabId = 0;
  let toTabId = 0;

  // change the index of the item in the tabMap and find the id of the tab
  tabMap.value = tabMap.value.map((tab) => {
    if (tab.index == from) {
      tab.index = to;
      fromTabId = tab.tab.id;
    } else if (tab.index == to) {
      tab.index = from;
      toTabId = tab.tab.id;
    }

    return tab;
  });
  onTabChange({ index: to });
  swapTabs(fromTabId, toTabId);
};

const swapTabs = (from: number, to: number) => {
  // Find the index of tabs in 'tabs' array
  const fromIndex = tabs.value.findIndex((tab) => tab.id == from);
  const toIndex = tabs.value.findIndex((tab) => tab.id == to);
  const temp = tabs.value[fromIndex];
  tabs.value[fromIndex] = tabs.value[toIndex];
  tabs.value[toIndex] = temp;

  updateValuesOnSwitch(from, to);
};

const updateValuesOnSwitch = (fromIndex: number, toIndex: number) => {
  emit('switchTabs', { fromIndex, toIndex });
  setup(toIndex);
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: hideEditDialog,
});

const saveButton = new CreateButton({
  label: ts.tGlobal('save'),
  position: CrudButtonPosition.RIGHT,
  onClick: updateTabName,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, saveButton],
});

onMounted(() => {
  const tabsUl = document.querySelector('ul.p-tabview-nav');
  tabsUl?.classList.add('drag-zone');
});
</script>

<template>
  <p-accordion class="tab-field-accordion w-full">
    <p-accordion-tab class="flex w-full" :header="name">
      <p-tab-view
        :active-index="index"
        class="border-1 border-gray-100 border-right-round w-full"
        :style="'background: var(--surface-200)'"
        @tab-change="onTabChange"
        @drop="handleDrop"
        @dragover.prevent
        @dragenter.prevent
      >
        <p-tab-panel v-for="(item, i) in tabNames" :key="i">
          <template #header>
            <div
              :draggable="true"
              class="align-items-center draggable-tab flex h-3rem"
              @dragstart="handleDragStart($event, i)"
              @dragend="handleDragEnd"
              @dragenter="handleDragEnter($event, i)"
              @dragleave="handleDragLeave"
              @drop="handleDrop($event, i)"
            >
              <p-button icon="mdi mdi-drag-vertical" text class="move-icon" aria-haspopup="true" />
              <!-- Add / Edit tab name -->
              <div v-if="item == ''">
                <p-input-text
                  v-model="editedTabName"
                  v-focus
                  placeholder="new tab"
                  class="relative w-full"
                  @keyup.enter="updateTabName"
                  @focusout="updateTabNameOnFocusout"
                ></p-input-text>

                <!-- Cancel button -->
                <p-button
                  icon="mdi mdi-close right-0"
                  text
                  severity="danger"
                  class="absolute mr-2 right-0 z-1"
                  @mousedown="cancelCreateTab"
                />
              </div>

              {{ item }}

              <!-- Action button -->
              <p-button
                v-if="!isCreatingNewTab"
                icon="mdi mdi-dots-vertical"
                text
                aria-haspopup="true"
                aria-controls="overlay_menu"
                @click="(e: PointerEvent) => toggleMenu(e, i)"
              />
              <p-menu id="overlay_menu" ref="actions" :model="menuItems" :popup="true" />
            </div>
          </template>
          <slot v-if="tabs.length !== i" name="items"></slot>
        </p-tab-panel>
        <!-- Plus button -->
        <p-tab-panel>
          <template #header>
            <div class="align-items-center flex h-3rem w-20">
              <p-button
                v-if="!isCreatingNewTab"
                icon="mdi mdi-plus"
                class="bg-primary p-button-rounded"
                :style="'width: 20px; height:20px '"
                @click="createNewTab"
              />
              <p-button
                v-if="isCreatingNewTab"
                icon="mdi mdi-check"
                class="bg-primary p-button-rounded"
                :style="'width: 20px; height:20px '"
                @click.stop="updateTabName"
              />
            </div>
          </template>
          <div v-if="!tabNames.length">
            <p-message p-message severity="info" :closable="false">
              No tabs found. Create a new tab to get started.
            </p-message>
          </div>
        </p-tab-panel>
      </p-tab-view>
      <!-- delete / duplicate  -->
      <p-confirm-dialog />

      <BaseDialog
        :visible="editDialog"
        size="small"
        title="Edit tab name"
        :style="{ width: '20vw' }"
        :buttons-options="buttonsOptions"
        @update:visible="hideEditDialog"
      >
        <p-input-text v-model="editedTabName" class="w-full" />
      </BaseDialog>
    </p-accordion-tab>
  </p-accordion>
</template>

<style scoped lang="scss">
*:focus {
  box-shadow: none !important;
}

.p-tabview:deep(.drag-zone) {
  padding-top: 0.4rem !important;
}

.p-tabview:deep(.draggable-tab) {
  padding-left: 1rem;
}

.p-tabview:deep(.draggable-tab.tab-over) {
  border: 1px dotted gray;
  border-radius: 10px;
}

.p-tabview:deep(.p-tabview-panels) {
  background: #f5f7fa;
}
.p-tabview:deep(.p-tabview-nav) {
  padding-left: 15px;
}

.p-tabview:deep(.p-tabview-nav li .p-tabview-nav-link) {
  padding: 0.25rem 0.25rem 0.25rem 1.2rem;
  min-width: 6rem;

  &:hover .move-icon {
    visibility: visible;
  }
}

.p-accordion.tab-field-accordion:deep(.p-accordion-header-link) {
  background: #ffffff !important;
  height: 3rem;
  color: var(--text-color) !important;
}

.p-accordion.tab-field-accordion:deep(.p-accordion-header .p-accordion-header-link) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion.tab-field-accordion:deep(.p-accordion-content) {
  padding: 0;
}

.p-accordion.tab-field-accordion:deep(.move-icon) {
  position: absolute;
  left: 0.9rem;
  margin: 0;
  padding: 0;
  width: 1rem;
  cursor: move;
  visibility: hidden;
  color: gray;
}
</style>
