<script setup lang="ts">
import { inject, ref } from 'vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';

import { GtinFieldService } from '@/general/services/attribute-fields/field-services/gtin-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.v2.vue';

/** Props */
interface Props {
  attributeField: AttributeField<string | undefined>;
  productAttributeField: ProductAttributeField<string | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const gtinFieldService = ref(
  new GtinFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>

<template>
  <WrapperField :attribute-field-service="gtinFieldService">
    <pInputText
      v-model="gtinFieldService.productAttributeFieldValue"
      :disabled="gtinFieldService.productAttributeField.locked"
      :class="gtinFieldService.getErrorTypeClass()"
      :placeholder="gtinFieldService.ts.tGlobal('placeholder')"
    />
  </WrapperField>
</template>
