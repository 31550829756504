import { actions } from 'admin/modules/organisations/store/organisations.actions';
import { Module } from 'vuex';

const namespaced = true;
export const state: {} = {};

export const organisationsStore: Module<{}, {}> = {
  namespaced,
  state,
  actions,
};
