import {
  Attribute,
  AttributeOptions,
  CreateAttributeRequest,
  UpdateAttributeRequest,
  AttributeRestService,
  KeyValueOptions,
} from 'platform-unit2-api/attributes';
import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import { ListResponse } from 'platform-unit2-api/core';

export class AttributeViewService extends BaseViewService<
  AttributeRestService,
  Attribute,
  CreateAttributeRequest,
  UpdateAttributeRequest
> {
  //#region Properties
  public _availableParrentAttribute: Attribute[] = [];
  public parent?: Partial<Attribute> = undefined;
  private _options: Partial<AttributeOptions> = {};
  public isValidJson = true;
  //#endregion

  //#region Getters and Setters

  /**
   * Fields (attributes).
   */
  public get fields(): Attribute[] {
    return this._data;
  }

  /**
   * @inheritdoc
   */
  protected get current() {
    return super.current;
  }

  /**
   * @inheritdoc
   */
  protected set current(object: Attribute | Partial<Attribute> | undefined) {
    super.current = object;

    this._options = this.current?.options || {};

    if (this.current?.parent_id) {
      this._restService
        .get(this.current.parent_id)
        .then((parent) => {
          this.parent = parent;
        })
        .catch(() => {
          this.parent = undefined;
        });
    } else {
      this.parent = undefined;
    }
  }

  public get options(): Partial<AttributeOptions> {
    if (!this._options) {
      this._options = {};
    }

    if (this.current?.options) {
      this._options = this.current.options;
    }

    return this._options;
  }

  public get editorFieldValue(): string {
    const removedEmtyValues: Partial<AttributeOptions> = {};

    Object.keys(this.options).forEach((key) => {
      if ((this.options as any)[key].length !== 0 && (this.options as any)[key] != null) {
        (removedEmtyValues as any)[key] = (this.options as any)[key];
      }
    });

    return JSON.stringify(removedEmtyValues);
  }

  public set editorFieldValue(newVal: string) {
    try {
      const obj: Object = JSON.parse(newVal);
      this._options = this.getObjectWithoutEmptyValues(obj);
      this.isValidJson = true;
    } catch (err) {
      this.isValidJson = false;
    }
  }

  public get choices(): (KeyValueOptions | string)[] {
    if (!this.options.choices) {
      this.options.choices = [];
    }

    return this.options.choices;
  }

  public get validated(): boolean {
    if (!this._currentObject || !this._currentObject.key || !this.options.type) {
      return false;
    }

    return true;
  }
  //#endregion

  constructor(ts: TranslationService) {
    super({
      Api: AttributeRestService,
      fetchAllFunction: 'getAll',
      ts: ts,
      createRouteName: 'new-field',
      updateRouteName: 'edit-field',
      overviewRouteName: 'fields',
      confirmPopUpGroup: 'fields',
    });
  }

  public conditionallyRender = (requiredType: string) => {
    return this.options.type == requiredType;
  };

  public deleteChoice(index: number) {
    this.choices.splice(index, 1);
  }

  public addChoice() {
    this.choices.push({ key: '', value: '' });
  }

  public getObjectWithoutEmptyValues(object: Object): Object {
    Object.keys(object).forEach((key) => {
      if ((object as any)[key].length === 0) {
        delete (object as any)[key];
      }
    });
    if ('multiSelect' in object && (object as any)['multiSelect'] === false)
      delete (object as any)['multiSelect'];
    return object;
  }

  public getType(attribute: Attribute) {
    if (!attribute.options || !attribute.options.type) {
      return 'DEFAULT';
    }

    return attribute.options.type;
  }

  /**
   * @inheritdoc
   */
  public fetchAll(): void {
    this._isLoadingOverview = true;

    this._restService
      .getAll(this._fetchVariables, { withChildren: true })
      .then((response: ListResponse<Attribute>) => {
        this._data = response.data;
        this.total = response.meta?.total;
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._data = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.isInitialized = true;
      });
  }

  public createBody(): CreateAttributeRequest | undefined {
    if (!this.validated || !this.current) {
      return undefined;
    }

    return {
      key: this.current.key!,
      options: this.options! as AttributeOptions,
      required: this.current.required ?? false,
      parent_id: this.parent?.id,
      global_attribute: this.current.global_attribute,
    };
  }

  public updateBody(): UpdateAttributeRequest | undefined {
    if (!this.validated || !this._currentObject) {
      return undefined;
    }

    return {
      id: this._currentObject.id!,
      key: this._currentObject.key!,
      options: this.options! as AttributeOptions,
      required: this._currentObject.required ?? false,
      parent_id: this.parent?.id,
      global_attribute: this._currentObject.global_attribute,
    };
  }

  /**
   * Returns popup confirmation for deleting a module
   * @param event PointerEvent
   */
  public confirmDelete(event: PointerEvent, attribute?: Attribute): void {
    if (this.current?.id == null && attribute == null) {
      return;
    }

    if (attribute != null) {
      this.current = attribute;
    }

    this._confirmService.confirmDelete({
      event: event,
      group: this.confirmPopUpGroup,
      callback: () => this.delete(),
      message: this._ts.deleteConfirm(),
    });
  }
}
