<script setup lang="ts">
//Core
import { MenuItem } from 'primevue/menuitem';
import moment from 'moment';

//services
import { TranslationService } from '@/general/services/translations/translation.service';
import { useService } from '@/general/composables/useService/useService';
import ValidationModelService from 'admin/modules/validation-rules/services/validation-models.service';

import { serviceKey } from 'admin/modules/validation-rules/ts/keys/validation-model-key';

//Components
import StatusChip from '@/general/ui/components/status-chip.vue';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';

//Types
import { ValidationModel } from 'platform-unit2-api/validation-models';
import { ValidationModelStatusEnum } from 'admin/modules/validation-rules/ts/enums/validation-model-status.enum';
import { DataTablePageEvent, DataTableRowClickEvent } from 'primevue/datatable';

const service = useService(serviceKey, ValidationModelService);

const props = defineProps<{
  ts: TranslationService;
}>();

const menuItems = (validationModel: ValidationModel): MenuItem[] => [
  {
    label: props.ts.tGlobal('edit'),
    icon: 'mdi mdi-pencil-outline',
    command: () => {
      service.goToUpdateRoute(validationModel.id);
    },
  },
  {
    label: props.ts.tGlobal('duplicate'),
    icon: 'mdi mdi-content-copy',
    command: () => {
      service.duplicateValidationModel([validationModel.id]);
    },
  },
  {
    label: props.ts.tGlobal('pause'),
    icon: 'mdi mdi-pause-circle-outline',
    visible: () => validationModel.is_active,
    command: () => {
      service.changeStatus([validationModel.id], ValidationModelStatusEnum.paused);
    },
  },
  {
    label: props.ts.tModule('activate'),
    icon: 'mdi mdi-play-circle-outline',
    visible: () => !validationModel.is_active,
    command: () => {
      service.changeStatus([validationModel.id], ValidationModelStatusEnum.active);
    },
  },
  {
    separator: true,
  },
  {
    label: props.ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      service.deleteValidationModel(validationModel.id);
    },
  },
];
</script>

<template>
  <pDataTable
    :value="service.validationModels"
    class="validation-rules-model"
    paginator
    lazy
    removable-sort
    row-hover
    auto-layout
    scrollable
    scroll-height="flex"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :first="service.first"
    :total-records="service.total"
    :rows-per-page-options="[5, 15, 25, 50]"
    :sort-field="service.sortField"
    :sort-order="service.sortOrder"
    @sort="service.onSortChange($event)"
    @page="(event:DataTablePageEvent)=>service.onDatatablePageEvent(event)"
    @row-click="(event: DataTableRowClickEvent) => service.goToCollectionsRoute(event.data.id)"
  >
    <!-- Name -->
    <pColumn field="name" :header="ts.tModule('validationModelName')" sortable />
    <!-- Channel -->
    <pColumn field="module.name" :header="ts.tModule('linkedChannel')" sortable />
    <!-- Collections -->
    <pColumn field="collections" :header="ts.tModule('collections.title')" class="align-center">
      <template #body="slotProps">
        <div class="text-center">{{ slotProps.data.validation_sets?.data?.length }}</div>
      </template>
    </pColumn>
    <!-- Status -->
    <pColumn field="is_active" :header="ts.tModule('status')" sortable>
      <template #body="slotProps">
        <StatusChip
          class="capitalize"
          :label="ValidationModelStatusEnum[+slotProps.data.is_active]"
          :severity="slotProps.data.is_active ? 'success' : 'paused'"
        />
      </template>
    </pColumn>

    <pColumn field="updated_at" :header="ts.tModule('lastEdited')" sortable>
      <template #body="slotProps">
        {{ moment(slotProps.data.updated_at).format('MMM DD, Y, H:mm A') }}
      </template>
    </pColumn>

    <pColumn field="has_error" :header="ts.tModule('errors')" class="align-center">
      <template #body="slotProps">
        <div class="text-center">
          <pBadge
            v-if="slotProps.data.error"
            value="!"
            severity="danger"
            :style="{ fontSize: '1rem' }"
          ></pBadge>
        </div>
      </template>
    </pColumn>
    <!-- Actions -->
    <pColumn header="&nbsp;">
      <template #body="slotProps">
        <ActionsMenu :menu-items="menuItems(slotProps.data)" />
      </template>
    </pColumn>
  </pDataTable>
</template>
<style scoped lang="scss">
.validation-rules-model :deep(th) {
  &.align-center .p-column-header-content {
    justify-content: center;
  }
}
</style>
