<script setup lang="ts">
//Core
import { ref, watchEffect } from 'vue';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { DatamodelVueService } from 'supplier/modules/datamodels/services/datamodel-vue.service';
import { Datamodel } from 'platform-unit2-api/datamodels';

// Components
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

/** Props */
const props = defineProps<{
  visible: boolean;
  selectedDatamodel: Datamodel;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'reload'): void;
}>();
const emitClose = () => emit('hide');
const emitReload = () => emit('reload');

const ts = new TranslationService('supplier', 'datamodels');
const datamodelService = ref(new DatamodelVueService({ emitClose, emitReload }));

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const duplicateButton = new CreateButton({
  label: ts.tGlobal('duplicate'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => datamodelService.value.duplicate(props.selectedDatamodel.id),
  isEnabled: () => datamodelService.value.selectedClientToDuplicate != null,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, duplicateButton],
});

watchEffect(() => {
  !props.visible && datamodelService.value.clearDuplicationForm();
});
</script>
<template>
  <BaseDialog
    :visible="visible"
    :title="ts.tModule('datamodel_duplicate.title')"
    :subtitle="ts.tModule('datamodel_duplicate.subtitle')"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="() => $emit('hide')"
  >
    <!-- Workspace -->
    <div class="field mb-3">
      <label class="block mb-1">{{ ts.tGlobal('workspace') }}</label>
      <p-dropdown
        v-model="datamodelService.selectedClientToDuplicate"
        :options="datamodelService.userWorkspaces"
        class="w-full"
        option-label="name"
        data-key="id"
        :filter="true"
        :placeholder="ts.tModule('datamodel_duplicate.modal_client_placeholder')"
      />
    </div>
    <!-- Duplicate Import Mapping -->
    <div class="align-items-center field flex mb-3">
      <p-checkbox v-model="datamodelService.shouldDuplicateImportMappings" :binary="true" />
      <label class="mb-0 ml-3">{{
        ts.tModule('datamodel_duplicate.duplicate_import_mapping')
      }}</label>
    </div>

    <!-- Duplicate Export Mapping -->
    <div class="align-items-center field flex mb-3">
      <p-checkbox v-model="datamodelService.shouldDuplicateExportMappings" :binary="true" />
      <label class="mb-0 ml-3">{{
        ts.tModule('datamodel_duplicate.duplicate_export_mapping')
      }}</label>
    </div>
  </BaseDialog>
</template>

<style scoped lang="scss">
.p-button :deep(.p-button-label) {
  font-weight: normal;
}
</style>
