<script setup lang="ts">
import { LocaleDetails, Locale } from 'platform-unit2-api/locales';
import { getLocaleDetails } from '@/general/utils/get-locale-details';
import { has } from 'lodash';
import { onBeforeMount } from 'vue';

const props = defineProps<{
  global: boolean;
  locale?: LocaleDetails | Locale;
}>();

let finalLocale: LocaleDetails;

const isLocaleDetails = (locale?: Locale): locale is LocaleDetails => {
  return has(locale, ['format', 'language', 'region']);
};

onBeforeMount(() => {
  if (props.locale == null) {
    return;
  }

  finalLocale = isLocaleDetails(props.locale)
    ? props.locale
    : getLocaleDetails(props.locale as Locale);
});
</script>
<template>
  <span
    v-if="global"
    v-tooltip.bottom="'global'"
    class="bg-gray-50 p-inputgroup-addon pup-w-3 px-2"
  >
    <span class="mdi mdi-earth text-color" style="font-size: 12px"></span>
  </span>
  <span v-else class="bg-gray-50 p-inputgroup-addon pup-w-3 px-2">
    <span
      v-if="finalLocale?.format"
      v-tooltip.bottom="finalLocale?.language"
      class="border-round-xs"
      :class="'fi fi-' + finalLocale?.format + ' fis'"
    />
    <span v-else v-tooltip.bottom="finalLocale?.value" class="mdi mdi-web" />
  </span>
</template>
