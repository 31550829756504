<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Locale } from 'platform-unit2-api/locales';
import { Attribute } from 'platform-unit2-api/attributes';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  maxLength?: number;
  global: boolean;
  locale?: Locale;
  attribute?: Attribute;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  maxLength: 0,
  locale: undefined,
  attribute: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'error', errorMessages: string[]): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const inputText = ref('');

const validateData = (value?: string) => {
  const errorMessages: string[] = [];

  if (
    value == null ||
    (value === '' && !props.attribute?.required && !props.attribute?.options?.required)
  ) {
    emit('error', errorMessages);
    return;
  }

  if (props.maxLength && inputText.value.length > props.maxLength) {
    errorMessages.push(ts.tModule('baseField.error', { params: { value: props.maxLength } }));
  }

  emit('error', errorMessages);
};

const valueUpdated = () => {
  validateData(inputText.value);
  emit('update:modelValue', inputText.value);
};

watchEffect(() => {
  props.modelValue && validateData(props.modelValue);
  inputText.value = props.modelValue ?? '';
});

onMounted(() => {
  validateData(props.modelValue);
  inputText.value = props.modelValue ?? '';
});
</script>
<template>
  <div class="flex w-full">
    <p-input-text
      v-model="inputText"
      :disabled="disabled"
      class="w-full"
      :class="[
        inputText.length > maxLength && maxLength ? 'border-pink-400' : '',
        maxLength ? 'border-right-none' : '',
      ]"
      type="text"
      @update:model-value="valueUpdated"
    />
    <span
      v-if="maxLength"
      class="bg-white min-w-max p-inputgroup-addon"
      :class="inputText.length > maxLength ? 'border-pink-400' : ''"
    >
      {{ inputText ? inputText.length : 0 }} / {{ maxLength }}</span
    >
  </div>
</template>
