<script setup lang="ts">
import { inject } from 'vue';
import emptyState from '@/general/ui/components/empty-state.vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { fieldServiceKey } from '../../ts/keys/fields.keys';

const ts = inject(tsKey)!;
const service = inject(fieldServiceKey);
</script>

<template>
  <emptyState
    :translation-service="ts"
    :icon-name="'no-preview-image'"
    :empty-state-title="ts.tModule('emptyStateTitle')"
    :empty-state-subtitle="ts.tModule('emptyStateSubTitle')"
    :button-label="ts.tModule('createButton')"
    @clicked="service?.openCrudComponent()"
  ></emptyState>
</template>
