<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

//Components
import EmptyState from '@/general/ui/components/empty-state.vue';
import { MenuItem } from 'primevue/menuitem';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';

//Types
import { User } from 'platform-unit2-api/users';
import StatusChip, { StatusSeverity } from '@/general/ui/components/status-chip.vue';

//Composables and Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { PipelineStatusCompact, PipelinesRestService } from 'platform-unit2-api/pipelines';
import { ImportService } from '@/general/services/overview-service/modules/import.service';
import { useService } from '@/general/composables/useService';
import { importServiceKey } from '../ts/keys/import-service.key';
import { ImportPipeline } from 'platform-unit2-api/imports';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import { DataTableFilterEvent } from 'primevue/datatable';

/**translation services */
const ts = new TranslationService('supplier', 'imports');
const tsLocales = new TranslationService('supplier', 'locales');
const tsChannels = new TranslationService('supplier', 'modules');

/** Services */
const importService = useService(importServiceKey, ImportService);

/** constants */
const store = useStore();
const expandedRows = ref([]);

/** Methods */
const currentUser = computed((): User => {
  return store.getters['users/currentUser'];
});

const menuItems = (pipeline: ImportPipeline): MenuItem[] => [
  {
    label: ts.tModule('tooltip.download'),
    icon: 'mdi mdi-download',
    visible: () => Boolean(pipeline?.files?.length),
    command: () => {
      importService.downloadFiles(pipeline?.files ?? []);
    },
  },
  {
    label: ts.tModule('tooltip.restart'),
    icon: 'mdi mdi-restart',
    command: () => importService.openCrudComponent(pipeline),
  },
  {
    label: ts.tModule('go_to_products'),
    icon: 'mdi mdi-open-in-new',
    visible: () => !pipeline?.settings.dry_run && (pipeline?.product_count ?? -1) > 0,
    command: () => {
      importService.goToProducts(pipeline?.id);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      importService.deleteAction(pipeline.id);
    },
  },
];
</script>
<template>
  <pDataTable
    v-if="importService != null && ts != null"
    v-model:expanded-rows="expandedRows"
    v-model:filters="importService.filters"
    :paginator="importService.total > (importService.limit ?? 0)"
    filter-display="menu"
    lazy
    removable-sort
    row-hover
    auto-layout
    scrollable
    scroll-height="flex"
    :loading="importService.isLoadingOverView"
    :value="importService.imports"
    :rows="importService.limit"
    :first="importService.first"
    :global-filter-fields="['module.name']"
    :total-records="importService.total"
    :sort-field="importService.sortField"
    :sort-order="importService.sortOrder"
    @page="importService.onPageChange($event.page)"
    @filter="(event: DataTableFilterEvent) => importService.applyFilter(event)"
    @sort="importService.onSortChange($event)"
  >
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-visible="true"
        :button-icon="'mdi mdi-plus'"
        :icon-name="'imports'"
        @clicked="importService.openCrudComponent()"
      />
    </template>
    <p-column
      class="flex-none"
      body-style="width: 5rem"
      :expander="true"
      header-style="width: 5rem"
    />

    <p-column field="status" :header="ts.tModule('table_headers.status')">
      <template #body="slotProps: { data: ImportPipeline }">
        <StatusChip
          icon-only
          :severity="importService.getCurrentStatus(slotProps.data)?.level as StatusSeverity ?? 'info'"
          :label="importService.getCurrentStatus(slotProps.data)?.text"
        />
      </template>
    </p-column>

    <p-column field="dry_run" :header="ts.tModule('table_headers.saved_products')">
      <template #body="slotProps: { data: ImportPipeline }">
        <p-tag
          v-if="!slotProps.data.settings?.dry_run"
          severity="success"
          :value="ts.tGlobal('yes')"
        />
        <p-tag v-else severity="danger" :value="ts.tGlobal('no')" />
      </template>
    </p-column>

    <p-column field="module.name" :header="ts.tModule('table_headers.channel')">
      <template #body="slotProps: { data: ImportPipeline }">
        {{ slotProps.data?.module?.name }}
      </template>
      <template #filter="{ filterModel }">
        <ModuleSelect
          v-model="filterModel.value"
          hide-label
          multiselect
          class="max-w-20rem"
          :rest-service="PipelinesRestService"
          fetch-function-name="getFilterChannels"
        />
      </template>
    </p-column>

    <p-column field="user" :header="ts.tModule('table_headers.user')">
      <template #body="slotProps: { data: ImportPipeline }">
        {{
          slotProps.data.user != null
            ? currentUser && slotProps.data.user.id === currentUser.id
              ? ts.tGlobal('me')
              : slotProps.data.user.name
            : ts.tGlobal('system')
        }}
      </template>
    </p-column>

    <p-column field="created_at" :header="ts.tModule('table_headers.started_at')">
      <template #body="slotProps: { data: ImportPipeline }">
        {{ moment(slotProps.data.created_at).format('DD MMM Y, HH:mm') }}
      </template>
    </p-column>

    <p-column class="flex-none" header-style="width: 5rem" body-style="width: 5rem">
      <template #body="slotProps: { data: ImportPipeline }">
        <div class="flex justify-content-end">
          <ActionsMenu :menu-items="menuItems(slotProps.data)" />
        </div>
      </template>
    </p-column>

    <template #expansion="slotProps: { data: ImportPipeline }">
      <div class="flex justify-content-around w-full">
        <div class="w-6">
          <div class="pl-8 w-full">
            <h3 class="font-bold mb-6 text-xl">{{ ts.tModule('import_details') }}</h3>
            <div class="grid">
              <div class="col-6">
                <div class="flex mb-1">
                  <div class="col-8">
                    <label>{{ ts.tModule('dry_run') }}</label>
                  </div>

                  <div class="col-4 mb-3">
                    <pCheckbox :model-value="slotProps.data?.settings.dry_run" binary disabled />
                  </div>
                </div>

                <div class="flex mb-1">
                  <div class="col-8">
                    <label>{{ ts.tModule('update_variants') }}</label>
                  </div>

                  <div class="col-4 mb-3">
                    <pCheckbox
                      :model-value="slotProps.data?.settings.update_variants"
                      binary
                      disabled
                    />
                  </div>
                </div>
                <div class="flex mb-1">
                  <div class="col-8">
                    <label>{{ ts.tModule('update_brands') }}</label>
                  </div>

                  <div class="col-4 mb-3">
                    <pCheckbox
                      :model-value="slotProps.data?.settings.update_brand"
                      binary
                      disabled
                    />
                  </div>
                </div>
                <div class="flex mb-1">
                  <div class="col-8">
                    <label>{{ ts.tModule('update_category') }}</label>
                  </div>

                  <div class="col-4 mb-3">
                    <pCheckbox
                      :model-value="slotProps.data?.settings.update_categories"
                      binary
                      disabled
                    />
                  </div>
                </div>
                <div class="flex mb-1">
                  <div class="col-8">
                    <label>{{ ts.tModule('update_title') }}</label>
                  </div>

                  <div class="col-4 mb-3">
                    <pCheckbox
                      :model-value="slotProps.data?.settings.update_title"
                      binary
                      disabled
                    />
                  </div>
                </div>
                <div class="flex mb-1">
                  <div class="col-8">
                    <label>{{ ts.tModule('trim_ean') }}</label>
                  </div>
                  <div class="col-4 mb-3">
                    <pCheckbox :model-value="slotProps.data?.settings.trim_ean" binary disabled />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="flex mb-1">
                  <div class="col-6">
                    <label>{{ tsChannels.title }}</label>
                  </div>

                  <div class="col-6">
                    <template v-if="slotProps.data.module">
                      {{ slotProps.data.module.name }}
                    </template>
                  </div>
                </div>

                <div class="flex mb-1">
                  <div class="col-6">
                    <label>{{ tsLocales.title }}</label>
                  </div>

                  <div class="col-6">
                    <template v-if="slotProps.data.locale">
                      {{ slotProps.data.locale.value }}
                    </template>
                  </div>
                </div>

                <div class="flex mb-1">
                  <div class="col-6">
                    <label>{{ ts.tGlobal('files') }}</label>
                  </div>

                  <div class="col-6">
                    <a
                      v-if="slotProps.data?.files?.length"
                      download
                      target="_blank"
                      :href="slotProps.data.files[0]"
                      ><i class="mdi mdi-download text-primary"
                    /></a>
                  </div>
                </div>

                <div class="flex mb-1">
                  <div class="col-6">
                    <label>{{ ts.tModule('table_headers.mapping') }}</label>
                  </div>

                  <div class="col-6">
                    <span v-if="slotProps.data.mapping"> {{ slotProps.data.mapping.name }}</span>
                    <span
                      v-else
                      v-tooltip.bottom="{
                        value: ts.tModule('import_mapping_tooltip'),
                        class: 'text-sm',
                      }"
                      class="text-red-600"
                    >
                      {{ ts.tGlobal('none') }}</span
                    >
                  </div>
                </div>

                <div class="flex mb-1">
                  <div class="col-6">
                    <label> {{ ts.tModule('table_headers.importId') }}</label>
                  </div>

                  <div class="col-6">
                    {{ slotProps.data.id }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="align-items-center flex w-6">
          <p-timeline
            :value="slotProps.data.statuses?.map((item: PipelineStatusCompact) => item).reverse()"
            layout="vertical"
            align="left"
          >
            <template #marker="prop">
              <p-badge
                :severity="prop.item.level === 'error' ? 'danger' : prop.item.level"
                :pt="{
                  root: () => ({
                    style: { 'min-width': '1rem', height: '1rem' },
                  }),
                }"
              />
            </template>
            <template #opposite="prop">
              <small class="p-text-secondary">{{
                moment(prop.item.occurred_at).format('DD MMM Y, HH:mm')
              }}</small>
            </template>
            <template #content="prop">
              <p v-tooltip.right="{ value: String(prop.item.message), class: 'text-sm' }">
                {{ prop.item.text }}
              </p>
            </template>
          </p-timeline>
        </div>
      </div>
    </template>
  </pDataTable>
</template>
