import { RouteRecordRaw } from 'vue-router';
import clientsRoutes from 'admin/modules/clients/routes/clients.routes';
import DatamodelsRoutes from 'admin/modules/datamodels/routes/datamodels.routes';
import fieldsRoutes from 'admin/modules/fields/routes/fields.routes';
import modulesRoutes from 'admin/modules/modules/routes/modules.routes';
import organisationRoutes from 'admin/modules/organisations/routes/organisations.routes';
import retailersRoutes from 'admin/modules/retailers/routes/retailers.routes';
import rolesRoutes from 'admin/modules/roles/routes/roles.routes';
import settingsRoutes from 'admin/modules/settings/routes/settings.routes';
import templatesRoutes from 'admin/modules/templates/routes/templates.routes';
import userRoutes from 'admin/modules/users/routes/users.routes';
import dashboardRoutes from 'admin/modules/dashboard/routes/dashboard.routes';
import validationRulesRoutes from '../modules/validation-rules/routes/validation-rules.routes';
import statusNotificationsRoutes from '../modules/status-notifications/routes/status-notifications.routes';
export function getAdminRoutes(): RouteRecordRaw[][] {
  return [
    clientsRoutes,
    DatamodelsRoutes,
    templatesRoutes,
    userRoutes,
    retailersRoutes,
    modulesRoutes,
    fieldsRoutes,
    rolesRoutes,
    settingsRoutes,
    organisationRoutes,
    dashboardRoutes,
    validationRulesRoutes,
    statusNotificationsRoutes,
  ];
}
