<script setup lang="ts">
import BrandSelect from '@/general/ui/components/selects/brand-select.vue';
import { BrandCompact } from 'platform-unit2-api/brands';
import { ref, watch } from 'vue';
import { DropdownChangeEvent } from 'primevue/dropdown';
/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

/** Consts */
const selectedBrand = ref<BrandCompact>({ name: props.modelValue } as BrandCompact);
const updateBrand = (event: DropdownChangeEvent) => {
  emit('update:modelValue', event.value?.name ?? '');
};

watch(
  () => props.modelValue,
  () => (selectedBrand.value = { name: props.modelValue } as BrandCompact),
);
</script>

<template>
  <div class="w-full">
    <BrandSelect
      v-model="selectedBrand"
      :disabled="disabled"
      data-key="name"
      :show-clear="false"
      hide-label
      class="h-full"
      @change="updateBrand"
    />
  </div>
</template>
