import { TemplateMetaData } from './../interfaces/template-meta-data.interface';
import {
  TemplateEndpointsRestService,
  TemplateEndpoint,
} from 'platform-unit2-api/template-endpoints';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import axios, { Method } from 'axios';
import {
  TemplatesRestService,
  ProcessTemplateRequest,
  Template,
  UpdateTemplateRequest,
} from 'platform-unit2-api/templates';

import { SignFileResponse, UppyFile } from 'platform-unit2-api/core';

export default class TemplateService {
  private templateApi = new TemplatesRestService();
  private templateEndpointsApi = new TemplateEndpointsRestService();

  public async createNewTemplate(
    uploader: FileUploadUploaderEvent,
    templateMetaData: TemplateMetaData,
  ): Promise<Template> {
    if (Array.isArray(uploader.files)) {
      return this.signTemplateRequest(uploader.files[0]).then((res: SignFileResponse) => {
        const newTemplateDTO: ProcessTemplateRequest = {
          name: templateMetaData.name,
          type: 'template',
          data_row: Number(templateMetaData.dataRows),
          header_row: Number(templateMetaData.headerRows),
          reference: res.reference,
          sheets: templateMetaData.sheets.map((s) => +s),
        };
        if (templateMetaData.api) {
          return this.templateApi.post({
            name: templateMetaData.name,
            type: 'api',
            reference: res.reference,
            sheets: templateMetaData.sheets.map((s) => +s),
          });
        }

        return this.processNewTemplate(newTemplateDTO);
      });
    }

    throw 'upload is no array';
  }

  signTemplateRequest(fileToSign: File): Promise<SignFileResponse> {
    return this.templateApi
      .signTemplate({ name: fileToSign.name, type: fileToSign.type } as UppyFile)
      .then(async (res) => {
        await this.signedAxiosCallToAWS(fileToSign, res);
        return res;
      });
  }

  async signedAxiosCallToAWS(fileToSign: File, signedResponse: SignFileResponse): Promise<void> {
    await axios.request({
      url: signedResponse.url,
      method: signedResponse.method as Method,
      data: fileToSign,
      headers: {
        'Content-Type': fileToSign.type,
      },
    });
  }

  processNewTemplate(newTemplate: ProcessTemplateRequest): Promise<Template> {
    return this.templateApi.processNewTemplate(newTemplate);
  }

  isDataCompliant(templateMetaData: TemplateMetaData): boolean {
    return Object.entries(templateMetaData)
      .map(([_, val]) => val)
      .some((val) => {
        if (val == null) {
          return false;
        }

        if (Array.isArray(val)) {
          return val == null;
        }

        return val.toString() == (null || '');
      });
  }

  saveChangesToTemplate(template: Template): Promise<void> {
    return this.updateTemplate(template);
  }

  updateTemplate(template: Template): Promise<void> {
    this.templateApi.update(template.id, { id: template.id, name: template.name });
    return this.templateEndpointsApi.updateTempalateEndpoints(template.endpoints);
  }

  async createEndpoint(newEndpoint: TemplateEndpoint): Promise<TemplateEndpoint> {
    return this.templateApi.createTemplateEndpoints(newEndpoint).then((res) => {
      return res;
    });
  }

  async createTemplate(newTemplate: UpdateTemplateRequest): Promise<Template> {
    return this.templateApi.post(newTemplate);
  }

  async deleteEndpoints(newEndpoint: TemplateEndpoint): Promise<void> {
    return this.templateEndpointsApi.delete(newEndpoint.id);
  }

  async getTemplate(id?: number): Promise<Template | undefined> {
    if (id == null) {
      return undefined;
    }

    return this.templateApi.get(id);
  }
}
