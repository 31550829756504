import { Attribute } from 'platform-unit2-api/attributes';
import {
  CategoryRestService,
  Category,
  CategoryTreeItem,
  UpdateCategoryRequest,
  CreateCategoryRequest,
} from 'platform-unit2-api/categories';

import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { MapCategoryTree } from '@/platforms/supplier/modules/categories/ts/categories.types';
import { ActionRestService } from 'platform-unit2-api/actions';
import { FileUploadSelectEvent } from 'primevue/fileupload';

/**
 * Service for the Category type view.
 */
export class CategoryViewService extends BaseViewService<
  CategoryRestService,
  Category,
  CreateCategoryRequest,
  UpdateCategoryRequest & { id: number }
> {
  public nodes: MapCategoryTree[] = [];
  public parentCategory?: Record<number, boolean>;
  private fileToUpload?: any;
  private actionAPI: ActionRestService;
  public categoryAttributes: Record<number, Attribute[]> = {};
  public loadingAttributes = false;
  public treeViewNodes: MapCategoryTree[] = [];

  constructor(ts: TranslationService) {
    super({
      Api: CategoryRestService,
      fetchAllFunction: 'getAll',
      ts: ts,
      overviewRouteName: 'categories',
      createRouteName: 'new-category',
      updateRouteName: 'edit-category',
      confirmPopUpGroup: 'categories',
    });

    this.refetch = this._refetch;
    this.actionAPI = new ActionRestService();
  }

  public createBody(): CreateCategoryRequest | undefined {
    if (this.partialObject == null || !this.validated) {
      return;
    }

    let parentId;
    if (this.parentCategory && !isNaN(Number(Object.keys(this.parentCategory)[0]))) {
      parentId = +Object.keys(this.parentCategory)[0];
    }

    const body: CreateCategoryRequest = {
      name: this.partialObject.name!,
      parent_id: parentId,
    };

    return body;
  }

  protected _create(): void {
    // Upload import file if one has been attached, instead of saving a new category
    if (this.fileToUpload) {
      this.actionAPI
        .importCategoryAttribute({
          file: this.fileToUpload,
        })
        .then(() => {
          this._toastService.displaySuccessToast(this._ts.importSuccess());
        })
        .catch(() => {
          this._toastService.displayErrorToast(this._ts.importFailed());
          this.fileToUpload = undefined;
        });
    } else {
      super._create();
    }
  }

  public updateBody(): (UpdateCategoryRequest & { id: number }) | undefined {
    if (this.partialObject == null || !this.validated || this.partialObject?.id == null) {
      return;
    }

    let parentId;
    if (this.parentCategory && !isNaN(Number(Object.keys(this.parentCategory)[0]))) {
      parentId = +Object.keys(this.parentCategory)[0];
    }

    const body: UpdateCategoryRequest & { id: number } = {
      id: this.partialObject.id!,
      name: this.partialObject.name!,
      parent_id: parentId,
    };

    return body;
  }

  public get validated(): boolean {
    return this.partialObject?.name || this.fileToUpload;
  }

  /**
   * Get categories.
   */
  public get categories(): Category[] {
    return this._data;
  }

  public loadAttributes(categoryId: number): void {
    if (!this.categoryAttributes[categoryId]) {
      this.loadingAttributes = true;
      this._restService
        .getCategoryAttributes(categoryId)
        .then((res) => {
          this.categoryAttributes = { ...this.categoryAttributes, [categoryId]: res.data };
        })
        .catch(() => {
          this._toastService.displayErrorToast(this._ts.tModule('getAttributesError'));
        })
        .finally(() => {
          this.loadingAttributes = false;
        });
    }
  }

  public detachAttribute(categoryId: number, attributeId: number): void {
    this._restService
      .detachAttribute(categoryId, attributeId)
      .then(() => {
        // Removes modified category attributes, so they can be refetched
        if (this.categoryAttributes[categoryId]) {
          delete this.categoryAttributes[categoryId];
        }

        this.loadAttributes(categoryId);
        this._toastService.displaySuccessToast(this._ts.tModule('detachAttributeSuccess'));
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('detachAttributeError'));
      });
  }

  public confirmCategoryDelete(category: Category): void {
    this._confirmService.confirmDelete({
      callback: () => this.deleteCategory(category.id),
      group: 'category',
      message: this._ts.deleteConfirm(category.name),
    });
  }

  private deleteCategory(id: number): void {
    this._restService
      .delete(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.deleteFailed());
      });
  }

  public async getTreeViewNodes(): Promise<MapCategoryTree[]> {
    const response = await this._restService.getCategoryTreeIndex();
    return this.mapTree(response.data);
  }

  public async getCategoryTreeIndex() {
    const response = await this._restService.getCategoryTreeIndex();
    this.nodes = this.mapTree(response.data);
    if (this.current?.parent?.id) {
      this.parentCategory = { [this.current.parent.id]: true };
    }
  }
  private mapTree = (items: CategoryTreeItem[]): MapCategoryTree[] => {
    return items
      .map((item: CategoryTreeItem) => ({
        id: item.id,
        key: item.id,
        label: item.name,
        parent_id: item.parent_id,
        owner_id: item.owner_id,
        children: item.id !== this.current?.id ? this.mapTree(item.children) : undefined,
        selectable: true,
      }))
      .filter((treeItem) => treeItem.id !== this.current?.id);
  };

  public onFileSelect(event: FileUploadSelectEvent) {
    this.fileToUpload = event.files[0];
  }

  protected _refetch(): void {
    this.getTreeViewNodes().then((res) => {
      this.treeViewNodes = res;
    });

    this.fetchAll();
  }
}
