import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { TranslationAction } from '@/core/i18n/ts/interfaces/translation-action.interface';
import { TranslationActions } from '@/core/i18n/ts/interfaces/translation-actions.interface';
import { Composer, useI18n } from 'vue-i18n';
import { TranslationObjectActions } from '@/core/i18n/ts/interfaces/translation-object-actions.interface';
import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationFormLabels } from '@/core/i18n/ts/interfaces/translation-forms.interface';
import { TranslationGlobal } from '@/core/i18n/ts/interfaces/translation-global.interface';

/**
 * Translation service
 * @description
 * This service is used to translate the application. This is a tool to make it easier and type safe to translate the application.
 * In the constructor set the platform and the module the translations should work for.
 * When you call a function or property it will translate and add the platform and module to the translation key.
 *
 *
 * In this service docs we use ts in the comments.
 * ts stands for a instance of the TranslationService.
 * @example
 * TranslationsService.title // == t('platform.module.title')
 */
export class TranslationService {
  private _i18n: Composer;
  private _module: string;
  private _platform: string;

  public set platform(value: 'admin' | 'retailer' | 'supplier' | 'general' | 'global') {
    this._platform = value;
  }

  public set module(value: string) {
    this._module = value;
  }

  private _isStrict: boolean;

  //#region General

  /**
   * translate to: "module.title"
   * @example
   * ts.title // t('platform.module.title')
   * @returns string
   */
  public get title(): string {
    return this.tModule('title');
  }

  /**
   * translate to: "module.title(s)"
   * @example
   * ts.titlePlural // t('platform.module.title', 2)
   * @returns string
   */
  public get titlePlural(): string {
    return this.tModule('title', { choice: 2 });
  }

  /**
   * translate to: "Select module.placeholder"
   * @example
   * ts.placeholder // t('platform.module.placeholder')
   * @returns string
   */
  public get placeholder(): string {
    return this.tModule('placeholder');
  }

  /**
   * translate to: "Select module.placeholder(s)"
   * @example
   * ts.placeholderPlural // t('platform.module.placeholder', 2)
   * @returns string
   */
  public get placeholderPlural(): string {
    return this.tModule('placeholder', { choice: 2 });
  }

  /**
   * Translates to: "There are no {entity} yet."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.getEmptyStateTitle //
   * @returns string
   */
  public get emptyStateTitle(): string {
    return this.tGlobal('emptyStateTitle', { entity: this.titlePlural.toLowerCase(), choice: 1 });
  }

  /**
   * Translates to: "Start by creating your first {entity}. When your new {entity} is saved it will show"
   * @example
   * ts.getEmptyStateSubtitle //
   * @returns string
   */
  public get emptyStateSubtitle(): string {
    return this.tModule('emptyStateSubtitle');
  }

  //#endregion General

  // #region Create

  /**
   * translate to: "Create"
   * @example
   * ts.createTitle // t('actions.create.title')
   * @returns string
   */
  public get createTitle(): string {
    return this.tAction('create', 'title');
  }

  /**
   * Translates to: "Successfully created (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.createSuccess('entity') // t('actions.create.success', 2 , { entity: 'entity' })
   * ts.createSuccess() // t('actions.create.success', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public createSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('create', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('create', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translates to: "Failed to create (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.createFailed('entity') // t('actions.create.failed', 2 ,{ entity: 'entity' })
   * ts.createFailed() // t('actions.create.failed', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public createFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('create', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('create', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to the "Create (module.title)"
   * @example
   * ts.createButton('entity') // t('actions.create.button', 2 , { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public get createButton(): string {
    return this.tAction('create', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  //#endregion create

  // #region Save

  /**
   * Translates to: "Are you sure you want to save (entity)?"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.saveConfirm('entity') // t('actions.save.failed', { entity: 'entity' })
   * ts.saveConfirm() // t('actions.save.confirm', 2)
   * @returns string
   */
  public saveConfirm(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('save', 'confirm', { entity: entity });
    }

    return this.tAction('save', 'confirm', { choice: 2 });
  }

  /**
   * Translates to: "Save (entity)"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.saveButton('entity') // t('actions.save.failed', 2 , { entity: 'entity' })
   * ts.saveButton() // t('actions.save.button', 2 )
   * @returns string
   */
  public saveButton(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('save', 'button', { entity: entity, choice: 2 });
    }

    return this.tAction('save', 'button', { entity: this.title.toLowerCase() });
  }

  // #endregion Save

  // #region Update

  /**
   * translate to: "Update"
   * @example
   * ts.updateTitle // t('actions.update.title')
   * @returns string
   */
  public get updateTitle(): string {
    return this.tAction('update', 'title');
  }

  /**
   * Translates to: "Successfully updated (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.updateSuccess('entity') // t('actions.update.success', 2 ,{ entity: 'entity'})
   * ts.updateSuccess() // t('actions.update.success', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public updateSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('update', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('update', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translates to: "Failed to update (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.updateFailed('entity') // t('actions.update.failed', 2 ,{ entity: 'entity'})
   * ts.updateFailed() // t('actions.update.failed', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public updateFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('update', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('update', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translates to: "Update (entity)"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.updateButton('entity') // t('actions.update.button', 2 ,{ entity: 'entity'})
   * ts.updateButton() // t('actions.update.button', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public updateButton(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('update', 'button', { entity: entity, choice: 2 });
    }

    return this.tAction('update', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  // #endregion Update

  // #region Delete

  /**
   * Translate to: "Delete"
   * @example
   * ts.deleteTitle // t('actions.delete.title')
   * @returns string
   */
  public get deleteTitle(): string {
    return this.tAction('delete', 'title');
  }

  /**
   * Translate to: "Successfully deleted (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.deleteSuccess('entity') // t('actions.delete.success', 2 , { entity: 'entity' })
   * ts.deleteSuccess() // t('actions.delete.success', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public deleteSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('delete', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('delete', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to delete (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.deleteFailed('entity') // t('actions.delete.failed', 2 , { entity: 'entity' })
   * ts.deleteFailed() // t('actions.delete.failed', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public deleteFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('delete', 'failed', { entity: entity });
    }

    return this.tAction('delete', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Are you sure you want to delete (entity)?"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.deleteConfirm('entity') // t('actions.delete.confirm', 2 , { entity: 'entity' })
   * ts.deleteConfirm() // t('actions.delete.confirm', 2)
   * @returns string
   */
  public deleteConfirm(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('delete', 'confirm', { entity: entity });
    }

    return this.tAction('delete', 'confirm', { choice: 2 });
  }

  /**
   * Translate to: "Delete (entity)"
   * @example
   * ts.deleteButton // t('actions.delete.button', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public get deleteButton(): string {
    return this.tAction('delete', 'button', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to delete (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.deleteFailedIsUsed // t('actions.delete.failedIsUsed', { entity: t('platform.module.title'),toLowerCase() })
   */
  public get deleteFailedIsUsed(): string {
    return this.tModuleAction('delete', 'failedIsUsed');
  }

  // #endregion Delete

  // #region Get

  /**
   * Translate to: "Get"
   * @example
   * ts.getTitle // t('actions.get.title')
   * @returns string
   */
  public get getTitle(): string {
    return this.tAction('get', 'title');
  }

  /**
   * Translate to: "Successfully got (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.getSuccess('entity') // t('actions.get.success', { entity: 'entity' })
   * ts.getSuccess() // t('actions.get.success', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public getSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('get', 'success', { entity: entity });
    }

    return this.tAction('get', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to get (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.getFailed('entity') // t('actions.get.failed', { entity: 'entity' })
   * ts.getFailed() // t('actions.get.failed', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public getFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('get', 'failed', { entity: entity });
    }

    return this.tAction('get', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "No (entity) found."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.getNotFound('entity') // t('actions.get.notFound', { entity: 'entity' })
   * ts.getNotFound() // t('actions.get.notFound', { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public getNotFound(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('get', 'notFound', { entity: entity });
    }

    return this.tAction('get', 'notFound', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "No (entity) found."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.getEmpty // t('actions.get.empty', { entity: 'ts.titlePlural.toLowerCase()' })
   * @returns string
   */
  public get getEmpty(): string {
    return this.tAction('get', 'notFound', { entity: this.titlePlural.toLowerCase() });
  }

  /**
   * Translate to: "Get (entity)"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.getButton() // t('actions.get.button', 2 , { entity: t('platform.module.title'),toLowerCase() })
   * @returns string
   */
  public get getButton(): string {
    return this.tAction('get', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  //#endregion Get

  // #region Search

  /**
   * Translate to: "Search"
   * @example
   * ts.searchTitle // t('actions.search.title')
   * @returns string
   */
  public get searchTitle(): string {
    return this.tAction('search', 'title');
  }

  /**
   * Translate to: "Successfully found (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.searchSuccess('entity') // t('actions.search.success', { entity: 'entity' })
   * ts.searchSuccess() // t('actions.search.success', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public searchSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('search', 'success', { entity: entity });
    }

    return this.tAction('search', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to search on (entity(s))."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.searchFailed('entity') // t('actions.search.failed', 2 ,{ entity: 'entity' })
   * ts.searchFailed() // t('actions.search.failed', { entity: t('platform.module.title', 2).toLowerCase() })
   */
  public searchFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('search', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('search', 'failed', { entity: this.titlePlural.toLowerCase() });
  }

  /**
   * Translate to: "No (entity) found."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.searchNotFound // t('actions.search.notFound', { entity: t('platform.module.title', 2).toLowerCase() })
   * @returns string
   */
  public get searchNoResults(): string {
    return this.tAction('search', 'noResults', { entity: this.titlePlural.toLowerCase() });
  }

  /**
   * Translate to: "Search (entity)"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.searchButton() // t('actions.search.button', { entity: t('platform.module.title', 2).toLowerCase() })
   * @returns string
   */
  public get searchButton(): string {
    return this.tAction('search', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  //#endregion Search

  // #region send

  /**
   * Translate to: "Send"
   * @example
   * ts.sendTitle // t('actions.send.title')
   * @returns string
   */
  public get sendTitle(): string {
    return this.tAction('send', 'title');
  }

  /**
   * Translate to: "Successfully sent (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.sendSuccess('entity') // t('actions.send.success', 2 , { entity: 'entity' })
   * ts.sendSuccess() // t('actions.send.success')
   * @returns string
   */
  public sendSuccess(entity?: string): string {
    if (entity) {
      return this.tAction('send', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('send', 'success');
  }

  /**
   * Translate to: "Failed to send (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.sendFailed('entity') // t('actions.send.failed', 2 , { entity: 'entity' })
   * ts.sendFailed() // t('actions.send.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public sendFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('send', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('send', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Send"
   * @example
   * ts.sendButton // t('actions.send.button')
   * @returns string
   */
  public get sendButton(): string {
    return this.tAction('send', 'button');
  }

  //#endregion send

  // #region upload

  /**
   * Translate to: "Upload"
   * @example
   * ts.uploadTitle // t('actions.upload.title')
   * @returns string
   */
  public get uploadTitle(): string {
    return this.tAction('upload', 'title');
  }

  /**
   * Translate to: "Successfully uploaded (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.uploadSuccess('entity') // t('actions.upload.success', 2 , { entity: 'entity' })
   * ts.uploadSuccess() // t('actions.upload.success')
   * @returns string
   */
  public uploadSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('upload', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('upload', 'success');
  }

  /**
   * Translate to: "Failed to upload (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.uploadFailed('entity') // t('actions.upload.failed', 2 , { entity: 'entity' })
   * ts.uploadFailed() // t('actions.upload.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public uploadFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('upload', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('upload', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Upload"
   * @example
   * ts.uploadButton // t('actions.upload.button')
   * @returns string
   */
  public get uploadButton(): string {
    return this.tAction('upload', 'button');
  }

  //#endregion upload

  // #region load

  /**
   * Translate to: "Load"
   * @example
   * ts.loadTitle // t('actions.load.title')
   * @returns string
   */
  public get loadTitle(): string {
    return this.tAction('load', 'title');
  }

  /**
   * Translate to: "Successfully loaded (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.loadSuccess('entity') // t('actions.load.success', 2 , { entity: 'entity' })
   * ts.loadSuccess() // t('actions.load.success')
   * @returns string
   */
  public loadSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('load', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('load', 'success');
  }

  /**
   * Translate to: "Failed to load (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.loadFailed('entity') // t('actions.load.failed', 2 , { entity: 'entity' })
   * ts.loadFailed() // t('actions.load.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public loadFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('load', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('load', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Load"
   * @example
   * ts.loadButton // t('actions.load.button')
   * @returns string
   */
  public get loadButton(): string {
    return this.tAction('load', 'button');
  }

  //#endregion load

  // #region import

  /**
   * Translate to: "Import"
   * @example
   * ts.importTitle // t('actions.import.title')
   * @returns string
   */
  public get importTitle(): string {
    return this.tAction('import', 'title');
  }

  /**
   * Translate to: "Successfully imported (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.importSuccess('entity') // t('actions.import.success', 2 , { entity: 'entity' })
   * ts.importSuccess() // t('actions.import.success', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public importSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('import', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('import', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to import (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.importFailed('entity') // t('actions.import.failed', 2 , { entity: 'entity' })
   * ts.importFailed() // t('actions.import.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public importFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('import', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('import', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Import"
   * @example
   * ts.importButton // t('actions.import.button', 2)
   * @returns string
   */
  public get importButton(): string {
    return this.tAction('import', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  //#endregion import

  // #region export

  /**
   * Translate to: "Export"
   * @example
   * ts.exportTitle // t('actions.export.title')
   * @returns string
   */
  public get exportTitle(): string {
    return this.tAction('export', 'title');
  }

  /**
   * Translate to: "Successfully exported (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.exportSuccess('entity') // t('actions.export.success', 2 , { entity: 'entity' })
   * ts.exportSuccess() // t('actions.export.success', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public exportSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('export', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('export', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to export (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.exportFailed('entity') // t('actions.export.failed', 2 , { entity: 'entity' })
   * ts.exportFailed() // t('actions.export.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public exportFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('export', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('export', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Export"
   * @example
   * ts.exportButton // t('actions.export.button', 2 , { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public get exportButton(): string {
    return this.tAction('export', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  //#endregion export

  // #region duplicate

  /**
   * Translate to: "Duplicate"
   * @example
   * ts.duplicateTitle // t('actions.duplicate.title')
   * @returns string
   */
  public get duplicateTitle(): string {
    return this.tAction('duplicate', 'title');
  }

  /**
   * Translate to: "Successfully duplicated (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.duplicateSuccess('entity') // t('actions.duplicate.success', 2 , { entity: 'entity' })
   * ts.duplicateSuccess() // t('actions.duplicate.success', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public duplicateSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('duplicate', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('duplicate', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to duplicate (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.duplicateFailed('entity') // t('actions.duplicate.failed', 2 , { entity: 'entity' })
   * ts.duplicateFailed() // t('actions.duplicate.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public duplicateFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('duplicate', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('duplicate', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Duplicate"
   * @example
   * ts.duplicateButton // t('actions.duplicate.button', 2 , { entity: t('platform.module.title').toLowerCase() }})
   * @returns string
   */
  public get duplicateButton(): string {
    return this.tAction('duplicate', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  /**
   * Translate to: "Are you sure you want to duplicate (entity)?"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.duplicateConfirm('entity') // t('actions.duplicate.confirm' { entity: 'entity' })
   * ts.duplicateConfirm() // t('actions.duplicate.confirm', 2)
   * @returns string
   */
  public duplicateConfirm(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('duplicate', 'confirm', { entity: entity });
    }

    return this.tAction('duplicate', 'confirm', { choice: 2 });
  }

  //#endregion duplicate

  // #region download

  /**
   * Translate to: "Download"
   * @example
   * ts.downloadTitle // t('actions.download.title')
   * @returns string
   */
  public get downloadTitle(): string {
    return this.tAction('download', 'title');
  }

  /**
   * Translate to: "Successfully downloaded (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.downloadSuccess('entity') // t('actions.download.success', 2 , { entity: 'entity' })
   * ts.downloadSuccess() // t('actions.download.success', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public downloadSuccess(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('download', 'success', { entity: entity, choice: 2 });
    }

    return this.tAction('download', 'success', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Failed to download (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.downloadFailed('entity') // t('actions.download.failed', 2 , { entity: 'entity' })
   * ts.downloadFailed() // t('actions.download.failed', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public downloadFailed(entity?: string): string {
    if (entity != null && entity != '') {
      return this.tAction('download', 'failed', { entity: entity, choice: 2 });
    }

    return this.tAction('download', 'failed', { entity: this.title.toLowerCase() });
  }

  /**
   * Translate to: "Download"
   * @example
   * ts.downloadButton // t('actions.download.button', 2 , { entity: t('platform.module.title').toLowerCase() }}})
   * @returns string
   */
  public get downloadButton(): string {
    return this.tAction('download', 'button', { entity: this.title.toLowerCase(), choice: 2 });
  }

  //#endregion download

  // #region Module Actions

  /**
   * Translate to: "Create"
   * @example
   * ts.moduleCreateTitle // t('actions.module.create.title')
   * @returns string
   */
  public get moduleCreateTitle(): string {
    return this.tModuleAction('create', 'title');
  }

  /**
   * Translate to: "Here you can create a new (entity)."
   * @example
   * ts.moduleCreateSubTitle // t('actions.module.create.subTitle')
   * @returns string
   */
  public get moduleCreateSubTitle(): string {
    return this.tModuleAction('create', 'subTitle');
  }

  /**
   * Translate to: "Update (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleUpdateTitle // t('actions.module.update.title')
   * @returns string
   */
  public get moduleUpdateTitle(): string {
    return this.tModuleAction('update', 'title');
  }

  /**
   * Translate to: "Here you can edit (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleUpdateTitle // t('actions.module.update.subTitle')
   * @returns string
   */
  public get moduleUpdateSubTitle(): string {
    return this.tModuleAction('update', 'subTitle');
  }

  /**
   * Translate to: "Delete (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleDeleteTitle // t('actions.module.delete.title')
   * @returns string
   */
  public get moduleDeleteTitle(): string {
    return this.tModuleAction('delete', 'title');
  }

  /**
   * Translate to: "Here you can delete (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleCreateSuccess('entity') // t('actions.module.create.success', 2 , { entity: 'entity' })
   * ts.moduleCreateSuccess() // t('actions.module.create.success', { entity: t('platform.module.title').toLowerCase() })
   * @returns string
   */
  public get moduleDeleteSubTitle(): string {
    return this.tModuleAction('delete', 'subTitle');
  }

  /**
   * Translate to: "Get (entity)."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleGetSubTitle // t('actions.module.get.subTitle')
   * @returns string
   */
  public get moduleGetTitle(): string {
    return this.tModuleAction('get', 'title');
  }

  /**
   * Translate to: "Get your (entity) here."
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleGetSubTitle // t('actions.module.get.subTitle')
   * @returns string
   */
  public get moduleGetSubTitle(): string {
    return this.tModuleAction('get', 'subTitle');
  }

  /**
   * Translate to: "duplicate (entity)"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleDuplicateTitle // t('actions.module.duplicate.title')
   * @returns string
   */
  public get moduleDuplicateTitle(): string {
    return this.tModuleAction('duplicate', 'title');
  }

  /**
   * Translate to: "duplicate (entity)"
   * @param entity: string - Text that replaces the {entity} in the translation.
   * @example
   * ts.moduleDuplicateSubTitle // t('actions.module.duplicate.subTitle')
   * @returns string
   */
  public get moduleDuplicateSubTitle(): string {
    return this.tModuleAction('duplicate', 'subTitle');
  }

  //#endregion Module Actions

  // #region warnings

  /**
   * Translate to: "Warning"
   * @example
   * ts.warningTitle // t('warnings.title')
   * @returns string
   */
  public get warningTitle(): string {
    return this._i18n.t('warnings.title');
  }

  /**
   * Translate to: "No {entity} could be found"
   * @example
   * ts.notFoundWarning // t('warnings.notFound', { entity: t('platform.module.title', 2 ).toLowerCase() })
   * @returns string
   */
  public get notFoundWarning(): string {
    return this._i18n.t('warnings.notFound', { entity: this.titlePlural.toLowerCase() });
  }

  /**
   * Translate to: "Are you sure you want to leave this page?"
   * @example
   * ts.confirmLeaveWarning // t('warnings.confirmLeave')
   * @returns string
   */
  public get confirmLeaveWarning(): string {
    return this._i18n.t('warnings.confirmLeave');
  }

  //#endregion

  /*
   * Translate to: "required"
   * @example
   * ts.required // t('validation.required')
   * @returns string
   */
  public get required() {
    return this.t('validation.required');
  }

  /**
   * Translate to: "Something unexpected happened, please try again."
   * @example
   * ts.unexpectedError // t('errors.unexpectedError')
   * @returns string
   */
  public get unexpectedError() {
    return this._i18n.t('errors.unexpectedError');
  }

  private _isStrictTranslationSetAndFalse(strictTranslations?: string) {
    return strictTranslations != null && strictTranslations.toLowerCase() == 'false';
  }

  /**
   * @description
   * set the platform and module that are used for translation.
   * @param platform The platform of the application.
   * @param module The module of the application.
   * @param developmentMode A boolean that sets the development mode. If false it simulates production.
   */
  constructor(
    platform: 'admin' | 'retailer' | 'supplier' | 'general' | 'global',
    module?: string,
    isStrict = process.env.NODE_ENV !== 'production',
    i18n = useI18n(),
  ) {
    this._i18n = i18n;
    this._module = module ?? '';
    this._platform = platform == 'global' ? '' : platform;
    this._isStrict = isStrict;

    const strictTranslations = import.meta.env.VITE_APP_STRICT_TRANSLATIONS;

    if (this._isStrictTranslationSetAndFalse(strictTranslations)) {
      this._isStrict = false;
    }

    if (process.env.NODE_ENV === 'production') {
      this._isStrict = false;
    }

    //Give error in console to warn the developer that he isn't using development mode.
    if (!this._isStrict && process.env.NODE_ENV !== 'production') {
      console.error(
        'You are ignoring translations, development mode should be true in development. If a missing translation is' +
          'ignored this will display: "MISSING TRANSLATION" in development.' +
          ' In production the last key inside the translation will be displayed.',
      );
    }
  }

  /**
   * Validate if the translations exists otherwise throw an error.
   * @param translation The translation that is returned by the i18n function.
   * @param tKey The translation key that is used to get the translation.
   * @param key The key that is passed to the TranslationService function.
   * @param translationFunction The name of the TranslationService function.
   * @param args Any arguments that are passed to the Validate function.
   * @returns string | throws Error
   */
  private validateTranslation(
    translation: string,
    tKey: string,
    key: string,
    translationFunction: string,
    args?: any,
  ): string {
    if (translation == tKey) {
      const path = tKey.split('.');

      let file: string | undefined;

      if (
        path.includes(this._module) &&
        (translationFunction == 'tModule' || translationFunction == 'tModuleAction')
      ) {
        file = this._platform + '/modules/' + this._module + '/translations/en.ts';
      }

      const errorMessage = `
        Translation not found: ${translationFunction}('${key}').

        Data:
        platform: ${this._platform}, module: ${this._module}
        arguments: ${JSON.stringify(args)}
        i18n key: ${tKey}
        ${file != null ? 'OR missing translation in file: ' : ''}
        ${file != null ? file : ''}

        Replace the key with the correct translation or
        add the translation to the module translation
        file.
      `;

      //throw error if development mode is true
      if (this._isStrict) {
        throw new Error(errorMessage);
      }

      //If the application isn't running in production return MISSING TRANSLATIONS
      if (process.env.NODE_ENV !== 'production') {
        //Give console error if development mode is false and application is in development.
        console.error(errorMessage);
        return 'MISSING TRANSLATION';
      }

      //Give console warning if development mode is false and application is in production.
      console.warn(errorMessage);

      // If the application is running in production return the last key of the translation.
      // For example: 'example.dasbhoard.title' will return 'title'
      const translationParts = translation.split('.');

      if (translationParts.length != 1) {
        return translationParts[translationParts.length - 1];
      } else {
        return translation;
      }
    }

    return translation;
  }

  /**
   * Sub translation, used for translations that are in a sub module.
   * @param subModule The sub module that is used for the translation.
   * @param key The key that is used for the translation.
   * @param args Any arguments that are passed to the translation.
   * @returns string
   */
  private subTranslation(
    subModule: string,
    key: string,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: any;
    },
  ): { translation: string; key: string } {
    {
      if (args && args.entity != null) {
        if (args.choice == null) {
          return {
            translation: this._i18n.t(`${subModule}.${key}`, { entity: args.entity }),
            key: `${subModule}.${key}`,
          };
        }

        return {
          translation: this._i18n.t(`${subModule}.${key}`, { entity: args.entity }, args.choice),
          key: `${subModule}.${key}`,
        };
      }

      if (args) {
        if (args.params && args.choice == null) {
          return {
            translation: this._i18n.t(`${subModule}.${key}`, args.params),
            key: `${subModule}.${key}`,
          };
        }

        if (!args.params && args.choice != null) {
          return {
            translation: this._i18n.t(`${subModule}.${key}`, args.choice),
            key: `${subModule}.${key}`,
          };
        }

        if (args.params && args.choice != null) {
          return {
            translation: this._i18n.t(`${subModule}.${key}`, args.params, args.choice),
            key: `${subModule}.${key}`,
          };
        }
      }

      return {
        translation: this._i18n.t(`${subModule}.${key}`),
        key: `${subModule}.${key}`,
      };
    }
  }

  /**
   * I18n Translation function.
   * @param key Key that is used for the translation.
   * @param args arguments that are passed to the translation.
   * @returns string
   */
  private translate(
    key: string,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: any;
    },
  ): string {
    if (args && args.entity != null) {
      if (args.choice == null) {
        return this._i18n.t(key, { entity: args.entity });
      }

      return this._i18n.t(key, { entity: args.entity }, args.choice);
    }

    if (args) {
      if (args.params && args.choice == null) {
        return this._i18n.t(key, args.params);
      }

      if (!args.params && args.choice != null) {
        return this._i18n.t(key, args.choice);
      }

      if (args.params && args.choice != null) {
        return this._i18n.t(key, args.params, args.choice);
      }
    }

    return this._i18n.t(key);
  }

  /**
   * Translates using i18n and validates the translation
   * @param key Key that is used for the translation.
   * @param args arguments that are passed to the translation.
   * @returns string
   */
  public t(
    key: string,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: any;
    },
  ): string {
    return this.validateTranslation(this.translate(key, args), key, key, 't', args);
  }

  /**
   * Form translation function. The translations used point to 'forms.labels.key'
   * @param key key that is used for the translation.
   * @param args arguments that are passed to the translation.
   * @returns string
   */
  public tForms(
    key: keyof TranslationFormLabels,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: any;
    },
  ): string {
    const translationResult = this.subTranslation(`forms.labels`, key, args);

    return this.validateTranslation(
      translationResult.translation,
      translationResult.key,
      key,
      'tForms',
      args,
    );
  }

  /**
   * Global translation function. The translations used point to 'global.key'
   * @param key key that is used for the translation.
   * @param args arguments that are passed to the translation.
   * @returns string
   */
  public tGlobal(
    key: keyof TranslationGlobal,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: any;
    },
  ): string {
    const translationResult = this.subTranslation(`global`, key, args);

    return this.validateTranslation(
      translationResult.translation,
      translationResult.key,
      key,
      'tGlobal',
      args,
    );
  }

  /**
   * Actionm translation function. The translations used point to 'actions.action.key'
   * @param key key that is used for the translation.
   * @param args arguments that are passed to the translation.
   * @returns string
   */
  public tAction(
    action: keyof TranslationActions,
    key: keyof TranslationAction,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: any;
    },
  ): string {
    if (process.env.NODE_ENV !== 'production' && key === 'failed') {
      console.trace();
    }

    const translationResult = this.subTranslation(`actions.${action}`, key, args);

    return this.validateTranslation(
      translationResult.translation,
      translationResult.key,
      key,
      'tAction',
      args,
    );
  }

  /**
   * Module translation function. The translations used point to 'platform.module.key'.
   * The translations are fetched from the corrisponding module en file if its registred in the platform
   * translation
   * @param key key that is used for the translation.
   * @param args arguments that are passed to the translation.
   * @returns string
   */
  public tModule(
    key: keyof TranslationObject,
    args?: {
      params?: Record<string, unknown>;
      choice?: number;
      entity?: string;
    },
  ): string {
    const translationResult = this.subTranslation(
      `${this._platform}.${this._module}`,
      String(key),
      args,
    );

    return this.validateTranslation(
      translationResult.translation,
      translationResult.key,
      String(key),
      'tModule',
      args,
    );
  }

  /**
   * tModuleAction translation function. The translations used point to 'platform.module.action.key',
   * this is not generic and diffrent for each module. For example if you have create "title":
   * Create datamodel, is diffrent from Create an organisation.
   * @param key key that is used for the translation.
   * @returns string
   */
  public tModuleAction(
    action: keyof TranslationObjectActions,
    key: keyof TranslationObjectAction,
  ): string {
    return this.validateTranslation(
      this._i18n.t(`${this._platform}.${this._module}.${action}.${key}`),
      `${this._platform}.${this._module}.${action}.${key}`,
      key,
      'tModuleAction',
    );
  }
}
