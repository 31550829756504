<script setup lang="ts">
import { ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { PreferencesService } from '@/general/services/preferences/preferences.service';
import { useStore } from 'vuex';
import { DebounceService } from '@/general/services/debounce-service/debounce.service';
import { ToastService } from '@/general/services/toasts/toast.service';

/* Composalbes */

/* Services */
const store = useStore();
const ts = new TranslationService('supplier', 'settings');
const debounceService = new DebounceService(1000);
const preferencesService = ref(new PreferencesService());
const toastService = ToastService.getInstance();

const scales = ref([12, 13, 14, 15, 16]);

async function updateSettings() {
  try {
    await preferencesService.value.updatePreferences();
    await store.dispatch('users/GET_CURRENT_USER_DETAILS');
    toastService.displaySuccessToast(ts.tModule('preferences.saved'));
  } catch {
    toastService.displayErrorToast(ts.updateFailed());
  }
}

const setSetting = async () => {
  debounceService.debounce(updateSettings);
};

const decrementScale = async () => {
  preferencesService.value.scale--;
  setSetting();
};

const incrementScale = async () => {
  preferencesService.value.scale++;
  setSetting();
};
</script>

<template>
  <section class="h-full pt-3 px-4">
    <h4 class="font-bold mb-1">{{ ts.tModule('preferences.title') }}</h4>
    <p-message severity="info" :closable="true">
      {{ ts.tModule('preferences.description') }}
    </p-message>
    <p-divider />
    <div class="field flex justify-content-between mb-3">
      <label>{{ ts.tModule('preferences.defaultExportMapping') }}</label>
      <p-input-switch
        v-model="preferencesService.expandExportMappings"
        class="flex-shrink-0"
        @change="setSetting"
      />
    </div>
    <div class="field flex justify-content-between mb-3">
      <label>{{ ts.tModule('preferences.defaultMasterDataProducts') }}</label>
      <p-input-switch
        v-model="preferencesService.defaultMasterDataFilter"
        class="flex-shrink-0"
        @change="setSetting"
      />
    </div>
    <div class="field flex justify-content-between mb-3">
      <label>{{ ts.tModule('preferences.defaultEmailRetailerFilled') }}</label>
      <p-input-switch
        v-model="preferencesService.autoFillRetailerDetails"
        class="flex-shrink-0"
        @change="setSetting"
      />
    </div>
    <div class="field flex justify-content-between mb-3">
      <label>{{ ts.tModule('preferences.defaultArchivedNotVisible') }}</label>
      <p-input-switch
        v-model="preferencesService.defaultArchivedNotVisible"
        class="flex-shrink-0"
        @change="setSetting"
      />
    </div>

    <div class="field flex justify-content-between mb-3">
      <label class="block mr-3">{{ ts.tModule('preferences.applicationScaling') }}</label>
      <div class="align-items-center flex">
        <p-button
          icon="mdi mdi-minus"
          text
          :disabled="preferencesService.scale === scales[0]"
          @click="decrementScale"
        />
        <i
          v-for="s of scales"
          :key="s"
          class="mdi mdi-circle mr-1 text-500"
          :class="{ 'text-primary text-3xl': s === preferencesService.scale }"
        />
        <p-button
          icon="mdi mdi-plus"
          text
          :disabled="preferencesService.scale === scales[scales.length - 1]"
          @click="incrementScale"
        />
      </div>
    </div>
  </section>
</template>
