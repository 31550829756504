<script setup lang="ts">
import AttributeEditor from 'retailer/modules/products/components/attribute-editor.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Locale } from 'platform-unit2-api/locales';
import { Attribute } from 'platform-unit2-api/attributes';
import { ProductField } from 'platform-unit2-api/product-fields';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');

/**Props */
const props = withDefaults(
  defineProps<{
    productId: number;
    loading: boolean;
  }>(),
  {
    loading: false,
  },
);

/** Consts */
const store = useStore();
const route = useRoute();

const fields = ref([]);
const attributes = ref([]);

const loadingFieldsData = ref(true);
const loadingAttributeData = ref(true);
const showMissingFields = ref(false);

const currentProduct = computed(() => store.getters['products/currentProduct']);

const selectedLocale = ref<Locale>();

const isLoading = computed(() => {
  return props.loading || loadingAttributeData.value || !selectedLocale.value;
});

const filterAttributes = (attributes: Attribute[]) => {
  if (showMissingFields.value === true) {
    const filledFields = fields.value
      .filter((field: ProductField) => field.value != null && field.value !== '')
      .map((field: ProductField) => field.attribute_id);

    return attributes.filter((attribute) => filledFields.indexOf(attribute.id) === -1);
  }

  return attributes;
};

const getFieldValue = (attributeId: number) => {
  return fields.value.find((field: ProductField) => field.attribute_id === attributeId);
};

const updateFieldValue = (attributeId: number) => {
  return async (value: any) => {
    await store.dispatch('products/SET_EDITING_FIELD_DATA', {
      attribute_id: attributeId,
      value,
      locale: selectedLocale.value!.value,
      overwrite: false,
    });
  };
};

const getAttributeData = async () => {
  loadingAttributeData.value = true;
  try {
    attributes.value = await store.dispatch(
      'products/GET_PRODUCT_CATEGORY_ATTRIBUTES',
      store.getters['products/currentProduct'].category_id,
    );
  } catch (ex) {
    toastService.displayErrorToast(ts.tModule('snackbars.loadingFailed'));
  } finally {
    loadingAttributeData.value = false;
  }
};

const getFieldData = async () => {
  loadingFieldsData.value = true;
  try {
    const response = await store.dispatch('products/GET_PRODUCT_FIELDS', {
      productId: route.params.id,
      locale: selectedLocale.value?.value || null,
    });

    fields.value = response.data;
  } catch (ex) {
    toastService.displayErrorToast(ts.tModule('snackbars.loadingFailed'));
  } finally {
    loadingFieldsData.value = false;
  }
};

watch(
  () => route,
  () => {
    if (route.params.id == null) return;

    getFieldData();
    getAttributeData();
  },
  {
    deep: true,
  },
);

onMounted(() => {
  getAttributeData();
});
</script>
<template>
  <div class="align-items-center flex mb-3">
    <LocaleSelect v-model="selectedLocale" hide-label @change="getFieldData" />
    <p-divider layout="vertical" />
    <div>
      <p class="mb-2 text-xs">{{ ts.tModule('show_missing_fields') }}</p>
      <p-input-switch v-model="showMissingFields" />
    </div>
  </div>
  <div v-if="isLoading || !currentProduct">
    <div class="flex mb-5">
      <p-skeleton height="2.5rem" width="8rem" />
    </div>
    <div class="mb-5">
      <p-skeleton height="1rem" width="10rem" class="mb-2" />
      <p-skeleton height="2rem" />
    </div>
    <div>
      <p-skeleton height="1rem" width="10rem" class="mb-2" />
      <p-skeleton height="10rem" />
    </div>
  </div>
  <div v-else>
    <p-message
      v-if="filterAttributes(attributes).length <= 0"
      :severity="currentProduct.module_id != null ? 'warn' : 'info'"
      :closable="false"
    >
      <p>{{ ts.tGlobal('warning') }}</p>
      <p v-if="currentProduct.module_id != null">
        {{ ts.tModule('no_empty_fields_module') }}
        <a href="mailto:rpxsupport@productsup.com">{{ ts.tGlobal('contact_productsup') }}</a>
      </p>
      <p v-else>
        {{ ts.tModule('no_empty_fields') }}
      </p>
    </p-message>
    <AttributeEditor
      v-for="attribute in filterAttributes(attributes)"
      v-else
      :key="attribute.id"
      :attribute="attribute"
      :field-data="getFieldValue(attribute.id)"
      :current-product="currentProduct"
      @input="(value) => updateFieldValue(attribute.id)(value)"
    />
  </div>
</template>
