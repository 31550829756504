<script setup lang="ts">
import { SupplierImportMappingItemsViewService } from '../ts/services/supplier-import-mapping-items-view.service';
import { onBeforeRouteLeave } from 'vue-router';
import { importMappingItemsServiceKey } from '../ts/keys/import-mapping-items.keys';
import { useService } from '@/general/composables/useService/useService';
import { destroyService } from '@/general/composables/useService';

import HelpAndSupportSidebar from '@/general/ui/layout/components/help-and-support-sidebar.vue';
import AccountMenu from '@/general/ui/layout/components/account-menu.vue';
import { ref } from 'vue';

const service = useService(importMappingItemsServiceKey, SupplierImportMappingItemsViewService)!;

onBeforeRouteLeave((to, __, next) => {
  if (service.deleted) {
    destroyService(importMappingItemsServiceKey);
    next();
    return;
  }

  if (service.dirtyState.isTouched()) {
    service.dirtyState.showDirtyDialog(
      () => {
        if (to.fullPath === '/productsup') {
          window.open('https://platform.productsup.com', '_blank');
        } else {
          destroyService(importMappingItemsServiceKey);
          next();
        }
      },
      undefined,
      next,
    );
  } else {
    destroyService(importMappingItemsServiceKey);

    next();
  }
});

const helpAndSupportVisible = ref(false);
const helpAndSupportToVisible = (value: boolean) => {
  helpAndSupportVisible.value = value;
};
</script>

<template>
  <div class="bg-white border-bottom pup-mt-5 pup-px-7">
    <div class="align-items-center flex justify-content-between pup-mb-7">
      <router-link :to="{ name: 'mappings' }">
        <pButton
          class="custom-button-styles p-button-outlined p-button-sm"
          icon="mdi mdi-chevron-left"
          label="Import mappings"
        />
      </router-link>
      <div class="flex">
        <pButton
          v-tooltip.bottom="{
            value: 'Help and Support',
            class: 'text-sm',
          }"
          text
          plain
          rounded
          class="pup-h-3 pup-mr-4 pup-w-3"
          @click="helpAndSupportToVisible(true)"
          ><template #icon>
            <p-image src="/src/assets/images/pup_ic_question.svg"></p-image>
          </template>
        </pButton>
        <AccountMenu />
        <!-- Sidebar, it is not visible in layout -->
        <HelpAndSupportSidebar
          :visible="helpAndSupportVisible"
          @hide="helpAndSupportToVisible(false)"
        />
      </div>
    </div>
    <div class="align-items-center flex gap-3 pb-4">
      <h3 v-if="!service.isLoadingParent">
        {{ service.parent?.name }}
      </h3>
      <p-skeleton v-else width="30rem" height="28px" />
    </div>
    <pConfirmDialog group="dirty-dialog" />
  </div>
</template>
<style lang="scss" scoped>
.border-bottom {
  border-bottom: 2px solid #e5e5e5;
}
.custom-button-styles {
  border-color: #dce5ef !important;
  color: black !important;
}
</style>
