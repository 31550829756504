<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import AttributeSelect from '@/general/ui/components/selects/attribute-select.vue';
import { Attribute } from 'platform-unit2-api/attributes';
import { useRoute } from 'vue-router';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { useSort } from '@/general/composables/useSort';
import { DatamodelService } from '../../ts/services/datamodel.service';
import { DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { isNegative } from '@/general/utils/isNegative';

/** Composables */
const route = useRoute();

/** Services */
const ts = new TranslationService('admin', 'datamodels');
const tsAttributes = new TranslationService('admin', 'attributes');
const dataModelApi = new DatamodelsRestService();

const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();

/** Constants */
const datamodelService = new DatamodelService();
const loading = ref(false);
const fields = ref<Attribute[]>([]);
const newAttributes = ref<Attribute[]>([]);
const selectedAttributeIdsToDelete = ref<number[]>([]);

const loadAsyncData = async () => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.loadFailed());
    return;
  }

  //TODO move to service
  loading.value = true;
  try {
    fields.value = (await dataModelApi.getDatamodelFieldDefinitions(Number(route.params.id))).data;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const add = async () => {
  //TODO move to service
  if (newAttributes.value?.length) {
    try {
      const bulkAttributes: Attribute[] = [...fields.value, ...newAttributes.value];

      const data = bulkAttributes.map((attr) => {
        return {
          id: attr.id,
          order: attr.order ?? 0,
        };
      });

      await dataModelApi.attachDatamodelFields(Number(route.params.id), {
        attributes: data,
      });

      toastService.displaySuccessToast(ts.tModule('attachAttributesSuccess'));
    } catch (err) {
      toastService.displayErrorToast(ts.tModule('attachAttributesFailed'));
    } finally {
      loadAsyncData();
      newAttributes.value = [];
    }
  }
};

const remove = async (fieldId: number): Promise<void> => {
  //TODO move to service
  try {
    if (isNegative(route.params.id)) {
      toastService.displayErrorToast(ts.tModule('detachAttributesFailed'));

      return;
    }

    await dataModelApi.detachDatamodelField(Number(route.params.id), fieldId);
    await loadAsyncData();

    toastService.displaySuccessToast(ts.tModule('detachAttributesSuccess'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('detachAttributesFailed'));
  }
};

const confirmFieldDelete = (event: PointerEvent, field: Attribute) => {
  confirmService.confirmDelete({
    event: event,
    callback: () => remove(field.id),
    message: ts.deleteConfirm(field.key),
    group: 'fields',
  });
};

const { sortArray } = useSort();

const sortedFields = computed(() => {
  return sortArray<Attribute>(fields.value, 'key');
});

const handleBulkDelete = async () => {
  await datamodelService.bulkDetach(
    +route.params.id,
    selectedAttributeIdsToDelete.value,
    async () => {
      await loadAsyncData();
      selectedAttributeIdsToDelete.value = [];
    },
  );
};

onMounted(async () => {
  await loadAsyncData();
});
</script>

<template>
  <div>
    <loading-indicator v-if="loading" class="mb-5" />
    <p-message v-else-if="!loading && !fields.length" :closable="false" severity="info">{{
      ts.tModule('noAttributes')
    }}</p-message>
    <div v-else-if="!loading && fields.length">
      <pButton
        icon="mdi mdi-delete-outline"
        :label="ts.deleteButton"
        text
        severity="danger"
        class="mb-4"
        :disabled="selectedAttributeIdsToDelete.length === 0"
        @click="handleBulkDelete()"
      />
      <pDataTable :value="sortedFields" :row-hover="true">
        <p-column :header="''">
          <template #body="slotProps">
            <pCheckbox
              v-model="selectedAttributeIdsToDelete"
              :value="slotProps.data.id"
              class="mr-3"
            />
          </template>
        </p-column>

        <p-column :header="ts.tGlobal('name')">
          <template #body="slotProps">
            <p
              class="cursor-pointer hover:text-primary"
              @click="
                $router.push({ name: 'edit-field', params: { id: slotProps.data.id.toString() } })
              "
            >
              {{ slotProps.data.key }}
            </p>
          </template>
        </p-column>
        <p-column :header="tsAttributes.title">
          <template #body="slotProps">
            {{ slotProps.data.options?.type ?? 'DEFAULT' }}
          </template>
        </p-column>
        <p-column :header="ts.tGlobal('actions')">
          <template #body="slotProps">
            <p-button
              icon="mdi mdi-delete-outline"
              text
              severity="danger"
              rounded
              @click="(event: PointerEvent) => confirmFieldDelete(event, slotProps.data)"
            />
          </template>
        </p-column>
      </pDataTable>
    </div>
    <pDataTable v-if="!loading && fields.length" :value="sortedFields" :row-hover="true">
    </pDataTable>
    <div class="border-round bottom-0 flex-row mt-2 p-3 sticky surface-ground">
      <div class="flex">
        <AttributeSelect
          v-model="newAttributes"
          :attributes-to-exclude="fields"
          hide-label
          style="width: 90%"
          multiselect
          @keyup.enter="add()"
        />
        <p-button icon="mdi mdi-check" class="ml-3" @click="add" />
      </div>
    </div>
    <p-confirm-dialog group="fields-dialog" />
  </div>
</template>
