<script setup lang="ts">
import { assetViewServiceKey } from '../keys/asset-view-service.key';
import { ref } from 'vue';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { useService } from '@/general/composables/useService';
import { TranslationService } from '@/general/services/translations/translation.service';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new TranslationService('supplier', 'media');
const fileUpload = ref();
</script>
<template>
  <ApplicationHeader>
    <template #createButton>
      <pFileUpload
        ref="fileUpload"
        auto
        multiple
        custom-upload
        mode="basic"
        :disabled="service.isUploading"
        :pt="{
          chooseButton: {
            class: 'hidden',
          },
        }"
        @uploader="(e: FileUploadUploaderEvent) => service.uploadAssets(e)"
      />
      <pButton
        class="font-bold headerButton primary-button"
        :label="(ts.tGlobal('upload') + ' ' + ts.title).toUpperCase()"
        rounded
        :disabled="service.isUploading"
        icon="mdi mdi-upload"
        :pt="{
          root: {
            style: { height: '40px' },
          },
          icon: {
            style: { fontSize: '20px' },
          },
          label: {
            style: { fontSize: '12px' },
          },
        }"
        @click="fileUpload.choose()"
      />
    </template>
  </ApplicationHeader>
</template>
<style>
.headerButton {
  position: absolute !important;
  margin-bottom: -5rem;
  right: 2rem;
  transform: translate(0, -50%);
  width: fit-content;
}
</style>
