<script setup lang="ts">
import { ref } from 'vue';

import CrudSidebar from 'ui/components/crud-sidebar.vue';
import useFormValidation from 'composables/form-validation';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import ColorPicker from 'ui/components/color-picker.vue';

import { Client } from 'platform-unit2-api/clients';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { CreateRetailerRequest, Retailer } from 'platform-unit2-api/retailers';
import { Thumbnail } from 'platform-unit2-api/thumbnails';
import { RetailersRestService } from 'platform-unit2-api/retailers';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';

/** Emits */
const emit = defineEmits(['hide', 'refresh']);

/** Composables */
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/** Services */
const ts = new TranslationService('admin', 'retailers');
const toastService = ToastService.getInstance();
const retailerApi = new RetailersRestService();

/** Add a new retailer */
const saving = ref<boolean>(false);
const editorRetailer = ref<Retailer>({} as Retailer);
const thumbnailValue = ref<Thumbnail>();

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const retailer: CreateRetailerRequest = {
      email: editorRetailer.value?.email ?? '',
      name: editorRetailer.value?.name ?? '',
      thumbnail_id: thumbnailValue.value?.id,
      color: editorRetailer.value?.color ?? '',
      client_ids: editorRetailer.value?.clients?.map((client: Client) => client.id) ?? [],
    };

    await retailerApi.post(retailer);
    emit('refresh');
    emit('hide');

    toastService.displaySuccessToast(ts.createSuccess());
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.createFailed());
    });
  } finally {
    saving.value = false;
  }
};

const setColor = (event: string | undefined) => {
  if (event == null || editorRetailer.value == null) {
    return;
  }

  if (editorRetailer.value != null) {
    const color = event.replace('#', '');
    editorRetailer.value.color = color;
  }
};
</script>
<template>
  <CrudSidebar
    :title="ts.moduleCreateTitle"
    :subtitle="ts.moduleCreateSubTitle"
    :saving="saving"
    @cancel="emit('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <!-- Name -->
      <div class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          v-if="editorRetailer"
          id="name"
          v-model="editorRetailer.name"
          class="w-full"
          type="text"
          :class="{ 'p-invalid': hasError('name') }"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <!-- Email -->
      <div class="field mb-3">
        <label for="email">{{ ts.tGlobal('email') }}</label>
        <p-input-text
          v-if="editorRetailer"
          id="email"
          v-model="editorRetailer.email"
          class="w-full"
          type="email"
          :class="{ 'p-invalid': hasError('email') }"
        />
        <small
          v-if="hasError('email')"
          :class="{ 'p-error block': hasError('email') }"
          class="hidden"
          >{{ fieldErrorMessage('email').toString() }}</small
        >
      </div>
      <!-- Logo -->
      <div class="field mb-3">
        <label for="logo">{{ ts.tGlobal('logo') }}</label>
        <div class="align-items-center flex formgroup-inline">
          <ThumbnailUploader
            :thumbnail="thumbnailValue"
            @uploaded="(value) => (thumbnailValue = value)"
          />
        </div>
      </div>
      <!-- Color -->
      <div class="field mb-3">
        <label for="color">{{ ts.tGlobal('color') }}</label>
        <ColorPicker
          v-if="editorRetailer"
          id="color"
          :model-value="editorRetailer.color"
          @update:model-value="(value: string | undefined) => setColor(value)"
        />
      </div>
      <!-- Client -->
      <div class="field mb-3">
        <ClientSelect
          v-if="editorRetailer"
          v-model="editorRetailer.clients"
          multiselect
          :class="{ 'p-invalid': hasError('client_ids') }"
        />
        <small
          v-if="hasError('client_ids')"
          :class="{ 'p-error block': hasError('client_ids') }"
          class="hidden"
          >{{ fieldErrorMessage('client_ids').toString() }}</small
        >
      </div>
    </template>
  </CrudSidebar>
</template>
