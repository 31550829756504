<script setup lang="ts">
import ProductFilter from 'supplier/modules/products/components/product-filter.vue';
import ProductVariants from 'supplier/modules/products/components/product-variants.vue';
import SelectLanguageModal from 'supplier/modules/products/components/select-language-modal.vue';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ProductField } from 'platform-unit2-api/product-fields';

/** Props */
const props = withDefaults(
  defineProps<{
    datamodel?: Datamodel;
    fields: ProductField[];
    isLoading: boolean;
    locales: LocaleDetails[];
    filteredLocales: LocaleDetails[];
  }>(),
  {
    datamodel: undefined,
    fields: () => [],
    isLoading: false,
    locales: () => [],
    filteredLocales: () => [],
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'update:locales', locales: LocaleDetails[]): void;
  (e: 'update:filteredLocales', locales: LocaleDetails[]): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const tsLocales = new TranslationService('supplier', 'locales');

/** Consts */
const store = useStore();
const filterActive = ref<boolean>(false);
const languageActive = ref<boolean>(false);
const currentUser = store.getters['users/currentUser'];

const calculateFieldCount = computed(() => {
  let count = 0;
  const attributeIds =
    props.datamodel?.attributes.map((attribute) => {
      return attribute.id;
    }) ?? [];

  const fieldIds = props.fields
    ?.filter((field: ProductField) => {
      return !(field.value == null || field.value === '');
    })
    .map((field) => {
      return field.attribute_id;
    });

  attributeIds.forEach((id: number) => {
    if (fieldIds.includes(id)) {
      count++;
    }
  });
  return count;
});

const filterVisible = (value: boolean): void => {
  filterActive.value = value;
};

const languageVisible = (value: boolean): void => {
  languageActive.value = value;
};
</script>
<template>
  <section>
    <ProductFilter
      v-if="filterActive"
      :is-active="filterActive"
      :locales="locales"
      :filtered-locales="filteredLocales"
      @update:filtered-locales="(value) => emit('update:filteredLocales', value)"
      @hide="filterVisible(false)"
    />
    <div class="bg-white border-200 border-bottom-1 sticky top-0 z-2">
      <div class="align-items-center flex justify-content-between mb-3">
        <h3 v-if="datamodel && $route.name === 'product-attributes'">
          {{ datamodel?.name }}
          <h5 class="inline-block m-0 text-gray-400">
            ({{ calculateFieldCount }}/{{ datamodel.attributes_count }})
          </h5>
        </h3>
        <h3 v-else-if="$route.name === 'product-assets' || $route.name === 'product-story'">
          {{ $route.name === 'product-assets' ? ts.tGlobal('assets') : ts.tModule('story') }}
        </h3>
        <div
          v-if="currentUser.workspace.workspace_type?.type !== ClientTypeEnum.RETAILER"
          class="flex"
        >
          <p-button
            plain
            outlined
            icon="mdi mdi-translate"
            :label="tsLocales.title"
            :disabled="$route.name === 'product-assets' || $route.name === 'product-story'"
            @click="languageVisible(true)"
          />
          <p-divider layout="vertical" />
          <p-button
            plain
            outlined
            icon="mdi mdi-filter-variant"
            :label="ts.tModule('filters')"
            :disabled="$route.name === 'product-assets' || $route.name === 'product-story'"
            @click="filterVisible(true)"
          />
        </div>
      </div>
      <ProductVariants />
    </div>
    <!-- Language Dialog -->
    <SelectLanguageModal
      v-if="languageActive"
      :is-active="languageActive"
      :locales="locales"
      @update:locales="(value) => emit('update:locales', value)"
      @hide="languageVisible(false)"
    />
  </section>
</template>
