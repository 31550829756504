import {
  AttributeField,
  ProductAttributeField,
  FieldErrorEnum,
  ProductAttributeFieldError,
} from 'platform-unit2-api/attribute-fields';
import { AttributeFieldService } from './attribute-field.service';
import { FieldFunctions } from './interfaces/field-functions.interface';

export abstract class MaxLengthAttributeFieldService extends AttributeFieldService<
  string | undefined
> {
  private _maxLength: string | undefined;

  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this._maxLength = attributeField.attribute.options.maxLength;
    this.validate();
  }

  get maxLength(): number | undefined {
    return this._maxLength ? Number(this._maxLength) : undefined;
  }

  public textAreaLength(): number {
    return this.productAttributeField.value ? this.productAttributeField.value.length : 0;
  }

  /**
   * Generic validation for maximum length of field
   */
  public validate(): boolean {
    this._productAttributeField.errors = [];

    // Validate if field length is 0 and field is required
    if (this._attributeField.attribute.required && this.textAreaLength() === 0) {
      this._productAttributeField.errors.push({
        message: this.ts.t('validation.required'),
        severity: FieldErrorEnum.WARNING,
      } as ProductAttributeFieldError);
    }

    // Additional check for maxLength
    if (
      this.maxLength != null &&
      !Number.isNaN(this.maxLength) &&
      this.productAttributeField.value != null &&
      this.textAreaLength() > this.maxLength
    ) {
      this.productAttributeField.errors.push({
        message: this.ts.t('validation.max.string', {
          params: { max: this.maxLength },
        }),
        severity: FieldErrorEnum.WARNING,
      } as ProductAttributeFieldError);
    }

    return true;
  }
}
