<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  global: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

/** Consts */

const state = ref<boolean | null>();

const changeValue = () => {
  emit('update:modelValue', `${state.value}`);
};

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue === 'true') {
      state.value = true;
    } else if (props.modelValue === 'false') {
      state.value = false;
    } else {
      state.value = null;
    }
  },
);

onMounted(() => {
  if (props.modelValue) {
    if (props.modelValue === 'true') {
      state.value = true;
    } else if (props.modelValue === 'false') {
      state.value = false;
    } else {
      state.value = null;
    }
  }
});
</script>
<template>
  <span class="bg-white p-inputgroup-addon">
    <p-tri-state-checkbox v-if="!disabled" v-model="state" @change="changeValue" />

    <p-tri-state-checkbox v-else v-model="state" disabled />
  </span>
</template>
