import { actions } from 'admin/modules/locales/store/locales.actions';
import { getters } from 'admin/modules/locales/store/locales.getters';
import { mutations } from 'admin/modules/locales/store/locales.mutations';
import { Module } from 'vuex';
import { LocaleState } from 'admin/modules/locales/locales.types';

const namespaced = true;
export const state: LocaleState = {
  selectedLocale: undefined,
};

export const localesStore: Module<LocaleState, {}> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
