import {
  ScheduledImportsRestService,
  CreateScheduledImport,
  UpdateScheduledImport,
  ScheduledImport,
  CreateScheduledImportSettings,
} from 'platform-unit2-api/scheduled-import';
import { ScheduledPipelinesRestService } from 'platform-unit2-api/scheduled-pipelines';
import { BaseViewService } from '../view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import { stringToUpperCase } from '@/general/utils/string-to-upper-case';
import { User } from 'platform-unit2-api/users';
import store from '@/core/store';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';

export class ScheduledImportViewService extends BaseViewService<
  ScheduledImportsRestService,
  ScheduledImport,
  CreateScheduledImport,
  UpdateScheduledImport & { id: number }
> {
  shouldDisableNextCron = false;
  pipelineRest = new ScheduledPipelinesRestService();
  multipleVariants;
  get childRoutes() {
    return [
      {
        label: this._ts.tGlobal('settings'),
        to: {
          name: `${this.isCreating ? 'new' : 'update'}-scheduled-import-settings`,
          params: { id: this.partialObject.id },
        },
      },
      {
        label: this._ts.tModule('import_scheduled.frequency'),
        to: {
          name: `${this.isCreating ? 'new' : 'update'}-scheduled-import-upload`,
          params: { id: this.partialObject.id },
        },
      },
      {
        label: stringToUpperCase(this._ts.tGlobal('optional')),
        to: {
          name: `${this.isCreating ? 'new' : 'update'}-scheduled-import-optional`,
          params: { id: this.partialObject.id },
        },
      },
    ];
  }

  constructor(ts?: TranslationService) {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;
    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    super({
      Api: ScheduledImportsRestService,
      fetchAllFunction: 'getAll',
      confirmPopUpGroup: 'scheduled-import',
      createRouteName: 'new-scheduled-import-settings',
      overviewRouteName: 'import-scheduled',
      updateRouteName: 'update-scheduled-import-settings',
      ts:
        ts ??
        new TranslationService(
          currentSpace.toLowerCase() as 'admin' | 'retailer' | 'supplier' | 'general' | 'global',
          'imports',
        ),
    });
    this.multipleVariants = Boolean(this.partialObject.settings?.variant_column);
  }
  public get scheduledImports() {
    return this.data;
  }
  public get partialObject(): Partial<ScheduledImport> {
    if (this._currentObject == null) {
      this._currentObject = {
        settings: {} as CreateScheduledImportSettings,
      };
    }

    return this._currentObject;
  }
  get dialogHeader() {
    return this.isCreating
      ? this._ts.tModule('import_scheduled.new.title')
      : this._ts.tModule('import_scheduled.edit.title');
  }

  public get isCreating(): boolean {
    return (this._route.name?.toString() ?? '').includes('new');
  }

  public get disableNextButtons() {
    return [
      {
        pageIndex: 0,
        disableNextStep:
          this.partialObject.module == null ||
          this.partialObject.locale == null ||
          this.partialObject.mapping == null,
      },
      {
        pageIndex: 1,
        disableNextStep: this.shouldDisableNextCron,
      },
      {
        pageIndex: 2,
        disableNextStep:
          (!this.partialObject.settings?.import_assets &&
            !this.partialObject.settings?.import_data) ||
          ((this.partialObject.settings.variant_column == null ||
            this.partialObject.settings.variant_column === '') &&
            this.multipleVariants) ||
          this.disableSave,
      },
    ];
  }

  public handleDisableNextButton(value: { daily: boolean; weekly: boolean; monthly: boolean }) {
    this.shouldDisableNextCron = Object.values(value).every((val) => val);
  }

  public createBody(): CreateScheduledImport | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    return {
      client_id: this.partialObject.client?.id,
      module_id: this.partialObject.module!.id,
      cron: this.partialObject.cron!,
      locale_id: this.partialObject.locale!.id,
      mapping_id: this.partialObject.mapping!.id,
      name: this.partialObject.name!,
      settings: {
        update_variants: this.partialObject.update_variants ?? false,
        update_brand: this.partialObject.settings?.update_brand ?? false,
        update_categories: this.partialObject.settings?.update_categories ?? false,
        update_title: this.partialObject.settings?.update_title ?? false,
        import_assets: this.partialObject.settings?.import_assets ?? false,
        import_data: this.partialObject.settings?.import_data ?? false,
        mail_to: [],
        is_paused: this.partialObject.is_paused ?? false,
        trim_ean: this.partialObject.settings?.trim_ean ?? false,
        variant_column: this.partialObject.settings?.variant_column ?? '',
        dry_run: this.partialObject.settings?.dry_run ?? false,
        custom_message: this.partialObject.settings?.custom_message ?? '',
        overwrite_data: this.partialObject.settings?.overwrite_data ?? false,
      },
    };
  }
  public updateBody(): (UpdateScheduledImport & { id: number }) | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    return {
      id: this.partialObject.id!,
      client_id: this.partialObject.client?.id,
      module_id: this.partialObject.module!.id,
      cron: this.partialObject.cron!,
      locale_id: this.partialObject.locale!.id,
      mapping_id: this.partialObject.mapping!.id,
      name: this.partialObject.name!,
      settings: {
        update_variants: this.partialObject.update_variants ?? false,
        update_brand: this.partialObject.settings?.update_brand ?? false,
        update_categories: this.partialObject.settings?.update_categories ?? false,
        update_title: this.partialObject.settings?.update_title ?? false,
        import_assets: this.partialObject.settings?.import_assets ?? false,
        import_data: this.partialObject.settings?.import_data ?? false,
        mail_to: [],
        is_paused: this.partialObject.is_paused ?? false,
        trim_ean: this.partialObject.settings?.trim_ean ?? false,
        variant_column: this.partialObject.settings?.variant_column ?? '',
        dry_run: this.partialObject.settings?.dry_run ?? false,
        custom_message: this.partialObject.settings?.custom_message ?? '',
        overwrite_data: this.partialObject.settings?.overwrite_data ?? false,
      },
    };
  }

  public get resolveCrudComponentCondition(): boolean {
    return this.shouldResolveForRoute();
  }

  public resolveCrudComponent(): void {
    if (this.resolveCrudComponentCondition) {
      super.openCrudComponent();
    }
  }

  shouldResolveForRoute() {
    const prevRoute: string = (this._router.options.history.state['back'] as string) ?? '';

    return (
      this.childRoutes.map((child) => child.to.name).includes(this._route.name?.toString() ?? '') &&
      prevRoute === '/import/scheduled'
    );
  }

  public get validated(): boolean {
    return (
      this.partialObject.name != null &&
      this.partialObject.module != null &&
      this.partialObject.locale != null &&
      this.partialObject.mapping != null &&
      this.partialObject.cron != null
    );
  }

  public async restartScheduledPipeline(id?: number): Promise<void> {
    if (!id) {
      return;
    }

    this._isLoadingOverview = true;

    try {
      await this.pipelineRest.restartScheduledExport(id);
      this._toastService.displaySuccessToast(
        this._ts.tModule('import_scheduled.restarted_successfully'),
      );
      await this.refetch();
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.tModule('import_scheduled.restart_failed'));
    } finally {
      this._isLoadingOverview = false;
    }
  }

  public async togglePipelineRunningState(id: number, isPaused: boolean) {
    this._isLoadingOverview = true;

    try {
      await this._restService.togglePipelineRunningState([
        { pipeline_id: id, is_paused: isPaused },
      ]);
      this._toastService.displaySuccessToast(this._ts.updateSuccess());
      await this.refetch();
    } catch (err) {
      this._toastService.displayErrorToast(this._ts.updateFailed());
    } finally {
      this._isLoadingOverview = false;
    }
  }

  public deleteAction(id: number): void {
    this._confirmService.confirmDelete({
      callback: () => {
        this._restService
          .delete(id)
          .then((_) => {
            this._isLoadingOverview = true;
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this.refetch();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'scheduled-import',
    });
  }
}
