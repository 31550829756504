<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import moment from 'moment';
import { Activity } from 'platform-unit2-api/activities';
import { computed, onMounted, ref } from 'vue';

/** Props */
const props = defineProps<{
  activity: Activity[];
}>();

// Services
const ts = new TranslationService('retailer', 'activities');

/** constants */
const activities = ref<Activity[]>([]);
const statusChange = ref<Activity>();
const storyChanges = ref<Activity>();

/** check if an activity is media changes  */
const isMedia = computed(() => {
  return activities.value[0]?.modified?.thumbnail_filename != null;
});

/** Remove status changes and from the activities */
onMounted(() => {
  statusChange.value = props.activity.find((activity) => activity.modified?.product_status_id);
  storyChanges.value = props.activity.find((activity) => activity.modified?.data);
  // Remove status changes and from the activities
  activities.value = props.activity.filter(
    (activity) => !activity.modified?.product_status_id && !activity.modified?.data,
  );
});

/** modified object returns all the details related to attributes.cretaing a blacklist to remove unneccessary keys */
const blackList = [
  'attribute_id',
  'id',
  'locale_id',
  'locked',
  'product_id',
  'type',
  'product_status_id',
];

/** Find the attributes in object */
const getAttribute = (activity: Activity) => {
  const attr = Object.keys(activity.modified ?? {}).find((key) => !blackList.includes(key));
  return attr
    ? {
        name: attr,
        new: activity.modified?.[attr]?.new,
        old: activity.modified?.[attr]?.old,
      }
    : {};
};
</script>

<template>
  <div v-if="activities.length" class="flex">
    <!-- #region: label -->
    <div class="flex flex-column">
      <div
        class="align-items-center border-circle flex font-bold justify-content-center text-white text-xl"
        :class="isMedia ? 'bg-orange-400' : 'bg-primary'"
        :style="{ width: '40px', aspectRatio: '1' }"
      >
        {{ activities[0]?.user?.charAt(0).toLocaleUpperCase() }}
      </div>
      <div class="flex flex-grow-1 justify-content-center line py-1">
        <div class="border-1 border-gray-200" :style="{ width: '0px' }"></div>
      </div>
    </div>
    <!--  #endregion -->

    <!-- #region: activity details -->
    <div class="body flex-grow-1 px-3 text-gray-600">
      <!-- Title -->
      <div class="mb-3">
        {{ activities[0]?.user }}
        <span class="font-bold text-gray-800">
          {{
            isMedia
              ? ts.tModule('userChangedMedia')
              : ts.tModule('userUpdatedAttributes', { params: { count: activities.length } })
          }}:
        </span>
      </div>
      <!-- details -->
      <div v-if="isMedia" class="mb-3">{{ ts.tModule('mediaChanges') }}</div>
      <div v-else class="mb-3">
        <div
          v-for="currentActivity in activities"
          :key="currentActivity.id"
          class="bg-gray-50 m-2 p-2"
        >
          <div v-if="currentActivity.event == 'attach'">
            {{ getAttribute(currentActivity)?.name }} <br />
            {{ ts.tGlobal('from') }} '{{ getAttribute(currentActivity)?.old ?? '_' }}'
            {{ ts.tGlobal('to') }} ' {{ currentActivity.modified?.upload?.new?.filename }} '
          </div>
          <div v-else>
            {{ getAttribute(currentActivity)?.name }} <br />
            {{ ts.tGlobal('from') }} '{{ getAttribute(currentActivity)?.old ?? '_' }}'
            {{ ts.tGlobal('to') }} '{{ getAttribute(currentActivity)?.new }}'
          </div>
        </div>
      </div>
      <!-- Time -->
      <div class="mb-4 text-gray-500 text-sm time">
        {{ moment(activities[0]?.created_at).format('DD MMM Y, HH:mm') }}
      </div>
    </div>
    <!-- #endregion -->
  </div>

  <!-- If product status has changed -->
  <div v-if="statusChange" class="flex">
    <!-- #region: label -->
    <div class="flex flex-column">
      <div
        class="align-items-center bg-purple-500 border-circle flex font-bold justify-content-center text-white text-xl"
        :style="{ width: '40px', aspectRatio: '1' }"
      >
        {{ statusChange.user?.charAt(0).toLocaleUpperCase() }}
      </div>
      <div class="flex flex-grow-1 justify-content-center line py-1">
        <div class="border-1 border-gray-200" :style="{ width: '0px' }"></div>
      </div>
    </div>
    <!--  #endregion -->

    <!-- #region: activity details -->
    <div class="body flex-grow-1 px-3 text-gray-600">
      <!-- Title -->
      <div class="mb-3">
        {{ statusChange.user }}
        <span class="font-bold text-gray-800">{{ ts.tModule('userChangedProductStatus') }}:</span>
      </div>
      <!-- details -->
      <div v-if="isMedia" class="mb-3">Media changes</div>
      <div v-else class="mb-3">
        {{ ts.tGlobal('from') }} '{{ statusChange.modified?.productstatus_label.old ?? '_' }}'
        {{ ts.tGlobal('to') }} '{{ statusChange.modified?.productstatus_label.new }}'
      </div>
      <!-- Time -->
      <div class="mb-4 text-gray-500 text-sm time">
        {{ moment(statusChange.created_at).format('DD MMM Y, HH:mm') }}
      </div>
    </div>
    <!-- #endregion -->
  </div>

  <!-- If Story has changed -->
  <div v-if="storyChanges" class="flex">
    <!-- #region: label -->
    <div class="flex flex-column">
      <div
        class="align-items-center bg-green-500 border-circle flex font-bold justify-content-center text-white text-xl"
        :style="{ width: '40px', aspectRatio: '1' }"
      >
        {{ storyChanges.user?.charAt(0).toLocaleUpperCase() }}
      </div>
      <div class="flex flex-grow-1 justify-content-center line py-1">
        <div class="border-1 border-gray-200" :style="{ width: '0px' }"></div>
      </div>
    </div>
    <!--  #endregion -->

    <!-- #region: activity details -->
    <div class="body flex-grow-1 px-3 text-gray-600">
      <!-- Title -->
      <div class="mb-3">
        {{ storyChanges.user }}
        <span class="font-bold text-gray-800">{{ ts.tModule('userAddedNewStory') }}:</span>
      </div>
      <!-- details -->
      <div v-if="isMedia" class="mb-3">{{ ts.tModule('mediaChanges') }}</div>
      <div v-else class="mb-3">'{{ storyChanges.modified?.data.new }}'</div>
      <!-- Time -->
      <div class="mb-4 text-gray-500 text-sm time">
        {{ moment(storyChanges.created_at).format('DD MMM Y, HH:mm') }}
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>
