import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Workspace',
  subTitle: 'Here you can create a new Workspace.',
};

const update: TranslationObjectAction = {
  title: 'Edit Workspace',
  subTitle: 'Here you can edit an Workspace.',
};

const translationObject: TranslationObject = {
  title: 'Workspace | Workspaces',
  placeholder: 'Select Workspace | Select Workspaces',

  general_contact: 'General contact',
  financial_contact: 'Financial contact',
  has_public_api: 'Enable API key feature',
  hasAiScanning: 'Enable AI scanning',
  api_warning_dialog_header: 'Disable API key feature',
  api_warning_dialog_content:
    'You are about to disable the API feature for this workspace. The APl connection will be broken and the settings will be lost. This action cannot be undone.',
  api_warning_dialog_confirm: 'Disable',

  create: create,
  update: update,
};

const tranlation: Translation = {
  clients: translationObject,
};

export default tranlation;
