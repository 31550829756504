<script setup lang="ts">
import ProductVariants from 'retailer/modules/products/components/product-variants.vue';
import { computed } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ProductField } from 'platform-unit2-api/product-fields';
import { LocaleDetails } from 'platform-unit2-api/locales';

/** Props */
const props = withDefaults(
  defineProps<{
    datamodel?: Datamodel;
    fields: ProductField[];
    isLoading: boolean;
    showShadow: boolean;
    locales: LocaleDetails[];
    filteredLocales: LocaleDetails[];
  }>(),
  {
    fields: () => [],
    isLoading: false,
    showShadow: false,
    locales: () => [],
    filteredLocales: () => [],
    datamodel: undefined,
  },
);

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */

const calculateFieldCount = computed(() => {
  let count = 0;
  const attributeIds =
    props.datamodel?.attributes.map((attribute) => {
      return attribute.id;
    }) ?? [];

  const fieldIds = props.fields
    ?.filter((field: ProductField) => {
      return !(field.value == null || field.value === '');
    })
    .map((field) => {
      return field.attribute_id;
    });

  attributeIds.forEach((id: number) => {
    if (fieldIds.includes(id)) {
      count++;
    }
  });
  return count;
});
</script>
<template>
  <section>
    <div class="bg-white border-200 border-bottom-1 sticky top-0 z-2">
      <div class="align-items-center flex justify-content-between mb-3">
        <h3 v-if="datamodel && $route.name === 'product-attributes'">
          {{ datamodel?.name }}
          <h5 class="inline-block m-0 text-gray-400">
            ({{ calculateFieldCount }}/{{ datamodel.attributes_count }})
          </h5>
        </h3>
        <h3 v-else-if="$route.name === 'product-assets' || $route.name === 'product-story'">
          {{ $route.name === 'product-assets' ? ts.tGlobal('assets') : ts.tModule('story') }}
        </h3>
      </div>
      <ProductVariants />
    </div>
  </section>
</template>
