import { useDispatch } from '@/general/composables/UseDispatch';
import { deleteActionProps } from '@/general/composables/UseDelete/types';
import { Ref } from 'vue';
import { DisplayToastSetting } from '@/general/composables/UseDispatch/types';
import { TranslationService } from '@/general/services/translations/translation.service';

/**
 * loading and generic delete function for executing a dispatch.
 * @returns Loading Ref and deleteAction callback
 */
export function useDelete(): {
  loading: Ref<boolean>;
  deleteAction: (params: deleteActionProps) => void;
} {
  // Get Loading ref and dispatch function
  const ts = new TranslationService('general', 'components');
  const { dispatch, loading } = useDispatch();

  /**
   * Function is called deleteAction, delete is a code language default.
   * The function deletes an object set in the parameters.
   * @param action action store.action name
   * @param deleteProps properties that set behaivior of the use update (dispatch)
   */
  function deleteAction({ action, options }: deleteActionProps): void {
    dispatch(action, {
      parameters: { id: options.id },
      toast: {
        displaySetting: DisplayToastSetting.BOTH,
        successMessage: options.successMessage ?? ts.deleteSuccess(),
        rejectMessage: options.rejectMessage ?? ts.tModule('composables.defaultDeleteReject'),
      },
      successCallback: options.success,
      rejectCallback: options.reject,
    });
  }

  //Return delete function and loading ref.
  return { loading, deleteAction };
}
