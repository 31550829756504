<script setup lang="ts">
import { ProductField } from 'platform-unit2-api/product-fields';
import { computed } from 'vue';
import { ProductDatamodelSelectModel } from '../ts/products.types';
import ProgressBar from '@/general/ui/components/progress-bar.vue';

/** Props */
interface Props {
  datamodel?: ProductDatamodelSelectModel;
  fields: ProductField[];
  isSelected: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  datamodel: undefined,
  fields: () => [],
  isSelected: false,
});

const calculateFieldCount = computed(() => {
  let count = 0;
  const attributeIds = props.datamodel?.attributes.map((attribute) => {
    return attribute.id;
  });
  const fieldIds = props.fields
    ?.filter((field: ProductField) => {
      return !(field.value == null || field.value === '');
    })
    .map((field: ProductField) => {
      return field.attribute_id;
    });
  attributeIds?.forEach((id: number) => {
    if (fieldIds.includes(id)) {
      count++;
    }
  });
  return count;
});
</script>
<template>
  <div
    id="datamodel-select"
    class="border-1 border-200 border-round cursor-pointer flex flex-column hover:bg-primary mb-3 p-3"
    :class="isSelected ? 'bg-primary' : 'bg-white'"
  >
    <h5 :class="datamodel?.hide_options ? 'm-0' : 'm-0 mb-2'">{{ datamodel?.name }}</h5>
    <ProgressBar
      v-if="!datamodel?.hide_options"
      :progress="(calculateFieldCount / (datamodel?.attributes_count ?? 1)) * 100"
      :label="calculateFieldCount + '/' + datamodel?.attributes_count"
      :tooltip="Math.round((calculateFieldCount * 100) / (datamodel?.attributes_count ?? 1)) + '%'"
      :class="isSelected ? 'is-selected' : ''"
    />
  </div>
</template>
<style lang="scss" scoped>
#datamodel-select:hover :deep(.p-progressbar) {
  background-color: #0066c7;
}
#datamodel-select:hover :deep(.p-progressbar .p-progressbar-value) {
  background-color: #ffffff;
}

.is-selected :deep(.p-progressbar) {
  background-color: #0066c7;
}
.is-selected :deep(.p-progressbar .p-progressbar-value) {
  background-color: #ffffff;
}
</style>
