import { actions } from 'admin/modules/roles/store/roles.actions';
import { Module } from 'vuex';

const namespaced = true;
export const state: {} = {};

export const rolesStore: Module<{}, {}> = {
  namespaced,
  state,
  actions,
};
