import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create data model',
  subTitle: 'Here you can create a new data model.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete data model',
};

const update: TranslationObjectAction = {
  title: 'Update the data model',
  subTitle: 'You can change the name and attributes in this data model.',
};

const translationObject: TranslationObject = {
  title: 'Data model | Data models',
  placeholder: 'Select Data model | Select Data models',
  emptyStateSubtitle:
    'Start by creating your first data model. When your data model is created it will show here.',

  create: create,
  delete: deleteTranslation,
  update: update,

  attributes_config: 'Attributes config',
  attributes_added: 'Attributes added',
  attributes_overwritten: 'Overwritten',
  change_status_successful: 'Successfully {status} the attributes',
  enabled: 'enabled',
  select_template: 'Select a template',
  disabled: 'disabled',
  reset_override: 'Reset to default values',
  confirm_reset_override: 'Are you sure you want to reset this overwrite?',
  override: 'Overwrite',
  override_rejected: 'Unable to remove overwrite',
  override_deleted: 'Successfully removed the overwrite',
  cancel: 'Cancel',
  create_overwrite: 'Create overwrite',
  update_overwrite: 'Update overwrite',
  rename: 'Rename',
  warning_removing_attributes:
    'Removing attributes from a data model deletes existing import mappings for the attribute',
  configuration: {
    dialog: {
      title: "Configure '{name}' attribute.",
      overrideHeader: 'Overwrite',
      defaultHeader: 'Set default value',
    },
    override: {
      informationText:
        'To change the default values of the attribute, add your edits in the Overwrite section and select Update.',
    },
    default: {
      fieldHeader: 'Default fields',
      informationText:
        'The values you enter below will be set as default for the current attribute',
      optionInformation: 'The options below are affecting only the current locale',
      allCheckBox: 'Apply to all fields',
      existingCheckBox: 'Apply to existing default values',
      emptyCheckBox: 'Apply to empty fields',
      editedCheckBox: 'Apply to edited fields',
      succes_delete: 'Successfully deleted default field for locale: {locale}',
      delete_not_found: 'Given locale has no attached default field',
      delete_confirm_header: 'Delete default field',
      delete_confirm_message: 'Are you sure you want to delete this default field?',
      delete_failed: 'Failed to delete default field',
      succes_update: 'Successfully updated default fields',
    },
  },

  datamodelRename: {
    subtitle: 'Here you can rename a data model.',

    title: 'Rename data model',
    succes: 'Data model successfully renamed to {name}',
  },
  datamodels_overwrite: {
    header: {
      name_prepend: 'Overwrite',
      name_append: 'attribute',
      info_text:
        'You see the default values of the attribute on the left which you can overwrite on the right.',
    },
    body: {
      default: 'Default',
      name: 'Name:',
      field_type: 'Field Type:',
      required: 'Required',
      help_text: 'Help text:',
      max_length: 'Max length',
    },
    form: {
      overwrite: 'Overwrite',
      choices: 'Choices',
      pre_defined_options: 'pre-defined options',
      input_select_values: 'input select values',
      choose_default_unit: 'choose default unit',
      currencies: 'currencies',
      choose_default_currency: 'Choose default currency',
      select_type: 'Type',
    },
  },
  datamodel_attributes: {
    name: 'Attributes',
    no_attributes: "You don't have any field attributes yet. Please add one first",
    reordering_failed: 'Failed to reorder the row. Please try again.',
    saving_error: "We couldn't save the attributes",
    adding: 'Start adding attributes',
    reordered: 'Rows Reordered',
    select_datamodel: 'Select an existing data model to see the attributes',
    no_attributes_in_selected_datamodel: 'There is no attributes in the selected data model(s)',
    taken_from_datamodel: ' From data model "{name}"',
    attribute_id: 'Attribute ID',
    no_attributes_found: 'No attributes found',
    no_attributes_found_description: 'No attributes found for the given search term',
  },
  datamodel_edit: {
    title: 'Edit data model',
    subtitle: 'Here you can edit an data model.',
  },
  datamodel_delete: {
    title: 'Delete data model',
    subtitle: 'Here you can delete an data model.',
  },
  datamodel_new: {
    title: 'New data model',
    subtitle: 'Here you can create a new data model.',
  },
  datamodel_inherited: {
    no_inherited: "You don't have any inherited data model yet. Please add one first",
  },
  datamodel_duplicate: {
    title: 'Duplicate data model',
    subtitle: 'Here you can duplicate a data model.',
    modal_client_placeholder: 'Select a workspace ',
    duplicate_import_mapping: 'Duplicate import mapping',
    duplicate_export_mapping: 'Duplicate export mapping',
    success: 'Successfully duplicated the data model.',
    fail: 'Failed to duplicate the data model. Please try again.',
  },
  duplicate_datamodels: {
    title: 'Duplicate data model',
    subtitle: 'Here you can duplicate a data model.',
    success: 'Successfully duplicated the data model.',
    failed: 'Failed to duplicate the data model. Please try again.',
  },
  table_header: {
    name: '@:global.name',
    options: '@:global.options',
    public: '@:global.public',
  },
  datamodel_select: {
    placeholder: 'Select a data model | Select data models',
  },
  datamodel_update: {
    title: 'Update the data model',
    detail: 'You can change the name and attributes in this data model.',
  },
  datamodel_name: {
    title: 'Name the data model',
    detail: 'What should we call this data model?',
  },
  datamodel_create: {
    title: 'Create a new data model',
    details:
      'We need attributes to create a new data model. Select them from predefined data model on the left.',
  },
  datamodel_template_endpoint_error: {
    detail: 'unable to get template endpoints',
  },

  datamodel_info_header: {
    date_created: 'Date Created',
    date_updated: 'Date Updated',
    attribute_count: 'Attributes',
  },
  predefined_datamodels: {
    title: 'Predefined data models',
  },
  datamodel_download: {
    title: 'Download data model',
  },
  datamodel_empty: {
    title: 'New data model is empty',
    subtitle: 'You can add attributes from the left side to this data model.',
  },
};

const translation: Translation = {
  datamodelsConfig: translationObject,
};

export default translation;
