<script setup lang="ts">
import { onMounted, ref, watch, watchEffect } from 'vue';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

/** Consts */
const inputValue = ref<string[]>([]);

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      inputValue.value = props.modelValue.split(', ');
    }
  },
);

const prepareInputValue = () => {
  if (props.modelValue === '' || props.modelValue == null) {
    inputValue.value = [];
    return;
  }

  const match = props.modelValue.match(/[^,\s][^\,]*[^,\s]*/gm);

  if (!match) {
    inputValue.value = [];
    return;
  }

  inputValue.value = Array.from(match);
};

const valueUpdated = () => {
  emit('update:modelValue', inputValue.value.join(', '));
};

watchEffect(() => {
  props.modelValue ? prepareInputValue() : (inputValue.value = []);
});

onMounted(() => {
  prepareInputValue();
});
</script>

<template>
  <p-chips
    v-model="inputValue"
    :disabled="disabled"
    :auto-resize="true"
    @update:model-value="valueUpdated"
  />
</template>
