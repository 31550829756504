import { FeaturePermission } from 'platform-unit2-api/roles';
import IncompleteProductsListView from 'supplier/modules/incomplete-products/views/incomplete-products-list.vue';
import ModuleEdit from 'admin/modules/modules/components/module-edit.vue';
import ModuleNew from 'admin/modules/modules/components/module-new.vue';
import Modules from 'admin/modules/modules/views/modules.view.vue';
import CredentialEdit from 'supplier/modules/modules/components/credential-edit.vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';

const modulesRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/channels',
    name: 'modules',
    component: Modules,
    title: 'Channels',
    translations: {
      module: 'modules',
      platform: 'admin',
    },
    rights: [FeaturePermission.MANAGE_MODULES],
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-module',
        component: ModuleNew,
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'edit-module',
        component: ModuleEdit,
      }),
      RouteFactory.createChildRoute({
        path: 'credentials/:id',
        name: 'new-credentials',
        component: CredentialEdit,
      }),
    ],
  }),
  RouteFactory.createAppRoute({
    path: '/channels/:id/incomplete',
    name: 'module-products-incomplete',
    component: IncompleteProductsListView,
    title: 'Incomplete products',
    rights: [FeaturePermission.MANAGE_PRODUCTS],
  }),
];

export default modulesRoutes;
