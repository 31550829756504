<script setup lang="ts">
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import { computed, onMounted, ref, watchEffect } from 'vue';
import useAttributeOption from 'utils/attribute-option';
import { TranslationService } from '@/general/services/translations/translation.service';
import { MultipleChoiceFieldOptions } from 'supplier/modules/attributes/ts/attributes.types';

/** Props */
interface Props {
  modelValue: string;
  disabled: boolean;
  global: boolean;
  locale?: LocaleDetails;
  moduleId?: number;
  options?: MultipleChoiceFieldOptions;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  global: false,
  locale: undefined,
  moduleId: undefined,
  options: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const { getFieldDefinitionOption } = useAttributeOption();

const selectedOptions = ref<Record<'name', string>[]>([]);

const choices = computed(() => {
  const items = getFieldDefinitionOption(props.options, 'choices', props.moduleId) || [];
  return items.map((item: any) => {
    return {
      name: item,
    };
  });
});

watchEffect(() => {
  selectedOptions.value = [];
  if (props.modelValue == null || props.modelValue === '') {
    return;
  } else {
    props.modelValue.split(/[,]{1}[\s]?/gm).forEach((element) => {
      selectedOptions.value.push({ name: element });
    });
  }
});

onMounted(() => {
  if (props.modelValue == null || props.modelValue === '') {
    return;
  }

  selectedOptions.value = [];
  props.modelValue.split(/[,]{1}[\s]?/gm).forEach((element) => {
    selectedOptions.value.push({ name: element });
  });
});

const valueUpdated = () => {
  const items: string[] = [];
  Object.values(selectedOptions.value).forEach((element) => {
    items.push(element.name);
  });
  emit('update:modelValue', items.join(', '));
};
</script>
<template>
  <div class="p-inputgroup relative">
    <pMultiselect
      v-model="selectedOptions"
      :options="choices"
      :filter="true"
      :disabled="disabled"
      :show-clear="true"
      class="w-full"
      option-label="name"
      @change="valueUpdated"
    >
      <template #empty> {{ ts.notFoundWarning }}</template>
    </pMultiselect>
  </div>
</template>
